import { TiDelete } from "react-icons/ti";
import {
  defaultFilterIncidents,
  getFlagPriorityIncident,
  getFormatDate,
  getIconStatusIncident,
  getNameIncidentsPriority,
  getNameIncidentsStatus,
  getNameIncidentsTypes,
  translate,
} from "../../../../../utils/Common";

const SelectedFilters = (props) => {
  const { filterOptions, setFilterOptions } = props;
  const viewOptionsFilter = Object.entries(filterOptions).every(([key, array]) => {
    // Omitir startDate y endDate
    if (key === 'startDate' || key === 'endDate') {
      return true;
    }

    return array.length === 0;
  });

  const handleDeleteFilter = (filterKey, value) => {
    let newFilterOptions = { ...filterOptions };
    newFilterOptions[filterKey] = newFilterOptions[filterKey].filter(
      (item) => item !== value
    );
    setFilterOptions(newFilterOptions);
  };

  // VALIDA SI NO HAY FILTROS SELECCIONADOS Y DEVUELVE LOS VALORES POR DEFECTO.
  if (viewOptionsFilter) {
    filterOptions.typeIncident = defaultFilterIncidents.typeIncident;
    filterOptions.priorityIncident = defaultFilterIncidents.priorityIncident;
    filterOptions.statusIncident = defaultFilterIncidents.statusIncident;
    setFilterOptions(filterOptions);
  }

  return (
    !viewOptionsFilter && (
      <div
        className="d-flex"
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "end",
          fontSize: "12px",
        }}
      >
        {filterOptions.typeIncident.map((type) => (
          <div key={`type-${type}`} className="filter-option-incident">
            <div className="filter-option-title-incident">
              {" "}
              {translate("global.type")}:
              <div
                className="btn-delete-option-incident"
                onClick={() => handleDeleteFilter("typeIncident", type)}
              >
                <TiDelete size={20} />
              </div>
            </div>
            <div className="filter-option-value-incident">
              {getNameIncidentsTypes(type)}
            </div>
          </div>
        ))}
        {filterOptions.priorityIncident.map((priority) => (
          <div key={`priority-${priority}`} className="filter-option-incident">
            <div className="filter-option-title-incident">
              {" "}
              {translate("global.priority")}:
              <div
                className="btn-delete-option-incident"
                onClick={() => handleDeleteFilter("priorityIncident", priority)}
              >
                <TiDelete size={20} />
              </div>
            </div>
            <div className="filter-option-value-incident">
              <span className="mr-1">{getFlagPriorityIncident(priority)}</span>{" "}
              {getNameIncidentsPriority(priority)}
            </div>
          </div>
        ))}
        {filterOptions.statusIncident.map((status) => (
          <div key={`status-${status}`} className="filter-option-incident">
            <div className="filter-option-title-incident">
              {" "}
              {translate("global.state")} :
              <div
                className="btn-delete-option-incident"
                onClick={() => handleDeleteFilter("statusIncident", status)}
              >
                <TiDelete size={20} />
              </div>
            </div>
            <div className="filter-option-value-incident">
              <span className="mr-1">{getIconStatusIncident(status)}</span>{" "}
              {getNameIncidentsStatus(status)}
            </div>
          </div>
        ))}
        {filterOptions.startDate.map((startDate) => (
          <div key={`status-${startDate}`} className="filter-option-incident">
            <div className="filter-option-title-incident">
              {" "}
              {translate("global.date")} :
            </div>
            <div >
              {getFormatDate(startDate || "")} - {getFormatDate(filterOptions.endDate[0] || "")}
            </div>
          </div>
        ))}
      </div>
    )
  );
};

export default SelectedFilters;
