import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './index.css';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import GoogleMapLocation from './GoogleMapsLocation';
import { translate } from '../../../utils/Common';
const PickLocation = () => {
    const { redirect } = useParams();
    const [locationType, setLocationtype] = useState(null);
    return (
        <>
            <div className="dashboardCard text-left">
                <h4 className="m-0"><FaIcons.FaLocationArrow className='mr-1'/> {translate("dashboard.pickLocation.destinationLocation")}</h4>
                <p className="mx-0 mb-0 mt-1">{translate("dashboard.pickLocation.recordatory")}</p>
            </div>
            
            <div className="dashboardCard text-left d-flex flex-column">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-white px-0 pt-0 pb-2 border-bottom">
                        <Link className="breadcrumb-item breadcrumbLink boxit-fucsia" to={`/dashboard/lockers/${redirect}`}>Paquetes</Link>
                        <li className={"breadcrumb-item breadcrumbLink " + (!locationType ? '' : 'boxit-fucsia')} onClick={() => setLocationtype(null)}>Destino</li>
                        <li className="breadcrumb-item">{translate("global.choose")}</li>
                    </ol>
                </nav>
                { !locationType ?
                    <>
                        <p className="m-0 text-small">{translate("dashboard.pickLocation.selectTypeDelivery")}</p>
                        <div className="d-flex justify-content-start text-center">
                            {/* <div className='py-3 px-4 m-3 border rounded shadow pickDestinationBox' onClick={() => setLocationtype(1)}>
                                <h6 className='mx-0 mt-0 mb-1'>Smart Locker</h6>
                                <img src="/img/locker.png" alt="SmartLocker" width="120px" />
                            </div> */}
                            <div className='p-4 my-3 border rounded shadow pickDestinationBox d-flex flex-column justify-content-start align-items-center' onClick={() => setLocationtype(2)}>
                                <h6 className='mx-0 mt-0'>{translate("dashboard.pickLocation.shippingToSite")}</h6>
                                <BsIcons.BsPinMapFill className='boxit-fucsia mt-2' size={70}/>
                            </div>
                        </div>
                    </>
                    :
                    // locationType === 1 ?
                    //     <>Locker</>
                    //     :
                    <GoogleMapLocation />
                }
            </div>
        </>
    );
}

export default PickLocation;