import * as BiIcons from "react-icons/bi";
import { LuAlertCircle } from "react-icons/lu";
import { FaSignal } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

import {
  statusBusyDoor,
  translate,
  statusOnline,
  statusReservedDoor,
  verifyHideCountry,
} from "../../utils/Common";
import { useState } from "react";
import NumberWithPercentage from "../NumberWithPercentage";

const ItemPlatform = ({ index, item, setSelectedItem }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleId = () => {
    if (item.locationId && item.locationId !== "") {
      if (item.zipCode) return `/ ID. ${item.locationId}`;
      else return `ID. ${item.locationId}`;
    } else return "";
  };

  const handleZipCode = () => {
    if (item.zipCode && item.zipCode !== "") {
      if (!verifyHideCountry()) return `CEP: ${item.zipCode}`;
      else return `Zip Code: ${item.zipCode}`;
    } else return "";
  };

  const percentageOcupacity = Number(item.totalDoors) === 0 ? 0 : Math.round(((item.occupiedDoors + item.reservedDoors) / item.totalDoors) * 100);

  const renderHeader = (
    <div className={`render-header${item.statusId === 2 ? '-disabled' : ''}`} style={{ display: "grid", gridTemplateColumns: "7fr 1fr", alignItems: "center", gap: 10 }}>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h6 className="font-bold text-left mb-1">
        {item.locationName ?? ""}
      </h6>
      <p className="mb-1 mt-2" style={{ color: "#bcbcbc", fontSize: "12px" }}>
        {handleZipCode()} {handleId()}
      </p>
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
      <NumberWithPercentage number={percentageOcupacity} />
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
        {item.locationStatus && (
          <>
            {item.locationStatus === "ONLINE" ? (
              <FaSignal color="rgb(74, 222, 128)" size={20} />
            ) : (
              <IoMdAlert color="red" size={20} />
            )}
          </>
        )}
      </div>
    </div>
  </div>

  );

  const renderActions = (
    <div className={`d-flex flex-column align-items-center p-3`}>
    <div className="w-100 mb-3 d-flex justify-content-center">
      <button
        className={`btn ${item.statusId === 2 ? 'btn-outline-disabled' : 'btn-outline-fucsia'} w-100`}
        style={{ fontSize: "12px" }}
        onClick={handleFlip}
      >
        {flipped ? (
          <>
            <BiIcons.BiArrowBack size={15} className="mr-1" />
            {translate("global.return")}
          </>
        ) : (
          <>
            <BiIcons.BiDoorOpen size={15} className="mr-1" />
            {translate("global.availability")}
          </>
        )}
      </button>
    </div>
    <div className="w-100 mb-2 d-flex justify-content-center">
      <button
        className={`btn ${item.statusId === 2 ? 'btn-disabled' : 'btn-fucsia'} w-100`}
        style={{ fontSize: "12px" }}
        onClick={() => {
          setSelectedItem(item);
        }}
      >
        {translate("global.details")}
      </button>
    </div>
  </div>
  );

  const renderContent = !flipped ? (
    <div className="px-3 pt-3" style={{ borderBottom: "1px solid #aca9a9" }}>
      <div className="d-flex justify-content-between pb-2">
        <div className="d-flex">
          <span>{statusBusyDoor}</span>
          {translate("global.occupiedDoors")}
        </div>
        <div style={{ color: "#bcbcbc" }}>{item.occupiedDoors ?? "0"}</div>
      </div>
      <div className="d-flex justify-content-between pb-2">
        <div className="d-flex">
          <span>{statusReservedDoor}</span>
          {translate("global.reservedDoors")}
        </div>
        <div style={{ color: "#bcbcbc" }}>{item.reservedDoors ?? "0"}</div>
      </div>
      <div className="d-flex justify-content-between pb-2">
        <div className="d-flex">
          <span>{statusOnline}</span>
          {translate("global.availableDoors")}
        </div>
        <div style={{ color: "#bcbcbc" }}>{item.availableDoors ?? "0"}</div>
      </div>
      <div className="d-flex justify-content-between font-weight-bold pb-3">
        <div>{translate("global.totalDoors")}</div>
        <div>{item.totalDoors ?? "0"}</div>
      </div>
    </div>
  ) : (
    <div className="px-3 pt-3" style={{ borderBottom: "1px solid #aca9a9" }}>
      <div className="d-flex justify-content-between pb-2">
        <div>{translate("global.smallDoors")}</div>
        <div style={{ color: "#bcbcbc" }}>{item.availableSmallDoors ?? "0"}</div>
      </div>
      <div className="d-flex justify-content-between pb-2">
        <div>{translate("global.mediumDoors")}</div>
        <div style={{ color: "#bcbcbc" }}>{item.availableMediumDoors ?? "0"}</div>
      </div>
      <div className="d-flex justify-content-between pb-2">
        <div>{translate("global.largeDoors")}</div>
        <div style={{ color: "#bcbcbc" }}>{item.availableLargeDoors ?? "0"}</div>
      </div>
      <div className="d-flex justify-content-between font-weight-bold pb-3">
        <div>
          <LuAlertCircle size={15} color="gray" className="mr-1" />
          {translate("global.inactiveDoors")}
        </div>
        <div>{item.inactivedDoors ?? "0"}</div>
      </div>
    </div>
  );
  

  return (
    <div className={`item-platform-container`}>
      <div
        key={index}
        className={`col-md-3 col-sm-6 p-2 ${flipped ? "flipped" : ""}`}
        style={{
          minWidth: "fit-content",
        }}
      >
        <div className={`border item-platform item-inside text-left ${item.statusId === 2 ? 'disabled' : ''} `}>
          {renderHeader}
          {renderContent}
          {renderActions}
        </div>
      </div>
    </div>
  );
};

export default ItemPlatform;
