import ItemsInfo from '../../../components/ShowItems/ItemsInfo';
import ShowItems from '../../../components/ShowItems';
import ItemPackage from '../../../components/ShowItems/ItemPackage';
import SearchBoxItems from '../../../components/SearchBoxItems';
import { translate } from '../../../utils/Common';

const PackagesResults = ({
    itemsArray,
    itemsFound,
    itemsShowing,
    itemName,
    itemSearch,
    setItemsShowing,
    setItemsFiltered,
    setSelectedPackage,
    handleCancelBusqueda,
    parentItemPackage,
    typeExport,
    filterType
}) => {

  


    return (
        <>
            <div className="dashboardCard">
                <ItemsInfo
                    cantItemsFound={ itemsFound?.length }
                    itemName={itemSearch} />

                <SearchBoxItems
                    itemsArray={ itemsArray }
                    itemsFound={ itemsFound }
                    setItemsShowing={ setItemsShowing }
                    setItemsFiltered={ setItemsFiltered }
                    itemName={ itemSearch}
                    filterType={filterType}/>
            </div>

            <ShowItems
                ItemComponent={ parentItemPackage}
                itemsArray={ itemsArray }
                itemsFound={ itemsFound }
                setItemsFiltered={ setItemsFiltered }
                itemName={itemName} 
                view='grid'
                showBox={ true }
                itemsShowing={itemsShowing}
                handleCancelBusqueda={ handleCancelBusqueda }
                setSelectedItem={ setSelectedPackage } 
                typeExport={typeExport} 
                />
        </>
    );
}

export default PackagesResults;