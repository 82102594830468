// icons
import * as MdIcons from "react-icons/md";
import {translate} from "../../../../utils/Common"


const ItemPaymentMethod = ({ paymentMethod, deleteMethod }) => {
    return (
        <div className="form-group col-12 col-md-12 border-bottom d-flex flex-row pb-2 mb-1" >
            <div className="paymentMethod-name">
                <label className="mb-0" htmlFor="inputFormaPago">{translate("global.paymentMethod")}:</label>
                <input type="text" value={ paymentMethod?.name ? paymentMethod.name : '' } readOnly className="form-control-sm font-small form-control font-bold"/>
            </div>
            <div className="paymentMethod-amount ml-2">
                <label className="mb-0" htmlFor="inputMonto">{translate("global.amountPayment")}</label>
                <input type="text" value={ paymentMethod?.amount >= 0 ? (`$ ${paymentMethod.amount}`) : '' } readOnly className="form-control-sm font-small form-control-plaintext font-bold"/>
            </div>
            { paymentMethod.id !== 5 &&
                <div className="packageForPickup d-flex flex-column"> 
                    <label className="mb-0" htmlFor="delete-btn">{translate("global.delete")}</label>
                    <MdIcons.MdRemoveCircle size={22} className="mt-1" id="delete-btn" onClick={ () => deleteMethod(paymentMethod.id) } />
                </div>
            }
        </div>
    );
}

export default ItemPaymentMethod;