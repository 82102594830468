import { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ModalContent = ({ title, body, footer, isOpenModal, onHide }) => {

    return (
        <Modal show={isOpenModal} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {body}
            </Modal.Body>

            <Modal.Footer>
                {footer}
            </Modal.Footer>
        </Modal>
    );
}

export default ModalContent;