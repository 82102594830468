import { BsDownload } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Spinner from "../../../../components/Spinner";
import { formatter, getMonthsBilling, generateExcelFile, getNameMonth, translate, getTypePlan } from "../../../../utils/Common";
import "./index.css";
import { useState } from "react";

const HeaderActions = ({ view, setActiveTab, detailsUsage,  reloadData, dataItems }) => {
  const months = getMonthsBilling();
  const nextMonthInvoicing = getNameMonth(months[0].month === "12" ? "1" : Number(months[0].month)+1)
  const [selectDate, setSelectDate] = useState(months[0]);
  const [viewMonths, setViewMonths] = useState(false);
  const [animate, setAnimate] = useState(false);

  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const typeUser = JSON.parse(sessionStorage.getItem("user"));

  // OTHERS
  const renderPeriodInvoicing = (
    <div className="col-lg-4 col-sm-12 mt-2 mb-2">
      <span style={{ color: "#36B516" }}>
        {translate("global.cashback")}:{" "}
        <strong>{formatter.format(detailsUsage.montocashback || 0)}</strong>
      </span>
      <div
        className="d-flex align-items-center"
        style={{ fontSize: "14px", color: "#7A7A7A" }}
      >
        <span>{translate("global.periodInvoicing")}</span>{" "}
      </div>
      <div className="row">
      <div className="col-12 d-flex">
        <h4 style={{ color: "#424996", fontWeight: "bold" }}>
          {formatter.format(detailsUsage.MontoFacturado)}
        </h4> <span className="d-flex align-items-center justify-content-center pl-1">+ ITBMS</span>
      </div>
      <div className="col-12">
      <span className="mb-2" style={{ color: "#7A7A7A" }}>
          ( {translate("global.payUntil")} <strong>10 {nextMonthInvoicing} {selectDate.year}</strong> )
        </span>
      </div>
      </div>
     
     
    </div>
  );

  const renderPeriodInvoicingMonthly = (
    <div className="col-lg-3 col-sm-12 mt-2 mb-2">
      <div
        className="d-flex align-items-center"
        style={{ fontSize: "14px", color: "#7A7A7A" }}
      >
        <span>{translate("global.totalAmountInvoicing")}</span>{" "}
      </div>
      <div className="d-flex align-items-center">
        <h4 style={{ color: "#424996", fontWeight: "bold" }}>
          {formatter.format(detailsUsage.MontoFacturado)}
        </h4>{" "}
        <span className="pl-2 mb-2" style={{ color: "#7A7A7A" }}>
          <strong>{translate("global.in")} {selectDate.monthName} {selectDate.year}</strong>
        </span>
      </div>
    </div>
  );

  const renderPeriodInvoicingAnual = (
    <div className="col-lg-3 col-sm-12 mt-2 mb-2">
      <div
        className="d-flex align-items-center"
        style={{ fontSize: "14px", color: "#7A7A7A" }}
      >
        <span>{translate("global.totalAmountAnualInvoicing")}</span>{" "}
      </div>
      <div className="d-flex align-items-center">
        <h4 style={{ color: "#424996", fontWeight: "bold" }}>
          {formatter.format(detailsUsage.MontoAnual)}
        </h4>{" "}
        <span className="pl-2 mb-2" style={{ color: "#7A7A7A" }}>
          <strong>{translate("global.in")} {selectDate.year}</strong>
        </span>
      </div>
    </div>
  );

  // BUTTONS
  const renderBtnPaymentBilling = (
    <div
      className="btn-payment-billing-primary mt-2"
      onClick={() => setActiveTab(2)}
    >
      {translate("global.viewInvoicing")}
    </div>
  );

  const handleSelectedPayment = () => {
    setViewMonths(!viewMonths);    
    setAnimate(true)
  }

  const renderBtnFilterDate = (
    <div>
      <div
        className={`btn-filter-date mt-2 ${viewMonths && "isActive"} ${animate ? 'fade-in' : ''}`}
        onClick={() => handleSelectedPayment()}
      >
        {translate("global.filterBy")}{" "}
        <strong>
          {selectDate.monthName} {selectDate.year}
        </strong>{" "}
        <MdOutlineKeyboardArrowDown />
      </div>
      {viewMonths && (
        <div className="btn-filter-date-months">
           <ul className={`fade-in-list`} style={{ position: "absolute" }}>
            {months.map((date, index) => (
              <li
                key={index}
                className={`btn-filter-date-month`}
                onClick={() => {
                  setSelectDate(date);
                  setViewMonths(false);
                  reloadData(date);
                }}
              >
               {date.monthName} {date.year}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  const renderBtnDownloadPdf = (
    <div className="btn-download-billing mt-2">
      <BsDownload style={{ marginRight: "3px" }} /> {translate("global.download")} PDF
    </div>
  );

  const handleDownloadExcel = async () => {
    setIsLoadingExport(true);
  
    setTimeout(() => {
      generateExcelFile(dataItems, "BillingTransaction");
      setIsLoadingExport(false);
    }, 1000);
  };

  const renderBtnDownloadExcel = (
    dataItems && dataItems.length > 0 && (
      <div className="btn-download-billing mt-2" onClick={() => handleDownloadExcel()}>
        {!isLoadingExport ? (
          <>
            <BsDownload style={{ marginRight: "3px" }} /> {translate("global.download")} Excel
          </>
        ) : (
          <Spinner type={1} size={18} />
        )}
      </div>
    )
  );
  
  

  // TABS
  const renderUsageHeader = (
    <div className="row">
      <div className="col-lg-2">
        <span style={{ fontSize: "14px", color: "#7A7A7A" }}>{translate("global.plan")}</span>
        <h4>{getTypePlan(typeUser.IdTipoUsuario)}</h4>
      </div>

      {renderPeriodInvoicing}

      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="row">
          {renderBtnPaymentBilling}
          {renderBtnFilterDate}
          {/* {renderBtnDownloadPdf} */}
        </div>
      </div>
    </div>
  );

  const renderTransactionsHeader = (
    <div className="row">
      <div className="col-lg-2">
        <span style={{ fontSize: "14px", color: "#7A7A7A" }}>{translate("global.historyOfPayment")}</span>
        <h5>{translate("global.transactions")}</h5>
      </div>

      {renderPeriodInvoicingMonthly}
      {renderPeriodInvoicingAnual}

      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="row">
          {renderBtnFilterDate}
          {renderBtnDownloadExcel}
        </div>
      </div>
    </div>
  );

  const renderInvoicingHeader = (
    <div className="row">
      <div className="col-lg-2">
        <span style={{ fontSize: "14px", color: "#7A7A7A" }}>{translate("global.historyOfPayment")}</span>
        <h5>{translate("global.Invoicing")}</h5>
      </div>

      {renderPeriodInvoicing}

      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="row">
           {/* {renderBtnFilterDate} */}
          {renderBtnDownloadExcel}
        </div>
      </div>
    </div>
  );

  const renderReturnsHeader = (
    <div className="row">
      <div className="col-lg-10">
        <span style={{ fontSize: "14px", color: "#7A7A7A" }}>{translate("global.historyOfPayment")}</span>
        <h5>{translate("global.returns")}</h5>
      </div>

      <div className="col-lg-2 col-md-6 col-sm-12">
        <div className="row">{renderBtnDownloadExcel}</div>
      </div>
    </div>
  );

  const renderRegisterPaymentHeader = (
    <div className="row">
      <div className="col-lg-10">
        <span style={{ fontSize: "14px", color: "#7A7A7A" }}>{translate("global.toRegister")}</span>
        <h5>{translate("global.Payments")}</h5>
      </div>

      <div className="col-lg-2 col-md-6 col-sm-12">
        <div className="row">{renderBtnDownloadExcel}</div>
      </div>
    </div>
  );

  let headerContent;

  switch (view) {
    case "invoicing":
      headerContent = renderInvoicingHeader;
      break;
    case "usage":
      headerContent = renderUsageHeader;
      break;
    case "transactions":
      headerContent = renderTransactionsHeader;
      break;
    case "returns":
      headerContent = renderReturnsHeader;
      break;
    case "register-payments":
      headerContent = renderRegisterPaymentHeader;
      break;
    default:
      headerContent = null;
  }

  return <div className="border-dotted">{headerContent}</div>;
};

export default HeaderActions;
