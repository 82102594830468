import { statusDeliveryOrderFormat, getNameStatus } from '../../utils/Common';
import './index.css';
const ItemDeliveryOrder = ({ item, selectItem }) => {
    return (
        <tr className='orderRow' onClick={() => selectItem(item)}>
            <th>{item.IdEnvio}</th>
            <td>{item.NumeroIdentificacion}</td>
            <td>{item.DireccionDestino}</td>
            <td className={`font-bold ${statusDeliveryOrderFormat(item.IdEstatus)}`}>{getNameStatus(item.IdEstatus)}</td>
            <td>{item.TipoEnvioDesc}</td>
        </tr>
    );
}

export default ItemDeliveryOrder;