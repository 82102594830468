import { useState, useEffect } from 'react';
import { getClientDomain, getToken,translate } from '../../../../utils/Common';

// tabla con el tracking del proveedor
const TableEstatusProveedor = ({ idPaquete, updateComponent }) => {
    // tracking proveedor
    const [proveedorTrackingPaquete, setProveedorTrackingPaquete] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getEstatusProveedor = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/tracking/proveedor/${idPaquete}`, {
                    method: 'GET',
                    accept: 'application/json',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    signal: signal
                });
                const result = await response.json();
                if (result.payload) setProveedorTrackingPaquete(result.payload);
            } catch (err) {
                console.log(err);
            }
        }

        getEstatusProveedor();

        return () => abortController.abort();
    }, [idPaquete, updateComponent]);
    
    let statusProveedorTracking = translate("global.loading");
    const tableHeader = <thead className="text-center">
                            <tr>
                                <th className="col-4">{translate("global.date")}</th>
                                <th className="col-8">{translate("global.status")}</th>
                            </tr>
                        </thead>;
    
    let tableRows = [];
    if (proveedorTrackingPaquete)
        if (proveedorTrackingPaquete.length > 0)
            tableRows.push(proveedorTrackingPaquete?.map((row, index) => {
                return  <tr key={ index }>
                            <td>{ `${row.Fecha_Movimiento} ${row.Hora}:${row.Minuto}` }</td>
                            <td>{ row.DesEstatus }</td>
                        </tr>;
            }));
        else statusProveedorTracking = translate("dashboard.packages.details.tableStatusBoxit.noMoved");

    return <>
                <div className="form-row text-left mx-3 font-small">
                    <div className="form-group col-12 mb-2 py-1 text-center bg-blue">
                        <h6 className="text-light mb-0">{translate("dashboard.packages.details.tableStatusBoxit.statusProvider")}</h6>
                    </div>
                </div>

                <div className="mx-4">
                    <div className="table-responsive">
                        <table className="table table-striped table-hover table-sm">
                            { tableHeader }
                            <tbody className="font-xsmall">
                                { tableRows.length > 0 ? tableRows : <tr><td colSpan='2'>{statusProveedorTracking}</td></tr> }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
}

export default TableEstatusProveedor;