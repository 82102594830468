import { getFormatDate, getIconStatusIncident, getNameIncidentsStatus, translate } from "../../utils/Common";
import "./index.css";
import { FaUser } from "react-icons/fa";
const ItemIncidents = ({ item }) => {
  return (
    <div className="vertical-timeline-item vertical-timeline-element" >
     
      <div>
        <span className="vertical-timeline-element-icon bounce-in">
          <div className="vertical-timeline-element-icon-perz">
            {getIconStatusIncident(item?.IdStatus)}
          </div>
        </span>
        <div className="vertical-timeline-element-content bounce-in">
          <span style={{ color: "#424996" }}>
            {translate("global.state")}:{" "}
            <span className="timeline-title">
              {" "}
              {getNameIncidentsStatus(item?.IdStatus)}
            </span>
          </span>
          <div style={{ fontSize: "12px" }} className="mt-0">
            {item?.DescriptionStatus}{" "}
            <br />
            <span style={{ fontSize: "12px", color: "#c6c7c9", marginTop:"5px"}}>
             <FaUser size={"10px"} />  {item?.NameUser}
            </span>
          </div>

          <span className="vertical-timeline-element-date">
            {getFormatDate(item?.FechaRegistro || "")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ItemIncidents;
