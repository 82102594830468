import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ModalContext from './index';

const ModalProvider = ({ children }) => {
    // variables to handle modal
    const [modalTitle, setModalTitle] = useState('Titulo');
    const [modalBody, setModalBody] = useState('Body');
    const [modalFooter, setModalFooter] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const confirm = (title, body, footer, isOpen) => {
        setModalTitle(title);
        setModalBody(body);
        setModalFooter(footer);
        setIsOpenModal(isOpen);
    }

    return (
        <ModalContext.Provider value={{ setIsOpenModal, confirm }} >
            { children }

            <Modal show={ isOpenModal } onHide={ () => setIsOpenModal(false) }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { modalTitle }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { modalBody }
                </Modal.Body>

                <Modal.Footer>
                    { modalFooter }
                </Modal.Footer>
            </Modal>
        </ModalContext.Provider>
    );
}

export default ModalProvider;