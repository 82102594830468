import { useState, useEffect } from 'react';
import { useIncidents } from '../../../hooks/useIncidents';
import { getNameIncidentsStatus, translate } from '../../../utils/Common';
const StatusIncidentList = ({
    register, 
    errors,
    nameShow,
    idShow,
    readOnly,
    sizeInput
}) => {
   
    const { statusIncidents , getStatus } = useIncidents();
    const [statusListIncidents, setStatusIncidents] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        const handleStatus = async () => {
            const response = await getStatus(abortController.signal);
            setStatusIncidents(response);
        }

        if (!statusIncidents) handleStatus()
        else setStatusIncidents(statusIncidents);
                
        return () => abortController.abort();
    }, [getStatus, statusIncidents]);
    
    return  (
        <>
            <select className={`${sizeInput ?? "form-control-sm" } form-control font-small`} 
                readOnly={ readOnly } 
                name="statusIncident" 
                ref={ register({
                  validate: value => value !== (translate("global.select") || translate("global.noResults")) || translate("global.chooseValidTypeIncident")
              }) }>
                <option defaultValue key={0} className="font-small">{nameShow ? nameShow : translate("global.noPlatform")}</option>
                { statusListIncidents ?
                    statusListIncidents?.map(item => 
                        (idShow !== item.id) && 
                        <option key={item.id} className="font-small" value={ item.id }>{getNameIncidentsStatus(item.id)}</option>)
                    :
                    <option defaultValue key={1} className="font-small">{translate("global.noResults")}</option>
                }
            </select>
            {errors.statusIncident && <span className="text-error">{errors.statusIncident.message}</span> }
        </>
    )
}

export default StatusIncidentList;