import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import Spinner from '../../../../components/Spinner';
import CustomTooltip from '../../../../components/Tooltip';
import { handleMonth } from '../../../../utils/Common';
// style
import './index.css';

// chart monto mensual de los ultimos 5 meses
const ChartMonthlyPackages = ({ cantMonthlyPackages, cardTitle, tooltipMessage }) => {
    const COLORS = ['#E9257D', '#424996', '#935CA2', '#4ABFEE', '#F26C3F'];
    
    const renderLegendText = () => {
        return <span>Mes</span>
    }

	const date = new Date()

    const [none,] = useState([
        { name: handleMonth(date.getMonth() - 3), value: 0 },
        { name: handleMonth(date.getMonth() - 2), value: 0 },
        { name: handleMonth(date.getMonth() - 1), value: 0 },
        { name: handleMonth(date.getMonth()), value: 0 },
        { name: handleMonth(date.getMonth() + 1), value: 0 }
    ]);
    
    return (
		(cantMonthlyPackages === null) ?
			<Spinner type={ 1 }/>
			:
			<>
				<div className="d-flex items-align-between">
					<h5 className="p-0 mt-0 mx-2 mb-2 text-bold">{ cardTitle }</h5>
					{ tooltipMessage && 
					<CustomTooltip className="mt-1"
						info={ tooltipMessage } />
					}
				</div>
				<ResponsiveContainer className="charts-container" id="chart-monthly-money">
					{(cantMonthlyPackages?.length > 0) ?
						<BarChart 
							width={240} 
							height={180}
							data={cantMonthlyPackages}
							margin={{
								top: 10
							}} >
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip />
							<Legend formatter={renderLegendText} />
							<Bar dataKey="cantidad" fill={ COLORS[0] } />
						</BarChart>
						:
						<BarChart 
							width={240} 
							height={180}
							data={none}
							margin={{
								top: 10
							}}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Legend formatter={renderLegendText} />
							<Bar dataKey="value" fill={ COLORS[0] } />
						</BarChart>
					}
				</ResponsiveContainer>
			</>
	);
}

export default ChartMonthlyPackages;