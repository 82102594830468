import { PieChart, Pie, Cell, Label, ResponsiveContainer, Tooltip } from 'recharts';
import { useContext } from 'react';
import AuthContext from '../../../../contexts/auth';
import Spinner from '../../../../components/Spinner';
import CustomTooltip from '../../../../components/Tooltip';
import './index.css';
import {translate} from "../../../../utils/Common"

// graph paquetes entregados por plataforma
const GraphPackagesPickUpByPlatform = ({ cantPackagesPickUp }) => {
    const userContext = useContext(AuthContext);
    const COLORS = ['#E9257D', '#424996', '#935CA2', '#4ABFEE', '#F26C3F'];

    const none = [
        { name: "none", value: 1 }
    ];

    const getTotalPackagesPickUp = () => {
        if (cantPackagesPickUp) {
            if (cantPackagesPickUp.length > 0) {
                let cantTotalPickUp = 0;
                cantPackagesPickUp.map((plataforma) => cantTotalPickUp += plataforma.value);
                return cantTotalPickUp;
            }
            else return 0;
        }
        else return 0;
    }

    return (
        cantPackagesPickUp === null ?
            <Spinner type={ 1 }/>
            :
            <>
                <div className="d-flex items-align-between">
                    <h6 className="p-0 mt-0 mx-2 mb-2 text-bold">
                        <span className="text-bold">{ userContext.user?.IdTipoUsuario === 1 ? translate("dashboard.home.package_delivered") : translate("dashboard.home.package_withdrawn") }</span>
                    </h6>
                    
                    <CustomTooltip className="mt-1"
                        info={ userContext.user?.IdTipoUsuario === 1 ?
                            (translate("dashboard.home.package_withdrawn.help1"))
                            :
                            (translate("dashboard.home.package_withdrawn.help2"))
                        } />
                        
                        
                </div>
                <div><h2>{getTotalPackagesPickUp()}</h2></div>
                {/* <ResponsiveContainer className="charts-container">
                { getTotalPackagesPickUp() ?
                    <PieChart width={240} height={180}>
                        <Pie
                            data={cantPackagesPickUp}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={70}
                            fill="#8884d8"
                            paddingAngle={5}
                            isAnimationActive={true}
                            label={(entry) => entry.name }
                            >
                            {cantPackagesPickUp.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label value={ getTotalPackagesPickUp() } position="center" fontSize={30}/>
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    :
                    <PieChart width={240} height={180}>
                        <Pie
                            data={none}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={70}
                            isAnimationActive={true}
                            >
                            <Cell key={ `cell-0` } fill="#DBDBDB" />
                            <Label value="0" position="center" fontSize={35}/>
                        </Pie>
                    </PieChart>
                }
                </ResponsiveContainer> */}
            </>
    );
}

export default GraphPackagesPickUpByPlatform;