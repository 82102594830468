import { useContext } from 'react';
import { Link } from 'react-router-dom';
import SidebarContext from '../../../../contexts/sidebar';
import './index.css';
import { translate } from '../../../../utils/Common';


const CounterHome = () => {
    const sidebarContext = useContext(SidebarContext);
    return (
        <>
            <div className="row mx-3 d-flex justify-content-center align-items-center p-4 mb-3" id="homeOptions" >
                <div className='d-flex flex-column border p-4 shadow'>
                    <Link to="/dashboard/packages/dropoff" onClick={ () => sidebarContext.handleChangeCurrentPage('Recibir Paquete') } className='btn btnCounterHome btn-blue mb-3'>{translate("dashboard.home.counter_home.receive")}</Link>
                    <Link to="/dashboard/packages/pickup" onClick={ () => sidebarContext.handleChangeCurrentPage('Entregar Paquete') } className='btn btnCounterHome btn-fucsia mt-3'>{translate("dashboard.home.counter_home.deliver")}</Link>
                </div>
            </div>
        </>
    );
}

export default CounterHome;