import React from "react";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";

import { Modal } from "react-bootstrap";
import { ImLocation } from "react-icons/im";
import { removeAccents, getClientDomain } from "../../../../utils/Common";

import MapContainer from "../../../../components/MapContainer";

import "./index.css";

const Locations = () => {
  const [platformsAvailable, setPlatformsAvailable] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    const getPlatforms = async () => {
      const response = await fetch(`${getClientDomain(2)}/dashboard/platforms`, {
        method: "GET",
        accept: "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result) {
        if (result.payload) {
          setPlatformsAvailable(result.payload);
        }
      }
    };

    setTimeout(() => {
      getPlatforms();
    }, 100);
    
  }, []);

  useEffect(() => {
    const filtered = platformsAvailable.filter((location) => {
      const locationNameLower = removeAccents(
        location.locationName.toLowerCase()
      );
      const locationAddressLower = removeAccents(
        location.locationAddress.toLowerCase()
      );
      const searchValueLower = removeAccents(searchValue.toLowerCase());
      return (
        locationNameLower.includes(searchValueLower) ||
        locationAddressLower.includes(searchValueLower)
      );
    });

    setFilteredLocations(filtered);
  }, [searchValue, platformsAvailable]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const openModal = (location) => {
    setSelectedLocation(location);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedLocation(null);
    setIsModalOpen(false);
  };

  return (
    <div className="content-location">
      <div className="row" >
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="input-search input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar..."
              style={{ borderRadius: "50px 0 0 50px" }}
              value={searchValue}
              onChange={handleSearchChange}
            />
            <div className="input-group-append">
              <button className="input-group-text">
                <BiSearch />
              </button>
            </div>
          </div>

          <div className="list-locations">
            {filteredLocations.length === 0 &&
              "No hay ubicaciones disponibles..."}
            {filteredLocations.length > 0 &&
              filteredLocations.map((location, index) => (
                <div
                  className="item-locations row"
                  key={index}
                  onClick={() => openModal(location)}
                >
                  <div className="col-2 d-flex align-items-center">
                    <img
                      src="../img/circle_logo_boxit.png"
                      alt="circle_logo_boxit"
                      width="45px"
                    />
                  </div>
                  <div className="col-10">
                    <h6 style={{ color: "#6E437A", fontWeight: "bold" }}>
                      {location.locationName}
                    </h6>
                    <p className="mb-0">{location.locationAddress}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-lg-8 col-md-6 col-sm-12 section-map">
          {platformsAvailable.length > 0 && (<MapContainer locations={platformsAvailable} zoomLevel={13}/>)}  
        </div>

        {isModalOpen && selectedLocation && (
          <Modal show={isModalOpen} onHide={closeModal} scrollable={false}>
            <Modal.Header closeButton={true}>
              <Modal.Title>{selectedLocation.locationName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center">
                <img
                  src={selectedLocation.locationPhoto}
                  alt="Location"
                  width="75%"
                />
              </div>

              <p className="row">
                <span className="col-2 font-bold">Dirección</span>
                <span className="col-10">
                  {selectedLocation.locationAddress}
                </span>
              </p>
              {
                selectedLocation?.indoorReference &&
                <p className="row">
                  <span className="col-6 font-bold">Referencia del local</span>
                  <span className="col-6">
                    {selectedLocation.indoorReference}
                  </span>
                </p>
              }
               {
                selectedLocation?.outdoorReference &&
                <p className="row">
                  <span className="col-6 font-bold">Referencia externa</span>
                  <span className="col-6">
                    {selectedLocation.outdoorReference}
                  </span>
                </p>
              }
            {
              selectedLocation?.schedule && (
                <p className="row">
                <span className="col-2 font-bold">Horario</span>
                <span className="col-10">{selectedLocation.schedule}</span>
              </p>
              )
            }
           
              <div className="d-flex justify-content-between align-items-center" >
                <span className="main-btn alternative btn" onClick={closeModal} style={{fontSize:"12px"}}>
                  Cerrar
                </span>
                <a
                  href={selectedLocation.linkMaps}
                  className="main-btn primary d-flex align-items-center btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImLocation
                    size={20}
                    style={{ marginRight: "5px", color: "white" }}
                  />{" "}
                  <span 
                  style={{fontSize:"12px"}}>Ver en el Mapa</span>
                </a>
              </div>
            </Modal.Body>
          </Modal>
        )}

      </div>

     
    </div>
  );
};

export default Locations;
