import { useState} from "react";
import { IoIosSearch } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoMdCloseCircle  } from "react-icons/io";


import {
  getFormatDate,
  getStatusText,
  formatter,
  translate,
} from "../../utils/Common";
import "./index.css";
import ApprovalsButtons from "../../views/Dashboard/Accounting/BillingAdmin/ApprovalsButtons";

const ItemInvoicingBilling = ({ item, setItemSelected, reloadData, selectDate }) => {
  const [idStatus, setIdStatus] = useState(item.IdEstatus);
  const statusInfo = getStatusText(idStatus);
  const [additionalText, setAdditionalText] = useState(item.Descripcion);

  const handleViewDetails = () => {
    setItemSelected({
      ...item,
      IdEstatus: idStatus,
      Descripcion: additionalText,
    })
  };

  return (
    <tr className={`orderRow`} key={item.IdBilling}>
      <td>#{item.IdBilling}</td>
      <td>{item.NombreCliente}</td>
      <td>
        {getFormatDate(item.FechaBillingInicio)} - {getFormatDate(item.FechaBillingFin)}
      </td>
      <td>
        <div>{formatter.format(item?.MontoFacturado)}</div>
      </td>
      <td>
      {item.MontoPagado
                  ? formatter.format(item.MontoPagado)
                  : "-"}
        <div style={{fontWeight:"bold"}}>
          {item.Banco || "-"}
        </div>
      </td>
      <td>
        <div>{formatter.format(item?.MontoDevuelto)}</div>
      </td>
      
      <td style={{ color: `${statusInfo.color}` }}>
        {statusInfo.icon} {statusInfo.text}
      </td>

      <td className="d-flex align-items-center">
      <span
            style={{
              cursor: "pointer",
              color: "white",
              background: "#424996",
              padding: "4px 10px",
              borderRadius: "50px",
              marginRight:"10px"
            }}
            onClick={handleViewDetails}
          >
           <IoIosSearch />  {translate("global.view")}
          </span>
        {statusInfo.actionAdmin && (
          <>
          <ApprovalsButtons item={item} setStatus={setIdStatus} additionalText={additionalText} setAdditionalText={setAdditionalText} reloadData={reloadData} selectDate={selectDate}/>
        </>
        )}
      </td>
    </tr>
  );
};

export default ItemInvoicingBilling;
