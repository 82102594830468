import React, { useState } from 'react';
import ResetPwdSuccess from './ResetPwdSuccess';
import FormResetPwd from './FormResetPwd';
import './index.css';
import TraductorList from "../../../components/Traductor";

const ResetPwd = (props) => {

    const token = props.match.params.token;
    const country = props.match.params.country;
    localStorage.setItem("country", country);

    // confirm if the server response ok
    const [resetPwdSuccess, setResetPwdSuccess] = useState(false);

    return (
        <div className="login d-flex align-items-center justify-content-center" style={{
            backgroundImage: "url('/img/pattern-boxit2.png')",
            backgroundSize: "cover",
          }}>
            <div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
            <div className="login-form shadow text-center">
                <img src="../../img/logo-boxit.png" className="mb-3" alt="boxit" height="93" />

                { resetPwdSuccess ?
                    <ResetPwdSuccess />
                :
                    <FormResetPwd setResetPwdSuccess={ setResetPwdSuccess } id={ token } />
                }
            </div>
        </div>
    );
}

export default ResetPwd;