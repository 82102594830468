import { useCallback, useState } from "react";
import { getClientDomain, getToken } from "../utils/Common";
export const useProviders = () => {
    const [providers, setProviders] = useState(null);

    const getProviders = useCallback(async (idTipoUsuario, signal, idHide) => {
        const response = await fetch(`${getClientDomain(2)}/users/tipoUsuario/${idTipoUsuario}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify({
                "idHide": idHide
            }),
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) {
            setProviders(result.payload);
            return (result.payload)
        }
        else {
            setProviders([]);
            return ([]);
        }
    }, [])

    return { providers, getProviders }
}