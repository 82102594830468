import React, { useEffect, useState } from "react";
import HeaderActions from "../HeaderActions";
import { BsBox } from "react-icons/bs";
import { MdLockClock, MdAccessAlarm } from "react-icons/md";

import CustomTooltip from "../../../../components/Tooltip";
import Spinner from '../../../../components/Spinner';
//others
import { useBilling } from "../../../../hooks/useBilling";
import { translate, calculatePercentage, formatter, getMonthsBilling } from "../../../../utils/Common";
import "./index.css";

const Usage = ({ setActiveTab }) => {
  const { getDetailsUsage } = useBilling();
  const [detailsUsage, setDetailsUsage] = useState(null);
  const [error, setError] = useState(false);


  const reloadDetailsUsage = async (date) => {
    const controller = new AbortController();
    try {
      const originData = date === undefined ? getMonthsBilling()[0] : date;
      const response = await getDetailsUsage(controller.signal, originData);
      setDetailsUsage(response);
    } catch (err) {
      console.log(err);
      setError(true);
      setDetailsUsage({});
    }
    return () => controller.abort();
  };

  useEffect(() => {
    reloadDetailsUsage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
     {error ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p>{translate("login.signin.validate.errorConnectionTrue")}</p>
        </div>
      ) : detailsUsage ? (
        <>
          <HeaderActions
            view={"usage"}
            setActiveTab={setActiveTab}
            detailsUsage={detailsUsage}
            reloadData={reloadDetailsUsage}
          />
          <div className="row mt-4">
            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card-billing">
                <div className="help-billing">
                  <CustomTooltip
                    className="ml-1"
                    info={translate("dashboard.billing.usage.transactions")}
                  />
                </div>
                <div className="card-billing-title">{translate("global.transactions")}</div>
                <span className="card-billing-subtitle">({translate("global.useDoor")})</span>

                <div className="d-flex justify-content-between mt-2">
                  <div className="d-flex align-items-center">
                    <div className="card-billing-icon">
                      <BsBox size={"15px"}/>
                    </div>
                    <div
                      className="card-billing-quantity"
                      style={{ color: "#7A7A7A" }}
                    >
                      {detailsUsage.CantUsoPuerta}
                    </div>
                    <div className="card-billing-subtitle">{translate("global.deliveries")}</div>
                  </div>
                  <div
                    className="btn-card-billing"
                    onClick={() => setActiveTab(1)}
                  >
                    {translate("global.view")}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card-billing">
                <div className="help-billing">
                  <CustomTooltip
                    className="ml-1"
                    info={translate("dashboard.billing.usage.expiredPackages")}
                  />
                </div>
                <span
                  className="card-billing-title"
                  style={{ marginRight: "5px" }}
                >
                  {translate("global.expiredPackages")}
                </span>
                <span className="card-billing-subtitle">
                  ( $0.50 {translate("global.perDayDoor")} )
                </span>
                <div className="d-flex justify-content-between mt-2">
                  <div className="d-flex align-items-center">
                    <div className="card-billing-icon">
                      <MdLockClock size={"20px"}/>
                    </div>
                    <div
                      className="card-billing-quantity"
                      style={{ color: "#ED0000" }}
                    >
                      {detailsUsage.cantpaquetesvencidos}
                    </div>
                    <div className="card-billing-subtitle">
                      {translate("global.of")} {detailsUsage.CantUsoPuerta} {translate("global.packages")}
                    </div>
                  </div>
                  <div className="card-amount-billing">
                    {formatter.format(detailsUsage.montopaquetesvencidos)}
                  </div>
                </div>

                <div className="progress mt-3">
                  <div
                    className="progress-bar"
                    style={{
                      background: "#ED0000",
                      width: `${calculatePercentage(
                        detailsUsage.cantpaquetesvencidos,
                        detailsUsage.CantUsoPuerta
                      )}`,
                    }}
                    role="progressbar"
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card-billing">
                <div className="help-billing">
                  <CustomTooltip
                    className="ml-1"
                    info={translate("dashboard.billing.usage.pickup24h")}
                  />
                </div>
                <div className="card-billing-title">
                  {translate("global.pickup24h")}
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="d-flex align-items-center">
                    <div className="card-billing-icon">
                      <MdAccessAlarm size={"20px"}/>
                    </div>
                    <div
                      className="card-billing-quantity"
                      style={{ color: "#7A7A7A" }}
                    >
                      {detailsUsage.cantidadpaquetes24hr}
                    </div>
                    <div className="card-billing-subtitle">
                      {translate("global.of")} {detailsUsage.CantUsoPuerta} {translate("global.packages")}
                    </div>
                  </div>
                  <div className="card-amount-billing">
                    {formatter.format(detailsUsage.montocashback || 0)}
                  </div>
                </div>

                <div className="progress mt-3">
                  <div
                    className="progress-bar"
                    style={{
                      background: "#424996",
                      width: `${calculatePercentage(
                        detailsUsage.cantidadpaquetes24hr,
                        detailsUsage.CantUsoPuerta
                      )}`,
                    }}
                    role="progressbar"
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card-billing" style={{ position: "relative" }}>
                <div className="help-billing">
                  <CustomTooltip
                    className="ml-1"
                    info={translate("dashboard.billing.usage.useDoor" )}
                  />
                </div>

                <div className="card-billing-title">
                  {translate("global.amountUseDoor")}
                </div>
                <span className="card-billing-subtitle">
                  ( $1.00 {translate("global.perDoor")} )
                </span>

                <div
                  style={{ color: "#424996" }}
                  className="d-flex mt-2 align-items-center"
                >
                  <div
                    className="card-billing-quantity"
                    style={{ color: "#424996" }}
                  >
                    {formatter.format(detailsUsage.MontoUsoPuerta)}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card-billing">
                <div className="help-billing">
                  <CustomTooltip
                    className="ml-1"
                    info={translate("dashboard.billing.usage.paymentsReceived")}
                  />
                </div>
                <div className="card-billing-title">{translate("global.paymentsReceived")}</div>
                <span className="card-billing-subtitle">
                  ({translate("global.useMethodsPayment")})
                </span>

                <div
                  style={{ color: "#424996" }}
                  className="d-flex mt-2 align-items-center"
                >
                  <div
                    className="card-billing-quantity"
                    style={{ color: "#424996" }}
                  >
                    {formatter.format(detailsUsage.MontoPagado)}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="card-billing">
                <div className="help-billing">
                  <CustomTooltip
                    className="ml-1"
                    info={translate("dashboard.billing.usage.taxPaymentMethods")}
                  />
                </div>
                <span className="card-billing-title">
                  {translate("global.taxPaymentMethods")}
                </span>
                <span className="card-billing-subtitle ml-2">
                  3% {translate("global.credit")} {translate("global.and")} 1.5% {translate("global.cash")}
                </span>

                <div
                  style={{ color: "#424996" }}
                  className="d-flex mt-2 align-items-center"
                >
                  <div
                    className="card-billing-quantity"
                    style={{ color: "#424996" }}
                  >
                    {formatter.format(detailsUsage.comisionmetodospago)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mx-2" style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
          <Spinner type={1} size={"30px"} />
        </div>
      )}      
    </>
  );
};

export default Usage;
