import React, { useState, useContext, useEffect } from "react";
import { BsCamera, BsXCircleFill } from "react-icons/bs";
import Spinner from '../../../../components/Spinner';

import {
  formatFileSize,
  formatFileType,
  getUser,
  translate, formatter, getDateSeparate
} from "../../../../utils/Common";

//others
import { useBilling } from "../../../../hooks/useBilling";
import { useSystem } from "../../../../hooks/useSystem";
import { useForm } from "react-hook-form";
import { defaultCatcher } from "../../../../config/defaultCatcher";
import ModalContext from "../../../../contexts/modal";

import "./index.css";

const MethodsPayment = ({ id, amount ,setActiveTab }) => {
  const modalContext = useContext(ModalContext);
  const { getPaymentMethodsUsersBLI } = useSystem();

  const [items, setItems] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [dataImage, setDataImage] = useState({ name: "", size: "", type: "" });
  const [formData, setFormData] = useState({ amount: "", datePayment: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  

  const { postLoadPayment } = useBilling();
  const { register, handleSubmit,reset, setValue, errors } = useForm();

  useEffect(() => {
    const controller = new AbortController();
    try {
      const handleMethodsPayments = async () => {
        const methodP = await getPaymentMethodsUsersBLI(controller.signal);
        setItems(methodP);
      };
  
      handleMethodsPayments();
    } catch (err) {
      setError(true);
      setItems([])
      defaultCatcher(err);
    }
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetForm = () => {
    reset();
    setSelectedPaymentMethod(null);
    setSelectedImage(null);
    setUploadImage(null);
    setDataImage({ name: "", size: "", type: "" });
    setFormData({ amount: "", datePayment: "" });
  };

  const onSubmit = (formData) => {
    setIsLoading(true);
    const user = getUser();
    const file_name = `payment_${user.Ruc}_${id}.${dataImage.type}`;

    const form = new FormData();
    form.append("amount", amount);
    form.append("month", getDateSeparate(formData.datePayment).month);
    form.append("year", getDateSeparate(formData.datePayment).year);
    form.append("datePayment", formData.datePayment);
    form.append("file", uploadImage);
    form.append("paymentMethod", selectedPaymentMethod.IdInfoBancaria);
    form.append("imageName", file_name);
    form.append("idBilling", id)
    
    if (selectedPaymentMethod && selectedImage) {
      const controller = new AbortController();
      const handleSendPayment = async () => {
        const response = await postLoadPayment(controller.signal, form);
        handleResetForm();
        setIsLoading(false);
        response.code === 200
          ? modalContext.confirm(
              translate("global.paySuccess"),
              <div className="modal-content">
                <p>{translate("global.datePayment")}: <strong>{formData.datePayment}</strong></p>
                <p>{translate("global.Amount")}: <strong>{formatter.format(amount)}</strong></p>
                <p>{translate("global.paymentMethod")}: <strong>{selectedPaymentMethod.Banco}</strong></p>  

                <img src={selectedImage} alt="img" style={{borderRadius:"12px"}}/>
              </div>,
              <button
                type="button"
                className="btn btn-sm btn-fucsia"
                onClick={() => {
                  modalContext.setIsOpenModal(false);
                  setActiveTab(2)
                }
                }
              >
                {translate("global.OK")}
              </button>,
              true
            )
          : modalContext.confirm(
              translate("global.alerts.ops"),
              <span>{translate("login.resetpwd.validate.catchError")}.</span>,
              <button
                type="button"
                className="btn btn-sm btn-fucsia"
                onClick={() => {
                  modalContext.setIsOpenModal(false);
                  setActiveTab(2)
                }
                }
              >
                {translate("global.OK")}
              </button>,
              true
            );
      };

      handleSendPayment();
      return () => controller.abort();
    }
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
    setValue("paymentMethod", method.IdInfoBancaria);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setUploadImage(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
    setDataImage({
      name: file.name,
      size: formatFileSize(file.size),
      type: formatFileType(file.type),
    });
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
    setDataImage({ name: "", size: "", type: "" });

    const fileInput = document.getElementById("imageUpload");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const btnConfirmPayment = (
    <button type="submit" className="btn-confirm-payment-billing" >
      {!isLoading ? translate("dashboard.billing.paymentsMethods.confirmPay") : <Spinner type={1} size={"15"}/> }
    </button>
  );

  return (
    <>
    {error ? (
       <div style={{ textAlign: "center", marginTop: "20px" }}>
         <p>{translate("login.signin.validate.errorConnectionTrue")}</p>
       </div>
     ) : items ? (
      <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="row mt-3 card-item-payment"
        style={{ position: "relative" }}
      >
        <div className="row col-12">
          <div className="row col-lg-12">
            <div className="col-lg-2 pb-3">
              <span style={{ fontSize: "14px", color: "#7A7A7A" }}>
                {translate("global.toRegister")}
              </span>
              <h5>{translate("global.Payments")}</h5>
            </div>

            <div className="col-lg-2 col-sm-12 pb-3">
              <label
                htmlFor="idBilling"
                className={`profile-subtitle ${
                  errors.idBilling ? "red-label" : ""
                }`}
              >
               ID {translate("global.Invoicing")} <span className="red-label">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="idBilling"
                id="idBilling"
                required
                disabled
                value={"# "+id}
              />
            </div>

            <div className="col-lg-2 col-sm-12 pb-3">
              <label
                htmlFor="amount"
                className={`profile-subtitle ${
                  errors.amount ? "red-label" : ""
                }`}
              >
                {translate("global.Amount")} <span className="red-label">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="amount"
                id="amount"
                placeholder={formatter.format(amount)}
                value={formatter.format(amount)}
                disabled
                required
                // onChange={handleFormChange}
                // ref={register({ required: "Monto es requerido" })}
              />
            </div>

            <div className="col-lg-3 col-sm-12 pb-3">
              <label
                htmlFor="datePayment"
                className={`profile-subtitle ${
                  errors.datePayment ? "red-label" : ""
                }`}
              >
                {translate("global.date")} <span className="red-label">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                name="datePayment"
                id="datePayment"
                required
                onChange={handleFormChange}
                ref={register({ required: "Fecha es requerida" })}
              />
            </div>

            

            {btnConfirmPayment}
          </div>
        </div>

        <div className="col-lg-6 col-sm-12 mt-3">
          {!selectedImage && (
            <label
              htmlFor="imageUpload"
              style={{ cursor: "pointer" }}
              className="input-click-image-billing"
            >
              <BsCamera size={20} style={{ marginRight: "5px" }} /> {translate("dashboard.billing.paymentsMethods.attachReceipt")} <span className="red-label">*</span>
              {errors.datePayment && (
                <p className="error-message">{errors.datePayment.message}</p>
              )}
            </label>
          )}
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            accept="image/*"
            required
            onChange={handleImageUpload}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              opacity: "0",
            }}
            ref={register}
          />
          {selectedImage && (
            <div className="section-view-image-billing">
              <div className="item-image-billing">
                <img src={selectedImage} alt="img" />
              </div>

              <div className="item-tittle-image-billing">
                <span style={{ fontWeight: "600", fontSize: "12px" }}>
                  {dataImage.name}
                </span>
                <p>{dataImage.size}</p>
              </div>
              <BsXCircleFill
                className="btn-delete-image"
                onClick={handleImageDelete}
              />
            </div>
          )}
        </div>

        <div className="col-lg-6 col-sm-12 mt-3">
          <label htmlFor="inputName" className={`profile-subtitle`}>
            {translate("dashboard.billing.paymentsMethods.selectMethod")} <span className="red-label">*</span>
          </label>
          <input
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              opacity: "0",
            }}
            type="text"
            className="form-control"
            name="paymentMethod"
            id="paymentMethod"
            value={selectedPaymentMethod?.IdInfoBancaria}
            required
            onChange={handleFormChange}
            ref={register({ required: "Metodo de pago es requerido" })}
          />
          <div className="list-payments-methods-billing">
  {items.map((method, index) => (
    method.IsActive && (
      <div
        className="item-payments-methods-billing"
        key={index}
        onClick={() => handlePaymentMethodSelect(method)}
      >
        <div style={{ marginRight: "15px" }}>
          <div
            className={`${
              selectedPaymentMethod &&
              selectedPaymentMethod.IdInfoBancaria === method.IdInfoBancaria
                ? "circle-selected-payment-billing"
                : "circle-select-payment-billing "
            }`}
          ></div>
        </div>

        <div className="image-select-payment-billing">
          <img src={method.Logo} alt={method.IdInfoBancaria} />
        </div>
        <div>
          <div className="name-select-payment-billing">{method.Banco}</div>
          <div className="list-description-payment-billing">
            <div className="item-description-payment-billing">
              {method.Nombre}
            </div>
            <div className="item-description-payment-billing">
              {method.Tipo}
            </div>
            <div className="item-description-payment-billing">
              {method.Numero}
            </div>
          </div>
        </div>
      </div>
    )
  ))}
</div>

        </div>
      </div>
    </form>
    ) : (
       <div className="mx-2" style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
         <Spinner type={1} size={"30px"} />
       </div>
     )}      
   </>

  
  );
};

export default MethodsPayment;
