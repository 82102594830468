import { translate } from '../../../utils/Common';
import CounterInsights from './CounterInsights';
import './index.css';
const Insights = () => {
    return (
        <>
            <div className="dashboardCard text-left mb-2">
                <h4 className="m-0">{translate("dashboard.insights.title")}</h4>
                <p className="m-0">{translate("dashboard.insights.message")}</p>
            </div>
            <CounterInsights/>
        </>
    );
}

export default Insights;