import React, { useState, useEffect } from 'react';
import {translate} from "../../../../utils/Common"

// campos para ingresar medidas 
const FieldSizes = ({ register, unidad }) => {
    // para calcular dimensiones
    const [alto, setAlto] = useState(0);
    const [largo, setLargo] = useState(0);
    const [ancho, setAncho] = useState(0);
    const [clasificacion, setClasificacion] = useState('S');
    // calcular la clasificacion
    useEffect(() => {
        const getClasificacionPaquete = () => {
            let numeros = [alto, largo, ancho];

            for (let i = 0; i < 2; i++)
            {
                for (let f = 0; f < 2 - i; f++)
                {
                    if (numeros[f] > numeros[f + 1])
                    {
                        let aux = numeros[f];
                        numeros[f] = numeros[f + 1];
                        numeros[f + 1] = aux;
                    }
                }
            }

            let altoAux = numeros[0];
            let anchoAux = numeros[1];
            let largoAux = numeros[2];
            
            if (altoAux > (unidad ? 14.96 : 38) || anchoAux > (unidad ? 17.32 : 44) || largoAux > (unidad ? 23.62 : 60))
            {
                setClasificacion('XG');
            }
            else if (altoAux <= (unidad ? 4.33 : 11))
            {
                setClasificacion('P');
            }
            else if (altoAux > (unidad ? 4.33 : 11) & altoAux <= (unidad ? 9.45 : 24))
            {
                setClasificacion('M');
            }
            else if (altoAux > (unidad ? 9.45 : 24) & altoAux <= (unidad ? 14.96 : 38))
            {
                setClasificacion('G');
            }
        }

        getClasificacionPaquete();
        return () => getClasificacionPaquete();
    }, [alto, largo, ancho, unidad]);
    
    return (
        <div className="form-row mx-0">
            <div className="form-group col-4 col-md-3 mb-1">
                <label htmlFor="inputAlto" className="mb-1 mt-2">{translate("global.high")} <span className="font-xsmall">({ unidad === 0 ? 'cm' : 'inch' })</span>:</label>
                <input className="form-control form-control-sm font-small" type="number" id="inputAlto" 
                    placeholder="0" name="alto" ref={ register } onChange={ (e) => setAlto(e.target.value ? Number(e.target.value) : 0) } />
            </div>

            <div className="form-group col-4 col-md-3 mb-1">
                <label htmlFor="inputLargo" className="mb-1 mt-2">{translate("global.long")} <span className="font-xsmall">({ unidad === 0 ? 'cm' : 'inch' })</span>:</label>
                <input className="form-control form-control-sm font-small" type="number" id="inputLargo"
                    placeholder="0" name="largo" ref={ register } onChange={ (e) => setLargo(e.target.value ? Number(e.target.value) : 0) } />
            </div>

            <div className="form-group col-4 col-md-3 mb-1">
                <label htmlFor="inputAncho" className="mb-1 mt-2">{translate("global.width")} <span className="font-xsmall">({ unidad === 0 ? 'cm' : 'inch' })</span>:</label>
                <input className="form-control form-control-sm font-small" type="number" id="inputAncho"
                    placeholder="0" name="ancho" ref={ register } onChange={ (e) => setAncho(e.target.value ? Number(e.target.value) : 0) } />
            </div>

            <div className="form-group col-sm-12 col-md-3 mb-1">
                <label htmlFor="inputClasificacion" className="mb-1 mt-2">{translate("global.sort")}</label>
                <input className="form-control form-control-sm font-small" type="text" id="inputClasificacion" 
                    readOnly name="size" ref={ register } value={ clasificacion } />
            </div>
        </div>
    );
}

export default FieldSizes;