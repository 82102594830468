import Spinner from '../../../../components/Spinner';
import Tooltip from '../../../../components/Tooltip';
import { translate } from '../../../../utils/Common';

// plataforma más utilizada
const MostUsedPlatform = ({ cantPackagesPickUp }) => {
    
    const getMostUsedPlatform = () => {
        return cantPackagesPickUp?.sort((a,b) => {
            return a.value - b.value
        })[cantPackagesPickUp.length - 1].name;
    }

    return (
        cantPackagesPickUp === null ?
            <Spinner type={ 1 }/>
            :
            ( cantPackagesPickUp?.length > 0 ?
                <>
                    <h4 className="p-0 m-0"><span className="text-bold">{ getMostUsedPlatform() }</span></h4>
                    <div className="d-flex flex-inline">
                        <p className="p-0 m-0">{translate("dashboard.home.platforms_most_use")}</p>
                        <Tooltip  className="mx-1"
                        info={ 
                            translate("dashboard.home.platforms_most_use.info")
                        } />
                    </div>
                </>
                :
                <>
                    <p className="p-0 m-0">{translate("dashboard.home.platforms_most_use.dont_package")}</p> 
                </>
            )
    );
}

export default MostUsedPlatform;