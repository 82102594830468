import * as BiIcons from "react-icons/bi";
import { FaDownload, FaMoneyBillWave } from "react-icons/fa";
import {
  exportToPDF,
  translate,
} from "../../utils/Common";
import { useState } from "react";
import { FaCalendarDays, FaClockRotateLeft } from "react-icons/fa6";
import { exportConfig } from "../../config/exportPdf";
import { Modal, Button } from "react-bootstrap";
import { usePlatforms } from "../../hooks/usePlatforms";

const ItemPlatformArching = ({ index, item, handleUpdateData }) => {
  const { archingPlatformById } = usePlatforms();
  const [flipped, setFlipped] = useState(false);
  const [showModal, setShowModal] = useState(false); 
  const [modalContent, setModalContent] = useState({}); 

  const handleArchingPlatform = async () => {
    const abortController = new AbortController();
    const response = await archingPlatformById(abortController.signal, {
      platformId: item.IdPlataforma,
    });

    if (response) {
      if (response.code === 200) {
        handleUpdateData();
        openModal(
          translate("global.successResetTitle"),
          translate("global.successResetMessage"),
          "success"
        );
      } else {
        openModal(
          translate("global.alert.attention"),
          translate("global.errorResetMessage"),
          "error"
        );
      }
    }
  };
  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const exportData = (data) => {
    const typeExport = "archingPlatforms";
    const config = exportConfig[typeExport];

    if (!config) {
        console.error(`Export type "${typeExport}" is not defined.`);
        return;
    }

    const columns = config.columns;
    const mapData = config.mapData;
    const fileNamePrefix = config.fileNamePrefix;
    const reportName = config.reportName;
    const reportDescription = config.reportDescription;
    
    const rows = data.map(item => mapData(item));
    
    const fileName = fileNamePrefix.default;
    const reportTitle = reportName.default;

    exportToPDF(reportTitle, fileName , columns, rows, reportDescription);
}

const handleReset = () => {
  
  // Validar si el monto total es 0.00
  if (item.Cash === "$0.00") {
    openModal(
      translate("global.alert.attention"),
      `${translate("global.errorAmountTotal0")} `,
      "error"
    );
    return;
  }

  openModal(
    translate("global.alert.attention"),
    translate("global.confirmResetMessage"),
    "confirmation",
    confirmReset
  );
};


  const renderHeader = (
    <div
      className="p-3 mb-0"
      style={{
        borderBottom: "1px solid #d4d4d4",
        background: "#fafafa",
        borderRadius: "0.5rem 0.5rem 0 0",
        position: "relative"
      }}
    >
      <div style={{position:"absolute", top:"7px", right:"7px"}}>
      <button 
          className="btn btn-outline-fucsia"
          style={{ fontSize: "12px", display:"flex", justifyContent:"center",alignItems:"center", borderRadius:"50px" }}
          onClick={() => exportData([item])}>
            <FaDownload size={15} />
          </button>
      </div>
      
      <div className="d-flex justify-content-between">

        <div className="d-flex flex-direction-row justify-content-between">
          <h6 className="font-bold text-left mb-1">
            {item.locationName ?? ""}
          </h6>
          
        </div>

        </div>
        <p className="mb-0" style={{ color: "#bcbcbc" }}>
        <FaCalendarDays /> {translate("global.lastAudit")}  : <p className="text-bold mb-0">{item.Last_Cash_Collection ?? ""}</p>
        </p>

      
    </div>
  );

  const renderActions = (
    <div className="row p-4 justify-content-center" style={{gap:"6px"}}>
      <button
        className="btn btn-fucsia col-lg-5 col-md-12 col-sm-7 mb-2"
        style={{ fontSize: "12px" }}
        onClick={handleFlip}
      >
        {flipped ? (
          <>
            <BiIcons.BiArrowBack size={15} className="mr-1" />
            {translate("global.return")}
          </>
        ) : (
          <>
            <FaMoneyBillWave size={15} className="mr-1" />
            {translate("global.tickets")}
          </>
        )}
      </button>


          <button 
          className="btn btn-outline-fucsia  col-lg-6 col-md-12 col-sm-4 mb-2"
          style={{ fontSize: "12px" }}
          onClick={handleReset}
          >
            <FaClockRotateLeft  size={12} className="mr-1" />
            {translate("global.boxClosure")}
          </button>

    </div>
  );

  const renderContent = !flipped ? (
    <div className="px-3 pt-3" style={{ borderBottom: "1px solid #aca9a9" }}>
      <div className="d-flex justify-content-between">
        <div className="d-flex text-bold">
          {translate("global.totalAmount")}
        </div>
        <div className="badge-status  text-bold"> {item.Cash ?? ""}</div>
      </div>
      <div className="d-flex justify-content-between mt-2 mb-2">
        <div className="d-flex">
          {translate("global.totalTickets")}
        </div>
        <div className="badge-status">{item.TotalBilletes ?? ""}</div>
      </div>
    </div>
  ) : (
    <div className="px-3 pt-3" style={{ borderBottom: "1px solid #aca9a9" }}>

    {
        item.AceptorBilletesUno > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$1.00</span></div>
            <div className="badge-status">{item.AceptorBilletesUno ?? "0"}</div>
          </div>
        )
    }

{
        item.AceptorBilletesDos > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$2.00</span></div>
            <div className="badge-status">{item.AceptorBilletesDos ?? "0"}</div>
          </div>
        )
    }

{
        item.AceptorBilletesCinco > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$5.00</span></div>
            <div className="badge-status">{item.AceptorBilletesCinco ?? "0"}</div>
          </div>
        )
    }

{
        item.AceptorBilletesDiez > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$10.00</span></div>
            <div className="badge-status">{item.AceptorBilletesDiez ?? "0"}</div>
          </div>
        )
    }

{
        item.AceptorBilletesVeinte > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$20.00</span></div>
            <div className="badge-status">{item.AceptorBilletesVeinte ?? "0"}</div>
          </div>
        )
    }

{
        item.AceptorBilletesCincuenta > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$50.00</span></div>
            <div className="badge-status">{item.AceptorBilletesCincuenta ?? "0"}</div>
          </div>
        )
    }

{
        item.AceptorBilletesCien > 0 && (
            <div className="d-flex justify-content-between mb-2">
            <div> {translate("global.tickets")} <span className="text-bold">$100.00</span></div>
            <div className="badge-status">{item.AceptorBilletesCien ?? "0"}</div>
          </div>
        )
    }

{
  item.TotalBilletes === 0 && (
    <div className="d-flex justify-content-between mb-3 p-2" style={{border:"1px dashed gray", color:"gray", borderRadius:"8px"}}>
              {translate("global.noTicketsAvailable")}
          </div>
        )
      }
     

    </div>
  );

    // Función para abrir el modal con contenido dinámico
    const openModal = (title, body, type = "info", onConfirm = null) => {
      setModalContent({ title, body, type, onConfirm });
      setShowModal(true);
    };

   
  
    // Función para confirmar el reseteo
    const confirmReset = () => {
      setShowModal(false);
      handleArchingPlatform();
    };
  
    // Función para cerrar el modal
    const closeModal = () => {
      setShowModal(false);
    };

  const renderModal = (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalContent.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalContent.body}</Modal.Body>
      <Modal.Footer>
        {modalContent.type === "confirmation" ? (
          <>
            <Button className="btn-outline-fucsia" onClick={closeModal}>
              {translate("global.cancel")}
            </Button>
            <Button className="btn-fucsia" onClick={modalContent.onConfirm}>
              {translate("global.confirm")}
            </Button>
          </>
        ) : (
          <Button className="btn-fucsia" onClick={closeModal}>
            {translate("global.ok")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );

  return (
    <div
      key={index}
      className={`col-md-3 col-sm-6 p-2 ${flipped ? "flipped" : ""}`}
      style={{
        minWidth:"fit-content"
      }}
    >
      <div className="border item-platform item-inside text-left">
        {renderHeader}
        {renderContent}
        {renderActions}

        {renderModal}
      </div>
    </div>
  );
};

export default ItemPlatformArching;
