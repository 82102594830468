
const ItemPackageLockers = ({ item, index }) => {
    return (
      <tr key={'itemPackage-'+index}>
      <td className="p-1 font-xsmall text-center">
          <span>{ item.CantPaquetes }</span>
      </td>
      <td className="p-1 font-xsmall text-left">
          <span>{ item.Plataforma }</span>
      </td>
  </tr>
    );
}

export default ItemPackageLockers;