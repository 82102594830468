import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";

export const useBilling = () => {

  const getDetailsUsage = useCallback(async (signal, data) => {
    const response = await fetch(`${getClientDomain(2)}/billing/detailsUsage`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getToken()}`,
      },
      signal: signal,
    });

    const result = await response.json();
    if (result?.payload) return result.payload;
    else return null;
  }, []);

  const getInvoicingMonthly = useCallback(async (signal) => {
    const response = await fetch(
      `${getClientDomain(2)}/billing/invoicingMonthly`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${getToken()}`,
        },
        signal: signal,
      }
    );

    const result = await response.json();
    if (result?.payload?.length > 0) return result.payload;
    else return [];
  }, []);

  const getTransactionsMonthly = useCallback(async (signal, data) => {
    const response = await fetch(
      `${getClientDomain(2)}/billing/transactionsMonthly`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${getToken()}`,
        },
        signal: signal,
      }
    );

    const result = await response.json();
    if (result?.payload?.length > 0) return result.payload;
    else return result;
  }, []);

  const postLoadPayment = useCallback(async (signal, form) => {

    const response = await fetch(`${getClientDomain(2)}/billing/loadPayment`, {
      method: "POST",
      body: form,
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
      signal: signal,
    });

    const result = await response.json();
    if (result?.payload) return result;
    else return result;
  }, []);

  const getAdminInvoicingMonthly = useCallback(async (signal, form) => {
    const response = await fetch(
      `${getClientDomain(2)}/billing/adminInvoicingMonthly`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${getToken()}`,
        },
        body:JSON.stringify(form) ,
        signal: signal,
      }
    );

    const result = await response.json();
    if (result?.payload?.length > 0) return result.payload;
    else return [];
  }, []);

  const getViewImage = useCallback(async (signal, form) => {
    const response = await fetch(
      `${getClientDomain(2)}/billing/imageBilling`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${getToken()}`,
        },
        body:JSON.stringify(form) ,
        signal: signal,
      }
    );

    const result = await response.json();
    if (result?.payload.data.length > 0) return result.payload;
    else return [];
  }, []);

  const updateStatus = useCallback(async (signal, form) => {
    const response = await fetch(
      `${getClientDomain(2)}/billing/updateStatus`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${getToken()}`,
        },
        body:JSON.stringify(form) ,
        signal: signal,
      }
    );

    const result = await response.json();
    if (result?.payload) return result;
    else return [];
  }, []);


  return {
    getDetailsUsage,
    getInvoicingMonthly,
    getTransactionsMonthly,
    getAdminInvoicingMonthly,
    getViewImage,
    postLoadPayment,
    updateStatus
  };
};
