import { useState } from "react";
import { translate } from "../../../utils/Common";

const DateIncident = ({ register, errors, sizeInput }) => {

    const [dateFrom, setDateFrom] = useState(null);

    const handleDateFromChange = (event) => {
      setDateFrom(event.target.value);
    };

    
  return (
    <div className="d-flex" style={{gap:8}}>
      <div>
        <label htmlFor="startDate" className="d-flex justify-content-center font-weight-bold">{translate("dashboard.accounting.from")}</label>
        <input
          className={`${
            sizeInput ?? "form-control-sm"
          } form-control font-small`}
          name="startDate"
          ref={register({ required: translate("global.select") })}
          type="date"
          onChange={handleDateFromChange}
        />
        {errors.dateFrom && (
          <span className="text-error">{errors.dateFrom.message}</span>
        )}
      </div>

      <div>
        <label htmlFor="endDate" className="d-flex justify-content-center font-weight-bold">{translate("dashboard.accounting.to")}</label>
        <input
          className={`${
            sizeInput ?? "form-control-sm"
          } form-control font-small`}
          name="endDate"
          ref={register({ required: translate("global.select") })}
          type="date"
          min={dateFrom}
          disabled={!dateFrom} 
        />
        {errors.dateTo && (
          <span className="text-error">{errors.dateTo.message}</span>
        )}
      </div>
    </div>
  );
};

export default DateIncident;
