import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { setPage, setValueSearchFilter, translate, setStatusAlertedObjTranslate } from '../../../../../utils/Common';
import AuthContext from '../../../../../contexts/auth';
import ClientsSearchList from "../../../../../components/ClientsSearchList";
import { Spinner } from 'react-bootstrap';
import { usePackages } from "../../../../../hooks/usePackages";
import ModalContent from "../../../../../components/ModalContent";
import ModalContext from '../../../../../contexts/modal';
// Icons
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import { AiFillDashboard } from "react-icons/ai";

// Formulario de búsqueda de paquetes alertados
const FormSearch = (props) => {
    const userContext = useContext(AuthContext);
    const { register, errors, handleSubmit, reset, clearErrors, setError } = useForm();
    const modalContext = useContext(ModalContext);
    // Estados del componente
    
    const [selectedClient, setSelectedClient] = useState(null);
    const [resetFlag, setResetFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [estatusAlerted, setEstatusAlerted] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [body, setBody] = useState(null);
    const [footer, setFooter] = useState(null);

    const { getEstatusAlerted } = usePackages();

    // Resetea el formulario y sus errores
    const handleResetForm = () => {
        clearErrors();
        setSelectedClient(null);
        setResetFlag(!resetFlag);
        reset();
    }

    // Maneja el envío del formulario
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await props.onFindPackage({ ...data, IdClient: selectedClient });
        } catch (error) {
            setIsOpenModal(true);
            setModalTitle(translate("global.alert.attention"));
            setBody(translate("global.alerts.requestError"));
            setFooter(<button type="button" className="btn btn-sm btn-fucsia" onClick={ () => setIsOpenModal(false) }>Aceptar</button> );
          
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        setPage('');
        setValueSearchFilter('');

        // Obtiene los estados de paquetes alertados
        const findEstatusAlerted = async () => {
            try {
                const result = await getEstatusAlerted(controller.signal);
                if (result?.payload) {
                    const response = setStatusAlertedObjTranslate(result.payload);
                    setEstatusAlerted(response);
                } else {
                    setEstatusAlerted([]);
                }
            } catch (error) {
                setEstatusAlerted([]);
            }
        };

        findEstatusAlerted();
        return () => controller.abort();
    }, [userContext.user?.IsAdmin]);

    return (
        <>
            <form className="dashboardCard tracking-busqueda text-left" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row text-left mt-0 mb-2">
                    <span className="font-small">
                        {translate("dashboard.packages.search.formSearch.description")}
                    </span>
                </div>

                <div className="form-row">
                    <div className="form-group text-left col-12 col-md-6 mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text font-medium" id="inputNumeroPaquete">
                                    <FaIcons.FaCubes size={14} />
                                </span>
                            </div>
                            <input
                                type="text"
                                className={`form-control font-medium${errors.numeroPaquete ? " is-invalid" : ""}`}
                                placeholder={translate("global.numPackage")}
                                id="inputNumeroPaquete"
                                name="numeroPaquete"
                                autoFocus
                                ref={register({
                                    maxLength: {
                                        value: 64,
                                        message: translate("login.signup.validate.maxLength32")
                                    }
                                })}
                            />
                            {errors.numeroPaquete && (
                                <div className="invalid-feedback">{errors.numeroPaquete.message}</div>
                            )}
                        </div>
                    </div>

                    <div className="form-group text-left col-12 col-md-6 mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputNumeroTracking">
                                    <MdIcons.MdTrackChanges size={14} />
                                </span>
                            </div>
                            <input
                                type="text"
                                className={`form-control${errors.numeroTracking ? " is-invalid" : ""}`}
                                placeholder={translate("global.numTracking")}
                                id="inputNumeroTracking"
                                name="numeroTracking"
                                ref={register({
                                    maxLength: {
                                        value: 32,
                                        message: translate("login.signup.validate.maxLength32")
                                    }
                                })}
                            />
                            {errors.numeroTracking && (
                                <div className="invalid-feedback">{errors.numeroTracking.message}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group text-left col-12 col-md-6 mb-2">
                        <ClientsSearchList
                            idUsuarioSelected={'all'}
                            errors={errors}
                            setError={setError}
                            clearErrors={clearErrors}
                            register={register}
                            idTipoCliente={props.idTipoCliente}
                            setSelectOption={setSelectedClient}
                            resetFlag={resetFlag}
                            isRequired={false}
                        />
                    </div>

                    <div className="form-group text-left col-12 col-md-6 mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputName">
                                    <AiFillDashboard size={14} />
                                </span>
                            </div>
                            <select
                                className="form-control"
                                id="selectEstatus"
                                name="estatusAlerted"
                                ref={register()}
                            >
                                <option defaultValue value="0">
                                    {translate("global.status.find.package.alerted")}
                                </option>
                                {estatusAlerted?.map((estatus, index) => (
                                    <option value={estatus.IdEstatus} key={index}>
                                        {estatus.EstatusAlerted}
                                    </option>
                                ))}
                            </select>
                            {errors.estatusAlerted && (
                                <div className="invalid-feedback">{errors.estatusAlerted.message}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12 text-center mb-0">
                        <button
                            className="btn btn-outline-fucsia mx-2 mt-2"
                            type="reset"
                            onClick={handleResetForm}
                        >
                            {translate("global.clean")}
                        </button>
                        <button
                            className={`btn btn-fucsia mx-2 mt-2`}
                            type={loading ? "button" : "submit"}
                            style={{ width: '90px' }}
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="sr-only">{translate("global.search")}</span>
                                </>
                            ) : (
                                <span style={{ visibility: loading ? 'hidden' : 'visible' }}>
                                    {translate("global.search")}
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </form>

            <ModalContent
                title={modalTitle}
                body={body}
                footer={footer}
                isOpenModal={isOpenModal}
                onHide={() => setIsOpenModal(false)}
            />
        </>
    );
};

export default FormSearch;
