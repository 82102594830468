import React, { useContext, useState, useEffect } from "react";
// icons
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import AuthContext from "../../../contexts/auth";
import ModalContext from "../../../contexts/modal";
import { translate, getToken, setUserSession } from "../../../utils/Common";
import { defaultCatcher } from "../../../config/defaultCatcher";
import { useForm } from "react-hook-form";
// stylesheet
import "./index.css";

const FormBankInformation = ({ userInformation }) => {
  const { handleSubmit, register, errors, clearErrors } = useForm();

  const UserContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  // para validar si de verdad se quiere actualizar los datos
  const [validate, setValidate] = useState(false);
  const [data, setData] = useState(null);
  const [selectedType, setSelectedType] = useState("1");

  useEffect(() => {
    const getUserInformation = async () => {
      try {
        const response = await UserContext.bankInfo();
  
        // Verificar si el campo TipoCuenta existe en la respuesta
        if (response.payload && response.payload.TipoCuenta) {
          setData(response.payload);
          setSelectedType(response.payload.TipoCuenta);
        } else {
          setSelectedType("1");
        }
      } catch (err) {
        defaultCatcher(err);
      }
    };

    getUserInformation();
  }, [UserContext]);

  const handleCancelUpdate = () => {
    clearErrors();
    setValidate(false);
  };

  // send data to server for update
  const sendUpdate = async (data) => {
    const controller = new AbortController();
    const handleSendUpdate = async () => {
      const response = await UserContext.updateInfoBank(data);
      response.code === 200
        ? modalContext.confirm(
            translate("dashboard.userprofile.personal.successful_update"),
            <p>
              {translate("dashboard.userprofile.personal.success_message")}
            </p>,
            <button
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
                setValidate(false);
              }}
            >
              {translate("global.OK")}
            </button>,
            true
          )
        : modalContext.confirm(
            translate("global.alerts.ops"),
            <span>{translate("login.resetpwd.validate.catchError")}.</span>,
            <button
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => modalContext.setIsOpenModal(false)}
            >
              {translate("global.OK")}
            </button>,
            true
          );
    };

    handleSendUpdate();
    return () => controller.abort();
  };

  return (
    <form
      className="personalInfo-form text-left"
      onSubmit={handleSubmit(sendUpdate)}
    >
      <div className="row text-left mt-5">
        <div className="col-md-12 mb-0 ">
          <h5>Datos bancarios</h5>
          <div className="clearfix border-bottom"></div>
        </div>

        <div className="col-md-6 mb-1 mt-2">
          <label htmlFor="inputNameAccount">
            {translate("global.nameProperty")}:
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputUsuario">
                <FaIcons.FaUniversity size={16} />
              </span>
            </div>
            <input
              disabled={!validate}
              type="text"
              className="form-control form-control-md"
              defaultValue={data?.NombreCuenta ? data?.NombreCuenta : ""}
              id="inputNameAccount"
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.userprofile.personal.invalid_name"
                  ),
                },
              })}
              name="nameAccount"
            />
          </div>
        </div>

        <div className="col-md-6 mb-1 mt-2">
          <label htmlFor="inputNumAccount">
            {translate("global.numAccount")}:
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputUsuario">
                #
              </span>
            </div>
            <input
              disabled={!validate}
              type="text"
              className="form-control form-control-md"
              id="inputNumAccount"
              defaultValue={data?.NumeroCuenta ? data?.NumeroCuenta : ""}
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.userprofile.personal.invalid_name"
                  ),
                },
              })}
              name="numAccount"
            />
          </div>
        </div>

        <div className="col-md-6 mb-1 mt-2">
          <label htmlFor="inputNameBank">{translate("global.bank")}:</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputUsuario">
                <FaIcons.FaUniversity size={16} />
              </span>
            </div>
            <input
              disabled={!validate}
              type="text"
              className="form-control form-control-md"
              id="inputNameBank"
              defaultValue={data?.Banco ? data?.Banco : ""}
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.userprofile.personal.invalid_name"
                  ),
                },
              })}
              name="nameBank"
            />
          </div>
        </div>

        <div className="col-md-6 mb-1 mt-2">
          <label htmlFor="selectTipoCuenta">
            {translate("global.typeAccount")}:
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="selectTipoCuenta">
                <FaIcons.FaMoneyCheck size={16} />
              </span>
            </div>
            
            <select
              disabled={!validate}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="form-control form-control-md"
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.userprofile.personal.invalid_name"
                  ),
                },
              })}
              name="typeAccount"
              id="selectTipoCuenta"
            >
                  <option value="1">
                    {translate("global.savings")}
                  </option>
                  <option value="2">
                    {translate("global.current")}
                  </option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center">
          {validate && (
            <button
              type="button"
              className="btn btn-outline-fucsia m-2"
              onClick={() => handleCancelUpdate()}
            >
              {translate("dashboard.userprofile.updatepsw.cancel")}
            </button>
          )}
          {userInformation &&
            (validate ? (
              <button className="btn btn-fucsia m-2" type="submit">
                {translate("dashboard.userprofile.personal.update")}
              </button>
            ) : (
              <button
                className="btn btn-fucsia m-2"
                type="button"
                onClick={() =>
                  modalContext.confirm(
                    translate("dashboard.userprofile.personal.attention"),
                    translate("dashboard.userprofile.personal.bankConfirmate"),
                    [
                      <button
                        type="button"
                        key="1"
                        className="btn btn-sm btn-update btn-outline-fucsia mr-2"
                        onClick={() => {
                          modalContext.setIsOpenModal(false);
                          setValidate(true);
                        }}
                      >
                        {translate("dashboard.userprofile.personal.accept")}
                      </button>,
                      <button
                        type="button"
                        key="2"
                        className="btn btn-sm btn-update btn-fucsia"
                        onClick={() => {
                          modalContext.setIsOpenModal(false);
                          setValidate(false);
                        }}
                      >
                        {translate("dashboard.userprofile.personal.cancel")}
                      </button>,
                    ],
                    true
                  )
                }
              >
                {translate("dashboard.userprofile.personal.update")}
              </button>
            ))}
        </div>
      </div>
    </form>
  );
};

export default FormBankInformation;
