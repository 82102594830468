import { useCallback, useState } from "react";
import { getClientDomain, getToken, setAvalibleTypesIncidents,getAvalibleTypesIncidents, setAvaliblePriorityIncidents, getAvaliblePriorityIncidents, getAvalibleStatusIncidents, setAvalibleStatusIncidents } from "../utils/Common";

export const useIncidents = () => {
    const [typesIncidents, setTypesIncidents] = useState(getAvalibleTypesIncidents() ? getAvalibleTypesIncidents() : null);
    const [priorityIncidents, setPriorityIncidents] = useState(getAvaliblePriorityIncidents() ? getAvaliblePriorityIncidents() : null);
    const [statusIncidents, setStatusIncidents] = useState(getAvalibleStatusIncidents() ? getAvalibleStatusIncidents() : null);

    const getIncidents = useCallback(async (data, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/all`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),
            signal: signal
        });

        const result = await response.json();
        
        if (result?.payload?.length > 0) return ({code: result.code, payload: result.payload});
        else return ({code: result.code, payload: []});
    }, []);
    
    const getTypes = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/types`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) {
            setAvalibleTypesIncidents(result.payload);
            setTypesIncidents(result.payload);
            return (result.payload);
        }
        else {
            setAvalibleTypesIncidents([])
            setTypesIncidents([]);
            return ([])
        }

    }, []);

    const getPriorities = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/priorities`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) {
            setAvaliblePriorityIncidents(result.payload);
            setPriorityIncidents(result.payload);
            return (result.payload);
        }
        else {
            setAvaliblePriorityIncidents([])
            setPriorityIncidents([]);
            return ([])
        }

    }, []);

    
    const getStatus = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/status`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) {
            setAvalibleStatusIncidents(result.payload);
            setStatusIncidents(result.payload);
            return (result.payload);
        }
        else {
            setAvalibleStatusIncidents([])
            setStatusIncidents([]);
            return ([])
        }

    }, []);

    const registerIncident = useCallback(async (data, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),
            signal: signal
        });

        const result = await response.json();
        
        return (result);
    }, []);

    const getDetailsIncident = useCallback(async (id, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/details/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload[0]);
        else return ([]);
    }, []);

    const updateIncident = useCallback(async (data, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),
            signal: signal
        });

        const result = await response.json();
        if (result?.payload) return (result.payload);
        else return ([]);
    }, []);

    const getTrackingIncident = useCallback(async (id, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/incidents/tracking/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal: signal
        });

        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, []);

    return { typesIncidents, priorityIncidents, statusIncidents, getIncidents, getTypes, getPriorities, registerIncident, getStatus, getDetailsIncident, updateIncident, getTrackingIncident }
}