import React from 'react';
// icons
import * as FaIcons from 'react-icons/fa';

const Tooltip = ({ info, className }) => {
    return  <div className="custom-tooltip">
                <FaIcons.FaQuestionCircle size={13} className={ className } />
                <div className="custom-tooltiptext p-3 font-xsmall">
                    <FaIcons.FaRegLightbulb size={17} className="mb-2 boxit-fucsia" />
                    <p className="p-0 m-0">
                        { info }
                    </p>
                </div>
            </div>
}

export default Tooltip;