import { useState, useEffect } from "react";
import ListInvoicing from "./ListInvoicing";
import DetailsInvoicing from "./DetailsInvoicing";
import { countIdStatus, getMonthsBilling } from "../../../../utils/Common";
import { useBilling } from "../../../../hooks/useBilling";

const BillingAdmin = () => {
  const months = getMonthsBilling();
  const { getAdminInvoicingMonthly } = useBilling();

const [itemSelected, setItemSelected] = useState(null);
const [selectDate, setSelectDate] = useState(months[0]);
const [listInvoicing, setListInvoicing] = useState(null);
const [categories, setCategories] = useState({});
const [originalListInvoicing, setOriginalListInvoicing] = useState([]);

const reloadData = async (date) => {
  const controller = new AbortController();
  try {

    const originData = date === undefined ? getMonthsBilling()[0] : date;
    const periodSelect = {
      period: originData.month + originData.year,
    };
    const response = await getAdminInvoicingMonthly(
      controller.signal,
      periodSelect
    );

    setListInvoicing(response);
    setCategories(countIdStatus(response));
    
  } catch (err) {
    // console.log(err);
    setListInvoicing([]);
    setCategories({});
  }
  return () => controller.abort();
};

useEffect(() => {
  reloadData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  return (
      itemSelected ? (
        <DetailsInvoicing item={itemSelected} setItemSelected={setItemSelected} reloadData={reloadData} selectDate={selectDate} />
      ) : (
        <ListInvoicing data={listInvoicing} setListInvoicing={setListInvoicing} setItemSelected={setItemSelected} setSelectDate={setSelectDate} selectDate={selectDate} categories={categories} setCategories={setCategories} originalListInvoicing={originalListInvoicing} setOriginalListInvoicing={setOriginalListInvoicing}/>
      )
);
};

export default BillingAdmin;
