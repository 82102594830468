import { useContext, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { useBilling } from "../../../../../hooks/useBilling";
import { translate, getStatusText } from "../../../../../utils/Common";
import Spinner from "../../../../../components/Spinner";

const ApprovalsButtons = ({ item, setStatus, setAdditionalText, additionalText, reloadData, selectDate  }) => {
  const { updateStatus } = useBilling();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [additionalText, setAdditionalText] = useState(item.Descripcion);
  const [idStatus, setIdStatus] = useState(item.IdEstatus);


  const handleApproveBilling = () => {
    setShowModal(true);
    setAdditionalText(translate("global.ApproveBilling"));
    setIdStatus(3); 
  };

  const handleDisapproveBilling = () => {
    setShowModal(true);
    setAdditionalText(translate("global.DisapproveBilling"));
    setIdStatus(4);
  };

  const handleChangeStatus = () => {
    const controller = new AbortController();

    const updateBilling = async () => {

      const form = {
        "idBilling" : item.IdBilling,
        "description": additionalText,
        "status" : idStatus
      }
      setIsLoading(true);
      await updateStatus(controller.signal, form);
      reloadData(selectDate);
      setIsLoading(false);

      closeModal();
      setStatus(idStatus);
    };

    updateBilling();
    return () => controller.abort();
  };

  const closeModal = () => {
    setShowModal(false);
    // Puedes realizar acciones adicionales al cerrar el modal si es necesario
  };

  const renderContent = (
    <>
      <span
        style={{
          cursor: "pointer",
          color: "#269400",
          fontSize: "30px",
        }}
        onClick={handleApproveBilling}
      >
        <IoCheckmarkCircle />
      </span>
      <span
        style={{
          cursor: "pointer",
          color: "#C86160",
          fontSize: "30px",
        }}
        onClick={handleDisapproveBilling}
      >
        <IoMdCloseCircle />
      </span>

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              width: "400px",
            }}
          >
            <div className="font-weight-bold mb-3" style={{ fontSize: "16px" }}>
              {translate("global.additionalInfo")}
            </div>
            <textarea
              onChange={(e) => setAdditionalText(e.target.value)}
              className="form-control"
              rows="4"
              style={{
                boxShadow: "0px 1px 11.2px 4px rgba(203, 203, 203, 0.25)",
                border: "0px solid",
                fontSize: "14px",
              }}
            >
              {additionalText}
            </textarea>
            <div>
              <button onClick={closeModal} className="btn btn-secondary mt-3 mr-3">{translate("global.cancel")}</button>
              <button onClick={handleChangeStatus} className="btn btn-primary mt-3" disabled={isLoading}> {!isLoading ? ( translate("global.accept") ) : (<Spinner type={1} size={18} /> )} </button>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return renderContent;
};

export default ApprovalsButtons;
