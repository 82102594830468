import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import RowReserva from "../ShowTable/RowReserva";
import ShowTable from "../ShowTable";
import {translate} from "../../utils/Common";

import './index.css';
const Results = ({ ItemComponent, itemsFound, itemName, setSelectedItem, showBox, actualPage, itemsShowing, view, handleUpdateData }) => {
    const handleNoResults = () => {
        if (itemName === "reservas") return <p className="my-4">{translate("components.showItems.results.NOT_NEW_PACKAGES")}</p>
        else if (itemName === "entregas") return <p className="my-4">{translate("components.showItems.results.NOT_DELIVERY_PACKAGES")}</p>
        else if(itemName === "plataformas" ) return <p className="my-4">{translate("components.showItems.results.NOT_PLATFORMS")}</p>
        else return <p className="my-4"><BsIcons.BsEmojiFrown size={ 16 } className="mx-1" /> {translate("components.showItems.results.NOT_RESULTS")}</p>
    }
    const [headerRow,] = useState(
        <tr>
            <th>#</th>
            <th>{translate("global.numIdentity")}</th>
            <th>{translate("global.ubication")}</th>
            <th>{translate("global.codeQR")}</th>
        </tr>
    );
    return (
        <>
            { view === 'grid' ?
                (itemsFound?.length > 0 ?
                    <div className="d-flex justify-content-center px-4">
                        <div className="d-flex justify-content-center row result-container w-100">
                            {   itemsFound?.map((paquete, index) => 
                                (index >= ((actualPage * itemsShowing) - itemsShowing) &&
                                index < (actualPage * itemsShowing)) &&
                                    <ItemComponent
                                        key={ index }
                                        itemName={ itemName}
                                        index={ index }
                                        showBox={ showBox } 
                                        item={ paquete }
                                        setSelectedItem={ setSelectedItem } 
                                        handleUpdateData={handleUpdateData}/>
                            )}
                        </div>
                    </div>
                    :
                    <div className="dashboardCard text-center">
                        {handleNoResults()}
                    </div>
                )
                :
                <div className="dashboardCard p-0">
                    <ShowTable 
                        itemArray={itemsFound}
                        headerRow={headerRow}
                        Item={RowReserva}
                        selectItem={setSelectedItem}
                        noItemsFound={handleNoResults()}
                        actualPage={actualPage}
                        itemsShowing={itemsShowing}
                        size='md'/>
                </div>
            }
        </>
    );
}

export default Results;