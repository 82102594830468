import React, { useState, useEffect, useContext } from 'react';
import { getClientDomain, getToken, translate } from '../../utils/Common';
import AuthContext from '../../contexts/auth';
import ModalContext from '../../contexts/modal';
import Spinner from '../Spinner';
import ShowItems from '../ShowItems';
import CustomTooltip from '../Tooltip'; 
import SearchBoxItems from '../SearchBoxItems';
import './index.css';

// paquetes por entregar en locker
const PackagesReservation = ({ updateTable, ItemComponent, handleUpdateTable, reservationType, selectedUser }) => {
    // reservationType: 1- hacer reserva, 2- entregas
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext)
    // para validar si se encontraron paquetes
    const [awaitingDelivery, setAwaitingDelivery] = useState(null);
    const [forReservation, setForReservation] = useState(null);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [addCounters, setAddCounters] = useState(true);
    const [itemsShowing, setItemsShowing] = useState(0);
    const [view, setView] = useState('grid');

    // hacer la reservacion de un paquete
    const confirmReservation = (paquete) => {
        if (reservationType === 1) {
            modalContext.confirm(translate("global.alert.attention"), 
                <span>{translate("components.packagesReservation.confirmReservation.title")} <span className="font-bold">{paquete.NumeroIdentificacion}</span>{translate("components.packagesReservation.confirmReservation.address")} <span className="font-bold">{paquete.Direccion}</span>{translate("components.packagesReservation.confirmReservation.question")}</span>,
                [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => sendReservations(paquete) }>{translate("global.yes")}</button>,
                <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
                true);            
        } else {
            if (paquete?.IsBoxit) {
                modalContext.confirm(translate("global.alert.doYouwantContinue"), 
                    <div className="p-0 mx-1">
                        <p className="m-0 p-0">{translate("dashboard.packages.details.optionPanel.removeReservation.cancelReservation")} <span className="font-bold">{translate("global.door")} {paquete.Identificacion}</span>  
                        {translate("components.packagesReservation.confirmReservation.address")} <span className="font-bold">{paquete.Direccion}</span>,
                        {translate("dashboard.packages.details.optionPanel.removeReservation.correspondingPackage")} <span className="font-bold">{paquete.NumeroIdentificacion}</span>.</p>
                        <p>{translate("global.question.continue")}</p>
                    </div>,
                    [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => sendReservations(paquete) }>{translate("global.yes")}</button>,
                    <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
                    true);
            }
            else {
                modalContext.confirm(translate("global.alert.attention"), 
                    <div className="p-0 mx-1">
                        <p className="mt-0 mb-1 p-0">{translate("components.packagesReservation.confirmReservation.NOT_DELETE_PACKAGE")}</p>
                        <p className="m-0 p-0">{translate("components.packagesReservation.confirmReservation.CHANGE_UBICATION")}</p>
                    </div>,
                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                    true);
            }
        }
    }

    const sendReservations = async (paquete) => {
        try {
            let dataToSend = {};
            if (reservationType === 1) {    
                dataToSend.numeroIdentificacion = paquete.NumeroIdentificacion;
                dataToSend.clasificacion = paquete.Clasificacion;
                dataToSend.idPlataforma = paquete.IdPlataforma;
                dataToSend.alto = paquete.Alto;
                dataToSend.ancho = paquete.Ancho;
                dataToSend.largo = paquete.Largo;
            } 
            else {
                dataToSend.idPaquete = paquete.IdPaquete;
            }
            modalContext.setIsOpenModal(false);

            const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/doors/reservation/${reservationType === 1 ? 'add' : 'remove'}` , {
                method: 'POST',
                connection: 'keep-alive',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend)
            });

            const result = await response.json();
            if (result) {
                if (result.code === 200) {
                    if (result.payload) {
                        if (reservationType === 1) {
                            if (result.payload[0].Codigo === "1") {
                                modalContext.confirm(translate("global.modal.allReady"), 
                                            <div className="mx-2 p-0">
                                                <p className="m-0 p-0 font-md">{translate("global.infoReservation")}:</p>
                                                <p className="m-0 p-0">{translate("global.numPackage")}: <span className="font-bold">{paquete.NumeroIdentificacion}</span></p>
                                                <p className="m-0 p-0">{translate("global.door")} {translate("components.packagesReservation.confirmReservation.assign")}: <span className="font-bold">{result.payload[0].Locker}</span></p>
                                                <p className="m-0 p-0">{translate("global.ubication")}: <span className="font-bold">{paquete.Direccion}</span></p>
                                            </div>,
                                            <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.done")}</button>,
                                            true);
                                handleUpdateTable(!updateTable);
                            }
                            else if (result.payload[0].Codigo === "2") {
                                modalContext.confirm(translate("global.alerts.ops"), 
                                    <span> {translate("dashboard.packages.details.optionPanel.makeReservation.noDoorsAvailable")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);
                            }
                            else if (result.payload[0].Codigo === "3") {
                                modalContext.confirm(translate("global.alert.attention"), 
                                    <span>{translate("dashboard.packages.details.optionPanel.makeReservation.sizePackage")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);                                
                                handleUpdateTable(!updateTable);
                            }
                            else if (result.payload[0].Codigo === "4") {
                                modalContext.confirm(translate("global.alert.attention"), 
                                    <span>{translate("components.packagesReservation.NOT_DOORS_AVAILABLE")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);
                            }
                            else {
                                modalContext.confirm(translate("global.alert.attention"), 
                                    <span>{translate("dashboard.packages.details.optionPanel.makeReservation.reservationActive")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);
                            }
                        }
                        else {
                            if (result.payload[0].Codigo === "1") {
                                modalContext.confirm(translate("global.alert.ready"),
                                <span>{translate("dashboard.packages.details.optionPanel.removeReservation.package")} <span className="font-bold">{`(${paquete.NumeroIdentificacion})` }</span> {translate("dashboard.packages.details.optionPanel.removeReservation.successfuly")}.</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.OK")}</button>,
                                    true);
                                handleUpdateTable(!updateTable);
                            }
                        }
                    }
                }
                else if (result.error || result.code === 404 || result.code === 500) {
                    modalContext.confirm(translate("global.alerts.ops"), 
                        <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                }
            }
        } catch (error) {
            console.log(error);
            modalContext.confirm(translate("global.alerts.ops"), 
                <span>{translate("global.error.request")}</span>,
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }

    const hasPackageToCounter = () => {
        let value = reservationType === 1 ? forReservation?.filter(paquete => !paquete?.IsBoxit) : awaitingDelivery?.filter(paquete => !paquete?.IsBoxit);
        if (value?.length > 0) return true;
        else return false;
    }

    const handleFilterCounter = (isActivated) => {
        let value = itemsFiltered?.filter(paquete => paquete?.IsBoxit);
        if (isActivated){
            setAddCounters(isActivated);
            setItemsFiltered([...awaitingDelivery]);
        }
        else {
            setAddCounters(isActivated);
            setItemsFiltered([...value]);
        }
    }

    useEffect(() => {
        setAwaitingDelivery(null);
        setForReservation(null);

        const findPackages = async (user) => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/${ reservationType === 1 ? 'awaitingReservations' : 'awaitingDelivery' }/${user}` , {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    }
                });

                const result = await response.json();
                if (result) {
                    if (result.code === 200) {
                        if (result.payload) {
                            if (result.payload.length > 0) {
                                if (reservationType === 1) {
                                    setItemsShowing(result.payload.length <= 20 ? result.payload.length : 20);
                                    setItemsFiltered(result.payload);
                                    setForReservation(result.payload);
                                }
                                else {
                                    setItemsShowing(result.payload.length <= 20 ? result.payload.length : 20);
                                    setItemsFiltered(result.payload);
                                    setAwaitingDelivery(result.payload);
                                }
                            }
                            else {
                                setItemsShowing(0);
                                setItemsFiltered([]);
                                if (reservationType === 1) setForReservation([]);
                                else setAwaitingDelivery([]);
                            }
                        }
                    }
                    else {
                        setItemsShowing(0);
                        setItemsFiltered([]);
                        if (reservationType === 1) setForReservation([]);
                        else setAwaitingDelivery([]);
                    }
                }
            } catch (error) {
                console.log(error);
                setItemsShowing(0);
                setItemsFiltered([]);
                if (reservationType === 1) setForReservation([]);
                else setAwaitingDelivery([]);
            }
        }

        if (userContext.user?.IsAdmin) {
            if (selectedUser !== null && selectedUser !== 0) findPackages(selectedUser);
        }
        else findPackages(userContext.user?.Ruc);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTable]);

    return (
        <>
            <div className="dashboardCard">
                <div className="row mx-0 mb-1">
                    <h6 className="mb-1">
                        { reservationType === 1 ? 
                            <>
                                {translate("global.pendingReservation")}
                                <CustomTooltip className="ml-1"
                                    info={ translate("components.packagesReservation.infoPendingReservation") } />
                            </>
                            :
                            <>
                                {translate("global.forDeliveryIn")}
                                <CustomTooltip className="ml-1"
                                    info={ translate("components.packagesReservation.infoForDeliveryIn") } />
                            </>
                        }
                    </h6>
                </div>

                {(awaitingDelivery?.length > 0 || forReservation?.length > 0) &&
                    <SearchBoxItems
                        itemsArray={reservationType === 1 ? forReservation : awaitingDelivery}
                        itemsFound={itemsFiltered}
                        setItemsShowing={setItemsShowing}
                        setItemsFiltered={setItemsFiltered}
                        view={view}
                        setView={setView}
                        itemName={reservationType === 1 ? translate("global.reservations") : translate("global.deliveries")}
                        filterType={"package"}/>
                }

                { userContext.user?.IsAdmin && hasPackageToCounter() &&
                    <div className="row mx-0">
                        <div className="col-12 col-md-12 pb-2 px-0 my-1 text-left">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switchAddCounter" checked={ addCounters } onChange={ (e) => handleFilterCounter(e.target.checked) } />
                                <label className="custom-control-label" htmlFor="switchAddCounter" >
                                    <div className="mt-1">{translate("global.question.counterPackages")}</div>
                                </label>
                            </div>
                        </div>
                    </div>
                }
            </div>

            { (awaitingDelivery || forReservation) ?
                <>
                    <ShowItems
                        ItemComponent={ ItemComponent }
                        itemsArray={ reservationType === 1 ? forReservation : awaitingDelivery }
                        itemsFound={ itemsFiltered }
                        setItemsFiltered={ setItemsFiltered }
                        itemName={ reservationType === 1 ? translate("global.reservations") : translate("global.deliveries") }
                        showBox={ true }
                        setSelectedItem={ confirmReservation }
                        itemsShowing={itemsShowing}
                        view={view} />
                </>
                :
                <div className="dashboardCard d-flex justify-content-center">
                    {
                        (userContext.user?.IsAdmin ?
                            <>{translate("components.packagesReservation.selectUser")}</>
                            :
                            <>
                                {translate("global.loading")} 
                                <div className="mx-2">
                                    <Spinner type={1} size={15} />
                                </div>
                            </>
                        )
                    }
                </div>
            }
        </>
    );
}

export default PackagesReservation;