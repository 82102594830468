import React, { useState, useEffect, useContext } from 'react';
import { IoIosSave } from 'react-icons/io';
import { useClient } from '../../../hooks/useClient';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { formatter, translate } from '../../../utils/Common';
import { useSystem } from '../../../hooks/useSystem';
import PackagesClientSearchList from '../../../components/PackagesClientSearchList';
import ModalContext from '../../../contexts/modal';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';

const FormManageBalance = ({ clientId, resetForm, currentBalance, handleGetDataClient }) => {
    const modalContext = useContext(ModalContext);
  // Estado para manejar el tipo de transacción, monto y saldo actual
  const { registerMovClientBalance, registerMovClientBalanceDetails } = useClient();
  const { register, handleSubmit, errors, setError } = useForm();
  const { getPaymentMethods } = useSystem();

  const [transactionType, setTransactionType] = useState('');
  const [amount, setAmount] = useState(0);
  const [idManageBalance, setIdManageBalance] = useState(null)
  const [itemsPaymentsMethod, setItemsPaymentsMethod] = useState([]);
  const [packageSelected, setPackageSelected] = useState(null);
  const [selectedMethodPayment, setSelectedMethodPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewBalance, setIsViewBalance] = useState(true);

  // Estado para el saldo calculado
  const [calculatedBalance, setCalculatedBalance] = useState(currentBalance);

  const handleRegisterMovClient = async (data) => {
    setIsLoading(true)
    const abortController = new AbortController();
    const response = await registerMovClientBalance(abortController.signal, {
      clientId: clientId,
      packageId: packageSelected,
      ...data
    });
    setIsLoading(false)

    if (response) {
      if (response.code === 200) {

        if (response.payload.length > 0) {
          setIdManageBalance(response.payload[0].NewId); 
      }

          modalContext.confirm(
              translate("global.alert.ready"),
              <span>{translate("dashboard.updateBalanceClient.success_message")}</span>,
              [
                <button
                  key={2}
                  type="button"
                  className="btn btn-sm btn-outline-fucsia"
                  onClick={() => {
                    modalContext.setIsOpenModal(false);
                    transactionType === "2" && resetForm() ;
                    setIsViewBalance(false);
                    handleGetDataClient({
                      clientId: clientId,
                    });
                  }}
                >
                  {translate("global.OK")}
                </button>,
              ],
              true
            );
      } else {
        modalContext.confirm(
          translate("global.alert.attention"),
          <span>{translate("dashboard.platforms.create.alert.error")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
                resetForm();
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );
      }
    }

    else {
        modalContext.confirm(
            translate("global.alert.attention"),
            <span>{translate("dashboard.platforms.create.alert.error")}</span>,
            [
              <button
                key={2}
                type="button"
                className="btn btn-sm btn-outline-fucsia"
                onClick={() => {
                  modalContext.setIsOpenModal(false);
                }}
              >
                {translate("global.OK")}
              </button>,
            ],
            true
          );
    }

  }

  const handleRegisterMovClientsDetails = async (data) => {
    setIsLoading(true)
    const abortController = new AbortController();
    const response = await registerMovClientBalanceDetails(abortController.signal, {
      packageId: packageSelected,
      movimientoId : idManageBalance,
      ...data
    });
    setIsLoading(false);

    if (response) {
        if (response.code === 200) {
            modalContext.confirm(
                translate("global.alert.ready"),
                <span>{translate("dashboard.updateBalanceClient.success_message2")}</span>,
                [
                  <button
                    key={2}
                    type="button"
                    className="btn btn-sm btn-outline-fucsia"
                    onClick={() => {
                      modalContext.setIsOpenModal(false);
                        resetForm();
                    }}
                  >
                    {translate("global.OK")}
                  </button>,
                ],
                true
              );
        } else {
          modalContext.confirm(
            translate("global.alert.attention"),
            <span>{translate("dashboard.platforms.create.alert.error")}</span>,
            [
              <button
                key={2}
                type="button"
                className="btn btn-sm btn-outline-fucsia"
                onClick={() => {
                  modalContext.setIsOpenModal(false);
                  resetForm();
                }}
              >
                {translate("global.OK")}
              </button>,
            ],
            true
          );
        }
      }
      
      resetForm();
  }

  const handleRegisterSubmit = (data) => {

    const handleEvaluateSubmit = async () => {

      if (idManageBalance) {
        await handleRegisterMovClientsDetails(data)
      } else {
          if (data.amount < 0) {
              setError("amount", {
                  type: "focus",
                  message: translate( "global.AmountCannotBeNegative"),
                });
          }
  
          if (calculatedBalance < 0) {
              setError("amount", {
                type: "focus",
                message: translate("global.balanceCannotBeNegative"),
              });
            }else{
                setError("amount", {
                    type: "focus",
                    message: "",
                  });
                 await handleRegisterMovClient(data);
              }        
      }

  };

  if (idManageBalance && (!selectedMethodPayment && !packageSelected)) {
    resetForm();
  }else{

    modalContext.confirm(
      translate("global.alert.attention"),
      <span>{idManageBalance ? translate("dashboard.updateBalanceClient.question2") : translate("dashboard.updateBalanceClient.question1")}</span>,
      [
        <button
        key={2}
        className="btn btn-sm btn-outline-fucsia"
        disabled={isLoading}
        onClick={() => {
         modalContext.setIsOpenModal(false);
           resetForm();
       }}
      >
        {translate("global.cancel")}
      </button>,
        <button
          key={2}
          type="button"
          className="btn btn-sm btn-fucsia"
          onClick={handleEvaluateSubmit}
          disabled={isLoading}
        >
          {translate("global.OK")}
        </button>
      ],
      true
    );
  }

    
  }

  useEffect(() => {
    const controller = new AbortController();
    try {
      const handleMethodsPayments = async () => {
        const response = await getPaymentMethods(controller.signal);
        setItemsPaymentsMethod(response);
      };
  
      handleMethodsPayments();
    } catch (err) {
        setItemsPaymentsMethod([])
    }
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Efecto para calcular el saldo cuando el tipo de transacción o el monto cambien
  useEffect(() => {
    calculateBalance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionType, amount]);

  const calculateBalance = () => {
    let newBalance;
  
    if (transactionType === '1') { // Crédito
      newBalance = currentBalance + parseFloat(amount || 0);
    } else if (transactionType === '2') { // Débito
      newBalance = currentBalance - parseFloat(amount || 0);
    } else {
      newBalance = currentBalance;
    }
  
    // Check if the new balance would be negative
    if (newBalance < 0) {
      setError("amount", {
        type: "focus",
        message: translate("global.balanceCannotBeNegative"),
      });
    }else{
        setError("amount", {
            type: "focus",
            message: "",
        });
    }
  
    setCalculatedBalance(newBalance);
  };

  const formatMoney = (amount) => {
    return formatter.format(amount)
  }

  return (
    <form onSubmit={handleSubmit(handleRegisterSubmit)}>
      <div className="details-section-info-group">
        <div className="box-card-general-dash w-100">
          <h6>
            <RiMoneyDollarCircleFill size={18}/> {translate("global.balanceManagement")}
          </h6>

          <div className="row">
            <div className="details-info col-lg-3 col-md-6 col-sm-12">
              <div className="details-info-label">
                {translate("global.typeTransaction")} <span className="text-danger">*</span>
              </div>
              <select
                className="form-control form-control-sm mt-2"
                name="transactionType"
                ref={register({ required: true })}
                onChange={(e) => setTransactionType(e.target.value)}
                disabled={idManageBalance}
              >
                <option value="" disabled selected>--- {translate("global.select")} ---</option>
                <option value="1">{translate("global.Credit")}</option>
                <option value="2">{translate("global.Debit")}</option>
              </select>
            </div>

            <div className="details-info col-lg-3 col-md-6 col-sm-12">
              <div className="details-info-label">
                {translate("global.amount")} <span className="text-danger">*</span>
              </div>
              <input
                type="number"
                className="form-control form-control-sm mt-2"
                name="amount"
                ref={register({ required: true, min: 0.01 })}
                placeholder="0.00"
                onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                        setAmount(e.target.value)
                    }else{
                        setError("amount", {
                            type: "focus",
                            message: translate( "global.AmountCannotBeNegative"),
                          });
                    }
                    
                }}
                disabled={idManageBalance}
                step="0.01"
              />
             {errors.amount?.message && <p className="text-danger mt-2" ><IoAlertCircleOutline className='mr-2' /> {errors.amount.message}</p>}
              {/* Mostrar el saldo calculado */}
              {(transactionType && amount > 0) && isViewBalance &&(
              <div className="mt-2 badge-status"  style={{color: "black"}}>
                  <> 
                   {formatMoney(currentBalance)} {transactionType === "2" ?   <span className='text-danger text-bold'>- {formatMoney(amount)}</span> : <span className='text-success text-bold'>+ {formatMoney(amount)}</span>} = <span className='text-bold' style={{fontSize:"14px", color:"#4c5bff"}}>{formatMoney(calculatedBalance)}</span>
                  </>
              </div>
                )}
            </div>

            <div className="details-info col-lg-6 col-md-6 col-sm-12">
              <div className="details-info-label">
                {translate("global.description")} <span className="text-danger">*</span>
              </div>
              <input
                type="text"
                className="form-control form-control-sm mt-2"
                name="description"
                ref={register({ required: true })}
                disabled={idManageBalance}
              />
            </div>
          </div>

          {
            idManageBalance && itemsPaymentsMethod.length > 0 && transactionType !== "2" &&
                (
                <div className="row">
                <div className="details-info col-lg-3 col-md-6 col-sm-12">
                  <div className="details-info-label">
                    {translate("global.methodPayment")}
                  </div>
                  <select
                    className="form-control form-control-md mt-2"
                    name="methodPayment"
                    ref={register({ required: false })}
                    disabled={!idManageBalance}
                    onChange={(e) => setSelectedMethodPayment(e.target.value)}
                  >
                    <option value="" disabled selected>--- {translate("global.select")} ---</option>
                    {itemsPaymentsMethod.map((item, index) => (
                      <option key={index} value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                </div>
    
                <div className="details-info col-lg-6 col-md-6 col-sm-12">
                  <div className="details-info-label">
                    {translate( "global.numPackage")} 
                  </div>

                  <div className="mt-2">
                    <PackagesClientSearchList setPackageSelected={setPackageSelected} disabled={idManageBalance} clientId={clientId}/>   
                  </div>
                </div>
              </div>
            )
          }
          

          <div className="d-flex mt-3">
            {
                !idManageBalance && <button
                className="btn btn-outline-fucsia btn-sm ml-2"
                onClick={() => resetForm()}
                type="button"
                disabled={isLoading}
              >
                {translate("global.cancel")}
              </button>
            }
            

            {!idManageBalance && (
              <>
              {
                transactionType === "2" ? (
                  <button className="btn btn-fucsia btn-sm ml-2" type="submit" disabled={isLoading}>
                    {translate("global.save")}
                  </button>
                ) : (
                  <button className="btn btn-fucsia btn-sm ml-2" type="submit" disabled={isLoading}>
                    {translate("login.signup.btn.next")}
                  </button>
                )
              }
              </>
            )}

            {idManageBalance &&  (
              <button className="btn btn-fucsia btn-sm ml-2" type="submit" disabled={isLoading}>
                <IoIosSave size={18} className="mr-1" /> {translate("global.save")}
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormManageBalance;
