import { round, translate } from "../../../../utils/Common";
// components
import ItemPackage from "./ItemPackage";

const PackagesForPickUp = ({ handleTotalToPay, pickUpPackagesFound, setPickUpPackagesFound }) => {

    const deletePackage = (paquete) => {
        setPickUpPackagesFound(pickUpPackagesFound.filter((p) => p.IdPaquete !== paquete.IdPaquete));
    }
    
    return (
        <>
            <div className="form-row mt-2 mx-3 mb-1 border-bottom">
                <h6 className="ml-2 my-1">{translate("dashboard.packages.pickUp.packageToDeliver" )}</h6>
            </div>
                
            <div className="form-row mb-2 mx-3">
                { pickUpPackagesFound.length > 0 &&
                    pickUpPackagesFound.map((packageForPickUp, index) => <ItemPackage packageForPickup={ packageForPickUp } key={index} deletePackage={deletePackage} />)
                }

                { pickUpPackagesFound.length > 0 &&
                    <div className="form-group col-12 col-md-12 d-flex flex-row pb-0 mb-0" >
                        <div className="packageForPickUp-precioTotal">
                            <label className="mb-0" htmlFor="inputNumeroPaquete">{translate("global.totalToPay")}</label>
                            <input type="text" value={ pickUpPackagesFound.length > 0 ? `$ ${round(handleTotalToPay(), 2)}` : `$ ${0.00}` } readOnly className="form-control-sm font-small form-control-plaintext font-bold"/>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default PackagesForPickUp;