import React from "react";
import "./index.css";
import { useEffect } from "react";
// icons
import { MdOutlineDashboard } from "react-icons/md";
import { RiTruckLine } from "react-icons/ri";
import { FiBox } from "react-icons/fi";
import { FaMapMarkerAlt, FaCheckCircle } from "react-icons/fa";
import { MdShoppingCart, MdOutlineNotificationsActive } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { IoMdContact } from "react-icons/io";
import { Tb24Hours } from "react-icons/tb";
import { AiFillLock } from "react-icons/ai";

// components
import Header from "../../../components/Landing//Header";
import Footer from "../../../components/Landing/Footer/Footer";
import Locations from "./Locations";
import Testimonials from "./Testimonials";
import PopUp from "./PopUps";
import { verifyHideCountryItems } from "../../../utils/Common";

export default function Home() {
  
  return (
    <>
      <Header />
      {verifyHideCountryItems(["br"])&& (
        <PopUp />
      )}
      
      <main id="home" className="section-main"
        style={{
          backgroundImage: "url('/img/pattern-boxit2.png')",
          backgroundSize: "cover",
        }}
      >
        <div className="main-img">
          <img src="/img/locker-boxit.png" alt="LockerBoxit" />
        </div>
        <div className="maint-content">
          <div className="maint-content-text">
            <span>Tus nuevos puntos</span>
            <span>
              de{" "}
              <span style={{ color: "#F26C3F", fontWeight: "bold" }}>
                Entrega
              </span>{" "}
              y{" "}
              <span style={{ color: "#4ABFEE", fontWeight: "bold" }}>
                Retiro
              </span>
            </span>
            <span>en la ciudad</span>
            <div className="maint-content-button">
              <span className="main-btn primary">Entrega</span>
              <span className="main-btn alternative">Retiro</span>
            </div>
          </div>
        </div>
      </main>

      <div className="section-benefits">
        <div className="section-objectives">
          <div className="item-objective">
            <MdOutlineDashboard size={50} />
            <h4>Dashboard BLI</h4>
            <p>
              Realiza el registro de tus paquetes por entregar y reserva una
              puerta en el locker que tu cliente desee.
            </p>
          </div>
          <div className="item-objective" style={{ background: "#895298" }}>
            <RiTruckLine size={50} />
            <h4>Entrega</h4>
            <p>
              Entrega tus ventas en el locker elegido, tu cliente recibirá una
              notificación con una clave única para retirar.
            </p>
          </div>
          <div className="item-objective" style={{ background: "#6E437A" }}>
            <FiBox size={50} />
            <h4>Retira</h4>
            <p>
              ¡Listo! Ahora tu cliente podrá retirar su paquete en el horario de
              su conveniencia.
            </p>
          </div>
        </div>

        <div className="section-benefits-title" id="benefits">
          <h2 style={{ marginRight: "5px" }} className="title-benefits">
            ¡MEJORA LA <strong>EXPERIENCIA</strong> DE{" "}
          </h2>
          <h2 className="title-benefits">COMPRAS DE TUS CLIENTES!</h2>
        </div>

        <div className="section-benefits-delivery">
          <div className="row">
          <div className="col-sm-12 col-lg-6">
            <img
              src="/img/benefit1.png"
              alt="benefits"
              className="col-sm-12 col-lg-6"
              style={{ maxWidth: "95%" }}
            />
          </div>
          <div className="content-benefits-delivery col-sm-12 col-lg-6">
            <h2
              style={{
                color: "#F26C3F",
                marginBottom: "25px",
                marginTop: "25px",
              }}
              className="title-benefits"
            >
              <RiTruckLine size={25} className="mr-3" />
              Beneficios de <strong>Entrega</strong>
            </h2>
            <ul>
              <li className="item-benefits">
                {" "}
                <RiMoneyDollarCircleLine className="icon-benefits" /> Ahorro en
                costos
              </li>
              <li className="item-benefits">
                {" "}
                <IoMdContact className="icon-benefits" /> Experiencia
                Contactless
              </li>
              <li className="item-benefits">
                {" "}
                <FaCheckCircle className="icon-benefits" /> No más entregas
                fallidas
              </li>
              <li className="item-benefits">
                {" "}
                <Tb24Hours className="icon-benefits" /> Accesos en horarios
                extendidos
              </li>
              <li className="item-benefits">
                {" "}
                <FaMapMarkerAlt className="icon-benefits" /> Ubicaciones en
                espacios convenientes
              </li>
            </ul>
          </div>
          </div>
        </div>

        <div className="section-benefits-pickup">
          <div className="row" style={{  flexDirection: "row-reverse", justifyContent: "center"}}>
          <div className="col-sm-12 col-lg-6">
            <img
              src="/img/benefit2.png"
              alt="benefits"
              className="img-responsive col-sm-12 col-lg-6"
              style={{ maxWidth: "75%" }}
            />
          </div>
          <div className="content-benefits-pickup col-sm-12 col-lg-6">
            <h2
              style={{
                color: "#E9257D",
                marginBottom: "25px",
                marginTop: "25px",
              }}
              className="title-benefits"
            >
              <FiBox size={25} className="mr-3" />
              Beneficios de <strong>Retiro</strong>
            </h2>
            <ul>
              <li className="item-benefits">
                {" "}
                <Tb24Hours className="icon-benefits" /> Accesos en horarios
                extendidos
              </li>
              <li className="item-benefits">
                {" "}
                <IoMdContact className="icon-benefits" /> Experiencia
                Contactless
              </li>
              <li className="item-benefits">
                {" "}
                <MdOutlineNotificationsActive className="icon-benefits" />{" "}
                Notificaciones en tiempo real
              </li>

              <li className="item-benefits">
                {" "}
                <AiFillLock className="icon-benefits" /> Seguridad y privacidad
                garantizada
              </li>

              <li className="item-benefits">
                {" "}
                <FaMapMarkerAlt className="icon-benefits" /> Ubicaciones en
                espacios convenientes
              </li>
            </ul>
          </div>
          </div>
        </div>

      </div>

      <div className="section-ubication pt-6"
        id="locations"
        style={{
          backgroundImage: "url('/img/pattern-boxit2.png')",
          backgroundSize: "cover",
          borderTop: "1px solid rgba(233, 233, 233, 0.4)",
          paddingBottom: "100px",
        }}
      >
        <div
          className="col-12 d-flex justify-content-center align-items-start"
          style={{
            marginTop: "80px",
            color: "#6E437A",
          }}
        >
          <FaMapMarkerAlt size={30} className="mr-4" />
          <h2 style={{ fontWeight: "bold" }}>UBICACIONES</h2>
        </div>

        <Locations />
      </div>

      <div className="section-banner"
        style={{
          backgroundImage: "url('/img/banner_internet_buy.png')",
          backgroundSize: "cover",
          borderTop: "1px solid rgba(233, 233, 233, 0.4)",
          color: "white",
          padding: "5% 15%",
        }}
      >
        <h2 style={{ textAlign: "center" }} className="title-banner">
         ¿Realizaste una compra online desde EEUU y necesitas saber como
          validar tus paquetes?
        </h2>
        
          <a
            href="https://store.myboxit.com/home"
            target="_blank"
            rel="noreferrer"
            className="btn-banner"
          >
            <MdShoppingCart style={{ marginRight: "10px" }} size={25} />
            <span>Todo sobre tus envíos aquí</span>
          </a>
      </div>

      <div className="section-testimonials" id="testimonials">
        <Testimonials />
      </div>

      <div className="section-banner"
        style={{
          backgroundImage: "url('/img/imagelocker.png')",
          backgroundSize: "cover",
          borderTop: "1px solid rgba(233, 233, 233, 0.4)",
          color: "white",
          padding: "5% 15%",
        }}
      >
        <div className="row">
          <div className="col-lg-2 col-sm-12 d-flex justify-content-center">
            <img
              src="../img/eco_friendly.png"
              alt="eco_friendly"
              width={"auto"}
              height={"150px"}
              style={{marginBottom:"10px", textAlign:"center"}}
            />
          </div>

          <h2 style={{ textAlign: "center", display:"flex", alignContent:"center" }} className="title-banner col-lg-10 col-sm-12">
            "Nuestro locker inteligente no solo simplifica la logística, sino
            que también reduce la huella de carbono al disminuir el número de
            entregas individuales."
          </h2>
        </div>
      </div>

      <Footer />
    </>
  );
}
