import React, { useContext, useEffect, useState } from 'react';
import { translate } from '../../../utils/Common';
import AuthContext from '../../../contexts/auth';

// Componente funcional
const TipoPaqueteSwitch = ({ idTipoPaquete, setIdTipoPaquete }) => {

    const userContext = useContext(AuthContext);

    const [typeTransaction, setTypeTransaction] = useState(3);

    const options = {
        "regular" :  (
            <div className="custom-control custom-switch">
            <input
                className="custom-control-input"
                checked={idTipoPaquete === 2}
                type="checkbox"
                onChange={() => handleSwitchChange(2)}
                id="inputTipoPaqueteNuevo"
            />
            <label htmlFor="inputTipoPaqueteNuevo" className="custom-control-label">
                <div className="mt-1">{translate("global.status.typePackage.regular")}</div>
            </label>
        </div>
        ),
        "return" : (
            <div className="custom-control custom-switch">
                <input
                    className="custom-control-input"
                    checked={idTipoPaquete === 3}
                    type="checkbox"
                    onChange={() => handleSwitchChange(3)}
                    id="inputTipoPaqueteDevolucion"
                />
                <label htmlFor="inputTipoPaqueteDevolucion" className="custom-control-label">
                    <div className="mt-1">{translate("global.registerPackage.return")}</div>
                </label>
            </div>
        )
    }

    const renderTiposPaquetes = () => {
        switch (typeTransaction) {
            case 1:
                return options.regular;
            case 2:
                    return options.return;

            case 3:
                return (
                    <>
                        {options.regular}
                        {options.return}
                    </>
                );

            default:
                break;
        }
    }

    const handleSwitchChange = (id) => {
        setIdTipoPaquete(id);
    };

    useEffect(() => {

        const getTypeTransaction = async () => {
            const typeTransaction = JSON.parse(sessionStorage.getItem('user'))?.typeTransactionId;
            setTypeTransaction(typeTransaction);

            switch (typeTransaction) {
                case 1:
                    handleSwitchChange(2);
                    break;
    
                    case 2:
                        handleSwitchChange(3);
                        break;
    
                        case 3:
                            handleSwitchChange(2);
                            break;
            
                default:
                    break;
            }

          };
        

        getTypeTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <div className="col-sm-12 col-md-6 mb-0 px-1 text-left">
            <p className="mb-0 font-weight-bold">{translate("global.typeOfPackage")}:</p>
            {renderTiposPaquetes()}
        </div>
    );
};

export default TipoPaqueteSwitch;
