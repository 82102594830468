// ImagePreview.js
import React, { useState } from 'react';
import "./index.css"

const ImagePreview = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div >
      <img src={url} alt="Preview" onClick={()=> openModal()} style={{ width: "100%", padding:"25px", cursor:"zoom-in" }} />

      {isModalOpen && (
        <div className="modal-admin-billing" onClick={closeModal}>
          <div className="modal-admin-billing-content">
            <span className="modal-admin-billing-close " onClick={closeModal}>
              &times;
            </span>
            <img src={url} alt="Full Preview" style={{ width: "100%", padding:"25px" }}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
