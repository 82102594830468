import React from "react";
import {BsWhatsapp} from "react-icons/bs";
import {FiMail} from "react-icons/fi"
import {AiFillPhone, AiFillInstagram} from "react-icons/ai";
import {IoLogoFacebook} from "react-icons/io";
import {FaTiktok, FaLinkedinIn} from "react-icons/fa";

import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="row" >

          <div className="col-lg-3 col-sm-12 mb-3">
            <img src="../img/logo-boxit.png" alt="Boxit" width="130" />
          </div>

          <div className="col-lg-3 col-sm-6 mb-3">
            <h5 className="mb-4">Enlaces de Interés</h5>
            <ul className="footer-ul">
             <a href="/"><li>Home</li></a>
             <a href="/planes"><li>Planes</li></a>
             <a href="/#locations"><li>Ubicaciones</li></a>
              <a href="https://store.myboxit.com/home"  target="_blank" rel="noreferrer"><li>Paquetería</li></a>
              <a href="/#testimonials"  target="_blank" rel="noreferrer"><li>Testimonios</li></a>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6 mb-3">
            <h5 className="mb-4">Contáctenos</h5>
            <ul className="footer-ul">
           <a href="mailto:hello@myboxit.com"><li><FiMail/><span className="ml-2">hello@myboxit.com</span></li></a>
            <a href="tel:+507317-1014"><li><AiFillPhone/><span className="ml-2">(+507) 317-1014</span></li></a>
           <a href="https://wa.me/50763494559?text=Hola%20Boxit%20,%20¿Podrías%20proporcionarme%20más%20información?" target="_blank" rel="noopener noreferrer"> <li><BsWhatsapp/><span className="ml-2">(+507) 6349-4559</span></li></a>
            </ul>
          </div>

          <div className="col-lg-3 social-media col-sm-12 mb-3">
            <h5 className="mb-4">Redes Sociales</h5>
            <a href="https://www.instagram.com/myboxitonline/" target="_blank" rel="noopener noreferrer"><AiFillInstagram size={35} className="mr-2"/></a>
            <a href="https://www.facebook.com/MyBoxitOnline" target="_blank" rel="noopener noreferrer"><IoLogoFacebook size={35} className="mr-2"/></a>
            <a href="https://www.linkedin.com/company/boxit-startup/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn size={25} className="mr-2"/></a>
            <a href="https://www.tiktok.com/@myboxit"><FaTiktok size={25} className="mr-2"/></a>
          </div>
        </div>
        <hr style={{marginTop:"20px"}}/>
        <div className="d-flex justify-content-center">© 2023 Boxit | All Rights Reserved</div>

      </footer>
    </>
  );
};

export default Footer;
