import { translate } from '../../utils/Common';
import Spinner from '../Spinner';
const ShowTable = ({ itemArray, headerRow, Item, selectItem, title, noItemsFound, actualPage, itemsShowing, size }) => {
    return (
        <>
            { title && <p className='mx-0 mt-0 mb-2 p-0'>{title}</p> }
            <div className="table-responsive">
                <table className={`table table-striped table-hover text-center border table-${size ? size : 'sm'} mb-0`}>
                    <thead>
                        { headerRow }
                    </thead>
                    <tbody>
                    { itemArray ?
                        (itemArray?.length > 0 ?
                            itemArray.map((item, index) => 
                            (index >= ((actualPage * itemsShowing) - itemsShowing) &&
                            index < (actualPage * itemsShowing)) &&
                                <Item key={index} index={index} item={item} selectItem={selectItem} />)
                            :
                            <tr><td colSpan={5}>{noItemsFound ? noItemsFound : translate("global.noResults")}</td></tr>
                        )
                        :
                        <tr><td colSpan={5}><Spinner type={1} /></td></tr>
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ShowTable;