import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../../contexts/auth';
import { getClientDomain, handleMonth, translate } from '../../../../utils/Common';
// components
import StatCard from '../../Home/UserStats/PackagesExpired';
import MoneyStatCard from './MoneyStatCard';
import PackagesWaitingDelivery from '../../Home/UserStats/PackagesWaitingDelivery';
import GraphPackagesTransactions from '../../Home/AdminStats/GraphOrders';
import ChartMonthlyPackages from './ChartMonthlyPackages';

const CounterStats = () => {
    const userContext = useContext(AuthContext);
    const [cantPackagesExpired, setCantPackagesExpired] = useState(null);
    const [cantPackagesAwaitingPickUp, setCantPackagesAwaitingPickUp] = useState(null);
    const [cantMonthlyPackages, setCantMonthlyPackages] = useState(null);
    const [cantMonthlyMoney, setCantMonthlyMoney] = useState(null);
    
    useEffect(() => {
        const controller = new AbortController();

        const handleMonthlyPackages = (data) => {
            let aux
            if (data?.length > 0) {
                aux = data.map(val => {
                    return { name: handleMonth(val.Mes), cantidad: val.Cantidad }
                });
            }
            return aux
        }

        const getDashboardInfo = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/expired/platform/${userContext.user?.IdPlataforma}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload.length > 0) {
                            setCantPackagesExpired(result.payload[0].CantPaquetesVencidos);
                        }
                        else {
                            setCantPackagesExpired(0);
                        }
                    }
                    else {
                        setCantPackagesExpired(0);
                    }
                }
            } catch (error) {
                console.log('error:',error);
            }
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/awaitingPickUp/platform/${userContext.user?.IdPlataforma}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload[0]) {
                            setCantPackagesAwaitingPickUp(result.payload[0].CantPaquetesEntrega);
                        }
                        else {
                            setCantPackagesAwaitingPickUp(0);
                        }
                    }
                    else {
                        setCantPackagesAwaitingPickUp(0);
                    }
                }
            } catch (error) {
                console.log('error:',error);
            }
            try {
                const date = new Date();
                let dataToSend = { dateRequested: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}` }
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/totalMoneyXMonth/counter/${userContext.user?.IdPlataforma}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    body: JSON.stringify(dataToSend),
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload.length > 0) {
                            setCantMonthlyMoney(result.payload[0].value);
                        }
                        else {
                            setCantMonthlyMoney(0);
                        }
                    }
                    else {
                        setCantMonthlyMoney(0);
                    }
                }
            } catch (error) {
                console.log('error:',error);
                setCantMonthlyMoney(0);
            }
            try {
                const date = new Date();
                let dataToSend = { dateRequested: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`};
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/pickUpXMonth/counter/${userContext.user?.IdPlataforma}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    body: JSON.stringify(dataToSend)
                });

                const result = await response.json();
                if (result) {
                    if (result.code === 200) {
                        if (result.payload) {
                            if (result.payload.length > 0) setCantMonthlyPackages(handleMonthlyPackages(result.payload))
                            else setCantMonthlyPackages([]);
                        }
                        else setCantMonthlyPackages([]);
                    }
                    else setCantMonthlyPackages([]);
                }
            } catch (error) {
                console.log(error);
                setCantMonthlyPackages([]);
            }
        }

        getDashboardInfo();
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="row mx-2 d-flex justify-content-between font-small">
                <div className="col-12 col-md-6 p-2">
                    <div className="graphCounter p-4 d-flex justify-content-center align-items-center flex-column bg-white">
                        <GraphPackagesTransactions 
                            cardTitle={ translate("dashboard.insights.packageInTransaction") }
                            cantMonthlyOrders={ [
                                { name: translate("global.Delivery"), value: cantPackagesAwaitingPickUp ? cantPackagesAwaitingPickUp : 0 },
                                { name: translate("global.Expired"), value: cantPackagesExpired ? cantPackagesExpired : 0 }] }/>
                    </div>
                </div>  

                <div className="col-12 col-md-3 p-0">
                    <div className="row card-info-vertical p-0 m-0 ">
                        <div className="col-12 col-md-12 p-2">
                            <div className="graphCounter p-4 d-flex justify-content-center align-items-center flex-column bg-white">
                                <PackagesWaitingDelivery 
                                    cantPackagesDelivery={ cantPackagesAwaitingPickUp }
                                    cardTitle={ translate("dashboard.insights.packagePending") }
                                    tooltipMessage={ 
                                        translate("dashboard.insights.packagePending.message")
                                    } />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3 p-0">
                    <div className="row card-info-vertical p-0 m-0 ">
                        <div className="col-12 col-md-12 p-2">
                            <div className="graphCounter p-4 d-flex justify-content-center align-items-center flex-column bg-white">
                                <StatCard 
                                    cantPackagesExpired={ cantPackagesExpired }
                                    tooltipMessage={ 
                                        translate("dashboard.insights.cantPackagesExpired.message")
                                    } />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div className="row mx-2 d-flex justify-content-between mb-3">
                <div className="col-12 p-2 col-md-6" >
                    <div className="graphCounter p-4 d-flex justify-content-center align-items-center flex-column bg-white">
                        <ChartMonthlyPackages
                            cantMonthlyPackages={ cantMonthlyPackages }
                            cardTitle={ translate("dashboard.insights.historyPakage")}
                            tooltipMessage={ translate("dashboard.insights.historyPakage.message") } />
                    </div>
                </div>

                <div className="col-12 col-md-6 p-2">
                    <div className="row card-info p-0 m-0 pb-2">
                        <div className="graphCounter p-4 d-flex justify-content-center align-items-center flex-column bg-white">
                            <StatCard 
                                cantPackagesExpired={ cantMonthlyPackages?.length > 0 ? cantMonthlyPackages[cantMonthlyPackages.length - 1].cantidad : 0 }
                                cardTitle={ translate("dashboard.insights.totalPackages") }
                                tooltipMessage={ 
                                    translate("dashboard.insights.totalPackages.message")
                                } />
                        </div>
                    </div>
                    
                    <div className="row card-info p-0 m-0 pt-2">
                        <div className="graphCounter p-4 d-flex justify-content-center align-items-center flex-column bg-white">
                            <MoneyStatCard 
                                value={ cantMonthlyMoney ? cantMonthlyMoney : cantMonthlyMoney }
                                cardTitle={ translate("dashboard.insights.totalPaymentsReceived") }
                                tooltipMessage={ 
                                    translate("dashboard.insights.totalPaymentsReceived.message")
                                } />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CounterStats;