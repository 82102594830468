import { formatter } from '../../utils/Common';
import { FaTruck, FaBox } from 'react-icons/fa';
import './index.css';
const ItemTransactionsBilling = ({ item }) => {
    return (
        <tr className='orderRow' key={item.IdTransaccion}>
            <th>{item.NumeroIdentificacion}</th>
            <td style={{display: 'flex', justifyItems:"left", flexDirection:"column", fontSize:"12px", minWidth:"150px"}}>
                <div><FaTruck color='green'/> {item.FechaEntrega}</div>
                <div><FaBox color='#8D6E63'/> {item.FechaRetiro}</div>  
            </td>
            <td>{item.EmailCliente}</td>
            <td>{formatter.format(item.Monto)}</td>
            <td>{item.Locker}</td>
        </tr>
    );
}

export default ItemTransactionsBilling;