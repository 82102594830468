import {translate} from "../../utils/Common"
const ItemsInfo = (props) => {
    return ( (props.itemName === translate("global.packages")  ||  props.itemName === translate("global.platforms") || props.itemName === translate("sidebar.options.packages.alerted") ) &&
        <div className="text-left mb-2">
            <span>
                {translate("global.haveBeenFound")} <span className="text-bold">{ props.cantItemsFound }</span> { props.itemName.toLowerCase() } {translate("global.parametersEntered")}
            </span>
        </div>
    )
}

export default ItemsInfo;