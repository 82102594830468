import React, { useState } from "react";
import "./index.css";
// components
import SignUpSuccess from "./SignUpSuccess";
import FormSignUpSteps from "./FormSignUpSteps";
import { translate } from "../../../utils/Common";
import TraductorList from "../../../components/Traductor";
import CountryList from "../../../components/Country";

const Signup = () => {
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);

  const onSignupSuccess = (value) => {
    setIsSignupSuccess(value);
  };

  return (
    <div className="login signup-container d-flex align-items-center justify-content-center"   style={{
      backgroundImage: "url('/img/pattern-boxit2.png')",
      backgroundSize: "cover",
    }}>
      <div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
      <div className="signup-form text-center">
        {/* <img src="../img/logo-boxit.png" className="mb-3" alt="Boxit" height="93" width="220"/> */}
        <div className="p-0 d-flex justify-content signup-header">
          <div>
            <div className="fucsia-left-line"></div>
            <div className="purple-left-line"></div>
          </div>

          <div className="text-left">
            <h2 className="title-signup-1">Boxit</h2>
            <h2 className="title-signup-2">{translate("login.signup.boxit.description")}</h2>
          </div>
        </div>
        <div className="text-left subtitle-signup mb-2">
          <p className="p-0" style={{ marginBottom: -7, color: "#000" }}>
          {translate("login.signup.boxit.subtitle1")}
          </p>
          <p className="m-0" style={{ color: "#000" }}>
            <span className="text-bold">{translate("login.signup.boxit.subtitle2")}</span>
          </p>
        </div>

        <CountryList />	
        
        {isSignupSuccess ? (
          <SignUpSuccess onSignupSuccess={onSignupSuccess} />
        ) : (
          <FormSignUpSteps onSignupSuccess={onSignupSuccess} />
        )}
      </div>
    </div>
  );
};

export default Signup;
