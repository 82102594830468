import { LuAlertCircle } from "react-icons/lu";
import { translate } from "../../../../utils/Common";
import PlatformList from "../../../../components/PlatformList";
import { useForm } from "react-hook-form";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import TypeIncidentList from "../TypeIncidentList";
import PriorityIncidentList from "../PriorityIncidentList";
import { useIncidents } from "../../../../hooks/useIncidents";
import ModalContext from "../../../../contexts/modal";
import DoorList from "../../../../components/DoorList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RegisterIncdent = () => {
  const {
    register,
    errors,
    reset,
    handleSubmit,
    watch } = useForm();

  const { registerIncident } = useIncidents();
  const modalContext = useContext(ModalContext);
  const history = useHistory();

  const [isSendingData, setIsSendingData] = useState(false);
  const [isTypeIncidentSoftware, setIsTypeIncidentSoftware] = useState(false);
  const [isBoxit, setIsBoxit] = useState(false);
  const idPlatform = useRef({});
  idPlatform.current = watch("plataforma", "");
  const handleIsBoxit = useCallback((value) => setIsBoxit(value), []);

  const submitIncident = async (data) => {
    try {
        setIsSendingData(true);
  
        const controller = new AbortController();
        const result  = await registerIncident(data, controller.signal);
        
        if (result) {
          if (result.code === 200) {
            if (result.payload) {
                modalContext.confirm(
                    translate(
                      "dashboard.packages.loadpackage.formProvider.successTittle"
                    ),
                    <div>
                      {translate("global.incident")} # <strong>{` ${result.payload.payload.incidentId}`}</strong>{" "}{translate("global.successrRegisterIncident")}
                    </div>,
                    <button
                      type="button"
                      className="btn btn-sm btn-fucsia"
                      onClick={handleCloseModal}
                    >
                      {translate("global.accept")}
                    </button>, true );
            reset();
            } 
          } else{
            modalContext.confirm(
              translate("global.alerts.ops"),
              translate("global.errorRegisterIncident"),
              <button
                type="button"
                className="btn btn-sm btn-outline-fucsia"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.accept")}
              </button>,
              true
            );
            reset();
          }
        }
      } catch (err) {
        console.log(err);
        modalContext.confirm(
          translate("global.alerts.ops"),
          translate(
            "dashboard.packages.loadpackages.formProvider.errorRegisterPackage"
          ),
          <button
            type="button"
            className="btn btn-sm btn-outline-fucsia"
            onClick={() => {modalContext.setIsOpenModal(false)}}
          >
            {translate("global.accept")}
          </button>,
          true
        );
      } finally {
        setIsSendingData(false);
      }
  };

  const handleCloseModal = () => {
    modalContext.setIsOpenModal(false);
    history.push('/dashboard/incidents')
  }

  useEffect(() => {
    // Verificar si el tipo de incidente es "SOFTWARE / DASHBOARD ID=9"
    const result = watch("typeIncident") !== "9" && watch("typeIncident") !== translate("global.select");
    setIsTypeIncidentSoftware(result);
  }, [watch]);

  
  const dateInputRef = useRef(null);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];

    if (dateInputRef.current) {
      dateInputRef.current.value = today;
    }
  }, []);


  return (
    <div className="dashboardCard text-left">
      <h5 className="m-0">
        <LuAlertCircle className="mr-2" size={25} />
        {translate("global.registerIncident")}
      </h5>
      <hr />

      <div className="mt-3">
        <form onSubmit={handleSubmit(submitIncident)}>
          <div className="row">
            <div className="col-lg-6 col-sm-12 form-group">
              <label htmlFor="incidentTitle">
                {translate("global.typeIncident")} <span style={{color:"red"}}>*</span>
              </label>
              <TypeIncidentList
                errors={errors}
                register={register}
                nameShow={translate("global.select")}
                readOnly={false}
                idPlatform={idPlatform.current}
                sizeInput={"form-control-md"}
              />
            </div>

            <div className="col-lg-6 col-sm-12 form-group">
              <label htmlFor="incidentTitle">
                {translate("global.priority")} <span style={{color:"red"}}>*</span>
              </label>
              <PriorityIncidentList
                errors={errors}
                register={register}
                nameShow={translate("global.select")}
                readOnly={false}
                idPlatform={idPlatform.current}
                sizeInput={"form-control-md"}
              />
            </div>
          </div>

    {isTypeIncidentSoftware && (
       <div className="row">
       <div className="col-lg-6 col-sm-12 form-group">
         <label htmlFor="incidentTitle">
           {translate("global.platform")} <span style={{color:"red"}}>*</span>
         </label>
         <PlatformList
           errors={errors}
           register={register}
           nameShow={translate("global.select")}
           // idShow={ props.selectedPackage?.IdPlataforma }
           readOnly={false}
           idPlatform={idPlatform.current}
           handleIsBoxit={handleIsBoxit}
           sizeInput={"form-control-md"}
         />
       </div>

       <div className="col-lg-6 col-sm-12 form-group">
      
         {
       isBoxit &&
       idPlatform.current !== translate("global.select") &&
       idPlatform.current !== "" && (
         <div className="form-group col-sm-12 col-md-6 mb-1">
              <label htmlFor="incidentTitle">
           {translate("dashboard.platforms.numDoor")}
         </label>
           <input
                type="text"
                className="form-control form-control-md font-small"
                name="puerta"
                ref={register}
              />
         </div>
       )}
       </div>
     </div>
    )}
         

          <div className="row">
          {isTypeIncidentSoftware && (
            <div className="col-lg-6 col-sm-12 form-group">
              <label htmlFor="incidentTitle">
                {translate("global.numPackage")}
              </label>
              <input
                type="text"
                className="form-control form-control-md font-small"
                name="idPackage"
                ref={register}
              />
            </div>
          )}
            <div className="col-lg-6 col-sm-12 form-group">
              <label htmlFor="incidentTitle">{translate("global.email")}</label>
              <input
                type="email"
                className="form-control form-control-md font-small"
                name="email"
                ref={register}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="incidentDescription">
              {translate("global.description")} <span style={{color:"red"}}>*</span>
            </label>
            <textarea
              className="form-control form-control-md font-small"
              id="incidentDescription"
              rows="3"
              name="description"
              required
              ref={register}
            ></textarea>
          </div>

          <div
            className="form-group"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="incidentTitle" style={{ marginBottom: "5px" }}>
              {translate("global.date")} <span style={{color:"red"}}>*</span>
            </label>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                type="date"
                className="form-control form-control-md font-small"
                required
                ref={(e) => {
                    register(e);
                    dateInputRef.current = e; 
                  }}
                name="dateIncident"
                style={{ maxWidth: "200px" }}
              />
            </div>
          </div>

          <div
            className="form-group"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center" ,
              gap:"5px"
            }}
          >
              <button type="reset" className="btn btn-outline-fucsia m-2"  style={{ width: "150px" }}>
            {translate("global.clean")}
          </button>

            <button type="submit" className="btn btn-fucsia m-2"  style={{ width: "150px" }} disabled={isSendingData}>
            {translate("global.create")}
          </button>
          </div>
          
        </form>
      </div>
    </div>
  );
};

export default RegisterIncdent;
