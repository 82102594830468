import { getNameDoorsMovements, setStatusName } from "../../utils/Common";

const ItemDoorPlatforms = ({ item }) => {
  return (
    <tr
      className="orderRow"
      key={item?.movementId}
      style={{ position: "relative" }}
    >
      <td>{item?.movementId}</td>
      <td>{getNameDoorsMovements(item?.movementTypeId)}</td>
      <td>{item?.packageId}</td>
      <td>{item?.packageTracking}</td>
      <td>{item?.date}</td>
      <td>{setStatusName(item?.statusId)}</td>
    </tr>
  );
};

export default ItemDoorPlatforms;
