import React, { useState} from "react";
import GoogleMapReact from "google-map-react";
import { Modal } from "react-bootstrap";
import { ImLocation } from "react-icons/im";
import LocationPinMap from "../LocationPinMap";
import { convertCoordinatesToArrayWithLatAndLng} from "../../utils/Common";
import "./map.css";

const MapContainer = ({ locations, zoomLevel }) => {
  const locationsObj = convertCoordinatesToArrayWithLatAndLng(locations);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

 
  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    openModal(location);
  };

  const containerStyle = {
    width: "100%",
    height: "600px",
    borderRadius: "30px",
  };

  const openModal = (location) => {
    setSelectedLocation(location);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedLocation(null);
    setIsModalOpen(false);
  };

  return (
    <div className="map">
      {isModalOpen && selectedLocation && (
        <Modal show={isModalOpen} onHide={closeModal} scrollable={false}>
          <Modal.Header closeButton={true}>
            <Modal.Title>{selectedLocation.locationName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <img
                src={selectedLocation.locationPhoto}
                alt="Location"
                width="75%"
              />
            </div>

            <p className="row">
              <span className="col-2 font-bold">Dirección</span>
              <span className="col-10">{selectedLocation.locationAddress}</span>
            </p>
            {selectedLocation?.indoorReference && (
              <p className="row">
                <span className="col-6 font-bold">Referencia del local</span>
                <span className="col-6">
                  {selectedLocation.indoorReference}
                </span>
              </p>
            )}
            {selectedLocation?.outdoorReference && (
              <p className="row">
                <span className="col-6 font-bold">Referencia externa</span>
                <span className="col-6">
                  {selectedLocation.outdoorReference}
                </span>
              </p>
            )}

          {selectedLocation?.schedule && (
              <p className="row">
              <span className="col-2 font-bold">Horario</span>
              <span className="col-10">{selectedLocation.schedule}</span>
            </p>
          )}
          
            <div className="d-flex justify-content-between">
              <span className="main-btn alternative btn" onClick={closeModal}>
                Cerrar
              </span>
              <a
                href={selectedLocation.linkMaps}
                className="main-btn primary d-flex align-content-center btn"
                target="_blank"
                rel="noreferrer"
              >
                <ImLocation
                  size={20}
                  style={{ marginRight: "5px", color: "white" }}
                />{" "}
                Ver en el Mapa
              </a>
            </div>
          </Modal.Body>
        </Modal>
      )}

 {
        <div style={containerStyle}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
            defaultCenter={locationsObj[0]}
            defaultZoom={zoomLevel}
          >
            {locationsObj.map((location, index) => (
              <LocationPinMap
                key={index}
                lat={location.lat}
                lng={location.lng}
                text={location.locationName}
                onClick={() => handleMarkerClick(location)}
              />
            ))}
          </GoogleMapReact>
        </div>
      }
    </div>
  );
};

export default MapContainer;
