import React from "react";
import {BsFillPeopleFill} from "react-icons/bs"
import "./index.css";

const testimonialsData = [
  {
    stars: "★ ★ ★ ★ ★",
    imageSrc: "../img/circle_logo_boxit.png",
    text:
      "Somos IIDEAS IIMPRESAS TILKA y estamos encantados con BOXIT. Full recomendado. Muchas veces nuestros clientes nos solicitan el servicio de delivery, pero en ocasiones no están disponibles a la hora que tenemos una ruta de entrega por su área y BOXIT soluciona este detalle. Dejamos el paquete en el BOXIT mas cercano al lugar de entrega y el cliente lo retira cuando esta disponible. Así evitamos tener que regresar otro día a hacer la entrega, lo que es un ahorro de tiempo y combustible (dinero). Ojalá algún día tengan BOXIT a nivel nacional.",
    author: "IIDEAS IIMPRESAS TILKA",
    role: "Cliente Boxit",
  },
  {
    stars: "★ ★ ★ ★ ★",
    imageSrc: "../img/circle_logo_boxit.png",
    text:
      "¡Mi experiencia con Boxit ha sido excepcional!, muy buena atención y personalizada, no tengo queja alguna y lo recomiendo 100%",
    author: "AL estilo Panamá",
    role: "Cliente Boxit",
  },
  {
    stars: "★ ★ ★ ★ ★",
    imageSrc: "../img/circle_logo_boxit.png",
    text:
      "Mi nombre es Andrés, mi emprendimiento se llama Printologo. Para mi contar con Boxit ha sido una experiencia maravillosa ya que ha sido la cura para ciertos dolores puntuales en mi negocio. Debido a que tengo un negocio que opera de 9am a 5pm, muchos clientes no pueden pasar al taller a retirar sus pedidos, ya que laboran en el mismo horario. Boxit me permite darles a mis clientes la facilidad de retirar sus paquetes en cualquier horario de una manera fácil y rápida. Incluso puedo disfrutar del fin de semana sin preocuparme de coordinar entregas con los clientes.",
    author: "Andrés",
    role: "Cliente Boxit",
  },
];

const TestimonialItem = ({ stars, imageSrc, text, author, role }) => (
  <div className="item-testimonials">
    <div className="stars">{stars}</div>
    <img src={imageSrc} alt="circle_logo_boxit" />
    <p>{text}</p>
    <strong>{author}</strong>
    <div style={{ color: "gray" }}>{role}</div>
  </div>
);

const Testimonials = () => {
  return (
    <div className="section-testimonials">
      <h1 style={{ color: "#6E437A" }} className="title-benefits">
      <BsFillPeopleFill className="mr-4"/>
        <strong>TESTIMONIOS</strong> DE CLIENTES
      </h1>

      <div className="list-testimonials">
        {testimonialsData.map((testimonial, index) => (
          <TestimonialItem key={index} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
