import React from 'react';
import { getFormatDate, getStatusText, formatter, translate } from '../../utils/Common';
import './index.css';


const ItemInvoicingBilling = ({ item, setActiveTab, activeBilling, activeBillingAmount }) => {
    const statusInfo = getStatusText(item.IdEstatus);

    const handleViewBilling = () => {
        activeBilling(item.IdBilling);
        activeBillingAmount(item.MontoFacturado);
        setActiveTab(3);
    };
    

    return (
        <tr
            className={`orderRow`}
            key={item.IdBilling}
        >
            <th>#{item.IdBilling}</th>
            <td >{getFormatDate(item.FechaBillingInicio)} - {getFormatDate(item.FechaBillingFin)}</td>
            <td>
                {formatter.format(item?.MontoFacturado)}
            </td>
            <td> <div style={{ color: `${statusInfo.color}` }}>{statusInfo.icon} {" "} {statusInfo.text}</div> <div style={{fontSize:"10px"}}>{statusInfo.infoText && item?.Descripcion}</div> </td>

            <td>
            {statusInfo.action && (
                    <span
                        style={{ cursor: 'pointer', color:"white", background: '#424996', padding: '4px 10px', borderRadius:"50px" }}
                        onClick={handleViewBilling}
                    >
                      {translate("global.makePayment")}
                    </span>
                ) }
            </td>
        </tr>
    );
};

export default ItemInvoicingBilling;
 
