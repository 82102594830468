import { translate } from "../../utils/Common";
import Spinner from "../Spinner";
const ShowCanvasFilters = ({
  itemArray,
  Item,
  noItemsFound,
  actualPage,
  itemsShowing,
}) => {
  return (
    <>
      <div className="grid-container">
        {itemArray ? (
          itemArray?.length > 0 ? (
            itemArray.map(
              (item, index) =>
                index >= actualPage * itemsShowing - itemsShowing &&
                index < actualPage * itemsShowing && (
                  <Item key={index} index={index} item={item} />
                )
            )
          ) : (
            <div>
              {noItemsFound ? noItemsFound : translate("global.noResults")}
            </div>
          )
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div
              className="spinner-border text-fucsia"
              role="status"
              style={{ color: "#424996" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShowCanvasFilters;
