import { useState, useEffect } from 'react';
import { getClientDomain, getToken, translate } from '../../../../utils/Common';
import Spinner from '../../../../components/Spinner';

// tabla con el tracking de boxit
const TableEstatusBoxit = ({ idPaquete, updateComponent }) => {
    // tracking boxit
    const [boxitTrackingPaquete, setBoxitTrackingPaquete] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const abortController = new AbortController();

        const getTrackingPaquete = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/tracking/boxit/${idPaquete}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    signal: abortController.signal
                });
                const result = await response.json();
                setIsLoading(false);
                if (result.payload) setBoxitTrackingPaquete(result.payload);
            } catch (err) {
                console.log(err);
            }
        }

        getTrackingPaquete();
        return () => abortController.abort();
    }, [idPaquete, updateComponent]);

    const tableHeader = <thead className="text-center font-small">
                            <tr>
                                <th className="col-4">{translate("global.date")}</th>
                                <th className="col-8">{translate("global.status")}</th>
                            </tr>
                        </thead>;
    
    let tableRows = [];
    let statusBoxitTracking;
    if (boxitTrackingPaquete) {
        if (boxitTrackingPaquete.length > 0){
            tableRows.push(boxitTrackingPaquete?.map((row, index) => {
                return  <tr key={ index }>
                            <td>{ row.Fecha_Movimiento }</td>
                            <td className="text-left">{ row.Tracking }</td>
                        </tr>;
            }))
        }
    } else{
        statusBoxitTracking = translate("dashboard.packages.details.tableStatusBoxit.noMoved");
    }
        
    return (
        <>
            <div className="form-row text-left mx-1 font-small">
                <div className="form-group col-12 mb-0 py-1 text-center bg-blue">
                    <h6 className="text-light mb-0">{translate("global.trackingPackage")}</h6>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-hover table-sm mb-0">
                    { tableHeader }
                    <tbody className="font-xsmall">
                        { tableRows.length > 0 ? tableRows : <tr><td colSpan='2'>{isLoading ? <Spinner type={1} size={18} /> : statusBoxitTracking}</td></tr> }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TableEstatusBoxit;