import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getClDomain, translate} from '../../utils/Common';
import Traductor from '../../components/Traductor';
// contexts
import AuthContext from "../../contexts/auth";
import SidebarContext from "../../contexts/sidebar";
// icons
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import * as RiIcons from 'react-icons/ri';
import './index.css';
import ViewCountryFlag from '../Country/ViewCountryFlag';

const Navbar = () => {
    const userContext = useContext(AuthContext);
    const sidebarContext = useContext(SidebarContext);

    const [isMenuProfileOpen, setIsMenuProfileOpen] = useState(false);

    let menuRef = useRef();

    useEffect(() => {
        const handlerCloseMenuProfile = (e) => !menuRef.current.contains(e.target) && setIsMenuProfileOpen(false)

        document.addEventListener("mousedown", handlerCloseMenuProfile);

        return () => document.removeEventListener("mousedown", handlerCloseMenuProfile);
    }, []);

    return(
        <div 
            className={`d-flex justify-content-between shadow-sm nav${!sidebarContext.sidebarOpen ? '-close' : ''}`}
            style={ getClDomain() === 'bli.myboxit.com' ? { top: '0' } : { top: '22px' } }>
            <div className="bars-div" onClick={ () => sidebarContext.showSidebar(!sidebarContext.sidebarOpen) }>
                <FaIcons.FaBars className="bars" size={ 25 } />
            </div>

            <div className="d-flex align-items-center">
                <div><ViewCountryFlag /></div>
                <div className="btn-traductor-nav">
                    <Traductor /> 
                </div>
                
            <div ref={ menuRef } className={ "user-section" + (isMenuProfileOpen ? " active" : "") } onClick={ () => setIsMenuProfileOpen(!isMenuProfileOpen) }>
                <div className="d-flex align-items-center justify-conetnt-center">
                    <BsIcons.BsPersonCircle className="user shadow" size={ 25 } />
                    <p className="username text-uppercase">
                        { " "}
                     { userContext.user?.Usuario ? userContext.user?.Usuario : 'usuario' }
                   </p>
                    { isMenuProfileOpen ? <RiIcons.RiArrowUpSFill className="menu-arrow"/> : <RiIcons.RiArrowDownSFill className="menu-arrow"/> }
                </div>
                <ul className="dropdown-user">
                    <li className="nav-option"><Link className="user-option-text" to="/dashboard/profile/" onClick={ () => sidebarContext.handleChangeCurrentPage("Perfil") }>{translate("navbar.profile")}</Link></li>
                    <li className="nav-option"><Link className="user-option-text" to="/login" onClick={ () => userContext.signOut() }>{translate("navbar.logout")}</Link></li>
                </ul>
            </div>
            </div>
        </div>
    );
}

export default Navbar;