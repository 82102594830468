import { useState, useRef, useContext, useEffect } from 'react';
import { getClientDomain, getToken, statusDeliveryOrderFormat, translate,getNameStatus } from '../../../utils/Common';
import GoogleMap from 'google-map-react';
import ModalContext from '../../../contexts/modal';
import DestinationMarker from '../../../components/DestinationMarker';
import { useSystem } from '../../../hooks/useSystem';

const OrderDetails = ({ selectedOrder, setSelectedOrder, setUpdate, update }) => {
    const { getCarriers } = useSystem();
    const [transport, setTransport] = useState('');
    const amountRef = useRef({});
    const [error, setError] = useState('');
    const modalContext = useContext(ModalContext);
    const [carriers, setCarriers] = useState(null);
    // [
    //     {
    //         IdTransportista: 1,
    //         DescDetalle: 'PEDIDOSYA'
    //     },
    //     {
    //         IdTransportista: 2,
    //         DescDetalle: 'UBER'
    //     },
    //     {
    //         IdTransportista: 3,
    //         DescDetalle: 'ASAP'
    //     },
    //     {
    //         IdTransportista: 4,
    //         DescDetalle: 'INDRIVER'
    //     },
    //     {
    //         IdTransportista: 5,
    //         DescDetalle: 'PIDEPAYA'
    //     },
    //     {
    //         IdTransportista: 6,
    //         DescDetalle: 'YAVOY'
    //     },
    //     {
    //         IdTransportista: 7,
    //         DescDetalle: 'INDEPENDIENTE'
    //     }
    // ]

    const handleAskDelivery = async () => {
        try {
            setError('');
            if (amountRef.current.value !== '' && transport !== '') {
                try {
                    let dataToSend = {}
                    dataToSend.idPaquete = Number(selectedOrder.IdPaquete);
                    dataToSend.idEnvio = Number(selectedOrder.IdEnvio);
                    dataToSend.montoEnvio = Number(amountRef.current.value);
                    dataToSend.idTransportista = Number(transport);
                    
                    const response = await fetch(`${getClientDomain(2)}/dashboard/packages/delivery/ask` , {
                        method: 'POST',
                        connection: 'keep-alive',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${getToken()}`
                        },
                        body: JSON.stringify(dataToSend)
                    });
                    const result = await response.json();
                    if (result?.code === 200) {
                        if (result?.payload.length > 0) {
                            modalContext.confirm(translate("global.alert.ready"), 
                                <span>{translate("global.alerts.registeredSuccessfully")}.</span>,
                                <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                            setSelectedOrder(null);
                            setUpdate(!update);
                        }
                    }
                    else setError(translate("global.alerts.requestError"));
                } catch (err) {
                    console.log(err);
                    setError(translate("global.alerts.requestError"));
                }
            }
            else setError(translate("dashboard.delivery.selectTransport"));
        } catch (err) {
            console.log(err);
            setError(translate("global.alerts.requestError"));
        }
    }

    const handleDeliveryComplete = async () => {
        try {
            try {
                let dataToSend = {}
                dataToSend.idPaquete = Number(selectedOrder.IdPaquete);
                dataToSend.idEnvio = Number(selectedOrder.IdEnvio);
                
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/delivery/complete` , {
                    method: 'POST',
                    connection: 'keep-alive',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    body: JSON.stringify(dataToSend)
                });
                const result = await response.json();
                if (result?.code === 200) {
                    if (result?.payload.length > 0) {
                        modalContext.confirm(translate("global.alert.ready"), 
                            <span>{translate("global.alerts.completedSuccessfully")}</span>,
                            <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                            true);
                        setSelectedOrder(null);
                        setUpdate(!update);
                    }
                }
                else setError(translate("global.alerts.requestError"));
            } catch (err) {
                console.log(err);
                setError(translate("global.alerts.requestError"));
            }
        } catch (err) {
            console.log(err);
            setError(translate("global.alerts.requestError"));
        }  
    }

    const resetForm = () => {
        amountRef.current.value = '';
        setTransport('');
        setError('');
    }

    useEffect(() => {
        const controller = new AbortController()
        const handleCarriers = async () => {
            const carrier = await getCarriers(controller.signal);
            setCarriers(carrier);
        }

        !carriers && handleCarriers();
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="row mx-2">
                <div className="col-12 col-md-7 p-2">
                    <div className='dashboardCard2 p-4 text-left'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-white px-0 pt-0 pb-2 border-bottom">
                                <li className={"breadcrumb-item breadcrumLink " + (!selectedOrder ? '' : 'boxit-fucsia')} onClick={() => setSelectedOrder(null)}>{translate("global.Orders")}</li>
                                <li className="breadcrumb-item">{translate("global.detail")}</li>
                            </ol>
                        </nav>
                        <h6 className='m-0'>{translate("global.orderDetail")}</h6>
                        <div className="form-row">
                            <div className="col-3">
                                <p className='mb-1 mt-2'>Nro. {translate("global.order")}:</p>
                                <input type="text" className="form-control form-control-sm mb-1" defaultValue={selectedOrder.IdEnvio} disabled/>
                            </div>
                            <div className="col-5 col-md-5">
                                <p className='mb-1 mt-2'>Nro. {translate("global.package")}:</p>
                                <input type="text" className="form-control form-control-sm mb-1" defaultValue={selectedOrder.NumeroIdentificacion} disabled/>
                            </div>
                            <div className="col-4 col-md-4">
                                <p className='mb-1 mt-2'>{translate("global.orderType")}:</p>
                                <input type="text" className="form-control form-control-sm mb-1" defaultValue={selectedOrder.TipoEnvioDesc} disabled/>
                            </div>
                            <div className="col-3">
                                <p className='mb-0 mt-2'>{translate("global.shippingStatus")}:</p>
                                <input type="text" className={`form-control-plaintext font-bold form-control-sm mb-1 ${statusDeliveryOrderFormat(selectedOrder.IdEstatus)}`} defaultValue={getNameStatus(selectedOrder.IdEstatus)} disabled/>
                            </div>
                            <div className="col-8 col-md-9">
                                <p className='mb-0 mt-2'>{translate("global.destinationAddress")}:</p>
                                <input type="text" className="form-control-plaintext font-bold form-control-sm mb-1" defaultValue={selectedOrder.DireccionDestino} disabled/>
                            </div>
                            <div className="col-8 col-md-9">
                                <p className='mb-0 mt-2'>{translate("global.description")}:</p>
                                <input type="text" className="form-control-plaintext font-bold form-control-sm mb-1" defaultValue={selectedOrder.Descripcion} disabled/>
                            </div>
                            { selectedOrder.IdTransportista &&
                            <>                                
                                <div className="col-4 col-md-4">
                                    <p className='mb-0 mt-2'>{translate("global.shippingAmount")}:</p>
                                    <input type="text" className="form-control-plaintext font-bold form-control-sm mb-1" defaultValue={selectedOrder.Monto} disabled/>
                                </div>
                                <div className="col-4 col-md-4">
                                    <p className='mb-0 mt-2'>{translate("global.carrier")}:</p>
                                    <input type="text" className="form-control-plaintext font-bold form-control-sm mb-1" defaultValue={selectedOrder.TransportistaDet} disabled/>
                                </div>
                                { selectedOrder.IdEstatus === 5 &&
                                    <div className="col-12">
                                        <p className='mb-1'>{translate("global.question.completeShipping")}</p>
                                        <button className="btn btn-sm btn-fucsia" type='button' onClick={handleDeliveryComplete}>{translate("global.complete")}</button>
                                    </div>
                                }
                            </>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 p-2">
                    <div className='dashboardCard2 p-4 text-left'>
                        <h6 className='mb-2 mt-0'>{translate("global.shippingLocation")}</h6>
                        <div style={{ width: '100%', height: '15rem'}}>
                            <GoogleMap 
                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                                center={{ lat: Number(selectedOrder?.Lat), lng: Number(selectedOrder?.Lng) }}
                                zoom={15}
                                defaultCenter={{ lat: Number(selectedOrder?.Lat), lng: Number(selectedOrder?.Lng) }}
                                defaultZoom={17}
                            >
                                <DestinationMarker 
                                lat={selectedOrder?.Lat}
                                lng={selectedOrder?.Lng}
                                text={selectedOrder?.DireccionDestino ? selectedOrder.DireccionDestino : translate("global.destination")}
                            />
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            </div>
            { selectedOrder.IdEstatus !== 6 && !selectedOrder.IdTransportista &&
                <div className="row mx-2 mb-2">
                    <div className="col-12 p-2">
                        <div className="dashboardCard2 p-4 text-left">
                            <h6 className='mb-2'>{translate("global.chooseCarrier")}</h6>
                            <div className="form-row mb-2">
                                { carriers?.length > 0 ? carriers?.map(transportista => 
                                    <div className="col-4 col-md-3 p-2 text-center transpCardOut" key={transportista.IdTransportista}>
                                        <div className={`p-1 transportistaCard rounded shadow-sm border ${transport === transportista.IdTransportista ? 'transportActive' : ''}`} onClick={() => setTransport(transportista.IdTransportista)}>
                                            <img src={`/img/${transportista.DescDetalle}.png`} className='p-2'
                                            width="100%" border="0" alt={transportista.DescDetalle} />
                                        </div>
                                    </div>
                                )
                                :
                                <div className="col-4 col-md-3 p-2 text-center transpCardOut">
                                    <div className="p-1 transportistaCard rounded shadow-sm border">
                                        {translate("global.loading")}
                                    </div>
                                </div>
                                }
                            </div>  
                            
                            <h6 className='mb-2'>{translate("dashboard.delivery.shippingAmount")}</h6>
                            <div className='col-4 p-0'>
                                <div className="input-group">
                                    <input type="number"
                                        className="form-control form-control-sm" 
                                        placeholder='3.5'
                                        ref={amountRef} />
                                    <div className="input-group-append">
                                        <button className="btn btn-sm btn-fucsia" type='button' onClick={handleAskDelivery}>{translate("global.accept")}</button>
                                        <button className="btn btn-sm btn-outline-fucsia" type='button' onClick={resetForm}>{translate("global.reset")}</button>
                                    </div>
                                </div>
                            </div>
                            { error && <p className="mx-0 mb-0 mt-1 text-danger">{error}</p> }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default OrderDetails;