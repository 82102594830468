import { Alert } from 'react-bootstrap';
import {translate} from ".././../../../utils/Common"

const SuccessPickUp = ({ setSuccessPickUp, pickUpPackagesFound, setPickUpPackagesFound }) => {
    const handleBack = () => {
        setPickUpPackagesFound([]);
        setSuccessPickUp(false);
    }

    return (
        <div className="form-row text-center d-flex justify-content-center flex-column dashboardCard py-0" >
            <Alert variant='success' className="col-12 my-4">
                <h2>{translate("dashboard.packages.pickUp.success.title" )}</h2>
                <p className="mb-1">
                    {translate("dashboard.packages.pickUp.success.description")}:
                </p>
                { pickUpPackagesFound?.map((paquete, index) => <p className="mb-0 font-bold" key={ index }>{ paquete.Nro_Id }</p>) }
                <p className="mt-1 mb-1">
                    {translate("dashboard.packages.pickUp.success.email")}
                </p>
            </Alert>

            <div className="col-12 mb-4">
                <button className="btn btn-md btn-fucsia" type="button" onClick={ () => handleBack() }>
                    {translate("global.accept")}
                </button>
            </div>
        </div>
    );
}

export default SuccessPickUp;