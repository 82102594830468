import React, { useContext } from "react";
import { useEffect, useState } from "react";
import MapContainerSearch from "../../../../components/MapContainerSearch";

import "./index.css";
import AuthContext from "../../../../contexts/auth";
import { usePlatforms } from "../../../../hooks/usePlatforms";

const LocatePlatforms = () => {
  const userContext = useContext(AuthContext); 
  const { getPlatforms } = usePlatforms();

  const [platformsAvailable, setPlatformsAvailable] = useState([]);


  useEffect(() => {
    const abortController = new AbortController();

    const handlePlatforms = async () => {
        const platforms = await getPlatforms(userContext.user?.IdCliente, abortController.signal);
        setPlatformsAvailable(platforms);
    }

    handlePlatforms();
    
    return () => abortController.abort();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div >
      <div className="content-location-map">
        <div>
          {platformsAvailable.length > 0 && (<MapContainerSearch locations={platformsAvailable} zoomLevel={13}/>)}  
        </div>

      </div>

     
    </div>
  );
};

export default LocatePlatforms;
