import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../contexts/auth';
import { exportToPDF } from '../../utils/Common';
import Results from './Results';
import ItemsPagination from './ItemsPagination';
import './index.css';
import { exportConfig } from '../../config/exportPdf';

const ShowItems = ({ 
    ItemComponent, itemsFound, itemName, showBox, handleCancelBusqueda, setSelectedItem, itemsShowing, view, typeExport, handleUpdateData
}) => {
    const userContext = useContext(AuthContext);
    const [actualPage, setActualPage] = useState(Number(sessionStorage.getItem('currentPage')) ? Number(sessionStorage.getItem('currentPage')) : 1);
    const [pages, setPages] = useState(Math.ceil(itemsFound.length / itemsShowing));

    const exportData = (data) => {
        
        const config = exportConfig[typeExport || "packages"];

        if (!config) {
            console.error(`Export type "${typeExport}" is not defined.`);
            return;
        }

        const columns = config.columns;
        const mapData = config.mapData;
        const fileNamePrefix = config.fileNamePrefix;
        const reportName = config.reportName;
        const reportDescription = config.reportDescription;
        
        const rows = data.map(item => mapData(item, userContext?.user.IdTipoUsuario));
        
        const fileName = fileNamePrefix[itemName] || fileNamePrefix.default;
        const reportTitle = reportName[itemName] || reportName.default;

        exportToPDF(reportTitle, fileName + "Boxit", columns, rows, reportDescription);
    }

    
    useEffect(() => {
        if (actualPage >= pages) setActualPage(pages);
        if (actualPage === 0) setActualPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])
    
    useEffect(() => {
        if (Math.ceil(itemsFound.length / itemsShowing) !== pages) setPages(Math.ceil(itemsFound.length / itemsShowing));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsFound, itemsShowing]);

    return(
        <>
            <Results
                ItemComponent={ ItemComponent }
                itemName={ itemName }
                showBox={ showBox }
                setSelectedItem={ setSelectedItem }
                itemsFound={ itemsFound }
                actualPage={ actualPage }
                itemsShowing={ itemsShowing }
                view={view}
                handleUpdateData={handleUpdateData} />

            { itemsFound?.length > 0 &&
                <ItemsPagination
                    actualPage={ actualPage }
                    setActualPage={ setActualPage }
                    pages={ pages }
                    itemsFound={ itemsFound }
                    exportData={exportData}
                    itemName={itemName}
                    handleCancelBusqueda={handleCancelBusqueda}/>
            }
        </>
    );
}

export default ShowItems;