import { useState, useEffect } from "react";
// icons
import * as BsIcons from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
//others

import { useBilling } from "../../../../../hooks/useBilling";
import Spinner from "../../../../../components/Spinner";
import ShowTable from "../../../../../components/ShowTable";
import ItemAdminBilling from "../../../../../components/ShowTable/ItemAdminBilling";
import {
  translate,
  generateExcelFile,
  getMonthsBilling,
  getNameMonth,
  rangeYears,
  getAllMonths,
  typeStatus,
  countIdStatus,
  formatListAdminBilling,
} from "../../../../../utils/Common";
import "./index.css";

const ListInvoicing = ({ data, setListInvoicing, setItemSelected, setSelectDate, selectDate, categories, setCategories, originalListInvoicing ,setOriginalListInvoicing}) => {
  const { getAdminInvoicingMonthly } = useBilling();

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [viewMonths, setViewMonths] = useState(false);
  const [selectStatus, setSelectStatus] = useState(null);
  const [viewStatus, setViewStatus] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getYear = (date) => {
    return date.getFullYear();
  };

  const years = rangeYears(2015, getYear(new Date()) + 1, 1);
  const monthsN = getAllMonths();

  const [headerRow] = useState(
    <tr>
      <th>ID {translate("global.invoice")}</th>
      <th>{translate("global.client")}</th>
      <th>{translate("global.periodInvoicing")}</th>
      <th>{translate("global.amountBilled")}</th>
      <th>{translate("global.amountPayment")}</th>
      <th>{translate("global.returned")}</th>
      <th>{translate("global.state")}</th>
      <th>{translate("global.action")}</th>
    </tr>
  );

  const renderTitleHeader = (
    <h6 className="m-0" style={{ color: "#424996", fontSize: "20px" }}>
      <BsIcons.BsCreditCard
        size={35}
        style={{ paddingRight: "15px", fontWeight: "bold" }}
      />{" "}
      {translate("sidebar.options.accounting.billing")}
    </h6>
  );

  const renderCategoriesTabs = (
    <>
      {typeStatus.map((category) => (
        <div key={category.id} className="item-category mr-3">
          <div
            className="d-flex align-items-center"
            style={{ color: category.color }}
          >
            {category.icon && (
              <span style={{ marginRight: "5px" }}>{category.icon}</span>
            )}
            <div style={{ fontSize: "20px" }}>{categories[category.id]}</div>
          </div>
          <h6 style={{ color: "#7A7A7A" }}>{category.text}</h6>
        </div>
      ))}
    </>
  );

  const renderBtnDownloadExcel = data && data.length > 0 && (
    <div
      className="btn-download-billing mt-2"
      onClick={() => handleDownloadExcel()}
    >
      {!isLoadingExport ? (
        <>
          <BsDownload style={{ marginRight: "3px" }} />{" "}
          {translate("global.download")} Excel
        </>
      ) : (
        <Spinner type={1} size={18} />
      )}
    </div>
  );

  const renderBtnFilterDate = (
    <div>
      <div
        className={`btn-filter-date mt-2 ${viewMonths && "isActive"}`}
        onClick={() => handleCloseViewDate()}
      >
        {translate("global.filterBy")}{" "}
        <strong>
          {selectDate.monthName} {selectDate.year}
        </strong>{" "}
        <MdOutlineKeyboardArrowDown />
      </div>
      {viewMonths && (
        <div
          className="btn-filter-date-admin"
          style={{ position: "absolute", marginTop: "10px" }}
        >
          <div className="d-flex justify-content-between">
            <select
              className="form-select form-control"
              style={{ fontSize: "12px", marginRight: "5px" }}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option disabled selected value="">
                -- {translate("global.month")}
              </option>
              {monthsN.map((option, index) => (
                <option key={index + 1} value={index + 1}>
                  {option}
                </option>
              ))}
            </select>

            <select
              className="form-select form-control"
              style={{ fontSize: "12px" }}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option disabled selected value="">
                -- {translate("global.year")}
              </option>
              {years.reverse().map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <button
              className="btn btn-outline-primary text-sm mr-2"
              style={{ fontSize: "12px" }}
              onClick={() => handleCloseViewDate()}
            >
              {translate("global.cancel")}
            </button>
            <button
              className="btn btn-primary"
              style={{ fontSize: "12px" }}
              disabled={!selectedMonth || !selectedYear}
              onClick={() => handleSelectDate()}>
                { !isLoading ? translate("global.apply") : <Spinner type={1} size={"25"} />}
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const filterByStatus = (array, idEstatus) => {
    return array.filter(function (objeto) {
      return objeto.IdEstatus === idEstatus;
    });
  };

  const handleSelectStatus = (item) => {
    if (item) {
      const response = filterByStatus(originalListInvoicing, item.id);
      console.log(response);
      setListInvoicing(response);
    } else {
      setListInvoicing(originalListInvoicing);
    }
    setSelectStatus(item);
    setViewStatus(false);
  };

  const renderBtnStatus = (
    <div>
      <div
        className={`btn-filter-date mt-2 ${viewStatus && "isActive"}`}
        onClick={() => setViewStatus(!viewStatus)}
      >
        {translate("global.state")}{" "}
        <strong>
          {selectStatus ? selectStatus.text : translate("global.all")}
        </strong>{" "}
        <MdOutlineKeyboardArrowDown />
      </div>
      {viewStatus && (
        <div className="btn-filter-date-months">
          <ul className={`fade-in-list`} style={{ position: "absolute" }}>
            <li
              className={`btn-filter-date-month`}
              onClick={() => handleSelectStatus()}
            >
              {translate("global.all")}
            </li>
            {typeStatus.map((item, index) => (
              <li
                key={index}
                className={`btn-filter-date-month`}
                onClick={() => handleSelectStatus(item)}
              >
                {item.icon} {item.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  const handleDownloadExcel = async () => {
    setIsLoadingExport(true);

    setTimeout(() => {
      generateExcelFile(formatListAdminBilling(data), "BillingAdmin");
      setIsLoadingExport(false);
    }, 1000);
  };

  const handleCloseViewDate = () => {
    setViewMonths(!viewMonths);
    setSelectedMonth("");
    setSelectedYear("");
  };

  const handleSelectDate = () => {
    const dateSelect = {
      month: selectedMonth,
      year: selectedYear,
      monthName: getNameMonth(selectedMonth),
    };
    reloadDetailsUsage(dateSelect);
    setSelectDate(dateSelect);
    handleCloseViewDate();
  };

  const reloadDetailsUsage = async (date) => {
    const controller = new AbortController();
    try {
      setIsLoading(true)
      const originData = date === undefined ? getMonthsBilling()[0] : date;
      const periodSelect = {
        period: originData.month + originData.year,
      };
      const response = await getAdminInvoicingMonthly(
        controller.signal,
        periodSelect
      );
      
      setIsLoading(false);
      setListInvoicing(response);
      setOriginalListInvoicing(response);
      setCategories(countIdStatus(response));
    } catch (err) {
      // console.log(err);
      setListInvoicing([]);
      setOriginalListInvoicing([]);
      setCategories({});
    }
    return () => controller.abort();
  };

  const reloadData = async (date) => {
    reloadDetailsUsage(date);
  }

  const renderContent = (
    <div className="text-left" style={{ padding: "2rem 3rem" }}>
      {renderTitleHeader}
      <div className="d-flex justify-content-between">
        <div className="mt-2 d-flex">{renderCategoriesTabs}</div>
        <div className="d-flex align-items-center">
          {renderBtnDownloadExcel}
          {renderBtnStatus}
          {renderBtnFilterDate}
        </div>
      </div>
      <div className="mt-4">
        <ShowTable
          itemArray={data}
          headerRow={headerRow}
          Item={(props) => (
            <ItemAdminBilling {...props} setItemSelected={setItemSelected} reloadData={reloadData} selectDate={selectDate}/>
          )}
          noItemsFound={translate("dashboard.delivery.table.noItemsFound")}
          actualPage={1}
          itemsShowing={data?.length}
        />
      </div>
    </div>
  );

  const renderLoading = (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <Spinner type={1} size={"25"} />
    </div>
  );

  // useEffect(() => {
  //   reloadDetailsUsage();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return data ? renderContent : renderLoading;
};

export default ListInvoicing;
