import { useState, useContext, useEffect, useCallback } from "react";
import { round, getClientDomain, getToken, translate } from "../../../../utils/Common";
import ModalContext from "../../../../contexts/modal";
import AuthContext from "../../../../contexts/auth";
import FormSearchPackage from "../DropOff/FormSearchPackage";
import FormForPickUp from "./FormForPickUp";
import PackagesForPickUp from './PackagesForPickUp';
import PaymentMethods from "./PaymentMethods";
import SuccessPickUp from "./SuccessPickUp";
import InfoClient from "./InfoClient";
import * as BiIcons from "react-icons/bi";
import './index.css';

const PickUp = () => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    const [cliente, setCliente] = useState(null);

    const [montoFacturado, setMontoFacturado] = useState(0);
    const [saldo, setSaldo] = useState(0);
    const [cambio, setCambio] = useState(0);
    const [addCambio, setAddCambio] = useState(true);

    const [pickUpPackage, setPickUpPackage] = useState(null);
    const [pickUpPackagesFound, setPickUpPackagesFound] = useState([]);
    const [methodsChosen, setMethodsChosen] = useState([]);
    const [saveValidate, setSaveValidate] = useState(false);
    const [paymentValidate, setPaymentValidate] = useState(false);
    const [messageResult, setMessageResult] = useState('');
    const [successPickUp, setSuccessPickUp] = useState(false);
    
    const confirmCancelPickUp = () => {
        modalContext.confirm(
            translate("global.alert.attention"),
            '¿Estás seguro que deseas cancelar la entrega de los paquetes?',
            [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ cancelPickUp }>{translate("global.yes")}</button>,
            <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
            true);
    }

    const cancelPickUp = () => {
        modalContext.setIsOpenModal(false);
        setSaveValidate(false);
        setPickUpPackage(null);
        setPickUpPackagesFound([]);
        setMethodsChosen([]);
        setCliente(null);
        setMessageResult('');
        setSaldo(0);
        setMontoFacturado(0);
        setCambio(0);
        setAddCambio(true);
    }
    
    const handleTotalToPay = () => {
        let total = 0;
        if (pickUpPackagesFound?.length > 0) pickUpPackagesFound.map((p) => total += p.Precio);
        return round(total,2);
    }

    const confirmSavePickUp = () => {
        modalContext.confirm(
            translate("global.alert.attention"),
            translate("global.questionDataCorrect"),
            [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ handleSavePickUp }>{translate("global.yes")}</button>,
            <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
            true);
    }

    const handleSavePickUp = async () => {
        modalContext.setIsOpenModal(false);
        let dataToSend = {
            paquetesEntregar: pickUpPackagesFound, 
            metodosPago: methodsChosen,
            idCliente: cliente,
            addAbono: userContext.user?.IsAdmin ? addCambio : false
        }
        try {
            setMessageResult('');
            if (userContext.user?.IdCliente) {
                if (userContext.user?.IdTipoUsuario === 6 || userContext.user?.IsAdmin) {
                    const response = await fetch(`${getClientDomain(2)}/dashboard/packages/pickup` , {
                        method: 'POST',
                        connection: 'keep-alive',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${getToken()}`
                        },
                        body: JSON.stringify(dataToSend)
                    });
    
                    const result = await response.json();
                    if (result.code === 200) {
                        if (result.payload?.length > 0) {
                            switch (result.payload[0]?.Mensaje) {
                                case 'PICKUP_PROCESSED_SUCCESSFULLY':
                                    setSaveValidate(false);
                                    setPickUpPackage(null);
                                    setMethodsChosen([]);
                                    setCliente(null);
                                    setMessageResult('');
                                    setSaldo(0);
                                    setMontoFacturado(0);
                                    setCambio(0);
                                    setSuccessPickUp(true);
                                    modalContext.confirm(translate("global.alert.ready"), translate("dashboard.packages.pickUp.PICKUP_PROCESSED_SUCCESSFULLY"),
                                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                        true);
                                    break;
                                default:
                                    break;
                            }
                        }
                        else {
                            setMessageResult(translate("dashboard.packages.pickUp.PICKUP_PROCESSED_ERROR"));
                        }
                    }
                    else {
                        setMessageResult(translate("dashboard.packages.pickUp.PICKUP_PROCESSED_ERROR"));
                    }
                }
                else {
                    setSaveValidate(false);
                    setMessageResult(translate("dashboard.packages.pickUp.NOT_AUTHORIZED"));
                }
            }
            else {
                setMessageResult(translate("dashboard.packages.pickUp.SESSION_ERROR"));
            }
        } catch (error) {
            console.log(error);
            setMessageResult(translate("dashboard.packages.pickUp.NOT_FOUND"));
        }
    }

    const handleMethodsChosen = useCallback((value) => setMethodsChosen(value), []);

    useEffect(() => {
        let total = null;
        pickUpPackagesFound?.map(p => total += Number(p.Precio));
        total = total ? round(total, 2) : 0;
        let totalPaid = null;
        methodsChosen?.map(m => totalPaid += Number(m.amount));
        totalPaid = totalPaid ? round(totalPaid, 2) : 0;
        
        const handleSaldo = () => {            
            if ((total - totalPaid) >= 0) setSaldo(round(total - totalPaid,2));
            else setSaldo(0);
        }

        const handleCambio = () => {            
            if ((total - totalPaid) >= 0) setCambio(0);
            else setCambio(Math.abs(round(totalPaid - total,2)));
        }

        const handleMontoFacturado = () => {            
            setMontoFacturado(total > totalPaid  ? round(totalPaid,2) : round(total,2));
        }

        if (total === 0) {
            setSaveValidate(true);
            setPaymentValidate(false);
        }
        else {
            handleSaldo();
            handleCambio();
            handleMontoFacturado();
            setPaymentValidate(true);
            if (totalPaid >= total) setSaveValidate(true);
            else setSaveValidate(false);
        }
    }, [pickUpPackagesFound, methodsChosen]);

    return (
        <>
            <div className="dashboardCard text-left">
                <p className="mb-0"><span className="font-bold">{translate("global.ubication")}: </span>{ userContext.user?.Direccion ? (userContext.user?.Direccion) : translate("global.toBeAssigned") }</p>
                <p className="mb-0"><span className="font-bold">ID {translate("global.ubication")}: </span>{ userContext.user?.IdPlataforma ? (userContext.user?.IdPlataforma) : translate("global.toBeAssigned")}</p>
            </div>

            <div className="dashboardCard text-center py-2 px-0">
                <ul id="progressbar">
                    <li className="li-pickup active" id="search">{translate("global.search")}</li>
                    <li className={ "li-pickup " + ((pickUpPackage || pickUpPackagesFound.length > 0 || successPickUp) ? "active" : "") } id="receive">{translate("global.paymentMethod")}</li>
                    <li className={ "li-pickup " + (successPickUp ? "active" : "") } id="summary">{translate("global.summary")}</li>
                </ul>
            </div>

            { !successPickUp ?
            <>
                { !pickUpPackage ? 
                    <FormSearchPackage 
                        searchType={ 2 }
                        setPackage={ setPickUpPackage } />
                    :
                    <FormForPickUp
                        cliente={ cliente }
                        setCliente={ setCliente }
                        pickUpPackagesFound={ pickUpPackagesFound }
                        setPickUpPackagesFound={ setPickUpPackagesFound }
                        setPackageFound={ setPickUpPackage }
                        packageFound={ pickUpPackage } />
                }
    
                { pickUpPackagesFound.length > 0 &&
                <div className="dashboardCard text-left p-0">
                    <div className="form-row mx-0 mb-1 titleTransaccion">
                        <h6 className="my-1"><BiIcons.BiTransfer size={18} className="mx-2" />{translate("dashboard.packages.pickUp.infoTransaction")}</h6> 
                    </div>

                    {  cliente &&
                        <InfoClient 
                            idCliente={ cliente }
                            methodsChosen={ methodsChosen }
                            handleMethodsChosen={ handleMethodsChosen } />
                    }
                    
                    <PackagesForPickUp
                        handleTotalToPay={ handleTotalToPay }
                        pickUpPackagesFound={ pickUpPackagesFound }
                        setPickUpPackagesFound={ setPickUpPackagesFound }
                        setSaveValidate={ setSaveValidate }
                        setPaymentValidate={ setPaymentValidate }
                        cliente={ cliente }
                        setCliente={ setCliente } />
    
                    { paymentValidate &&                
                        <PaymentMethods
                            handleTotalToPay={ handleTotalToPay }
                            saldo={ saldo }
                            cambio={ cambio }
                            addCambio={ addCambio }
                            setAddCambio={ setAddCambio }
                            montoFacturado={ montoFacturado }
                            pickUpPackagesFound={ pickUpPackagesFound }
                            methodsChosen={ methodsChosen }
                            setMethodsChosen={ setMethodsChosen } />
                    }
    
                    <div className="form-row mx-0">
                        { messageResult && messageResult !== '' &&
                            <div className="text-center col-12 mb-0">
                                <span className="text-danger m-2">{ messageResult }</span>
                            </div>
                        }
    
                        <div className="text-center col-12 mb-2">
                            { saveValidate && <button className="btn btn-sm btn-fucsia m-2 m-2" type="button" onClick={ confirmSavePickUp } >{translate("global.process")}</button>}
                            <button className="btn btn-sm btn-outline-fucsia m-2 m-2" type="button" onClick={ confirmCancelPickUp } >{translate("global.cancel")}</button>
                        </div>
                    </div>
                </div>
                }
            </>
            :
                <SuccessPickUp
                    setSuccessPickUp={ setSuccessPickUp }
                    pickUpPackagesFound={ pickUpPackagesFound }
                    setPickUpPackagesFound={ setPickUpPackagesFound } />
            }
        </>
    );
}

export default PickUp;