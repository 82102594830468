import { useForm } from 'react-hook-form';
import { getClientDomain, getToken, translate } from "../../../../utils/Common";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../../../../contexts/auth";
import QrReader from "react-qr-reader";
// import QrCode from "qrcode.react";
// import jsPDF from 'jspdf';
import 'jspdf-autotable';
// icons
import * as FaIcons from "react-icons/fa";
import * as FiIcons from 'react-icons/fi';
import * as MdIcons from "react-icons/md";

const FormeSearchPackage = ({ setPackage, searchType }) => {
    const { register, errors, setError, clearErrors, handleSubmit } = useForm();
    const userContext = useContext(AuthContext);
    const [showQrReader, setShowQrReader] = useState(false);
    // const [showBarReader, setShowBarReader] = useState(false);
    const [facingMode, setFacingMode] = useState(false);
    const [showPwd, setShowPwd] = useState(false);

    // const exportToPDF2 = () => {
    //     const doc = new jsPDF({
    //         orientation: "portrait",
    //         unit: "px",
    //         format: "a4"
    //     });
    //     let columns = [ 
    //         { title: "Nro. Paquete", dataKey: "nroPaquete" },
    //         { title: "Nro. Tracking", dataKey: "nroTracking" },
    //         { title: "ID Cliente", dataKey: "idCliente" },
    //         { title: "Qr Code", dataKey: "qrcode" } 
    //     ];
    //     let row = [
    //         {
    //             nroPaquete: "XXXX",
    //             nroTracking: "XXXX",
    //             idCliente: "1111"
    //         }
    //     ]
    
    //     let logoBoxit = new Image();
    //     logoBoxit.src = '/img/logo-boxit.png';
    //     doc.addImage(logoBoxit, 'PNG', 340, 20, 80, 30);
    
    //     doc.setFont("Helvetica", "bold");
    //     doc.setFontSize(25);
    //     doc.text("Boxit Logística Inteligente", 40, 40);
    //     doc.setFont("Helvetica", "normal");
    //     doc.setFontSize(10);
    //     doc.text(`Elaborado por: Manuel`, 40, 65);
    //     doc.text(`Reporte: demo guia boxit`, 40, 75);
    
    //     doc.setFont("Helvetica", "bold");
    //     doc.setFontSize(14);
    //     doc.text('DEMO GUIA BOXIT', 170, 95);
    
    //     doc.autoTable({
    //         startY: 110,
    //         styles: { fontSize: 8 },
    //         bodyStyles: { minCellHeight: 30},
    //         columns: columns,
    //         body: row,
    //         didDrawCell: (data) => {
    //             if (data.column.index === 4) console.log('columna 4')
    //         }
    //     });
    
    //     doc.save('report.pdf');    
    // }

    const searchPackage = async (data) => {
        try {
            if (userContext.user?.IdCliente) {
                data.IdUsuario = Number(userContext.user?.Ruc);
                data.IdTipoBusqueda = 2;
                if (searchType === 1) data.numeroPaquete = data.paquete;
                else data.clave = data.paquete;
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/find/tracking` , {
                    method: 'POST',
                    connection: 'keep-alive',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    body: JSON.stringify(data)
                });

                const result = await response.json();
                if (result.code === 200) {
                    if (result.payload?.length > 0) {
                        if ((result.payload[0]?.IdPlataforma !== userContext.user?.IdPlataforma) && !userContext.user?.IsAdmin)
                            setError('paquete', {
                                type: '',
                                message: 'Oh! El paquete que estás buscando tiene otra ubicación.'
                            });
                        else setPackage(result.payload[0]);
                    }
                    else {
                        setPackage(null);
                        setError('paquete', {
                            type: '',
                            message: 'Oh! No hemos encontrado el paquete que estas buscando.'
                        });
                    }
                }
                else {
                    setError('paquete', {
                        type: '',
                        message: 'Ops! Ha ocurrido un error en la búsqueda. Intenta de nuevo.'
                    });
                }
            }
            else {
                setError('paquete', {
                    type: '',
                    message: 'Ops! Vuelve a iniciar sesión e intenta de nuevo.'
                });
            }
        } catch (error) {
            console.log(error);
            setError('paquete', {
                type: '',
                message: 'Ops! Ha ocurrido un error en la búsqueda. Intenta de nuevo en unos minutos.'
            });
        }
    }

    const handleErrorWebCam = (error) => {
        console.log(error)
    }

    const handleScanWebcam = (result) => {
        if (result) {
            searchPackage({ paquete: result });
            setShowQrReader(false);
        }
    }

    const clearInput = () => {
        clearErrors();
        if (searchType === 2) setShowPwd(false);
    }

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);

        window.addEventListener('resize', handleResize);

        if (window.innerWidth < 768) setIsMobile(true);

        if (userContext.user?.IdTipoUsuario === 6) setShowQrReader(true);
        
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form className="dashboardCard mb-2" id="dropoffSearch" onSubmit={ handleSubmit(searchPackage) }>
            <div id="dropOffSearchContent" className="form-row d-flex justify-content-center ">
                <div className="form-group col-12 col-md-7 mb-0 px-0">
                    <label htmlFor="inputPaquete" className="mb-1">{translate("global.enterOrScan")} { searchType === 1 ? translate("global.numIdentity") : translate("global.key") } {translate("global.ofThe")} {translate("global.package")}:</label>
                    <div className="input-group mb-1">
                        <div className="input-group-prepend" onClick={ () => searchType === 2 && setShowPwd(!showPwd) }>
                            <span className="input-group-text" id={ searchType === 1 ? "" : "span-eye-pwd" }>
                            {
                                searchType === 1 ?
                                    <FaIcons.FaCubes size={14} />
                                    :
                                    ( !showPwd ?
                                        <FiIcons.FiEye size={14} className="text-dark"/>
                                        :
                                        <FiIcons.FiEyeOff size={14} className="text-dark"/>   
                                    )
                            }
                            </span>
                        </div>
                        <input type={ searchType === 1 ? "text" : (
                               showPwd ? "text": "password" 
                            ) }
                            className={ "form-control form-control-sm font-small " + (errors.paquete ? 'is-invalid' : '') } 
                            placeholder={ searchType === 1 ? translate("global.numPackages") : translate("global.key") } 
                            id="inputPaquete"
                            name="paquete"
                            ref={ register({
                                required: {
                                    value: true,
                                    message: searchType === 1 ? translate("global.error.notValidPackages") : translate("global.error.validPassword")
                                }}) }
                        />
                        <div className="input-group-append">
                            <button className={ "btn btn-sm font-small btn-" + (searchType === 2 ? "fucsia" : "blue") } type="submit">{translate("global.search")}</button>
                            <button className={ "btn btn-sm font-small btn-outline-" + (searchType === 2 ? "fucsia" : "blue") } type="reset" onClick={ () => clearInput() }>{translate("global.clean")}</button>
                        </div>
                        {errors.paquete && <div className="invalid-feedback mb-1">{errors.paquete.message}</div> }
                    </div>
                </div>

                <div className="form-group col-12 col-md-7 px-0 d-flex justify-content-between mb-0">
                    <button className={ "btn btn-sm btn-hide" + (searchType === 1 ? "-blue" : '') } type="button" onClick={ 
                        () => setShowQrReader(true) }>
                        <span className="font-bold font-small">{translate("global.scanQR")}</span>
                    </button>

                   {/* <button className="btn btn-sm btn-hide" type="button" onClick={ 
                        () => setShowBarReader(true) }>
                        <span className="font-bold font-small">Escanear código de barras</span>
                    </button> */}
                </div>

                { showQrReader &&
                <>
                    <div id="qrReaderBackground" className="col-12 col-md-6 mb-0 px-0" onClick={ () => setShowQrReader(false)}></div>

                    <div id={ isMobile ? "divQrReaderContentMobile" : "divQrReaderContent" }>
                        <QrReader 
                            delay={ 300 }
                            onError={ handleErrorWebCam }
                            onScan={ handleScanWebcam }
                            facingMode={ facingMode ? "user" : "environment" } />

                        <MdIcons.MdFlipCameraAndroid size={40} id="flipCamera" onClick={ () => setFacingMode(!facingMode) } />
                    </div>
                </>
                }
            </div>
        </form>
    );
}

export default FormeSearchPackage;