import React, { useState, useEffect } from "react";
// components
import HeaderActions from "../HeaderActions";
import ShowTable from "../../../../components/ShowTable";
import ItemTransactionsBilling from "../../../../components/ShowTable/ItemTransactionsBilling";
import Spinner from '../../../../components/Spinner';

//utils
import { translate, getMonthsBilling, formatTransactionMonthly } from "../../../../utils/Common";
import { useBilling } from "../../../../hooks/useBilling";

const Transactions = () => {
  const { getTransactionsMonthly, getDetailsUsage } = useBilling();
  const [transactionsMonthly, setTransactionsMonthly] = useState([]);
  const [detailsUsage, setDetailsUsage] = useState(null);
  const [error, setError] = useState(false);

  const reloadDetailsUsage = async (date) => {
    const controller = new AbortController();
    try {
      const originData = date === undefined ? getMonthsBilling()[0] : date;
      const responseUsage = await getDetailsUsage(controller.signal, originData);
      setDetailsUsage(responseUsage);
    } catch (err) {
      setError(true);
      setDetailsUsage({});
    }
    return () => controller.abort();
  };

  const reloadDetailsTransactions = async (date) => {
    const controller = new AbortController();
    try {
      const originData = date === undefined ? getMonthsBilling()[0] : date;
      const responseTransactions = await getTransactionsMonthly(controller.signal, originData);
      setTransactionsMonthly(responseTransactions);
    } catch (err) {
      setError(true);
      setTransactionsMonthly([]);
    }
    return () => controller.abort();
  };

  const reloadData = async (date) => {
    reloadDetailsUsage(date);
    reloadDetailsTransactions(date);
  }

  useEffect(() => {
      reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [headerRow] = useState(
    <tr>
      <th>{translate("global.nroIdentific")}</th>
      <th>{translate("global.date")}</th>
      <th>{translate("global.client")}</th>
      <th>{translate("global.Amount")}</th>
      <th>{translate("global.address")}</th>
    </tr>
  );

  return (
    <>
    {error ? (
       <div style={{ textAlign: "center", marginTop: "20px" }}>
         <p>{translate("login.signin.validate.errorConnectionTrue")}</p>
       </div>
     ) : detailsUsage ? (
      <>
        <HeaderActions
        view={"transactions"}
        detailsUsage={detailsUsage}
        reloadData={reloadData}
        dataItems={formatTransactionMonthly(transactionsMonthly)}
      />
      
       <div className="mt-3">
        <ShowTable
          itemArray={transactionsMonthly}
          headerRow={headerRow}
          Item={(props) => <ItemTransactionsBilling {...props} />}
          noItemsFound={translate("dashboard.accounting.message.NO_TRANSACTION")}
          actualPage={1}
          itemsShowing={transactionsMonthly?.length}
        />
      </div>
      </>
    ) : (
       <div className="mx-2" style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
         <Spinner type={1} size={"30px"} />
       </div>
     )}      
   </>
  );
};

export default Transactions;
