import React, { useState, useEffect } from "react";
// icons
import * as BsIcons from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import {
  translate,
  formatDatePicker,
  generateExcelAccounting,
} from "../../../utils/Common";
import { GrTransaction } from "react-icons/gr";
// hooks
import { useForm } from "react-hook-form";
import { useAccounting } from "../../../hooks/useAccounting";
// components
import DatePickerCustome from "../../../components/DatePicker";
import Spinner from "../../../components/Spinner";
import ItemsAccounting from "../../../components/ShowItems/ItemsAccounting";
import CreditsNotesView from "./CreditsNotes";
// styles
import "./index.css";

export const Accounting = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [service, setService] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [message, setMessage] = useState(null);

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isReadyExport, setIsReadyExport] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  const { getTransactions } = useAccounting();
  const { handleSubmit } = useForm();

  const handleService = (event) => {
    setService(event.target.value);
  };

  const onSubmit = async () => {
    setIsLoadingSearch(true);
    const data = {
      dateFrom: formatDatePicker(startDate),
      dateTo: formatDatePicker(endDate),
      service,
    };
    const result = await getTransactions(data);

    if (result?.code === 200) {
      setTransaction(result.payload);
      setIsReadyExport(true);
      setFormComplete(true);
    } else {
      setMessage(translate("dashboard.accounting.message.NO_TRANSACTION"));
      setTransaction([]);
      setIsReadyExport(false);
    }

    if (result?.code === 400) {
      setMessage(translate("dashboard.accounting.message.MAX_DATE_INTERVAL"));
      setTransaction([]);
      setFormComplete(false);
    }

    if (result === "SERVER_ERROR") {
      setMessage(translate("login.signup.validate.serverError"));
      setFormComplete(false);
    }

    setIsLoadingSearch(false);
  };

  const handleDownloadExcel = async () => {
    setIsLoadingExport(true);
    generateExcelAccounting(transaction);
    setIsLoadingExport(false);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setService("");
    setTransaction(null);
    setFormComplete(false);
    setIsReadyExport(false);
    setMessage(translate("dashboard.accounting.message.ENTER_DATE"));
  };

  const renderButtons = (
    <>
      <div className="input-group">
        {!formComplete ? (
          <button
            className="btn btn-blue btn-block rounded-pill btn-md"
            type="submit"
            disabled={!service || !startDate || !endDate}
          >
            {!isLoadingSearch ? (
              <>
                <BsIcons.BsSearch
                  style={{ fill: "white", marginRight: "5px" }}
                  size={18}
                />
                {translate("global.search")}
              </>
            ) : (
              <Spinner type={1} size={18} />
            )}
          </button>
        ) : (
          <button
            className="btn btn-blue btn-block rounded-pill btn-md btn-search mr-2"
            type="reset"
            onClick={handleReset}
          >
            {!isLoadingSearch ? (
              <>
                <BiReset
                  style={{ fill: "white", marginRight: "5px" }}
                  size={18}
                />
                {translate("global.reset")}
              </>
            ) : (
              <Spinner type={1} size={18} />
            )}
          </button>
        )}
      </div>

      <div className="input-group">
        <button
          className={`btn btn-block rounded-pill btn-md ${
            isReadyExport && "info btn-outline-blue"
          }`}
          type="button"
          onClick={() => handleDownloadExcel()}
          disabled={!isReadyExport}
        >
          {!isLoadingExport ? (
            <div style={{ color: "text-primary" }}>
              <BsIcons.BsDownload style={{ marginRight: "5px" }} />
              {translate("global.download")}
            </div>
          ) : (
            <Spinner type={1} size={18} />
          )}
        </button>
      </div>
    </>
  );

  const renderForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`text-left mb-3 p-1`}>
        <h5>
          <BsIcons.BsCashCoin size={16} className="mr-2" />
          {translate("sidebar.options.accounting")}
        </h5>
        <p style={{fontSize:"large", color:"gray"}}>{translate("dashboard.accounting.title")}</p>
        
        <div className="clearfix border-bottom mb-3"></div>

        <div className="contab-group">
          <div className="row">

            <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
              {/* Select de servicio */}
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text">
                    <GrTransaction />
                  </label>
                </div>
                <select
                  className="custom-select"
                  required
                  value={service}
                  onChange={handleService}
                  name="servicio"
                  disabled={formComplete}
                >
                  <option disabled hidden value="">
                    -- {translate("dashboard.accounting.select")}
                  </option>
                  <option value="pickup">
                    {translate("dashboard.accounting.select.pickup")}
                  </option>
                  <option value="pickup_office">
                    {translate("dashboard.accounting.select.pickup_office")}
                  </option>
                  <option value="credits">
                    {translate("dashboard.accounting.select.credits")}
                  </option>
                </select>
              </div>
            </div>

            {/* DatePickers */}
            <div className="col-lg-4 col-md-8 col-sm-12 mb-3 d-flex">
              <DatePickerCustome
                selectedDate={startDate}
                onChangeDate={setStartDate}
                placeholder={translate("dashboard.accounting.from")}
                startDate={startDate}
                endDate={endDate}
                name="dateFrom"
                disabled={!service || formComplete}
                isClearable={!formComplete}
              />

              <DatePickerCustome
                selectedDate={endDate}
                onChangeDate={setEndDate}
                placeholder={translate("dashboard.accounting.to")}
                startDate={startDate}
                endDate={endDate}
                name="dateTo"
                disabled={!startDate || formComplete}
                isClearable={!formComplete}
              />
            </div>

            {/* Botones */}
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 d-flex align-items-start">
              {renderButtons}
            </div>
          </div>
        </div>
      </div>
    </form>
  );

  useEffect(() => {
    setMessage(translate("dashboard.accounting.message.ENTER_DATE"));
  }, []);

  return (
    <div className="dashboardCard ">
      {renderForm}

      {transaction ? (
        transaction?.length > 0 ? (
          service !== "credits" ? (
            <ItemsAccounting transaction={transaction} />
          ) : (
            <CreditsNotesView transaction={transaction} />
          )
        ) : (
          <div className="dotted-border lead">{message}</div>
        )
      ) : (
        <div className="dotted-border lead">{message}</div>
      )}
    </div>
  );
};
