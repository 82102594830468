import { useState, useEffect } from "react";
// icons
import * as BsIcons from "react-icons/bs";
import { FaCreditCard } from "react-icons/fa";
import { FiFileText, FiBarChart } from "react-icons/fi";
import { BsBox } from "react-icons/bs";

// components
import TabsCustome from "../../../components/TabsCustome";
import Usage from "./Usage";
import Transactions from "./Transactions";
import Invoicing from "./Invoicing";
// import Returns from "./Returns";
import MethodsPayment from "./MethodsPayment";
import { translate } from "../../../utils/Common";
import "./index.css";

const Billing = () => {
 

  const [activeTab, setActiveTab] = useState(0);
  const [activeBillingId, setActiveBillingId] = useState("0");
  const [activeBillingAmount, setActiveBillingAmount] = useState("0");

  const tabs = [
    {
      label: translate("global.Usage"),
      icon: <FiBarChart />,
      component: <Usage setActiveTab={setActiveTab} />,
      show: true,
    },
    {
      label: translate("global.transactions"),
      icon: <BsBox />,
      component: <Transactions />,
      show: true,
    },
    {
      label: translate("global.Invoicing"),
      icon: <FiFileText />,
      component: (
        <Invoicing
          setActiveTab={setActiveTab}
          activeBilling={setActiveBillingId}
          activeBillingAmount={setActiveBillingAmount}
        />
      ),
      show: true,
    },
    {
      label: translate("global.registerPayments"),
      icon: <FaCreditCard />,
      component: (
        <MethodsPayment
          id={activeBillingId}
          setActiveTab={setActiveTab}
          amount={activeBillingAmount}
        />
      ),
      show: false,
    },
    // { label: 'Devoluciones', icon: <TfiReload />, component: <Returns />, show: false  },
  ];

  const renderPendingPayment = (
    <div
      className="alert alert-warning"
      style={{ position: "sticky", top: "0", zIndex: "999", cursor: "pointer" }}
      onClick={() => setActiveTab(2)}
    >
      Tienes <strong> 1 factura </strong> pendiente de pago.{" "}
      <strong style={{ textDecoration: "underline" }}>Ver Factura</strong>
    </div>
  );

  return (
    <>
      {/* {renderPendingPayment} */}
      <div className="text-left" style={{ padding: "2rem 2rem 1rem 2rem" }}>
        <h6 className="m-0" style={{ color: "#424996", fontSize: "20px" }}>
          <BsIcons.BsCreditCard
            size={35}
            style={{ paddingRight: "15px", fontWeight: "bold" }}
          />{" "}
          {translate("global.Invoicing")} & {translate("global.Usage")}
        </h6>
      </div>

      <TabsCustome
        items={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export default Billing;
