import { useEffect, useState } from "react";
import {translate} from "../../../../utils/Common"
const FormForPickUp = ({ cliente, setCliente, packageFound, setPackageFound, pickUpPackagesFound, setPickUpPackagesFound }) => {
    const [messageResult, setMessageResult] = useState('');
    const [validate, setValidate] = useState(false);

    const addPackage = () => {
        setPickUpPackagesFound([...pickUpPackagesFound, packageFound]);
        setPackageFound(null);
    }

    const validateClient = () => {
        if (cliente) {
            if (cliente === packageFound.IdCliente) return true;
            else {
                return false;
            }
        }
        else {
            setCliente(packageFound.IdCliente);
            return true;
        }
    }

    const cancelPackage = () => {
        if (pickUpPackagesFound.length === 0) setCliente(null); 
        setPackageFound(null);
    }
    
    useEffect(() => {
        if (packageFound?.IdEstatus !== 3) {
            setMessageResult(translate("dashboard.packages.pickUp.formForPickUp.incorrectStatus"));
            setValidate(false);
        }
        else if (pickUpPackagesFound.length > 0 && pickUpPackagesFound.filter((p) => p.IdPaquete === packageFound.IdPaquete ? true : false).length > 0) {
            setMessageResult(translate("dashboard.packages.pickUp.formForPickUp.duplicatePackage"));
            setValidate(false);
        }
        else if (!validateClient()) {
            setMessageResult(translate("dashboard.packages.pickUp.formForPickUp.otherClient"));
            setValidate(false);
        }
        else {
            setMessageResult('');
            setValidate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div className="dashboardCard p-0">
            <div className="form-row mx-0 mb-1 border-bottom titlePackage">
                <h6 className="ml-2 my-1">{translate("dashboard.packages.details.infoPackage")}</h6>  
            </div>
            
            <div className="form-row mx-3">
                <div className="form-group col-6 col-md-6 mb-2">
                    <label className="mb-0" htmlFor="inputNumeroPaquete">{translate("global.numPackages")}:</label>
                    <input type="text" value={ packageFound?.Nro_Id ? packageFound?.Nro_Id : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputNumeroPaquete"/>
                </div>

                <div className="form-group col-6 col-md-6 mb-2">
                    <label className="mb-0" htmlFor="inputCliente">{translate("global.client")}</label>
                    <input type="text" value={ packageFound?.Cliente ? packageFound?.Cliente : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputCliente"/>
                </div>
                
                <div className="form-group col-4 col-md-4 mb-0 monto-pickup">
                    <label className="mb-0" htmlFor="inputPrecioBase">{translate("global.baseCost")}</label>
                    <input type="text" value={ packageFound?.PrecioBase !== null ? `$ ${packageFound?.PrecioBase}` : '' } readOnly className="form-control-sm font-small form-control-plaintext font-bold" id="inputPrecioBase"/>
                </div>

                <div className="form-group col-4 col-md-4 mb-0 monto-pickup">
                    <label className="mb-0" htmlFor="inputItbms">Itbms</label>
                    <input type="text" value={ packageFound?.Itbms !== null ? `$ ${packageFound?.Itbms}` : '' } readOnly className="form-control-sm font-small form-control-plaintext font-bold" id="inputItbms"/>
                </div>

                <div className="form-group col-4 col-md-4 mb-0 monto-pickup">
                    <label className="mb-0" htmlFor="inputPrecio">{translate("global.total")}</label>
                    <input type="text" value={ packageFound?.Precio !== null ? `$ ${packageFound?.Precio}` : '' } readOnly className="form-control-sm font-small form-control-plaintext font-bold" id="inputPrecio"/>
                </div>

                { messageResult &&
                    <div className="form-group col-12 text-center mb-0">
                        <p className="text-danger mb-1">{ messageResult }</p>
                    </div>
                }
            </div>

            <div className="form-row mx-3 mb-2">
                <div className="form-group text-center col-12 mb-0">
                    { validate ?
                        <>
                            <button className="btn btn-sm btn-fucsia m-2" type="button" onClick={ addPackage } >{translate("global.add")}</button>
                            <button className="btn btn-sm btn-outline-fucsia m-2" type="button" onClick={ () => cancelPackage() } >{translate("global.cancel")}</button>
                        </>
                        :
                        <button className="btn btn-sm btn-outline-fucsia m-2" type="button" onClick={ () => setPackageFound(null) } >{translate("global.return")}</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default FormForPickUp;