import React, { useEffect } from 'react';
import { setClientDomain } from './utils/Common';
import AuthProvider from "./contexts/auth/Provider";
import './style/App.css';
// rutas
import Routes from './routes/routes';

const App = () => {
  useEffect(() => { setClientDomain(window.location.hostname); }, []);
  return (
    <AuthProvider>
      {window.location.hostname !== 'bli.myboxit.com' &&
        <div className={`d-flex align-items-center justify-content-center dev-env`}> 
          ENTORNO DESARROLLO
        </div>
      }
      <Routes />
    </AuthProvider>
  );
}

export default App;
