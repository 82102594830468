import { useEffect, useState } from 'react';
import { getClientDomain, getToken, translate } from '../../../utils/Common';
import ShowTable from '../../../components/ShowTable';
import ItemDeliveryOrder from '../../../components/ShowTable/ItemDeliveryOrder';
import OrderDetails from './OrderDetails';
import * as MdIcons from 'react-icons/md';
import './index.css';

const Delivery = () => {
    const [deliveryOrders, setDeliveryOrders] = useState(null);
    const [headerRow,] = useState(
        <tr>
            <th>Nro. {translate("global.order")}</th>
            <th>{translate("global.Package")}</th>
            <th>{translate("global.address")}</th>
            <th>{translate("global.status")}</th>
            <th>{translate("global.orderType")}</th>
        </tr>
    );
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        const abortController = new AbortController();
        const getDeliveryOrders = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/deliveries/all` , {
                    method: 'GET',
                    connection: 'keep-alive',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    signal: abortController.signal
                });
                const result = await response.json();
                if (result?.code === 200) {
                    if (result?.payload.length > 0) setDeliveryOrders(result.payload);
                    else setDeliveryOrders([]);
                }
                else setDeliveryOrders([]);
            } catch (err) {
                console.log(err);
                setDeliveryOrders([]);
            }
        }

        getDeliveryOrders();
        return () => abortController.abort();
    }, [update]);
    return (
        <>
            <div className="dashboardCard text-left">
                <h4 className="m-0"><MdIcons.MdOutlineDeliveryDining size={35}/> {translate("dashboard.delivery.title")}</h4>
                <p className="mx-0 mb-0 mt-1">{translate("dashboard.delivery.description")}</p>
            </div>

            { !selectedOrder ?
                <div className='dashboardCard text-left'>
                    <ShowTable 
                        itemArray={deliveryOrders}
                        headerRow={headerRow}
                        Item={ItemDeliveryOrder}
                        selectItem={setSelectedOrder}
                        title={translate("dashboard.delivery.table.title")}
                        noItemsFound={translate("dashboard.delivery.table.noItemsFound")}
                        actualPage={1}
                        itemsShowing={deliveryOrders?.length}/>
                </div>
                :
                <OrderDetails 
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                    setUpdate={setUpdate}
                    update={update}/>
            }
        </>
    );
}

export default Delivery;