import React, { useState, useEffect } from "react";
// components
import HeaderActions from "../HeaderActions";
import ShowTable from "../../../../components/ShowTable";
import ItemInvoicingBilling from "../../../../components/ShowTable/ItemInvoicingBilling.jsx";
import Spinner from "../../../../components/Spinner";

//utils
import { translate, getMonthsBilling } from "../../../../utils/Common";
import { useBilling } from "../../../../hooks/useBilling";

const Invoicing = ({ setActiveTab, activeBilling, activeBillingAmount }) => {
  const { getInvoicingMonthly, getDetailsUsage } = useBilling();
  const [invoicingMonthly, setInvoicingMonthly] = useState([]);
  const [detailsUsage, setDetailsUsage] = useState(null);
  const [error, setError] = useState(false);

  const reloadDetailsUsage = async (date) => {
    const controller = new AbortController();
    try {
      const originData = date === undefined ? getMonthsBilling()[0] : date;
      const responseUsage = await getDetailsUsage(controller.signal, originData);
      setDetailsUsage(responseUsage);
    } catch (err) {
      setError(true);
      setDetailsUsage({});
    }
    return () => controller.abort();
  };

  const reloadInvoicingMonthly = async (date) => {
    const controller = new AbortController();
    try {
      const originData = date === undefined ? getMonthsBilling()[0] : date;
      const responseInvoicingMonthly = await getInvoicingMonthly(controller.signal, originData );
      setInvoicingMonthly(responseInvoicingMonthly);
    } catch (err) {
      setError(true);
      setInvoicingMonthly([]);
    }
    return () => controller.abort();
  };

  const reloadData = async (date) => {
    reloadDetailsUsage(date);
    reloadInvoicingMonthly(date);
  }

  useEffect(() => {
      reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [headerRow] = useState(
    <tr>
      <th>ID</th>
      <th>{translate("global.periodInvoicing")}</th>
      <th>{translate("global.totalAmountInvoicing")}</th>
      <th>{translate("global.state")}</th>
      <th>{translate("global.action")}</th>
    </tr>
  );

  return (
    <>
     {error ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p>{translate("login.signin.validate.errorConnectionTrue")}</p>
        </div>
      ) : detailsUsage ? (
        <>
          <HeaderActions
            view={"invoicing"}
            setActiveTab={setActiveTab}
            detailsUsage={detailsUsage}
            reloadData={reloadData}
            // dataItems={invoicingMonthly}
          />
          <div className="mt-3">
            <ShowTable
              itemArray={invoicingMonthly}
              headerRow={headerRow}
              Item={(props) => (
                <ItemInvoicingBilling
                  {...props}
                  setActiveTab={setActiveTab}
                  activeBilling={activeBilling}
                  activeBillingAmount={activeBillingAmount}
                />
              )}
              noItemsFound={translate("dashboard.delivery.table.noItemsFound")}
              actualPage={1}
              itemsShowing={invoicingMonthly?.length}
            />
          </div>
        </>
      ) : (
        <div className="mx-2" style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
          <Spinner type={1} size={"30px"} />
        </div>
      )}      
    </>
  );
};

export default Invoicing;
