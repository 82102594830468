import { useState, useEffect, useMemo } from "react";
import { getCountryCodeFromCoordinates, setClientDomain } from "../../utils/Common";
import LoadingScreen from "../LoadingScreen";
import "./index.css";

const CountryList = () => {
  const [loading, setLoading] = useState(true);
  // Definir países con configuración
  const countries = useMemo(() => [
    { code: "pa", name: "PA", flag: "/img/pa_flag.png", actived: true },
    { code: "br", name: "BR", flag: "/img/br_flag.png", actived: true },
    { code: "us", name: "US", flag: "/img/usa_flag.png", actived: true }
  ], []);

  // Obtener país almacenado en localStorage o establecer uno predeterminado
  const storedCountry = localStorage.getItem("country") || "pa";
  const [selectedCountry, setSelectedCountry] = useState(storedCountry);
  

  // Obtener ubicación del usuario mediante geolocalización
  useEffect(() => {
    const handleGeolocationSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      const countryCode = getCountryCodeFromCoordinates(latitude, longitude);
      const validCountryCode = countries.find((country) => country.code === countryCode);
      setSelectedCountry(validCountryCode?.code || storedCountry);
      localStorage.setItem("country", validCountryCode?.code || storedCountry);
      setLoading(false); 
    };

    const handleGeolocationError = (error) => {
      console.log("Error getting user location:", error);
      setLoading(false);
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
    }
  }, [countries, storedCountry]);

  // Manejar clic en un país
  const handleCountryClick = (country) => {
    
    if (country.actived) {
      setSelectedCountry(country.code);
      localStorage.setItem("country", country.code);
      setClientDomain(window.location.hostname);
      window.location.reload();
    }
  };

  // Renderizar la lista de países
  return (
    <div className="selector-country">
      {loading && <LoadingScreen />}
      {!loading &&
        countries.map((country) => (
          <div
            key={country.code}
            className={`card-country ${selectedCountry === country.code ? "active" : "non-selected"} ${
              !country.actived && "disabled"
            }`}
            onClick={() => handleCountryClick(country)}
          >
            <img src={country.flag} alt={`${country.name}_flag`} />
            <div>{country.name}</div>
          </div>
        ))}
    </div>
  );
};

export default CountryList;
