import { translate } from "../../../../utils/Common";

const TipsForDropOff = () => {
    return (
        <div className="form-row text-center mx-2">
            <div className="col-12 col-md-4 p-2">
                <div className="dashboardCard m-0 h-100 d-flex align-items-center">
                    <p className="m-0">{translate("dashboard.packages.dropOff.tipsForDropOff.descripNumPackage")} <span className="font-bold">{translate("global.numPackage")} </span>
                        {translate("dashboard.packages.dropOff.tipsForDropOff.sealed")}
                    </p>
                </div>
            </div>
            
            <div className="col-12 col-md-4 p-2">
                <div className="dashboardCard m-0 h-100 d-flex align-items-center">
                    <p className="m-0">
                        {translate("dashboard.packages.dropOff.tipsForDropOff.the")} <span className="font-bold">{translate("global.numIdentity")} </span>
                        {translate("dashboard.packages.dropOff.tipsForDropOff.timeRegistration")}
                    </p>
                </div>
            </div>
            
            <div className="col-12 col-md-4 p-2">
                <div className="dashboardCard m-0 h-100 d-flex align-items-center">
                    <p className="m-0">
                        {translate("global.to")} <span className="font-bold">{translate("dashboard.packages.dropOff.tipsForDropOff.scanCode")} </span>
                        {translate("dashboard.packages.dropOff.tipsForDropOff.descripCamera")}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default TipsForDropOff;