import { PieChart, Pie, Cell, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Spinner from '../../../../components/Spinner';

const GraphOrders = ({ cantMonthlyOrders, cardTitle }) => {
    const COLORS = ['#E9257D', '#424996', '#935CA2', '#4ABFEE', '#F26C3F'];

    const none = [
        { name: "none", value: 1 }
    ];

    const getTotalOrders = () => {
        if (cantMonthlyOrders) {
            if (cantMonthlyOrders.length > 0) {
                let canTotalOrders = 0;
                cantMonthlyOrders.map((order) => canTotalOrders += order.value);
                return canTotalOrders;
            }
            else return 0;
        }
        else return 0;
    }

    return (
        cantMonthlyOrders === null ?
            <Spinner type={ 1 }/>
            :
            <>
                <h5 className="p-0 m-0 mb-2"><span className="text-bold">{ cardTitle }</span></h5>
                <ResponsiveContainer className="charts-container">
                { getTotalOrders() ?
                    <PieChart width={240} height={180}>
                        <Pie
                            data={cantMonthlyOrders}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={70}
                            fill="#8884d8"
                            paddingAngle={5}
                            isAnimationActive={true}
                            label={(entry) => entry.name }
                            >
                            {cantMonthlyOrders.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label value={ getTotalOrders() } position="center" fontSize={30}/>
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    :
                    <PieChart width={240} height={180}>
                        <Pie
                            data={none}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={70}
                            isAnimationActive={true}
                            >
                            <Cell key={ `cell-0` } fill="#DBDBDB" />
                            <Label value="0" position="center" fontSize={35}/>
                        </Pie>
                    </PieChart>
                }
                </ResponsiveContainer>
            </>
    );
}

export default GraphOrders;