import { getClasificacion, setStatusTypePackageName, translate } from "../../utils/Common";
// import { Link } from "react-router-dom";
// import * as MdIcons from 'react-icons/md';
import * as BiIcons from 'react-icons/bi';
import { FaBox, FaUndo } from 'react-icons/fa';

// Se comento el boton de envios mientras se estudia el proyecto
const ItemReserva = ({ index, showBox, item, setSelectedItem, itemName }) => {
    return (
    <div className="col-6 p-2 item-found d-flex align-items-start flex-column">
        <div className={ `border item-inside text-left font-xsmall ${ item.Identificacion ? "pt-2" : "py-2" }` } >
            <div className="d-flex justify-content-between px-2 font-bold">
                <p className="mb-0 font-bold text-left">Nro. { index + 1 }</p>
                { item.Identificacion && 
                    <div className="boxit-fucsia font-small">
                        <BiIcons.BiDoorOpen size={20}/> {item.Identificacion}
                    </div>
                }
            </div>
            { showBox &&
                <div id="box">
                    <img src="/img/box.png" alt="boxit-box" width="80px" />
                </div>
            }
            <div className="m-1 px-2 text-break">
                <p className="mb-0 font-bold text-center">{translate("global.numPackage")}: </p>
                <p className={"m-1 mb-0 font-bold p-1 text-center nroPackageFound-" + (itemName === translate("global.reservations") ? "blue" : "fucsia")}>{ item.NumeroIdentificacion ? item.NumeroIdentificacion : '' }</p>
                <p className="mb-0"><span className="font-bold">{translate("global.numTracking")}:</span> { item.NumeroTracking ? item.NumeroTracking : '' }</p>
                <p className="font-bold mb-2">{translate("global.client")}: 
                    <span className={"ml-1 boxit-" + (itemName === translate("global.reservations") ? "blue" : "fucsia")}>
                        { item.IdTipoUsuario !== 1 ?
                            (item.Email)
                            :
                            (`${item.Cliente} (B${item.IdCliente})`)
                        }
                    </span>
                </p>
                {itemName === "reservas" && <p className="mb-0"><span className="font-bold">{translate("global.size")}:</span> { item.Clasificacion ? getClasificacion(item.Clasificacion) : '' }</p>}
                <p className="mb-0"><span className="font-bold">{translate("global.typeOfPackage")}:</span> <span className="ml-1" style={{background: item?.IdTipoPaquete!== 3  ? "#f26c3f" : "#4abfee", padding:"4px 6px", color:"white", borderRadius:"15px"}}> {item?.IdTipoPaquete!== 3 ? <><FaBox className="mr-1"/>{translate("global.status.typePackage.regular")}</> : <><FaUndo className="mr-1"/>{translate("global.status.typePackage.returned")}</>}</span></p>
                <div className="my-2">
                    <p className="mb-0 font-bold text-center border-bottom">{translate("global.ubication")}:</p>
                    <p className="mb-0 text-center font-bold">{ item.Direccion ? item.Direccion : "" }</p>
                </div>
            </div>      
            { item.IsBoxit &&           
            <div className={ "w-100 d-flex justify-content-center p-2 " + (itemName === translate("global.reservations") ? "" : "mb-2") }>
                <div className="btn-group">
                        {itemName === translate("global.reservations") ? 
                        <button type="button" className="btn btn-sm btn-blue font-xsmall" onClick={ () => setSelectedItem(item) }><BiIcons.BiDoorOpen size={20} className="mr-1" />{translate("global.reserve")}</button>
                        :
                        <button type="button" className="btn btn-sm btn-fucsia font-xsmall" onClick={ () => setSelectedItem(item) }><BiIcons.BiDoorOpen size={20} className="mr-1" />{translate("global.cancel")}</button>
                          }
                    {/* { itemName === "entregas" && !item.IdEnvio &&
                        <Link type="button" 
                            className="btn btn-sm btn-outline-purple font-xsmall" 
                            to={`/dashboard/addDelivery/package=${item.IdPaquete}&from=${item.DireccionOrigen}&locName=${item.Direccion?.replace(/ /g, '-')}&redirect=${itemName === "reservas" ? "reservations" : "awaitingDelivery"}`}>
                                <MdIcons.MdOutlineDeliveryDining size={20} className="mr-1" />Enviar
                        </Link>
                    } */}
                </div>
            </div>
             }
        </div>
    </div>
    );
}

export default ItemReserva;