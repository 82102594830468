import { useState } from "react";
import * as FaIcons from "react-icons/fa";

import LockerCard from "./OpenLockerCard";
import SearchPackage from "./SearchPackages";
import { translate } from "../../../../utils/Common";

const Support = () => {
  const [pachageInfo, setPackageInfo] = useState(null);

  return (
    <>
      <div className="dashboardCard text-left mb-1">
        <h4 className="m-0">
          <FaIcons.FaTools className="mr-2" size={30} />
          {translate("global.support")}
        </h4>
        <p className="mx-0 mb-0 mt-1">{translate("dashboard.platforms.support.description")}</p>
      </div>

      <SearchPackage setPackageInfo={setPackageInfo} />

      { pachageInfo  && 
      <LockerCard pachageInfo={pachageInfo}/>
    }

    </>
  );
};

export default Support;
