import { useEffect, useState } from "react";
import Select from "react-select";
import { useClient } from "../hooks/useClient";
import { PiPackageBold } from "react-icons/pi";
import { getNameStatus, handlerColorPackagesEstatus, setStatusName, translate } from "../utils/Common";

const PackagesClientSearchList = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { getAllPackagesByClient } = useClient();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para manejar el cambio de selección
  const handleChangeSystem = (option) => {
    setSelectedOption(option);
  };

  const handleChangeClick = (option) => {
    if (option.IdPaquete) {
      props.setPackageSelected(option.IdPaquete)
    }
  };

  useEffect(() => {
    const handleGetData = async () => {
      const abortController = new AbortController();
      try {
        const response = await getAllPackagesByClient(abortController.signal, {
          clientId: props.clientId,
        });

        // Customize the options to display specific data
        const customizedOptions = response.map((packageData) => ({
          value: `${packageData.NumeroIdentificacion} - (${getNameStatus(packageData?.IdEstatus)})`,
          label: (
            <div className="d-flex justify-content-between" onClick={() => handleChangeClick(packageData)}>
              <div className="">
              <PiPackageBold size={20} className="mr-2"/>{" "}
                {packageData?.NumeroIdentificacion}
              </div>
              <div className={`font-bold ${handlerColorPackagesEstatus(packageData?.IdEstatus)}`}>{setStatusName(packageData?.IdEstatus)}</div>
            </div>
          ),
        }));

        setOptions(customizedOptions);
      } catch (error) {
        // console.error("Error fetching getAll:", error);
      } finally {
        setLoading(false);
      }
    };

    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleChangeSystem}
        options={options}
        placeholder={translate("global.search")+"..."}
        isClearable
        className="input-sm"
        isLoading={loading}
        isDisabled={!props.disabled}
      />
    </div>
  );
};

export default PackagesClientSearchList;
