import { useState, useEffect } from "react";
import Select from "react-select";
import { useProviders } from "../hooks/useProviders";
import { translate } from "../utils/Common";
import Spinner from "./Spinner";
import { FaBuilding } from "react-icons/fa";

const ProviderList = ({
  register,
  errors,
  nameShow,
  idShow,
  readOnly,
  setValue,
  valueId,
  setProveedor,
  idHide,
  selectedOption 
}) => {
  
  const [providersList, setProvidersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getProviders } = useProviders();
  

  useEffect(() => {
    const fetchProviders = async () => {
      setIsLoading(true);
      try {
        const abortController = new AbortController();
        const providers = await getProviders(idShow, abortController.signal, idHide);
        setProvidersList(providers);
      } catch (error) {
        console.error("Error fetching providers", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProviders();
  }, [idShow, getProviders, idHide]);

  const options = providersList.map(prov => ({
    value: prov.IdUsuario,
    label: `${prov.NombreEmpresa}`,
    isDisabled: prov.IdUsuario === idShow,
    typeUser: prov.IdTipoUsuario,
    ...prov
  }));

  const handleProviderSelection = (selectedOption) => {
    setValue(valueId, selectedOption ? selectedOption.value : "");
    if (setProveedor) setProveedor(selectedOption);
  };    

  return (
    <div>
      <div className="input-group" style={{ flexWrap: "nowrap" }}>
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputName">
            <FaBuilding size={16} />
          </span>
        </div>
        <Select
          isSearchable
          isLoading={isLoading}
          options={options}
         className="w-100"
          onChange={handleProviderSelection}
          placeholder={nameShow || translate("global.select")}
          value={selectedOption}
          isDisabled={readOnly}
          ref={register}
          name={valueId}
          isClearable
        />
      </div>
      {errors?.[valueId] && (
        <span className="text-error">{errors[valueId].message}</span>
      )}
    </div>
  );
};

export default ProviderList;
