// icons
import * as MdIcons from "react-icons/md";
import { translate } from "../../../../utils/Common";

const ItemPackage = ({ packageForPickup, deletePackage }) => {
    return (
        <div className="form-group col-12 col-md-12 border-bottom d-flex flex-row pb-2 mb-1" >
            <div className="packageForPickUp-numero">
                <label className="mb-0" htmlFor="inputNumeroPaquete">{translate("global.numPackages")}:</label>
                <input type="text" value={ packageForPickup?.Nro_Id ? packageForPickup.Nro_Id : '' } readOnly className="form-control-sm font-small form-control font-bold"/>
            </div>
            <div className="packageForPickUp-precio ml-2">
                <label className="mb-0" htmlFor="inputPrecio">{translate("global.price")}</label>
                <input type="text" value={ packageForPickup?.Precio >= 0 ? (`$ ${packageForPickup.Precio}`) : '' } readOnly id="inputPrecio" className="form-control-sm font-small form-control-plaintext font-bold"/>
            </div>
            <div className="packageForPickUp"> 
                <label className="mb-0" htmlFor="delete-btn">{translate("global.cancel")}.</label>
                <MdIcons.MdRemoveCircle size={22} className="mt-1" id="delete-btn" onClick={ () => deletePackage(packageForPickup) } />
            </div>
        </div>
    );
}

export default ItemPackage;