// LoadingScreen.js
import React from "react";
import "./index.css";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingScreen;
