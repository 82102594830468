import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useProviders } from "../hooks/useProviders";
import { translate } from "../utils/Common";
import "./index.css";
import { Spinner } from "react-bootstrap";
import { FaBuilding } from "react-icons/fa";

const ProviderList2 = ({
  register,
  errors,
  nameShow,
  idShow,
  readOnly,
  setValue,
  valueId,
  setProveedor,
  provSelect,
}) => {
  const [providersList, setProvidersList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [openSelect, setOpenSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);
  const { getProviders } = useProviders();

  useEffect(() => {
    const abortController = new AbortController();
    const handleProviders = async () => {
      const providers = await getProviders(idShow, abortController.signal);
      setProvidersList(providers);
    };
    handleProviders();
    return () => abortController.abort();
  }, [idShow, getProviders]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenSelect(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const toggleSelect = useCallback(() => {
    setOpenSelect((prevOpenSelect) => !prevOpenSelect);
    setSearchTerm("");
  }, []);

  const handleProviderSelection = useCallback(
    (selectedValue) => {
       setSelectedProvider(selectedValue.IdUsuario);
    setValue(valueId, selectedValue.IdUsuario);
    setProveedor(selectedValue);
    toggleSelect();
    },
    [setValue, valueId, setProveedor, toggleSelect]
  );

  const filteredProviders = useMemo(
    () =>
      providersList.filter(
        (prov) =>
          idShow !== prov.IdUsuario &&
          `${prov.IdUsuario} - ${prov.NombreEmpresa}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      ),
    [providersList, idShow, searchTerm]
  );

  const selectedProviderName = useMemo(
    () =>
      provSelect
        ? providersList.find((item) => item.IdUsuario === selectedProvider)
            ?.NombreEmpresa
        : translate("global.select") + ' ' +translate("global.business"),
    [provSelect, providersList, selectedProvider]
  );

  return (
    <div ref={wrapperRef}>
      <div className="input-group" style={{flexWrap:"nowrap"}}>
      <div className="input-group-prepend">
        <span className="input-group-text" id="inputName">
            <FaBuilding size={16} />
         </span>   
      </div>
      <div className="input-select-fake" onClick={toggleSelect}>
        <select
          readOnly={false}
          onClick={toggleSelect}
          value={provSelect}
          style={{ pointerEvents: "none" }}
          {...register(valueId)}
        >
          <option key={0} className="font-small">
            {selectedProviderName}
          </option>
          {providersList.length > 0 ? (
            providersList.map(
              (prov) =>
                idShow !== prov.IdUsuario && (
                  <option
                    key={prov.IdUsuario}
                    className="font-small"
                    value={prov.IdUsuario}
                  >
                    {prov.NombreEmpresa?.length > 1 ? prov.NombreEmpresa : " "}
                  </option>
                )
            )
          ) : (
            <option className="font-small">
              {translate("global.noUsers")}
            </option>
          )}
        </select>
      </div>
      </div>
     

      {openSelect && (
        <div className="section-select-search">
          <div className="p-2">
            <input
              type="text"
              name="querySearch"
              className="form-control form-control-sm font-small"
              placeholder={translate("global.search")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="select-input__list">
          <ul>
              {filteredProviders ? (
                filteredProviders.length > 0 ? (
                  filteredProviders?.map(
                    (prov) =>
                      idShow !== prov.IdUsuario && (
                        <li
                          key={prov.IdUsuario}
                          className="font-small item-select-search"
                          value={prov.IdUsuario}
                          onClick={() => handleProviderSelection(prov)}
                        >{`${
                          prov.NombreEmpresa?.length > 1
                            ?  prov.NombreEmpresa
                            : " "
                        }`}</li>
                      )
                  )
                )
                : (
                <li className="font-small p-3">
                  {providersList ? (<span> {`${translate("global.noUsers")} ${translate("global.withTheTerm")} "${searchTerm}"`} </span>)
                  :( <div className="mx-2"> <Spinner type={1} size={15} /></div>)} 
                </li>
                )
              ) : (
                <li className="font-small p-3">
                  {translate("global.usersNotAvailable")}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}

      {errors?.proveedor && (
        <span className="text-error">{errors.proveedor.message}</span>
      )}
    </div>
  );
};

export default ProviderList2;
