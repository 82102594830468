import {getFormatDateHour, formatNumDecimal} from "../../utils/Common";
import './index.css';
const ItemCreditsNotes = ({ item }) => {
    return (
        <tr className='orderRow'>
               <th>{item.IdTransaccion}</th>
            <td>{getFormatDateHour(item.fecha2)}</td>
            <td>{"("+item.IdCliente + ") - " +item.Cliente}</td>
            <td>{formatNumDecimal(item.Pago_Abono)}</td>
        </tr>
    );
}

export default ItemCreditsNotes;