import React from 'react';
import { translate } from '../utils/Common';

const Spinner = ({ type, size }) => {
    if (type)
        return  <div className="spinner-border spinner-border-sm" style={{ fontSize: 5, width: size, height: size }}>
                    <span className="sr-only">{translate("global.loading")}</span>
                </div>
    else
        return  <div className="spinner-grow" style={{ width: size, height: size }}>
                    <span className="sr-only">{translate("global.loading")}</span>
                </div>
}

export default Spinner;