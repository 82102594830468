import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => sessionStorage.getItem('token') ? 
            <>
                <Sidebar />
                <Navbar />
                <Component {...props} />
            </> 
            : 
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} 
        />}/>
    )
}

export default PrivateRoute;