import { useState, useEffect } from "react";
import { usePlatforms } from "../../../../hooks/usePlatforms";
import * as GiIcons from "react-icons/gi";
import ItemPlatformArching from "../../../../components/ShowItems/ItemPlatformArching";
import ItemsInfo from "../../../../components/ShowItems/ItemsInfo";
import SearchBoxItems from "../../../../components/SearchBoxItems";
import ShowItems from "../../../../components/ShowItems";
import { translate } from "../../../../utils/Common";
import Spinner from "../../../../components/Spinner";

const Arching = () => {
  const [platformArray, setPlatformArray] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [platformSelected, setPlatformSelected] = useState(null);
  const { getAllPlatformsArching } = usePlatforms();
  const [itemsShowing, setItemsShowing] = useState(0);
  const [update, setUpdate] = useState(false);

  const handlePlatforms = async () => {
    try {
      const platforms = await getAllPlatformsArching();

      if (platforms.length > 0) {
        setItemsShowing(platforms.length <= 20 ? platforms.length : 20);
        setItemsFiltered(platforms);
        setPlatformArray(platforms);
      } else {
        setItemsShowing([]);
        setItemsFiltered([]);
        setPlatformArray([]);
      }
    } catch (error) {
      setItemsShowing([]);
      setItemsFiltered([]);
      setPlatformArray([]);
      console.log(error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    handlePlatforms();
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  return (
    <>
      <div className="dashboardCard text-left">
        <h4 className="m-0">
          <GiIcons.GiPlatform className="mr-2" size={35} />
          {translate("sidebar.options.accounting.arching")} - {translate("dashboard.platforms.title")}
        </h4>
        {/* <p className="mx-0 mb-0 mt-1">{translate("dashboard.platforms.description")}</p> */}
      </div>

      {!platformSelected ? (
        <>
          <div className="dashboardCard">
            <ItemsInfo
              cantItemsFound={platformArray?.length}
              itemName={translate("global.platforms")}
            />

            <SearchBoxItems
              itemsArray={platformArray}
              itemsFound={itemsFiltered}
              setItemsShowing={setItemsShowing}
              setItemsFiltered={setItemsFiltered}
              filterType={"platform"}
            />
          </div>

        {platformArray && platformArray.length > 0 ? (
          <ShowItems
          ItemComponent={ItemPlatformArching}
          handleUpdateData={handlePlatforms}
          itemsArray={platformArray}
          itemsFound={itemsFiltered}
          setItemsFiltered={setItemsFiltered}
          itemName={"archingPlatforms"}
          showBox={true}
          setSelectedItem={setPlatformSelected}
          itemsShowing={itemsShowing}
          view={"grid"}
          typeExport={"archingPlatforms"}
        />) : (
          <div className="loader center">
          <Spinner type={0} size={40} />
        </div>
        )
          }
          
        </>
      ) : (
        <>DETAILS</>
      )}
    </>
  );
}

export default Arching;
