import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
  Autocomplete,
  OverlayView,
} from "@react-google-maps/api";
import { IoMdLocate, IoMdPin } from "react-icons/io";
import { RiZoomInLine } from "react-icons/ri";
import { RiZoomOutLine } from "react-icons/ri";
import { FaRegQuestionCircle, FaSearch } from "react-icons/fa";
import { translate } from "../../utils/Common";
import { FaClock } from "react-icons/fa";
import { BiPackage } from "react-icons/bi";
import  Spinner  from "../Spinner";
import "./map.css";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const mapStyle = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];

const MapContainerSearch = ({ locations, zoomLevel }) => {
  
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY_LOCATE,
    libraries: ["places", "geometry"],
  });

  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null); // Ubicación actual del usuario
  const [selectedLocation, setSelectedLocation] = useState(null); // Ubicación seleccionada en el Autocomplete
  const autocompleteRef = useRef(null);
  const [googleMaps, setGoogleMaps] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);

  // Función para obtener la ubicación actual del usuario
  const locateUser = useCallback(() => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(userLocation); // Guarda la ubicación actual del usuario

          if (map) {
            map.panTo(userLocation);
            map.setZoom(15);
          }
          setIsLoadingLocation(false);
        },
        (error) => {
          console.error("Error obteniendo la ubicación:", error);
          setIsLoadingLocation(false);
          alert("No se pudo obtener la ubicación. Verifica los permisos de geolocalización.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, [map]);

  const onLoad = useCallback(
    (mapInstance) => {
      setMap(mapInstance);
      setGoogleMaps(window.google.maps);
      locateUser(); // Obtener la ubicación cuando el mapa se carga
    },
    [locateUser]
  );

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const newLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setSelectedLocation(newLocation); // Guarda la nueva ubicación seleccionada
      map.panTo(newLocation);
      map.setZoom(15);
    }
  };

  const zoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  };

  const zoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  };

  const parsedLocations = locations.map((loc) => {
    if (!loc.coordinates) {
      return loc;
    }
  
    const [lat, lng] = loc.coordinates
      .split(",")
      .map((coord) => parseFloat(coord.trim()));
  
    return { ...loc, lat, lng };
  });

  const getAdjustedLatLng = (lat, lng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const worldCoordinateCenter = map
      .getProjection()
      .fromLatLngToPoint(new window.google.maps.LatLng(lat, lng));

    // Ajuste en píxeles según el tamaño del OverlayView y zoom
    const pixelOffsetY = 130 / scale; // Ajuste vertical (positivo para arriba, negativo para abajo)
    const pixelOffsetX = 170 / scale;

    const newWorldCoordinateCenter = new window.google.maps.Point(
      worldCoordinateCenter.x + pixelOffsetX, // Ajuste en x
      worldCoordinateCenter.y + pixelOffsetY // Ajuste en y
    );

    const newLatLngCenter = map
      .getProjection()
      .fromPointToLatLng(newWorldCoordinateCenter);

    return newLatLngCenter;
  };
  useEffect(() => {
    if (isLoaded && map) {
      locateUser();
    }
  }, [isLoaded, map, locateUser]);

  return isLoaded ? (
    <div>
      <div id="input-search-location">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <div className="search-locate-container">
            <div
              className="search-locate-icon-container"
              id="search-icon-container"
            >
              <FaSearch className="search-icon" size={15} />
            </div>

            <div className="search-locate-filters-input">
              <div style={{ display: "flex" }}>
                <input
                  type="text"
                  placeholder={translate("global.address") + " :"}
                  className="font-small"
                  style={{
                    width: "250px",
                    height: "30px",
                    padding: "5px",
                    fontSize: "16px",
                    border: "none",
                    outline: "none",
                  }}
                />

                <div style={{ position: "relative" }}>
                  <button
                    onClick={locateUser}
                    className="btn"
                    style={{
                      backgroundColor: "#424996",
                      color: "white",
                      padding: "5px",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                    }}
                  >
                    {isLoadingLocation ? (
                     <Spinner type={1} /> // Muestra este mensaje mientras carga
                    ) : (
                      <IoMdLocate size={20} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Autocomplete>
      </div>

      {/* Botones de accion */}
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          zIndex: 10,
        }}
      >
        <button
          onClick={zoomIn}
          className="btn"
          style={{
            display: "block",
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <RiZoomInLine size={20} />
        </button>
        <button
          onClick={zoomOut}
          className="btn"
          style={{
            display: "block",
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <RiZoomOutLine size={20} />
        </button>
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentLocation}
        zoom={10}
        onLoad={onLoad}
        options={{
          styles: mapStyle,
          fullscreenControl: false, // Oculta el botón de pantalla completa
          zoomControl: false, // Oculta los botones de zoom predeterminados
          streetViewControl: false, // Oculta el botón de Street View
          mapTypeControl: false, // Oculta el botón de cambio de tipo de mapa
        }}
      >


        
        {/* Ubicacion actual*/}
        {currentLocation  && googleMaps && (
          <>
            <div className="location-actual">
              {/* Punto central azul */}
              <Marker
                position={currentLocation }
                icon={{
                  path: googleMaps.SymbolPath.CIRCLE,
                  scale: 7,
                  fillColor: "#4285F4",
                  fillOpacity: 1,
                  strokeWeight: 2,
                  strokeColor: "white",
                }}
              /> 


              {/* Círculo animado */}
              <Circle
                center={currentLocation }
                radius={50} // Cambia el radio si es necesario
                options={{
                  strokeColor: "#4285F4",
                  strokeOpacity: 0.5,
                  strokeWeight: 2,
                  fillColor: "#4285F4",
                  fillOpacity: 0.2,
                }}
              />
            </div>
          </>
        )}


         {/* Marcadores de ubicaciones */}
         {
          parsedLocations.length > 0 && googleMaps && ( 
              <>
                   {parsedLocations.map((loc, index) => (
              <Marker
                key={index}
                position={{ lat: loc.lat, lng: loc.lng }}
                icon={{
                  url: "../../img/marker_boxit.png",
                  scaledSize: new window.google.maps.Size(40, 40),
                  scale: 7,
                  fillColor: "#FF0000",
                  fillOpacity: 1,
                  strokeWeight: 1,
                  strokeColor: "white",
                }}
                onClick={() => {
                  setActiveMarker(index);

                  const adjustedCenter = getAdjustedLatLng(
                    loc.lat,
                    loc.lng,
                    map
                  );
                  map.panTo(adjustedCenter);
                }}
              >
                {activeMarker === index && (
                  <OverlayView
                    position={{ lat: loc.lat, lng: loc.lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div style={customInfoWindowStyle}>
                      <button
                        style={closeButtonStyle}
                        onClick={() => setActiveMarker(null)}
                      >
                        &times;
                      </button>
                      <div className="d-flex" style={{ gap: "10px" }}>
                        <div>
                          <img
                            src={loc.locationPhoto  || "../../img/locker.png"}
                            alt="Location"
                            style={imageStyle}
                          />
                        </div>
                        <div>
                          <h6 style={titleStyle}>{loc.locationName}</h6>

                          {loc?.zipCode && (
                            <p style={textStyle}>
                              <span className="text-bold">Zip Code:</span>{" "}
                              {loc.zipCode}
                            </p>
                          )}

                          {loc?.locationAddress && (
                            <p style={textStyle}>
                              <IoMdPin size={15} className="mr-2" />
                              {loc.locationAddress}
                            </p>
                          )}

                          {loc?.schedule && (
                            <p style={textStyle}>
                              <FaClock size={12} className="mr-2" />
                              {loc.schedule}
                            </p>
                          )}
                        </div>
                      </div>
                      <hr />

                      <div style={footerStyle}>

                        {
                          loc?.boxitLocationId && (
                            <a href={"/dashboard/packages/loadpackage?plt="+loc?.boxitLocationId}
                            className="main-btn btn-blue  d-flex align-items-center  btn"
                            style={{ fontSize: "12px" }}
                            onClick={() => setActiveMarker(null)}
                          >
                            <BiPackage size={20} style={{ marginRight: "5px" }} />{" "}
                            {translate("global.createPackage")}
                          </a>
                          )
                        }
                        
                      </div>

                      {
                        loc.linkMaps && (
<a href={loc.linkMaps} target="_blank" rel="noreferrer">
                        <span class="text-muted">
                          {" "}
                          <FaRegQuestionCircle
                            size={14}
                            style={{ marginRight: "3px" }}
                          />{" "}
                          <u>{translate("global.howGetSite")}</u>
                        </span>
                      </a>
                        )
                      }

                      
                    </div>
                  </OverlayView>
                )}
              </Marker>
            ))}
              </>
            )
         }
    

      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

const customInfoWindowStyle = {
  padding: "15px",
  width: "350px",
  backgroundColor: "white", // Matches the background color from the image
  color: "#424996",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  position: "relative", // Ensures the close button is positioned relative to the containe
  textAlign: "center", // Center the text
};

const closeButtonStyle = {
  color: "white",
  backgroundColor: "red",
  borderRadius: "50px",
  border: "none",
  fontSize: "20px",
  width: "20px",
  height: "20px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  position: "absolute",
  right: "15px"
};

const imageStyle = {
  width: "100px",
  height: "auto",
  borderRadius: "8px",
};

const titleStyle = {
  fontSize: "16px",
  fontWeight: "bold",
  margin: "10px 0",
  textAlign: "left",
};

const textStyle = {
  fontSize: "12px",
  margin: "5px 0",
  textAlign: "left",
};

const smallTextStyle = {
  fontSize: "12px",
  margin: "3px 0",
  color: "#B0B0B0", // Light grey for less emphasis
};

const footerStyle = {
  display: "flex",
  justifyContent: "center"
};

export default MapContainerSearch;
