import { FaLock } from "react-icons/fa";
import { MdLaptopChromebook } from "react-icons/md";


const LockerDoor = ({ 
  index,
  item,
  column1,
  column2,
  selectedDoor,
  showPackageInfo,
  setshowPackageInfo,
  activeSearch,
  onLockerClick,
  renderValidateInfoPack, 
  styles,
}) => {
  const doorHeight = 540;
 
  
  const handleDoorStatus = (door) => {
    let status = {
      shadowColor: "#FFFFFF",
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.1,
      shadowRadius: 7,
    };
    if (door?.isActive) {
      if (door?.isAvailable) return { ...status, backgroundColor: "#68A46A" };
      if (door?.isAvailable === false) {
        switch (door?.packageStatusId) {
          case 0: return { ...status, 
            backgroundColor: (!door.packages || door.packages.length === 0) ? "#ea580c" : "#3AA0E8" 
          }; // RESERVED
          case 1: return { ...status, backgroundColor: "#E5BA00" }; // ON BOXIT
          case 2: return { ...status, backgroundColor: "#828282" }; // EXPIRED
          default:
            break;
        }

      }
    }
    //   else return { ...status, backgroundColor: "#E5BA00" };
    else return { ...status, background: "#393939" }; // INACTIVE
  };
    const getSize = (size, columnCount) => {
    let height;
    switch (size) {
      case "S":
        height = doorHeight / columnCount + "%"; // Porcentaje basado en la cantidad de columnas
        break;
      case "M":
        height = doorHeight / columnCount + "%";
        break;
      case "L":
        height = doorHeight / columnCount + "%";
        break;
      case "XL":
        height = doorHeight / columnCount + "%";
        break;
      default:
        height = doorHeight / columnCount + "%";
    }
    return { height };
  };

  return (
    <div
      key={"door-smart-" + index}
      style={{
        minWidth: "180px",
        ...getSize(
          item.dimension,
          item?.columnModule === 1 ? column1?.length : column2?.length
        ),
        cursor: "pointer",
        ...handleDoorStatus(item),
        ...(selectedDoor?.includes(item.boxitDoorId) && { opacity: 0.5 }),
      }}
      onClick={() => onLockerClick(item?.boxitDoorId)}
      className={`p-2 rounded ${activeSearch ? "disable-locker-click" : ""}`}
      onMouseEnter={() => setshowPackageInfo(item.boxitDoorId)}
      onMouseLeave={() => setshowPackageInfo(null)}
    >
      <div style={styles.doorStatus} className="section-smart-locker-door">
        <div>
          <span>{item.doorNumber}</span>
          {!item?.isActive && (
            <span className="ml-2" style={{ background: "gray", padding: "6px", borderRadius: "0px 0px 16px 16px" }}>
              <FaLock />
            </span>
          )}
           {item?.isScreen && (
            <span className="ml-2" style={{ background: "#0058ff", padding: "6px", borderRadius: "0px 0px 16px 16px" }}>
              <MdLaptopChromebook size={18}/>
            </span>
          )}
        </div>
        <span>
          {item?.isScreen ? "C - " : item?.dimension && `${item.dimension} - `}
          {item?.layer && `${item.layer}`}
        </span>
      </div>

      {/* Mostrar información de los paquetes usando renderValidateInfoPack */}
      {showPackageInfo === item.boxitDoorId && item?.isAvailable === false && item.packages && item.packages.length > 0 && (
        <div className="package-info-tooltip">
          {item.packages.map((pkg, pkgIndex) => (
            <div key={`packages-${pkgIndex}`} className={`${(item.packages.length > 1 && item.packages.length !== (pkgIndex + 1)) ? 'border-bottom mb-2 pb-2' : ''}`}>
              {renderValidateInfoPack(pkg)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LockerDoor;