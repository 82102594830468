
import { IoPersonCircle } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { GiPlatform } from "react-icons/gi";

import './index.css';
import { getColorStatusClient, getIconStatusClients, getLabelTipoCliente, translate } from "../../utils/Common";

const ItemClientsCanvas = ({ item }) => {
    return (
        <a href={`clients/details/${item?.IdCliente}`} className="grid-item" >

            <div className='d-flex h-100' style={{position:"relative"}}>

            <div className={'badge-status '+getColorStatusClient(item?.IdEstatus)} style={{position:"absolute", top:"5px", right:"5px"}}>
                {getIconStatusClients(item?.IdEstatus)}
             </div>

            <div className={"img-item-canvas "+(item?.IdTipoCliente === 7 ? "fucsia" : "")}>
                < IoPersonCircle size={50}/>
            </div>

            <div className='description-item-canvas'>
                <div className="text-bold" style={{color:"#424996"}}>{item?.Nombre + ' ' +item?.Apellidos + ' ('+ (item?.IdTipoCliente === 1 ? "B" : "") + item?.IdCliente+ ')'}</div>

                     {item?.IdTipoCliente === 7 && (<small> {translate("global.business")}: <strong>{item?.CompanyName}</strong></small>)}
                     
                <div style={{display:"flex", gap:"10px"}}><MdOutlineMailOutline size={20}/> {item?.Email}</div>
                {item?.IdTipoCliente !== 7 && <div style={{display:"flex", gap:"2px"}}><GiPlatform className="mr-2" size={20}/> {item?.NombrePlataforma}</div>}
           
           <div className="mt-2">
<strong>
  {getLabelTipoCliente(
    item?.IdTipoCliente
  )}{" "}
</strong>
           </div>
          
            </div>
            
            </div>
        </a>
    );
}

export default ItemClientsCanvas;