import Spinner from '../../../../components/Spinner';
import Tooltip from '../../../../components/Tooltip'
import { translate } from '../../../../utils/Common';

// paquetes en boxits vencidos por retirar
const PackagesExpired = ({ cantPackagesExpired, cardTitle, tooltipMessage }) => {
    return (
        (cantPackagesExpired || cantPackagesExpired === 0) ?
            <>
                <h4 className="p-0 m-0 text-bold">{cantPackagesExpired}</h4>
                <div className="d-flex flex-inline">
                    <p className="p-0 m-0">{ cardTitle ? cardTitle : translate("global.packages_expired.title") }</p>
                    <Tooltip  className="mx-1"
                        info={ 
                            tooltipMessage ? tooltipMessage :
                            translate("dashboard.home.packages_expired.info_1")+
                            translate("dashboard.home.packages_expired.info_2") +
                            translate("dashboard.home.packages_expired.info_3")
                        } />
                </div>
            </>
            :
            <Spinner type={ 1 }/>
    );    
}

export default PackagesExpired;