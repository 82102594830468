import { useState, useEffect } from 'react';
import { useIncidents } from '../../../hooks/useIncidents';
import { getNameIncidentsTypes, translate } from '../../../utils/Common';
const TypeIncidentList = ({
    register, 
    errors,
    nameShow,
    idShow,
    readOnly,
    sizeInput
}) => {
   
    const { typesIncidents, getTypes } = useIncidents();
    const [typeListIncidents,seTypeListIncidents] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        const handleTypes = async () => {
            const response = await getTypes(abortController.signal);
            seTypeListIncidents(response);
        }

        if (!typesIncidents) handleTypes()
        else seTypeListIncidents(typesIncidents);
        
        return () => abortController.abort();
        
    }, [getTypes, typesIncidents]);
    
    return  (
        <>
            <select className={`${sizeInput ?? "form-control-sm" } form-control font-small`} 
                readOnly={ readOnly } 
                name="typeIncident" 
                ref={ register({
                  validate: value => value !== (translate("global.select") || translate("global.noResults")) || translate("global.chooseValidTypeIncident")
              }) }>
                <option defaultValue key={0} className="font-small">{nameShow ? nameShow : translate("global.noResults")}</option>
                { typeListIncidents ?
                    typeListIncidents?.map(item => 
                        (idShow !== item.id) && 
                        <option key={item.id} className="font-small" value={ item.id }>{getNameIncidentsTypes(item.id)}</option>)
                    :
                    <option defaultValue key={1} className="font-small">{translate("global.noResults")}</option>
                }
            </select>
            {errors.typeIncident && <span className="text-error">{errors.typeIncident.message}</span> }
        </>
    )
}

export default TypeIncidentList;