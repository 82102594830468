import QrCode from "qrcode.react";
import './index.css';
const RowReserva = ({ item, selectItem, index }) => {
    return (
        <tr className='orderRow' onClick={() => selectItem(item)}>
            <th>{index + 1}</th>
            <th>{item.NumeroIdentificacion ? item.NumeroIdentificacion : ''}</th>
            <td>{item.Direccion ? item.Direccion : ''}</td>
            <td className="p-4">
                { item?.NumeroIdentificacion &&
                <QrCode 
                    id='packageQrCode' 
                    value={item?.NumeroIdentificacion} 
                    renderAs={'svg'}
                    height={150}
                    width={80} />}
            </td>
        </tr>
    );
}

export default RowReserva;