import { useState, useEffect } from 'react';
import { usePlatforms } from '../hooks/usePlatforms';
import { translate } from '../utils/Common';
const DoorList = ({
    register,
    nameShow,
    idShow,
    readOnly,
    sizeInput
}) => {
    const { getDoors } = usePlatforms();
    const [doorsList,setDoorsList] = useState(null);
    useEffect(() => {
        const abortController = new AbortController();
        const handleDoors = async () => {
            const doors = await getDoors(idShow, abortController.signal);
            setDoorsList(doors);
        }
        handleDoors();
        return () => abortController.abort();
    }, [idShow, getDoors]);

    return  (
        <select className={`${sizeInput ?? "form-control-sm" } form-control font-small`}
            name="puerta"
            readOnly={readOnly}
            ref={ register()} >
            <option defaultValue value={0} key={0} className="font-small">{nameShow ? nameShow : translate("global.noDoor")}</option>
            { doorsList ?
                doorsList?.map(door => 
                    (idShow !== door.IdPlataformaDet) && 
                    <option key={door.IdPlataformaDet} className="font-small" value={ door.IdPlataformaDet }>{`${door.Identificacion} - ${door.Dimension}`}</option>)
                :
                <option defaultValue key={1} className="font-small">{translate("global.alert.noDoorsAvailable")}</option>
            }
        </select>
    )
}

export default DoorList;