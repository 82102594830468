import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthContext from "../../../../contexts/auth";
import { getClientDomain, getToken, translate } from "../../../../utils/Common";
// components
import ItemMethod from "./ItemMethod";
// icons
import * as BsIcons from "react-icons/bs";

const PaymentMethods = ({ addCambio, setAddCambio, montoFacturado, cambio, saldo, methodsChosen, setMethodsChosen }) => {
    const userContext = useContext(AuthContext);
    const [methods, setMethods] = useState([]);
    const [methodSelected, setMethodSelected] = useState(null);
    const { register, reset, handleSubmit } = useForm();

    const [adminMethods, ] = useState([ 2, 3, 4, 6, 8, 9, 10 ]);
    const [counterMethods, ] = useState([ 2, 3, 4, 6, 10 ]);

    const addMethod = (data) => {
        setMethodsChosen([...methodsChosen, { id: Number(data.method), name: methods.filter(m => m.id === Number(data.method))[0].name, amount: Number(data.monto) }]);
        setMethodSelected(null);
        reset();
    }

    const deleteMethod = (id) => {
        setMethodsChosen(methodsChosen.filter((p) => p.id !== id));
    }

    const clearPayment = () => {
        setMethodSelected(null);
        reset();
    }

    useEffect(() => {
        const controller = new AbortController();
        try {
            const getPaymentMethods = async () => {
                const response = await fetch(`${getClientDomain()}/system/paymentMethods`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    }
                });

                const result = await response.json();
                if (result.code === 200) {
                    if (result.payload) setMethods(result.payload);
                    else setMethods([ { id: 0, name: 'No hay métodos disponibles' }]);
                }
                else {
                    setMethods([ { id: 0, name: 'No hay métodos disponibles' }]);
                }
            }

            getPaymentMethods();
        } catch (err) {
            console.log(err);
            setMethods([ { id: 0, name: 'No hay métodos disponibles' }]);
        }
        return () => controller.abort();
    }, []);

    return (
        <>
            <div className="form-row mt-3 mx-0 mb-1 titlePayments">
                <h6 className="my-1"><BsIcons.BsCashCoin size={18} className="mx-2" />{translate("global.paymentMethod")}</h6> 
            </div>
            
            <form className="form-row mx-3 mb-1 pb-2 border-bottom" onSubmit={ handleSubmit(addMethod) }>
                <div className="col-12 col-md-4 mb-1" >
                    <label className="mb-0 mt-1" htmlFor="selectMethods">{translate("global.selectPaymentMethod")}:</label>
                    <select className="form-control form-control-sm font-small" disabled={ saldo > 0 ? false : true } onChange={ (e) => setMethodSelected(Number(e.target.value)) } id="selectMethods" name="method" ref={register}>
                        <option defaultValue>Seleccionar</option>
                        { methods?.length > 0 && methods.map((method, index) => 
                        ((userContext.user?.IsAdmin && adminMethods.includes(method.id)) || 
                        (!userContext.user?.IsAdmin && counterMethods.includes(method.id))) && 
                        <option type="button" key={index} className="font-small text-left" value={method.id}>{ `${method.name?.substring(0,1)}${method.name?.toLowerCase().substring(1)}` }</option>) 
                    }
                    </select>
                </div>

                { methodSelected &&
                    <div className="col-12 col-md-5">
                        <label className="mb-0 mt-1" htmlFor="inputMonto">{translate("global.amount")}:</label>
                        <div className="input-group">
                            <input type="number" placeholder="0" step="0.01" id="inputMonto" name="monto" className="form-control form-control-sm font-small font-bold" ref={ register } />
                            <div className="input-group-prepend">
                                <button type="submit" className="btn btn-sm btn-fucsia font-small" >{translate("global.add")}</button>
                                <button type="button" className="btn btn-sm btn-outline-fucsia font-small" onClick={ () => clearPayment() } >{translate("global.clean")}</button>
                            </div>
                        </div>
                    </div>
                }
            </form>

            <div className="form-row mx-3">
                { methodsChosen.length > 0 &&
                    methodsChosen.map((method, index) => <ItemMethod paymentMethod={ method } key={index} deleteMethod={ deleteMethod } />)
                }

                { methodsChosen.length > 0 &&
                <>
                    <div className="col-12 col-md-12 border-bottom d-flex flex-row pb-2 mt-1" >
                        <div className="packageForPickUp-precioTotal">
                            <label className="mb-0 font-bold" >{translate("dashboard.packages.pickUp.paymentMethod.amountBilled")}</label>
                            <input type="text" value={ `$ ${montoFacturado}` } readOnly className="form-control-sm font-small form-control-plaintext font-bold py-0"/>
                        </div>

                        <div className="packageForPickUp-precioTotal">
                            <label className="mb-0 font-bold" >{translate("dashboard.packages.pickUp.paymentMethod.outstandingBalance" )}</label>
                            <input type="text" value={ `$ ${saldo}` } readOnly className="form-control-sm font-small form-control-plaintext font-bold py-0"/>
                        </div>

                        <div className="packageForPickUp-precioTotal">
                            <label className="mb-0 font-bold" >{translate("dashboard.packages.pickUp.paymentMethod.change" )}</label>
                            <input type="text" value={ `$ ${cambio}` } readOnly className="form-control-sm font-small form-control-plaintext font-bold py-0"/>
                        </div>
                    </div>

                    { (userContext?.user.IsAdmin || userContext?.user.IdPlataforma === 2) && cambio > 0 &&
                        <div className="col-12 col-md-12 border-bottom pb-2 mt-1">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switchAddAbono" checked={ addCambio } onChange={ (e) => setAddCambio(e.target.checked) } />
                                <label className="custom-control-label" htmlFor="switchAddAbono" >
                                    <div className="mt-1">{translate("global.question.payChange")}</div>
                                </label>
                            </div>
                        </div>
                    }
                </>
                }

            </div>
        </>
    );
}

export default PaymentMethods;