import React, { useState, useContext, useEffect, useRef } from 'react';
import { setPage, setValueSearchFilter, translate } from '../../../utils/Common';
import { useForm } from 'react-hook-form';
import AuthContext from '../../../contexts/auth';
import './index.css';
import * as FaIcons from 'react-icons/fa';
import ProviderList from '../../../components/ProviderList';
import PackagesReservation from '../../../components/PackagesReservation';
import ItemReserva from '../../../components/ShowItems/ItemReserva';

const AwaitingDelivery = () => {
    const { register, watch, setValue } = useForm();
    const provider = useRef({});
    provider.current = watch('proveedor', '');
    const userContext = useContext(AuthContext);

    // update table
    const [updateTable, setUpdateTable] = useState(false);

        // Seleccionar Empresa
        const [selectedCompany, setSelectedCompany] = useState(null);
    
    // para obtener los proveedores
    const [selectedUser, setSelectedUser] = useState(null);
    useEffect(() => {
        const handleUserChange = () => {
           setSelectedUser(selectedCompany ? Number(selectedCompany.IdUsuario) : 0);
            setUpdateTable(!updateTable);
        }
        handleUserChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider.current, selectedCompany])

    useEffect(() => {
        setPage('')
        setValueSearchFilter('');
    }, []);

    return (
        <>
            <div className="dashboardCard text-left">
                <div className={`reservations-title ${userContext.user?.IsAdmin ? 'mb-2' : ''}`}>
                    <h5><FaIcons.FaCar size={16} className="mr-2"/>{translate("dashboard.awaitingDelivery.title")}</h5>
                    <div className="clearfix border-bottom"></div>
                </div>

                { userContext.user?.IsAdmin &&
                    <div className="col-sm-12 col-md-6 mb-0 px-1">
                        <label htmlFor="selectProveedor" className="mb-1 mt-2"><span className="text-error text-bold">*</span> {translate("global.business")}:</label>
                        <ProviderList 
                            register={register}
                            nameShow={translate("global.select")}
                            idShow={"all"}
                            readOnly={false}
                            setValue={setValue}
                            valueId={"proveedor"}
                            setProveedor={setSelectedCompany}
                            />
                    </div>
                }
            </div>

            <PackagesReservation
                selectedUser={ selectedUser }
                updateTable={ updateTable }
                handleUpdateTable={ setUpdateTable }
                ItemComponent={ ItemReserva }
                reservationType={ 2 } />
        </>
    );
}

export default AwaitingDelivery;