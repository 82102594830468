import React, { useState, useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from "../../../components/Spinner";
import { checkConnectionStatus, verifyHideCountryItems } from "../../../utils/Common";
// auth
import AuthContext from "../../../contexts/auth";
import SidebarContext from "../../../contexts/sidebar";
import packageJson from '../../../../package.json';
import "./index.css";
import "../index.css";
import {translate} from "../../../utils/Common";
import TraductorList from "../../../components/Traductor";
import CountryList from "../../../components/Country";

const SignIn = () => {
	const userContext = useContext(AuthContext);
	const sidebarContext = useContext(SidebarContext)
	const history = useHistory();
	const { register, handleSubmit, errors, reset, setError } = useForm();
	const [isServerRun,setIsServerRun] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [message,setMessage] = useState();

	const onSubmit = async (data) => {
		setMessage('');
		setIsServerRun(false);
		setIsLoading(true);
		const result = await userContext.signIn(data);
		setIsLoading(false);
		
		switch (result) {
		case 'LOGIN_SUCCESS':
			reset();
			sidebarContext.handleChangeCurrentPage(translate("sidebar.options.start"));
			history.push('/dashboard');
			break;
		case 'PASSWORD_INVALID':
			setError('pwd', { type: '', message: translate("login.signin.validate.passIncorrect") });
			setIsServerRun(true);
			break;
		case 'USER_NOT_FOUND':
			setError('username', { type: '', message: translate("login.signin.validate.userNotFound") });
			setIsServerRun(true);
			break;
		case 'EMAIL_NOT_FOUND':
			setError('username', { type: '', message: translate("login.signin.validate.emailNotFound") });
			setIsServerRun(true);
			break;
		case 'USER_INACTIVATED':
			setError('username', { type: '', message: translate("login.signin.validate.userInactive") });
			setIsServerRun(true);
			break;
		case 'USER_NOT_VERIFIED':
			setError('username', { type: '', message: translate("login.signin.validate.userNotVerified") });
			setIsServerRun(true);
			break;
		case 'SERVER_ERROR':
			if (checkConnectionStatus()) {
				setMessage(translate("login.signin.validate.errorConnectionTrue"));
			}
			else {
				setMessage(translate("login.signin.validate.errorConnectionFalse"));
			}
			setIsServerRun(false);
			break;
		
		default:
			break;
		}
	};

	return (
		<div className="login d-flex align-items-center justify-content-center" style={{
			backgroundImage: "url('/img/pattern-boxit2.png')",
			backgroundSize: "cover",
		  }}>
			<div className="position-fixed fixed-top p-4 d-flex flex-row-reverse">
					<TraductorList />
			</div>
			<form className="signin-form  shadow text-center" onSubmit={handleSubmit(onSubmit)}>
				<img src="../img/logo-boxit.png" className="mb-3" alt="Boxit" height="85" width="220"/>
				<CountryList />	
				<h6 className="mb-2 font-weight-normal">{translate("login.signin.title")}</h6>

						<div className="form-group">
						<input disabled={ !isServerRun } type="username" className={ "input-signin form-control rounded-pill form-control-lg" + (errors.username ? " is-invalid" : "") } name="username" placeholder={translate("login.signin.user")} required autoFocus ref={register({
							minLength: {
							value: 6,
							message: translate("login.signin.validate.minLength")
							},
							maxLength: {
							value: 20,
							message: translate("login.signin.validate.maxLength")
							}
						})}/>
						{errors.username && <div className="invalid-feedback">{errors.username.message}</div>}
					</div>
			
				<div className="form-group mb-2">
					<input disabled={ !isServerRun } type="password" className={ "input-signin form-control rounded-pill form-control-lg" + (errors.pwd ? " is-invalid" : "")} name="pwd" placeholder={translate("login.signin.password")} required ref={register({
						minLength: {
						value: 6,
						message:  translate("login.resetpwd.validate.minLength")
						},
						maxLength: {
						value: 16,
						message: translate("login.resetpwd.validate.maxLength")
						}
					})}/>
					{errors.pwd && <div className="invalid-feedback">{errors.pwd.message}</div>}
				</div>

				<div className="mb-2">
					<Link to="/forgotpwd" className="link font-small">{translate("login.signin.forgot")}</Link>
				</div>

				<button disabled={ !isServerRun } className="btn btn-blue btn-login btn-block rounded-pill btn-lg text-uppercase" type="submit">
					{ !isLoading ? translate("login.signin.button") : <Spinner type={1} size={18} /> }
				</button>

				<div className="text-center">
					{verifyHideCountryItems(["br", "us"]) && (
						<>
						<p className="mt-3 mb-0 font-weight-normal">{translate("login.signin.question")}</p>
					<Link to="/registrar" className="link font-weight-bold">{translate("login.signin.registerHere")}</Link>
						</>
					)} 
					<p className="text-muted font-xsmall mb-0 mt-1">v{packageJson.version}</p>
				</div>

				{ message && <div className="text-center mt-3"><span className="text-danger font-xsmall">{message}</span></div> }
			</form>
		</div>
	);
};

export default SignIn;
