//@ts-nocheck
import React, { forwardRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GrCalendar } from "react-icons/gr";
import { registerLocale } from "react-datepicker";
import { es, pt, enUS } from "date-fns/locale";
import { getAllMonths, rangeYears } from "../../utils/Common";

const CustomDatePicker = ({
  selectedDate,
  onChangeDate,
  placeholder,
  startDate,
  endDate,
  name,
  disabled,
  isClearable,
}) => {
  const langStorage = localStorage.getItem("language");
  const lang = langStorage ? langStorage : "enUS";

  const locales = {
    es: es,
    pt: pt,
    en: enUS,
  };

  const selectedLocale = locales[lang] || enUS;
  registerLocale(lang, selectedLocale);

  const preventTextInput = (event) => {
    event.preventDefault();
  };

  const handleStartDateChange = (date) => {
    onChangeDate(date);
    if (endDate && date && endDate.getTime() < date.getTime()) {
      onChangeDate(null, "end");
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate) {
      onChangeDate(date);
    }
  };

  const isOutsideRange = (date) => {
    if (startDate) {
      const maxEndDate = new Date(startDate);
      maxEndDate.setDate(maxEndDate.getDate() + 90);
      return date.getTime() > maxEndDate.getTime();
    }
    return false;
  };

  const getYear = (date) => {
    return date.getFullYear();
  };

  const getMonth = (date) => {
    return date.getMonth();
  };

  const years = rangeYears(2015, getYear(new Date()) + 1, 1);
  const months = getAllMonths();

  const CustomInput = forwardRef(({ value, onClick, placeholderValue, disabled}, ref) => (
      <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <GrCalendar onClick={onClick} />
        </span>
      </div>
      <input
        type="text"
        value={value}
        onClick={onClick}
        placeholder={placeholderValue} 
        className="form-control text-sm"
        disabled={disabled}
      />
    </div>
  ));

  return (
    <div className="input-group mb-3 d-flex mr-2">
      <div className="flex-grow-1">
        <DatePicker
          selected={selectedDate}
          onChange={startDate ? handleEndDateChange : handleStartDateChange}
          dateFormat="dd-MM-yyyy"
          customInput={<CustomInput placeholderValue={placeholder} disabled={disabled} />}
          isClearable={isClearable}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={isOutsideRange}
          showIcon={false} // Desactiva el icono predeterminado
          name={name}
          disabled={disabled}
          onKeyDown={preventTextInput}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
              >
                {"<"}
              </button>
              <select
                className="form-select form-control-sm"
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                className="form-select form-control-sm"
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                className="btn"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
              >
                {">"}
              </button>
            </div>
          )}
          locale={lang}
        ></DatePicker>
      </div>
    </div>
  );
};

export default CustomDatePicker;
