// components
import Spinner from '../../../../components/Spinner';
import Tooltip from '../../../../components/Tooltip'
import { translate } from '../../../../utils/Common';

// Paquetes por entregar en boxit
const PackagesWaitingDelivery = ({ cantPackagesDelivery, cardTitle, tooltipMessage }) => {
    
    return (
        (cantPackagesDelivery || cantPackagesDelivery === 0) ?
            <>
                <h4 className="p-0 m-0">
                    <span className="text-bold">{cantPackagesDelivery}</span>
                </h4>
                <div className="d-flex flex-inline">
                    <p className="p-0 m-0">{ cardTitle ? cardTitle : translate("dashboard.home.packages_waiting.title")}</p>
                    <Tooltip className="mx-1"
                        info={ 
                            tooltipMessage ? tooltipMessage :
                            translate("dashboard.home.packages_waiting.info_1") +
                            translate("dashboard.home.packages_waiting.info_2")  +
                            translate("dashboard.home.packages_waiting.info_3") 
                        } />
                </div>
            </>
            :
            <Spinner type={ 1 }/>
    );
}

export default PackagesWaitingDelivery;