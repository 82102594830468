import { useState, useEffect } from 'react';
import { getClientDomain, getToken, translate } from '../../../../utils/Common';

// tabla con la facturación del proveedor
const TableFacturacionProveedor = ({ idPaquete, updateComponent }) => {
    // tracking proveedor
    const [proveedorBillingPaquete, setProveedorBillingPaquete] = useState(null);
    
    useEffect(() => {
        const abortController = new AbortController();

        const getFacturacionProveedor = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/billing/${idPaquete}`, {
                    method: 'GET',
                    accept: 'application/json',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    signal: abortController.signal
                });
                const result = await response.json();
                if (result.payload) setProveedorBillingPaquete(result.payload);
            } catch (err) {
                console.log(err);
            }
        }

        getFacturacionProveedor();

        return () => abortController.abort();
    }, [idPaquete, updateComponent]);
    
    let statusProveedorBilling = 'Cargando...';
    const tableHeader = <thead className="text-center">
                            <tr>
                                <th className="col-6">{translate("global.concept")}</th>
                                <th className="col-2">{translate("global.amount")}</th>
                                <th className="col-2">{translate("global.invoice")}</th>
                                <th className="col-2">{translate("global.date")} {translate("global.invoice")}</th>
                            </tr>
                        </thead>;

    let tableRows = [];
    if (proveedorBillingPaquete)
        if (proveedorBillingPaquete.length > 0)
            tableRows.push(proveedorBillingPaquete?.map((row, index) => {
                return  <tr key={ index }>
                            <td>{ row.estatus }</td>
                            <td>{ row.fecha }</td>
                        </tr>;
            }));
        else statusProveedorBilling = 'No hay registros de facturación';

    return  <>
                <div className="form-row text-left mx-3 font-small">
                    <div className="form-group col-12 mb-2 py-1 text-center bg-blue">
                        <h6 className="text-light mb-0">{translate("dashboard.packages.details.tableStatusBoxit.title")}</h6>
                    </div>
                </div>

                <div className="mx-4 font-small">
                    <div className="table-responsive">
                        <table className="table table-striped table-hover table-sm">
                            { tableHeader }
                            <tbody className="font-xsmall">
                                { tableRows.length > 0 ? tableRows : <tr><td colSpan='4'>{statusProveedorBilling}</td></tr> }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
}

export default TableFacturacionProveedor;