import { translate } from "../../../../utils/Common";
import "./index.css";

const lockerData = [
    { size: "P", width: "45px", highInch: "4.7 inch", highCm: "12 cm", widthInch: "12.6 inch", widthCm: "32 cm", longInch: "18.9 inch", longCm: "48 cm" },
    { size: "M", width: "60px", highInch: "7.2 inch", highCm: "18.2 cm", widthInch: "12.6 inch", widthCm: "32 cm", longInch: "18.9 inch", longCm: "48 cm" },
    { size: "G", width: "90px", highInch: "12.0 inch", highCm: "30.4 cm", widthInch: "12.6 inch", widthCm: "32 cm", longInch: "18.9 inch", longCm: "48 cm" },
  { size: "XG", width: "110px", highInch: "23.2 inch", highCm: "59 cm", widthInch: "12.6 inch", widthCm: "32 cm", longInch: "18.9 inch", longCm: "48 cm" }
];

const LockerSizes = (props) => {
  return (
    <div className="mt-3">
      <h6 className="boxit-fucsia">
        {translate("dashboard.packages.loadpackages.lockerSize.title")}:
      </h6>
      <div className="locker-size-container">
        {lockerData.map((locker) => (
          <div key={locker.size} className="locker-item-size">
            <div className="locker-size-title">{locker.size}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <img src="/img/box.png" alt="boxit-box" width={locker.width} />
            </div>
            <div className="locker-size-content">
              <div>
                <span className="font-bold">{translate("global.high")}</span> = {" "}
                {props.unidad === 1 ? locker.highInch : locker.highCm}
              </div>
              <div>
                <span className="font-bold">{translate("global.width")}</span> = {" "}
                {props.unidad === 1 ? locker.widthInch : locker.widthCm}
              </div>
              <div>
                <span className="font-bold">{translate("global.long")}</span> = {" "}
                {props.unidad === 1 ? locker.longInch : locker.longCm}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LockerSizes;
