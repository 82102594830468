// React
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// Context 
import ModalContext from "../../../../contexts/modal";
import AuthContext from "../../../../contexts/auth";

// Hooks
import { useIncidents } from "../../../../hooks/useIncidents";

// Icon
import { LuAlertCircle } from "react-icons/lu";
import { TbListDetails } from "react-icons/tb";
import { FaChevronLeft } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoIosSave } from "react-icons/io";

// Utility imports
import {
  getFlagPriorityIncident,
  getNameIncidentsPriority,
  getNameIncidentsStatus,
  getNameIncidentsTypes,
  translate,
  getIconStatusIncident,
  getFormatDate,
  getColorPriorityIncident
} from "../../../../utils/Common";

// Component imports
import TypeIncidentList from "../TypeIncidentList";
import PriorityIncidentList from "../PriorityIncidentList";
import StatusIncidentList from "../StatusIncidentList";
import Spinner from "../../../../components/Spinner";
import ShowTracking from "../../../../components/ShowTracking";
import ItemIncidents from "../../../../components/ShowTracking/ItemIncidents";

const UpdateIncident = () => {
  let { id } = useParams();
  const { register, errors, reset, handleSubmit } = useForm();

  const { updateIncident, getDetailsIncident, getTrackingIncident } =
    useIncidents();
  const modalContext = useContext(ModalContext);
  const userContext = useContext(AuthContext);

  const [detailsIncidents, setDetailsIncidents] = useState(null);
  const [trackingIncidents, setTrackingIncidents] = useState(null);
  const [isSendingData, setIsSendingData] = useState(false);

  const [isActiveUpdate, setIsActiveUpdate] = useState(true);

  const submitUpdateIncident = async (data) => {
    try {
      setIsActiveUpdate(!isActiveUpdate);

      if (!isActiveUpdate) {
        const controller = new AbortController();
        const result = await updateIncident(
          { ...data, incidentId: id },
          controller.signal
        );
        setIsSendingData(true);

        if (result) {
          if (result.code === 200) {
            if (result.payload) {
              modalContext.confirm(
                translate(
                  "dashboard.packages.loadpackage.formProvider.successTittle"
                ),
                <div>
                  <strong>
                    {" "}
                    {translate("global.incident")} # {result.payload.incidentId}
                  </strong>
                  {`${translate("global.successUpdateIncident")}`}
                </div>,
                <button
                  type="button"
                  className="btn btn-sm btn-fucsia"
                  onClick={() => modalContext.setIsOpenModal(false)}
                >
                  {translate("global.accept")}
                </button>,
                true
              );
              reset();
            }
          } else {
            modalContext.confirm(
              translate("global.alerts.ops"),
              translate("global.errorRegisterIncident"),
              <button
                type="button"
                className="btn btn-sm btn-outline-fucsia"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("global.accept")}
              </button>,
              true
            );
            reset();
          }
        }

        setIsActiveUpdate(!isActiveUpdate);
      }
    } catch (err) {
      modalContext.confirm(
        translate("global.alerts.ops"),
        translate(
          "dashboard.packages.loadpackages.formProvider.errorRegisterPackage"
        ),
        <button
          type="button"
          className="btn btn-sm btn-outline-fucsia"
          onClick={() => modalContext.setIsOpenModal(false)}
        >
          {translate("global.accept")}
        </button>,
        true
      );
    } finally {
      setIsSendingData(false);
    }
  };

  const renderDetailsIncident = (
    <form onSubmit={handleSubmit(submitUpdateIncident)}>
      <div className="mb-4 d-flex" style={{ gap: 6 }}>
        <button
          type="button"
          className="btn btn-outline-fucsia"
          onClick={() => window.history.back()}
        >
          <FaChevronLeft className="mr-2" />
          {translate("global.return")}
        </button>
        {userContext.user?.IsAdmin &&
          (isActiveUpdate ? (
            <button type="submit" className="btn btn-fucsia">
              <MdEdit className="mr-2" /> {translate("global.edit")}
            </button>
          ) : (
            <div style={{ display: "flex", gap: 5 }}>
              <button type="submit" className="btn btn-fucsia" disabled={isSendingData}>
                <IoIosSave className="mr-2" /> {translate("global.save")}
              </button>
              <button
                type="button"
                className="btn btn-outline-light"
                style={{ color: "gray", border: "1px solid #e5e5e5" }}
                onClick={() => setIsActiveUpdate(!isActiveUpdate)}
              >
                {translate("global.cancel")}
              </button>
            </div>
          ))}
      </div>

      <h6>
        {" "}
        <TbListDetails /> {translate("global.incidentDetails")}
      </h6>

      <div className={`alert alert-${getColorPriorityIncident(detailsIncidents?.IdPriority)} alert-dismissible fade show mt-3`} role="alert">
  <strong>{getFlagPriorityIncident(detailsIncidents?.IdPriority)} {translate("global.description")}: </strong> {detailsIncidents?.Description || ""}
</div>

      <div className="row">
        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.name")}</label>
          <input
            type="text"
            className="form-control form-control-md font-small"
            name="nameClient"
            value={detailsIncidents?.NameClient || ""}
            disabled
          />
        </div>

        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.email")}</label>
          <input
            type="email"
            className="form-control form-control-md font-small"
            name="email"
            value={detailsIncidents?.Email || ""}
            disabled
          />
        </div>

        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.date")}</label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="text"
              className="form-control form-control-md font-small"
              name="dateIncident"
              value={getFormatDate(detailsIncidents?.DateIncident || "")}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">
            {translate("global.typeIncident")}
          </label>
          {isActiveUpdate ? (
            <input
              type="text"
              defaultValue={getNameIncidentsTypes(detailsIncidents?.IdType)}
              disabled
              className="form-control form-control-md font-small"
            />
          ) : (
            <TypeIncidentList
              errors={errors}
              register={register}
              nameShow={getNameIncidentsTypes(detailsIncidents?.IdType)}
              idShow={detailsIncidents?.IdType}
              sizeInput={"form-control-md"}
            />
          )}
        </div>

        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.priority")} </label>

          {isActiveUpdate ? (
            <div className="input-group mb-3">
              <span className="input-group-text">
                {getFlagPriorityIncident(detailsIncidents?.IdPriority)}
              </span>
              <input
                type="text"
                defaultValue={getNameIncidentsPriority(
                  detailsIncidents?.IdPriority
                )}
                disabled
                className="form-control form-control-md font-small"
              />
            </div>
          ) : (
            <PriorityIncidentList
              errors={errors}
              register={register}
              nameShow={getNameIncidentsPriority(detailsIncidents?.IdPriority)}
              idShow={detailsIncidents?.IdPriority}
              sizeInput={"form-control-md"}
            />
          )}
        </div>

        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.status")}</label>

          {isActiveUpdate ? (
            <div className="input-group mb-3">
              <span className="input-group-text">
                {getIconStatusIncident(detailsIncidents?.IdStatus)}
              </span>
              <input
                type="text"
                defaultValue={getNameIncidentsStatus(
                  detailsIncidents?.IdStatus
                )}
                disabled
                className="form-control form-control-md font-small"
              />
            </div>
          ) : (
            <StatusIncidentList
              errors={errors}
              register={register}
              nameShow={getNameIncidentsStatus(detailsIncidents?.IdStatus)}
              idShow={detailsIncidents?.IdStatus}
              sizeInput={"form-control-md"}
            />
          )}
        </div>
      </div>

      <div className="row pt-2">
        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.platform")}</label>
          <input
            type="text"
            className="form-control form-control-md font-small"
            name="plataforma"
            value={detailsIncidents?.Plataforma || ""}
            disabled
          />
        </div>

        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">{translate("global.numDoor")}</label>
          <input
            type="text"
            className="form-control form-control-md font-small"
            name="numDoor"
            value={detailsIncidents?.DoorNumber || ""}
            disabled
          />
        </div>

        <div className="col-lg-4 col-sm-12 pt-2">
          <label htmlFor="incidentTitle">
            {translate("global.numPackage")}
          </label>
          <input
            type="text"
            className="form-control form-control-md font-small"
            name="idPackage"
            value={detailsIncidents?.IdPackage || ""}
            disabled
          />
        </div>
      </div>

        {
          !isActiveUpdate && (
            <div className="form-group mt-4">
            <label htmlFor="incidentDescription">
              {translate("global.description")} <span className="text-error">*</span>{" "}
            </label>
            <textarea
              className="form-control form-control-md font-small"
              id="incidentDescription"
              rows="3"
              name="description"
              ref={register}
              required
              disabled={isActiveUpdate}
            ></textarea>
          </div>
          )
        }
     

    </form>
  );

 
  const abortController = new AbortController();
  const handleGetIncident = async () => {
    const response = await getDetailsIncident(id, abortController.signal);
    setDetailsIncidents(response);
  };

  useEffect(() => {
    // setDetailsIncidents(null)
    handleGetIncident();
    // return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendingData]);

  const abortController2 = new AbortController();
  const handleGetTrackingIncident = async () => {
    const response = await getTrackingIncident(id, abortController2.signal);
    setTrackingIncidents(response);
  };

  useEffect(() => {
    handleGetTrackingIncident();
    // return () => abortController2.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendingData]);

  return (
    <div className="dashboardCard text-left">
      <div
        className="d-flex align-items-center"
        style={{ fontSize: "16px", gap: 4 }}
      >
        <span style={{ fontWeight: "300" }}>
          <LuAlertCircle className="mr-2" size={20} />
          {translate("global.listIncident")}
        </span>
        <span> / </span>{" "}
        <h6 className="m-0" style={{ color: "#424996" }}>
          {translate("global.incident")} # {id}
        </h6>
      </div>

      <hr />

      {detailsIncidents ? (
        <div className="mt-1 row">
            <><div className="col-lg-8">{renderDetailsIncident}</div>
            <div className="col-lg-4">{<ShowTracking itemArray={trackingIncidents}  title={translate("global.incidentTracking")} Item={(props) => ( <ItemIncidents {...props}/> )}
            noItemsFound={translate("dashboard.delivery.table.noItemsFound")}/>}</div></>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner type={1} size={15} />
        </div>
      )}
    </div>
  );
};

export default UpdateIncident;
