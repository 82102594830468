import React, { useState, useRef, useContext } from 'react';
import AuthContext from '../../../contexts/auth';
import ModalContext from '../../../contexts/modal';
import { useForm } from 'react-hook-form';
import {translate} from "../../../utils/Common";
// icons
import * as FiIcons from 'react-icons/fi';

const FormUpdatePasswords = ({ getUserInfo, setGetUserInfo, userInformation, validateUpdatePwd, validateUpdatePwdLocker, setValidateUpdatePwd, setValidateUpdatePwdLocker }) => {
    const { handleSubmit, register, errors, watch, reset, setError, clearErrors } = useForm();
	const userContext = useContext(AuthContext);
	const modalContext = useContext(ModalContext);

    // para el cambio de rontraseña
    const newPwd = useRef({});
    newPwd.current = watch("newPwd","");
    const oldPwd = useRef({});
    oldPwd.current = watch("oldPwd","");
    // para validar números, letras y caracteres especiales
    const regexLetras = /[a-zA-Z]+/;
    const regexNum = /[0-9]+/;
	const regexOnlyNum = /^[0-9]*$/;
    const regexChar = /["#$%&/()=¿?¡!_.,+*']+/;
	
    // para ver las contraseñas
    const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
    const [isConfirmPwdVisible, setIsConfirmPwdVisible] = useState(false);
    const [isOldPwdVisible, setIsOldPwdVisible] = useState(false);

	const validatePwdFormat = (value) => {
		if (validateUpdatePwdLocker) {
			if (regexOnlyNum.test(value) && !value.includes(' ')) return true;
			else return false;
		}
		else {
			if (regexLetras.test(value) && regexNum.test(value) && regexChar.test(value) && !value.includes(' ')) return true;
			else return false;
		}
	}
	
	const handleCancelUpdate = () => {
		clearErrors();
		setValidateUpdatePwd(false);
		setValidateUpdatePwdLocker(false);
	}

	const sendUpdatePwd = async (data) => {
		try {
            data.idCliente = userContext.user?.IdCliente;
            if (validateUpdatePwdLocker) {
                const result = await userContext.updatePwdLocker(data);

                switch (result) {
                    case 'PWD_LOCKER_UPDATED_SUCCESSFULLY':
                        setGetUserInfo(!getUserInfo);
                        clearErrors();
                        reset();
                        setValidateUpdatePwdLocker(false);
                        modalContext.confirm(
                            translate("dashboard.userprofile.personal.successful_update"),
                            translate('dashboard.userprofile.updatepsw.success_message_locker'),
                            <button type="button" key="1" className="btn btn-sm btn-fucsia mr-2" onClick={() => modalContext.setIsOpenModal(false)}>
                                {translate('dashboard.userprofile.updatepsw.accept')}
                            </button>,
                            true
                        );
                        break;
                    case 'SERVER_ERROR':
                        setError('newPwd', {
                            type: '',
                            message: translate('dashboard.userprofile.updatepsw.server_error')
                        });
                        break;
                    default:
                        break;
                }
            } else {
                if (data.newPwd !== data.oldPwd) {
                    const result = await userContext.updatePwd(data);

                    switch (result) {
                        case 'PASSWORD_UPDATED_SUCCESSFULLY':
                            clearErrors();
                            reset();
                            setValidateUpdatePwd(false);
                            modalContext.confirm(
                                translate("global.alert.ready"),
                                translate('dashboard.userprofile.updatepsw.success_message_general'),
                                <button type="button" key="1" className="btn btn-sm btn-fucsia mr-2" onClick={() => modalContext.setIsOpenModal(false)}>
                                    {translate("global.OK")}
                                </button>,
                                true
                            );
                            break;
                        case 'OLD_PASSWORD_INVALID':
                            setError('oldPwd', {
                                type: '',
                                message: translate('dashboard.userprofile.updatepsw.oldpassword_invalid')
                            });
                            break;
                        case 'USER_NOT_FOUND':
                        case 'SERVER_ERROR':
                            setError('newPwd', {
                                type: '',
                                message: translate('dashboard.userprofile.updatepsw.server_error')
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    setError('newPwd', {
                        type: '',
                        message: translate('dashboard.userprofile.updatepsw.newpassword_match')
                    });
                }
            }
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<form className="accountInfo-form text-left my-2" onSubmit={handleSubmit(sendUpdatePwd)}>
           
            <div className="row">
                <div className="form-group col-12 col-md-6 my-1">
                    <label htmlFor="inputPwd" className="mb-1">{translate('dashboard.userprofile.updatepsw.password')}</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text span-eye-pwd" onClick={() => setIsNewPwdVisible(!isNewPwdVisible)}>
                                {isNewPwdVisible ?
                                    <FiIcons.FiEye size={16} className="text-dark" />
                                    :
                                    <FiIcons.FiEyeOff size={16} className="text-dark" />
                                }
                            </span>
                        </div>
                        <input type={isNewPwdVisible ? "text" : "password"} className={"form-control form-control-md" + (errors.newPwd ? " is-invalid" : "")} id="inputPwd" name="newPwd" ref={register({
                            required: {
                                value: true,
                                message: translate('dashboard.userprofile.updatepsw.invalid_password')
                            },
                            minLength: {
                                value: 6,
                                message: translate('dashboard.userprofile.updatepsw.minlength', { min: 6 })
                            },
                            maxLength: {
                                value: validateUpdatePwdLocker ? 15 : 50,
                                message: translate('dashboard.userprofile.updatepsw.maxlength_general', { max: validateUpdatePwdLocker ? 15 : 50 })
                            },
                            pattern: {
                                value:  validateUpdatePwdLocker ? /^[0-9]*$/ : /[A-Z]/,
                                message: validateUpdatePwdLocker ? translate( "dashboard.userprofile.updatepsw.requirement_4") : translate("dashboard.userprofile.updatepsw.has_uppercase"),
                              },
                            validate: value => validatePwdFormat(value) || translate('dashboard.userprofile.updatepsw.validate')
                        })} />
                        {errors.newPwd && <div className="invalid-feedback">{errors.newPwd.message}</div>}
                    </div>
                </div>

                <div className="col-12 col-md-6 my-1">
                    <label htmlFor="pwdConfirm" className="mb-1">{translate('dashboard.userprofile.updatepsw.confirm_password')}</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text span-eye-pwd" onClick={() => setIsConfirmPwdVisible(!isConfirmPwdVisible)}>
                                {isConfirmPwdVisible ?
                                    <FiIcons.FiEye size={16} className="text-dark" />
                                    :
                                    <FiIcons.FiEyeOff size={16} className="text-dark" />
                                }
                            </span>
                        </div>
                        <input type={isConfirmPwdVisible ? "text" : "password"} className={"form-control form-control-md" + (errors.pwdConfirm ? " is-invalid" : "")} id="pwdConfirm" name="pwdConfirm" ref={register({
                            required: {
                                value: true,
                                message: translate('dashboard.userprofile.updatepsw.invalid_password')
                            },
                            minLength: {
                                value: 6,
                                message: translate('dashboard.userprofile.updatepsw.minlength', { min: 6 })
                            },
                            maxLength: {
                                value: validateUpdatePwdLocker ? 15 : 50,
                                message: `${translate('dashboard.userprofile.updatepsw.maxlength_locker')} ${validateUpdatePwdLocker ? 15 : 50 }) ${translate('dashboard.userprofile.updatepsw.characters')}`
                            },
                            validate: value => value === newPwd.current || translate('dashboard.userprofile.updatepsw.passwords_not_match')
                        })} />
                        {errors.pwdConfirm && <div className="invalid-feedback">{errors.pwdConfirm.message}</div>}
                    </div>
                </div>
            </div>

            {validateUpdatePwd &&
                <div className="row">
                    <div className="col-12 col-md-6 mt-1 mb-3">
                        <label htmlFor="inputOldPwd" className="profile-subtitle">{translate("dashboard.userprofile.updatepsw.old_password")}</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text span-eye-pwd" onClick={() => setIsOldPwdVisible(!isOldPwdVisible)}>
                                    {isOldPwdVisible ?
                                        <FiIcons.FiEye size={16} className="text-dark" />
                                        :
                                        <FiIcons.FiEyeOff size={16} className="text-dark" />
                                    }
                                </span>
                            </div>
                            <input type={isOldPwdVisible ? "text" : "password"} name="oldPwd" className={"form-control form-control-md" + (errors.oldPwd ? " is-invalid" : "")} id="inputOldPwd" ref={register({
                                required: {
                                    value: true,
                                    message: translate('dashboard.userprofile.updatepsw.invalid_password')
                                },
                                minLength: {
                                    value: 6,
                                    message: translate('dashboard.userprofile.updatepsw.minlength_oldpwd', { min: 6 })
                                },
                                maxLength: {
                                    value: 50,
                                    message: translate('dashboard.userprofile.updatepsw.maxlength_oldpwd', { max: 50 })
                                },  
                                // pattern: {
                                //     value: /[A-Z]/,
                                //     message: translate("dashboard.userprofile.updatepsw.has_uppercase"),
                                //   },
                            })} />
                            {errors.oldPwd && <div className="invalid-feedback">{errors.oldPwd.message}</div>}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="text-left p-3">
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirements')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_1')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_2')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_3')}</p>
                            <p className="font-xsmall m-0 p-0">{translate("dashboard.userprofile.updatepsw.requirement_5")}</p>
                        </div>
                    </div>
                </div>
            }

            {validateUpdatePwdLocker &&
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="text-left p-3">
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirements')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_1')}</p>
                            <p className="font-xsmall m-0 p-0">{translate('dashboard.userprofile.updatepsw.requirement_4')}</p>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12 text-center">
                    <button type="button" className="btn btn-outline-fucsia m-2" onClick={() => handleCancelUpdate()}>{translate('dashboard.userprofile.updatepsw.cancel')}</button>
                    <button type="submit" className="btn btn-fucsia m-2">{translate('dashboard.userprofile.updatepsw.update')}</button>
                </div>
            </div>
        </form>
	);
}

export default FormUpdatePasswords;