import { FaBox, FaUndo, FaBan, FaTrash, FaEye, FaEyeSlash, FaDownload } from "react-icons/fa"; // Íconos de ojo y ojo cerrado

import { useState } from "react";
import { translate, setStatusAlerted } from "../../utils/Common";
import { VscBlank } from "react-icons/vsc";
import { format } from 'date-fns';

// Componente para mostrar el detalle de los paquetes alertados
const ItemPackageAlerted = ({ index, showBox, item, setSelectedItem }) => {
    
    // Estado para controlar la expansión de la descripción
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); 
    // Función para obtener el estado del paquete y su correspondiente clase de estilo
    const handleEstatusAlerted = (item) => {
        let estatus = '';
        if (item.IdEstatusCompra === 3) estatus = "text-warning";
        else if (item.IdEstatusCompra === 4) estatus = "text-success";
        else if (item.IdEstatusCompra === 5) estatus = "text-danger";
        return <span className={estatus + " font-bold"}>{setStatusAlerted(item.IdEstatusCompra)}</span>;
    };

    // Función para alternar el estado de expansión de la descripción
    const toggleDescription = () => {
        setIsDescriptionExpanded(!isDescriptionExpanded);
    };

    // Función para truncar la descripción a 10 palabras
    const getShortDescription = (description) => {
        if (!description) return '';
        const words = description.split(' ');
        return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : description;
    };

    // Función para verificar si la descripción tiene más de 10 palabras
    const hasMoreThan10Words = (description) => {
        return description && description.split(' ').length > 10;
    };

    const formattedFechaEstimada = item.FechaEstimada ? format(new Date(item.FechaEstimada), 'dd/MM/yyyy') : '';
   // Función para descargar el archivo
    const downloadFile = async () => {
        if (item.url) {
            const link = document.createElement('a');
            link.href = item.url;
            link.download = 'archivo-descargado'; // Nombre del archivo, por defecto uno genérico
            link.target = '_blank'; // Abrir en una nueva pestaña en caso de que sea necesario
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Eliminar el enlace después de la descarga
        } else {
            console.error("URL de descarga no disponible");
        }
    };
    return (
        <div className="col-6 col-lg-3 p-2 item-found d-flex align-items-start flex-column">
            <div className="border py-2 px-2 item-inside text-left font-xsmall d-flex flex-column" style={{ height: '100%' }}>
                {/* Cabecera con número de paquete y botón de acción */}
                <div className="d-flex justify-content-between pl-2 font-bold">
                    <p className="mb-0 font-bold text-left">Nro. {index + 1}</p>
                    {(item.IdEstatusCompra === 5 || item.IdEstatusCompra === 4) && (
                        <button disabled type="button" className="btn btn-md rounded-pill btn-outline-blank font-xsmall p-2" onClick={() => setSelectedItem(item)}>
                            <VscBlank size={15} className="" />
                        </button>
                    )}
                        <div>
                          {item.NameFile && (
                         <button type="button" className="btn rounded-pill btn-fucsia font-xsmall px-2 m-1" onClick={() => downloadFile()}>
                         <FaDownload size={13} className="" />
                        </button>
                        )} 
                         {item.IdEstatusCompra === 3 && (
                            <button type="button" className="btn  rounded-pill btn-outline-fucsia font-xsmall px-2" onClick={() => setSelectedItem(item)}>
                                <FaTrash size={13} className="" />
                            </button>
                           )}  
                        </div>
                   
                </div>
                {showBox && (
                    <div id="box">
                        <img src="/img/box.png" alt="boxit-box" width="80px" />
                    </div>
                )}
                <div className="m-1 text-break">
                    <p className="mb-0 font-bold text-center">{translate("global.numTracking")}:</p>
                    <p className={`m-1 mb-0 font-bold p-1 text-center ${
                        item.IdEstatusCompra === 5 ? 'nroPackageFound-red' : 
                        item.IdEstatusCompra === 4 ? 'nroPackageFound-green' : 
                        item.IdEstatusCompra === 3 ? 'nroPackageFound-yellow' : 'nroPackageFound-blue'
                    }`}>
                        {item.NumeroTracking ? item.NumeroTracking : ''}
                    </p>
                    {item.NumeroPaquete && (
                        <p className="mb-0">
                            <span className="font-bold">{translate("global.numPackage")}: </span>
                            {item.NumeroPaquete ? item.NumeroPaquete : ''}
                        </p>
                    )}
                    <p className="mb-0 font-bold">
                        {translate("global.client")}:
                        <span className="ml-1 boxit-blue">{item.IdCliente || ''}</span>
                        <span className="ml-1 boxit-blue">
                            {item.IdTipoUsuario !== 1 ? item.Email : `${item.Client} (B${item.IdCliente})`}
                        </span>
                    </p>
    
                    <p className="mb-0">
                        <span className="font-bold">{translate("global.description")}: </span>
                        {isDescriptionExpanded ? item.Descripcion : getShortDescription(item.Descripcion)}
                        {hasMoreThan10Words(item.Descripcion) && (
                            <button className="btn btn-link p-0 ml-2" onClick={toggleDescription}>
                                {isDescriptionExpanded ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        )}
                    </p>
    
                    {item.FechaEstimada && (
                        <p className="mb-0">
                            <span className="font-bold">{translate("global.status.package.alerted.DateAssigned")}: </span>
                            {formattedFechaEstimada ? formattedFechaEstimada : ''}
                        </p>
                    )}
    
                    <p className="mb-0 font-bold">
                        {translate("global.status")}: {item.IdEstatusCompra ? handleEstatusAlerted(item) : ''}
                    </p>
    
                    {item.Plataforma && (
                        <>
                            <p className="mb-0 font-bold text-center border-bottom">
                                {translate("global.ubication")}:
                            </p>
                            <p className="mb-0 font-small font-bold text-center">
                                {item.Plataforma}
                            </p>
                        </>
                    )}
                </div>
            
            </div>
        </div>
    );
    
};

export default ItemPackageAlerted;
