import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";


export const useAccounting = () => {

    const getTransactions = useCallback(async (data) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/accounting/transactions` , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(data)
            });
            
            const result = await response.json();
             return result;

        } catch (error) {
            // setLoading(false);
            return 'SERVER_ERROR';
        }

    }, [])

    return {getTransactions}
}