import React, { useState, useEffect, useCallback } from 'react';
import { getClientDomain, getToken, generateResponse, translate } from '../../utils/Common';
import AuthContext from './index';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        try {
            const checkToken = async () => {
                setLoading(true);
                
                // if (sessionStorage.getItem('token')) {
                    const response = await fetch(`${getClientDomain(2)}/users/checkToken`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${sessionStorage.getItem('token')}`
                        },
                        signal: controller.signal
                    });

                    const result = await response.json();
                    if (result) {
                        if (result.user) setUser(result.user);
                        else signOut();
                        if (result.code === 401) signOut();
                    }
                    
                    setLoading(false);
                // }
            }

            if (user) checkToken();
        } catch (err) {
            console.log(err);
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signIn = async (data) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/signin`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authentication': 'Basic ' + Buffer.from(data.username.toUpperCase() + ":" + data.pwd).toString('base64'),
                }
            });
            
            const result = await response.json();

            if (result.code !== 500 && result.message) {
                switch (result.message) {
                    case 'USER_NOT_FOUND':
                        return 'USER_NOT_FOUND';
                    case 'EMAIL_NOT_FOUND':
                        return 'EMAIL_NOT_FOUND';
                    case 'PASSWORD_INVALID':
                        return 'PASSWORD_INVALID';
                    case 'SIGNIN_SUCCESS':
                        if (result.payload?.IdEstatus === 1) {
                            setUser(result.payload);
                            setTimeout(() => {
                                signOut()
                            }, (24 * 60 * 60 * 1000))
                            sessionStorage.setItem('token', result.token);
                            sessionStorage.setItem('user', JSON.stringify(result.payload));
                            sessionStorage.setItem('currentNavPage', translate("sidebar.options.start"));
                            setLoading(false);
                            return 'LOGIN_SUCCESS';
                        }
                        else if (result.payload.IdEstatus === 2) return 'USER_INACTIVATED'
                        else return 'USER_NOT_VERIFIED';
                    default:
                        break;
                }
            }
            else return 'SERVER_ERROR';
        } catch (error) {
            console.log(error);
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const signOut = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('currentNavPage');
        sessionStorage.removeItem('platforms');
        sessionStorage.removeItem('priority-incidents');
        sessionStorage.removeItem('types-incidents');
        sessionStorage.removeItem('status-incidents');
        setUser(null);
    }

    const signUp = async (data) => {
        try {
            const { username, pwd, ...fields } = data;
            const response = await fetch(`${getClientDomain(2)}/users/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authentication': 'Basic ' + Buffer.from(username.toString().trim().toUpperCase() + ":" + pwd.toString().trim()).toString('base64')
                },
                body: JSON.stringify(fields)
            });
            
            const result = await response.json();
            if (result.code === 200) {
                if (result.payload.length > 0) {
                    if (result.payload[0].Mensaje === 'USER_CREATED_SUCCESSFULLY') {
                        setLoading(false);
                        return 'SIGNUP_SUCCESSFULLY';
                    }
                    else if (result.payload[0].Mensaje === 'USER_OR_EMAIL_ALREADY_EXIST') {
                        if (result.payload[0].UsuarioValidator) return 'USERNAME_ALREADY_EXIST';
                        if (result.payload[0].EmailValidator) return 'EMAIL_ALREADY_EXIST';
                    }
                }
                else return 'SERVER_ERROR';
            }
            else return 'SERVER_ERROR';
        } catch (error) {
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const forgotPwd = async (email) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/forgotPwd/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(email)
            });
            
            const result = await response.json();
            if (result.code === 200) return 'EMAIL_SENT_SUCCESSFULLY';
            else return result.message;
        } catch (error) {
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const resetPwd = async (data, token) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/resetpwd`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Credential': Buffer.from(data.pwd.toString().trim()).toString('base64')
                }
            });
            
            const result = await response.json();
            if (result.code === 200 && result.payload) return result.payload.Mensaje;
            else if (result.code === 401) return 'TOKEN_EXPIRED';
            else return 'SERVER_ERROR';
        } catch (error) {
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const updateInfo = async (data) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/update/info/${data.idCliente}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(data)
            });
            
            const result = await response.json();
            if (result.code === 200) {
                if (result.payload) return result.payload.Mensaje;
                else return 'USER_NOT_FOUND';
            }
            else if (result.code === 401) return 'TOKEN_EXPIRED';
            else return 'SERVER_ERROR';
        } catch (error) {
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const updatePwd = async (data) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/update/pwd/${data.idCliente}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`,
                    'Credentials': Buffer.from(data.newPwd.toString() + ':' + data.oldPwd.toString()).toString('base64')
                }
            });
            
            const result = await response.json();
            if (result.code === 200) {
                if (result.payload) return result.payload.Mensaje;
                else return 'USER_NOT_FOUND';
            }
            else if (result.code === 401) return 'TOKEN_EXPIRED';
            else return 'SERVER_ERROR';
        } catch (error) {
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const updatePwdLocker = async (data) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/update/pwdLocker/${data.idCliente}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`,
                    'Credentials': Buffer.from(data.newPwd.toString()).toString('base64')
                }
            });
            
            const result = await response.json();
            if (result.code === 200) {
                if (result.payload) return result.payload.Mensaje;
                else return 'USER_NOT_FOUND';
            }
            else if (result.code === 401) return 'TOKEN_EXPIRED';
            else return 'SERVER_ERROR';
        } catch (error) {
            setLoading(false);
            return 'SERVER_ERROR';
        }
    }

    const activateAccount = async (country, token) => {
        try {
            const response = await fetch(`${getClientDomain(2)}/users/activate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ token }`
                }
            });
            
            const result = await response.json();
            const responseObj = generateResponse(result);
            return responseObj;

        } catch (error) {
            setLoading(false);
            return translate("login.forgotpwd.validate.errorServer");
        }
    }
    
    const updateInfoBank = async (data) => {
        const response = await fetch(`${getClientDomain(2)}/users/update/infobank`, {
            method: "PUT",
            headers: {
            'Content-Type': 'application/json',
              authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          });

          if (!response.ok) {
            console.error("Error en la solicitud:", response.status, response.statusText);
            // Puedes agregar más detalles del error si están disponibles en la respuesta.
          }

          const result = await response.json();
          if (result?.payload) return result;
          else return result;
        };

    const bankInfo = async () => {
        const response = await fetch(`${getClientDomain(2)}/users/infobank`, {
            method: "GET",
            headers: {
            'Content-Type': 'application/json',
              authorization: `Bearer ${getToken()}`,
            },
          });

          if (!response.ok) {
            console.error("Error en la solicitud:", response.status, response.statusText);
            // Puedes agregar más detalles del error si están disponibles en la respuesta.
          }

          const result = await response.json();
          if (result?.payload) return result;
          else return result;
        };

    return (
        <AuthContext.Provider value={{ user, setUser, signIn, signOut, signUp, forgotPwd, resetPwd, updatePwd, updatePwdLocker, updateInfo, updateInfoBank, loading, setLoading, activateAccount, bankInfo }}>
            { children }
        </AuthContext.Provider>
    );
}

export default AuthProvider;