import React, { useContext, useEffect, useState } from 'react';
// icons
import * as FaIcons from 'react-icons/fa';
import * as FiIcons from 'react-icons/fi';
import AuthContext from '../../../contexts/auth';
import ModalContext from '../../../contexts/modal';
import { translate, verifyHideCountryItems } from "../../../utils/Common";
// stylesheet
import './index.css';

const FormBusinesslInformation = ({ userInformation, validateUpdatePwdLocker, setValidateUpdatePwdLocker, validateUpdatePwd }) => {
	const userContext = useContext(AuthContext);
	const modalContext = useContext(ModalContext);
    // para ver las contraseñas
    const [isLockerPwdVisible, setIsLockerPwdVisible] = useState(false);
	
    // format to show business username
    const getMainUser = () => `${userInformation.PrimerNombre} ${userInformation.PrimerApellido}  `;
	const NombreEmpresa = userContext.user?.NombreEmpresa;

	return (
		<div className="row text-left">

			<div className="col-12 col-md-6 mb-2">
				<label htmlFor="inputMainUser">{translate("global.nameFull")}: </label>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputMainUser">
									<FaIcons.FaUser size={16} />
								</span>
							</div>
							<input value={userInformation ? (userContext.user?.NombreEmpresa ?? getMainUser()) : ''} 
                          disabled type="text" className="form-control form-control-md" id="inputMainUser"/>
						
						</div>
					</div>

			{ verifyHideCountryItems(["us"]) && userContext.user?.IdTipoUsuario !== 6 &&
				<>
					<div className="col-md-12 mb-0 mt-1">
						<h6 className="mb-1">{translate("dashboard.userprofile.business.credLocker")}</h6>
					</div>

					<div className="col-md-6 mb-1">
						<label htmlFor="inputUsuario">{translate("dashboard.userprofile.business.user")}:</label>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputUsuario">
									<FaIcons.FaUser size={16} />
								</span>
							</div>
							<input value={ userInformation ? (Number(userInformation.IdUsuario) < 99 ? `0${userInformation.IdUsuario}` : userInformation.IdUsuario) : '' } disabled type="text" className="form-control form-control-md" id="inputUsuario"/>
						</div>
					</div>

					{ !validateUpdatePwdLocker && !validateUpdatePwd &&
					<>
						<div className="col-md-6 mb-1">
							<label htmlFor="inputClave">{translate("dashboard.userprofile.business.password")}:</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text span-eye-pwd" onClick={ () => setIsLockerPwdVisible(!isLockerPwdVisible) }>
										{ !isLockerPwdVisible ?
											<FiIcons.FiEye size={16} className="text-dark"/>
											:
											<FiIcons.FiEyeOff size={16} className="text-dark"/>   
										}
									</span>
								</div>
								<input value={ userInformation ? (userInformation.Clave ? Buffer.from(userInformation.Clave, 'base64').toString('ascii') : "") : '' } disabled type={ isLockerPwdVisible ? "text" : "password" } className="form-control form-control-md" id="inputClave"/>
							</div>
						</div>

						<div className="col-12 mb-1">
							<div className="row mb-0">
								<div className="col-12 mb-0 text-left">
									<p className="mb-0">{translate("dashboard.userprofile.business.instructions")}.</p>

									<button className="btn btn-sm btn-hide ml-1" type="button" onClick={ 
										() => modalContext.confirm(translate("dashboard.userprofile.business.modal.title")+"!", 
											translate("dashboard.userprofile.business.modal.description"), [
											<button type="button" key="1" className="btn btn-sm btn-update btn-outline-fucsia mr-2" onClick={ () => { modalContext.setIsOpenModal(false); setValidateUpdatePwdLocker(true) } }>{translate("dashboard.userprofile.business.modal.accept")}</button>,
											<button type="button" key="2" className="btn btn-sm btn-update btn-fucsia" onClick={ () => { modalContext.setIsOpenModal(false); setValidateUpdatePwdLocker(false); } }>{translate("dashboard.userprofile.business.modal.cancel")}</button>
										], true) }>
										<span className="font-bold">{translate("dashboard.userprofile.business.buttonCredLocker")}</span>
									</button>
								</div>
							</div>
						</div>
					</>
					}
				</>
			}
		</div>
	);
}

export default FormBusinesslInformation;
