import { useState, useContext } from 'react';
import { translate } from '../../../../utils/Common';
import AuthContext from '../../../../contexts/auth';
import ModalContext from '../../../../contexts/modal';
import FormPackagesSearch from './FormSearch';
import ItemPackageAlerted from '../../../../components/ShowItems/ItemPackageAlerted';
import PackagesResults from '../PackagesResults';
import ModalContent from '../../../../components/ModalContent';
import './index.css';
import { usePackages } from '../../../../hooks/usePackages';

// Vista principal para paquetes alertados
const AlertedPackages = () => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);

    // Estados
    const [packagesFound, setPackagesFound] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [itemsShowing, setItemsShowing] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState(null);
    const [searchParams, setSearchParams] = useState({});
    const [isCancelled, setIsCancelled] = useState(false);
    const { getPackagesAlerted, deleteAlerted } = usePackages();

    // Función para buscar paquetes alertados
    const findPackagesAlerted = async (data) => {
        try {
            let dataToSend = {};
            if (data.numeroPaquete) dataToSend.numeroPaquete = data.numeroPaquete.toString().trim().toUpperCase();
            if (data.numeroTracking) dataToSend.numeroTracking = data.numeroTracking.toString().trim();
            if (data.estatusAlerted !== translate("dashboard.packages.search.alerted") &&
                data.estatusAlerted !== translate("dashboard.packages.search.alerted.AllStatus")) {
                dataToSend.estatusAlerted = data.estatusAlerted;
            }
            if (data.IdClient) dataToSend.IdClient = Number(data.IdClient);
            if (!dataToSend.numeroPaquete && !dataToSend.numeroTracking && dataToSend.estatusAlerted === "0" && !dataToSend.IdClient) {
               
                setTitle("global.alert.attention");
                setBody("global.packageSearch.alerted.invalidInput");
                setIsCancelled(true);
                setIsOpenModal(true);

                return;
            }
            const result = await getPackagesAlerted(dataToSend);
            if (result.code === 200) {
                if (result.payload?.length > 0) {
                    setItemsShowing(result.payload.length <= 20 ? result.payload.length : 20);
                    setItemsFiltered(result.payload);
                    setPackagesFound(result.payload);
                    setSearchParams(dataToSend);
                } else {
                    setTitle("global.alert.attention");
                    setBody("dashboard.packages.search.NOT_PACKAGE_FOUND");
                    setIsCancelled(true);
                    setIsOpenModal(true);
                }
            } else if (result.code === 404) {
                setTitle("global.alert.attention");
                setBody("dashboard.packages.search.NOT_PACKAGE_FOUND");
                setIsCancelled(true);
                setIsOpenModal(true);

            } else {
                setTitle("global.alerts.ops");
                setBody("dashboard.packages.search.ERROR_SEARCH");
                setIsCancelled(true);
                setIsOpenModal(true);
            }
        } catch (error) {
            setTitle("global.alert.somethingWrong");
            setBody("dashboard.packages.search.ERROR_SEARCH");
            setIsCancelled(true);
            setIsOpenModal(true);
        }
    };

    // Función para cancelar la búsqueda
    const handleCancelBusqueda = () => {
        setPackagesFound([]);
        setItemsFiltered([]);
        setSelectedPackage(null);
        setSelectedItem(null);
    };

    // Contenido del modal
    const BodyContent = () => (
        <div className="p-0 mx-1 text-left">
            <p className="m-0 p-0">
                <span>{translate(body)} </span>
                {isCancelled === false && (
                    <>
                        <span className="font-bold">{selectedItem?.NumeroTracking}</span>
                        <p className="m-0 p-0">{translate("global.question.continue")}</p>
                    </>
                )}
            </p>
        </div>
    );

    // Pie de página del modal
    const FooterContent = () => (
        <div className="d-flex justify-content-center mb-0">
            {isCancelled ? (
                <button
                    type="button"
                    className="btn btn-sm btn-fucsia mx-2"
                    onClick={() => setIsOpenModal(false)}
                >
                    {translate("global.accept")}
                </button>
            ) : (
                <>
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-fucsia mx-2"
                        onClick={() => setIsOpenModal(false)}
                    >
                        {translate("global.no")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-fucsia mx-2"
                        onClick={async () => {
                            await onCancelAlert();
                        }}
                    >
                        {translate("global.yes")}
                    </button>
                </>
            )}
        </div>
    );

    // Confirmar cancelación del paquete
    const onConfirm = (item) => {
        setSelectedItem(item);
        setTitle("dashboard.packages.search.alerted.CancelAlert");
        setBody("dashboard.packages.search.alerted.MessageModal");
        setIsCancelled(false);
        setIsOpenModal(true);
    };

    // Cancelar el paquete alertado
    const onCancelAlert = async () => {
        try {
            const result = await deleteAlerted({ IdCompraCliente: selectedItem?.IdCompraCliente });
            
            if (result.code === 200) {
                setIsCancelled(true);
                setBody("dashboard.packages.search.alerted.MessageModalSuccess");
            } else {
                setIsCancelled(false);
                setBody("dashboard.packages.search.alerted.MessageModalError");
            }


            setIsOpenModal(true);
            findPackagesAlerted(searchParams);


        } catch (error) {
            setIsCancelled(true);
            setBody("dashboard.packages.search.alerted.MessageModalError");
            setIsOpenModal(true);
        }
    };

    return (
        <>
            {packagesFound.length === 0 ? (
                <FormPackagesSearch
                    onFindPackage={findPackagesAlerted} 
                    handleCancelBusqueda={handleCancelBusqueda}
                />
            ) : (
                <PackagesResults
                    itemsArray={packagesFound}
                    itemsFound={itemsFiltered}
                    itemsShowing={itemsShowing}
                    itemName={translate("global.packages.alerted")}
                    itemSearch = {translate("sidebar.options.packages.alerted")}
                    setItemsShowing={setItemsShowing}
                    setItemsFiltered={setItemsFiltered}
                    setSelectedPackage={onConfirm}
                    handleCancelBusqueda={handleCancelBusqueda}
                    parentItemPackage={ItemPackageAlerted}
                    typeExport={"alertedPackages"}
                    filterType={"alerts"}
                />
            )}
            <ModalContent
                title={translate(title)}
                body={<BodyContent />}
                footer={<FooterContent />}
                isOpenModal={isOpenModal}
                onHide={() => setIsOpenModal(false)}
            />
        </>
    );
};

export default AlertedPackages;
