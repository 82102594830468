import { LuAlertCircle } from "react-icons/lu";
import { translate, defaultFilterIncidents } from "../../../../utils/Common";
import { useContext, useEffect, useState } from "react";
import { useIncidents } from "../../../../hooks/useIncidents";
import ShowTable from "../../../../components/ShowTable";
import ItemIncident from "../../../../components/ShowTable/ItemIncident";
import FilterIncident from "./FilterIncident";
import AuthContext from "../../../../contexts/auth";
import SelectedFilters from "./SelectedFilters";



const ListIncidents = () => {
  const { getIncidents} = useIncidents();
  const userContext = useContext(AuthContext);
  const [listIncidents, setListIncidents] = useState(null);
  const [msgError, setMsgError] = useState("");

  const filterDefaults = { ...defaultFilterIncidents };
  const [filterOptions, setFilterOptions] = useState( { ...filterDefaults } );

  const [headerRow] = useState(
    <tr>
      <th>ID</th>
      {userContext.user?.IsAdmin && <th>{translate("dashboard.userprofile.business.user")}</th>} 
      <th>{translate("global.type")}</th>
      <th>{translate("global.priority")}</th>
      <th>{translate("global.status")}</th>
      <th>{translate("global.date")}</th>
    </tr>
  );

  useEffect(() => {
    const abortController = new AbortController();
    const handleIncidents = async () => {
      setListIncidents(null);
      const response = await getIncidents(filterOptions, abortController.signal);
      setListIncidents(response.payload);

      if (response.code === 400) {
        setMsgError(translate("dashboard.accounting.message.NO_INCIDENTS_DATEMAX"));
      } else if (response.code === 404) {
        setMsgError(translate("dashboard.accounting.message.NO_INCIDENTS_FILTERS"));
      }
      
    };

    handleIncidents();
    return () => abortController.abort();

  }, [filterOptions, getIncidents]);

  return (
    <div className="dashboardCard text-left">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="m-0" style={{ fontSize: "16px"}}>
          <LuAlertCircle className="mr-2" size={20} />
          {translate("global.listIncident")}
        </h5>
        <FilterIncident filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
      </div>

      <hr />

      <div>
        <SelectedFilters filterOptions={filterOptions} setFilterOptions={setFilterOptions}/>
      </div>

      <div className="mt-3 d-flex justify-content-center">
           <ShowTable
           itemArray={listIncidents}
           headerRow={headerRow}
           Item={(props) => <ItemIncident {...props} />}
           noItemsFound={msgError}
           actualPage={1}
           itemsShowing={(listIncidents)?.length}
         /> 
         
      </div>
    </div>
  );
};

export default ListIncidents;
