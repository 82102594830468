import { translate} from "../../../utils/Common";

import {
  FaUserFriends,
} from "react-icons/fa";

import { ImProfile } from "react-icons/im";
import { MdDateRange, MdOutlineMailOutline, MdPhone } from "react-icons/md";
import { IoIosSave, IoIosSettings, IoMdPersonAdd } from "react-icons/io";

import ProviderList from "../../../components/ProviderList";
import { useClient } from "../../../hooks/useClient";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import ModalContext from "../../../contexts/modal";

import "./index.css";
import RoleBliSelect from "../../../components/Selects/RoleBliSelect";

const CreateClient = () => {
  const { createClient } = useClient();
  const [optionSelect, setOptionSelect] = useState("");
  const [isServerRun, setIsServerRun] = useState(true);

  const modalContext = useContext(ModalContext);
  const [providor, setProvidor] = useState(null);

  const { register, handleSubmit, errors, setValue, reset, setError } = useForm();

  const handleCreateClient = async (data) => {
    const abortController = new AbortController();
    const response = await createClient(abortController.signal, data);

    switch (response) {
      case "SIGNUP_SUCCESSFULLY":
        handleResetForm();
        setIsServerRun(true);
        modalContext.confirm(
          translate("global.alert.ready"),
          <span>{translate("global.clientCreateSuccess")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );
        break;
      case "USERNAME_ALREADY_EXIST":
        setError("user", {
          type: "focus",
          message: translate("login.signup.validate.userExist"),
        });
        setIsServerRun(true);


        break;
      case "EMAIL_ALREADY_EXIST":
        setError("email", {
          type: "focus",
          message: translate("login.signup.validate.emailExist"),
        });
        setIsServerRun(true);
        break;

      case "SERVER_ERROR":
        setIsServerRun(false);

        modalContext.confirm(
          translate("global.alert.attention"),
          <span>{translate("login.signup.validate.serverError")}</span>,
          [
            <button
              key={2}
              type="button"
              className="btn btn-sm btn-outline-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
          ],
          true
        );

        break;

      default:
        break;
    }

  };

  // -------------------------

  const handleResetForm = () => {
    reset();
    setProvidor(null);
    setOptionSelect("");
  };

  const renderBtnsCreate = () => (
    <div className="d-flex mt-3">
      <button
        className="btn btn-fucsia btn-sm"
        // disabled={isDisabled}
        type="submit"
      >
        <IoIosSave size={18} className="mr-1" /> {translate("global.save")}
      </button>
      <a href="/dashboard/clients"
        className="btn btn-outline-fucsia btn-sm ml-2"
        onClick={() => handleResetForm()}
        type="button"
      >
        {translate("global.goBack")}
      </a>
    </div>
  );


  const handleSubmitCreate = (data) => {
    if(!providor){
      setError('proveedor', { type: 'focus', message: 'Seleccione una empresa' });
    }else{
      handleCreateClient(data);
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () =>
      window.innerWidth < 1040 ? setIsMobile(true) : setIsMobile(false);

    window.addEventListener("resize", handleResize);

    if (window.innerWidth < 1040) setIsMobile(true);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* Header */}
      <div
        style={{
          position: "sticky",
          top: "0",
          backdropFilter: "blur(8px)",
          zIndex: 15,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          padding: "15px 25px 0",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="text-left d-flex" style={{ alignContent: "center" }}>
            <a href="/dashboard/clients">
              <h5 className="link-hover-effect">
                <FaUserFriends size={18} className="mr-2" />
                {translate("global.clients")}
              </h5>
            </a>
            <span className="ml-1 mt-1"> {" > "} {translate("global.createClient")}</span>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: " 0 15px ",
          marginBottom: "50px",
          background: "#fcfcfc",
        }}
        className="h-100 w-100"
      >
        <div className="box-card-general mt-2">
          <div className="details-section-general">
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
             <div className="ml-4 d-flex align-items-center" id="subtitle-createClient"> 
               <IoMdPersonAdd id="subtitle-createClient" className="mr-2" size={25}/>
               <h3 id="subtitle-createClient" >{translate("global.createClient")}</h3>
             </div>
            <div className="details-section-information" style={{display:"flex", flexDirection: isMobile ? "column" : "row", gap:"20px"}}>
                {/* Informacion Personal - View */}
                <div className="details-section-info-group"  >
                  <div className="box-card-general-dash w-100">
                    <h6>
                      <ImProfile />{" "}
                      {translate("dashboard.userprofile.personal.title")}
                    </h6>

                    <div className="row">
                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.name")} <span className="text-danger">*</span>
                        </div>

                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          name="name"
                          ref={register}
                          required
                        />
                      </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.lastNames")} <span className="text-danger">*</span>
                        </div>

                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          name="lastName"
                          ref={register}
                          required
                        />
                      </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          <MdOutlineMailOutline className="mr-1" size={18} />
                          {translate("global.email")} <span className="text-danger">*</span>
                        </div>

                        <input
                          type="email"
                          className="form-control form-control-sm mt-2"
                          name="email"
                          required
                          ref={register}
                        />

                                    {errors.email && <p className="text-danger mt-2">{errors.email.message}</p>}
                      </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          <MdPhone className="mr-1" size={18} />
                          {translate("global.phone")}
                        </div>

                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          name="phone"
                          ref={register}
                        />
                      </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          <MdDateRange className="mr-1" size={18} />{" "}
                          {translate(
                            "dashboard.userprofile.personal.birthdate_label"
                          )}
                        </div>

                        <input
                          type="date"
                          className="form-control form-control-sm mt-2"
                          name="birthDate"
                          ref={register}
                          autoComplete="bday"
                        />
                      </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.gender")}
                        </div>

                        <select
                          className="form-control form-control-sm mt-2"
                          name="gender"
                          ref={register}
                        >
                           <option value="H">{translate("global.select")}</option>
                          <option value="H">{translate("global.male")}</option>
                          <option value="M">
                            {translate("global.female")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                 {/* Other Info Client - View */}
                 <div className="details-section-info-group"
                >
                  <div className="box-card-general-dash w-100" style={{justifyContent:"start"}}>
                    <h6>
                    <IoIosSettings />{" "}
                      {translate("global.otherInfo")}
                    </h6>

                    <div className="row">

                      
<div className="details-info col-12">
          
          <div className="details-info-label mb-2">
                      {translate("global.business")} <span className="text-danger">*</span>
                    </div>
        <ProviderList
                register={register}
                errors={errors}
                nameShow={translate("global.select")}
                readOnly={false}
                setValue={setValue}
                valueId={"proveedor"}
                setProveedor={setProvidor}
                idShow={"all"}
              />

        </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("login.signin.user")} <span className="text-danger">*</span>
                        </div>

                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          name="user"
                          ref={register}
                          required
                        />
                        {errors.user && <p className="text-danger mt-2">{errors.user.message}</p>}
                      </div>

                      <div className="details-info col-lg-6 col-md-6 col-sm-12">
                        <div className="details-info-label">
                          {translate("global.role")} BLI <span className="text-danger">*</span>
                        </div>

                        <RoleBliSelect  register={register} isRequerid={true}/>
                      </div>


                    </div>
                  </div>
                </div>
                
         

            </div>
            <div className="mb-2" style={{display: "flex", justifyContent:"end"}}>
                    {renderBtnsCreate()}
                </div>
              </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateClient;
