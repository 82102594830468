import React, { useContext, useState } from 'react';
// components
import FormProveedores from "./FormProveedores";
import FormB2BEmprendedores from "./FormB2BEmprendedores";
import AuthContext from '../../../../contexts/auth';
import { translate, verifyHideCountry, verifyHideCountryItems } from '../../../../utils/Common';
import TipoPaqueteSwitch from '../TipoPaqueteSwitch';
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CargaIndividual = () => {
    const UserContext = useContext(AuthContext);
    const [idTipoUsuario, setIdTipoUsuario] = useState(UserContext.user ? UserContext.user.IdTipoUsuario : 3);
    const [idTipoPaquete, setIdTipoPaquete] = useState(2); 

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const platformAutoSelect = queryParams.get('plt');
    
    return (
        <div className="dashboardCard text-left">
            <div className="row mx-1 mb-2">
                <span>{translate("dashboard.packages.loadpackage.instructions")}</span>
            </div>

        <div className='d-flex'>
            {verifyHideCountryItems(["pa"]) && (
                     <TipoPaqueteSwitch idTipoPaquete={idTipoPaquete} setIdTipoPaquete={setIdTipoPaquete} />

            )}

            { UserContext.user?.IsAdmin &&
            <div className="form-row mx-0">
                <div className="form-group mb-1">
                    <p className="mb-0 font-weight-bold">{translate("dashboard.packages.loadpackage.selectedUser")}</p>
                    {
                        verifyHideCountry () && (
                            <>
                    <div className="custom-control custom-switch">
                        <input className="custom-control-input" checked={ idTipoUsuario === 1 ? true : false } type="checkbox" onChange={ (e) => e.target.checked ? setIdTipoUsuario(1) : setIdTipoUsuario(1) } id="inputTipoProveedor"/>
                        <label htmlFor="inputTipoProveedor" className="custom-control-label"><div className="mt-1">{translate("dashboard.packages.loadpackage.selectedUser.provider")}</div></label>
                    </div>
                      <div className="custom-control custom-switch">
                      <input className="custom-control-input" checked={ idTipoUsuario === 2 ? true : false } type="checkbox" onChange={ (e) => e.target.checked ? setIdTipoUsuario(2) : setIdTipoUsuario(2) } id="inputTipoB2B"/>
                      <label htmlFor="inputTipoB2B" className="custom-control-label"><div className="mt-1">{translate("dashboard.packages.loadpackage.selectedUser.b2b")}</div></label>
                  </div>
                  </>
                        )
                    }
                  
                    <div className="custom-control custom-switch">
                        <input className="custom-control-input" checked={ idTipoUsuario === 3 ? true : false } type="checkbox" onChange={ (e) => e.target.checked ? setIdTipoUsuario(3) : setIdTipoUsuario(3) } id="inputTipoEmprendedor"/>
                        <label htmlFor="inputTipoEmprendedor" className="custom-control-label"><div className="mt-1">{translate("dashboard.packages.loadpackage.selectedUser.pymes")}</div></label>
                    </div>
                </div>
            </div>
            }
        </div>
          

            { idTipoUsuario === 1 ? 
                <FormProveedores
                    idTipoUsuario={ idTipoUsuario }
                    idTipoCliente={ idTipoUsuario > 1 ? 7 : 1 } 
                    idTipoPaquete={ idTipoPaquete }
                    idPlatformSelect={platformAutoSelect}/>
                :
                <FormB2BEmprendedores
                    idTipoUsuario={ idTipoUsuario }
                    idTipoCliente={ idTipoUsuario > 1 ? 7 : 1 }
                    idTipoPaquete={ idTipoPaquete }
                    idPlatformSelect={platformAutoSelect} />
            }
        </div>
    );
}

export default CargaIndividual;