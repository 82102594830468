import { MdHistory } from "react-icons/md";
import Spinner from "../Spinner";
import "./index.css";

const ShowTracking = ({
  itemArray,
  Item,
  title,
  noItemsFound
}) => {
  return (
    <>

      <div className="card-tracking mb-3">
        <div className="p-1">
          {title && (
            <h6>
              <MdHistory size={25}/> {title}
            </h6>
          )}

          <div className="scroll-area">
            <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column mt-2">
            {itemArray && itemArray.length > 0 ? (
                  itemArray.map((item, index) => (
                    <Item
                    key={index}
                    index={index}
                    item={item}
                  /> 
                  ))
                ) : (
                    <div>
                    <Spinner type={1} />{" "}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowTracking;
