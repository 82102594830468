import React, { useState } from "react";
import ShowTable from "../../../../components/ShowTable";
import ItemCreditsNotes from "../../../../components/ShowTable/ItemCreditsNotes";
import { translate } from "../../../../utils/Common";

const CreditsNotesView = ({ transaction }) => {
  const [headerRow] = useState(
    <tr>
      <th>ID {translate("global.transaction")}</th>
      <th>{translate("global.date")}</th>
      <th>(ID) - {translate("global.client")}</th>
      <th>{translate("global.subscriptionPayment")}</th>
    </tr>
  );

  return (
    <>
        <div className="text-left">
          <ShowTable
            itemArray={transaction}
            headerRow={headerRow}
            Item={ItemCreditsNotes}
            noItemsFound={translate("dashboard.delivery.table.noItemsFound")}
            actualPage={1}
            itemsShowing={transaction?.length}
          />
        </div>
    </>
  );
};

export default CreditsNotesView;
