// React
import { useState } from "react";
import { useForm } from "react-hook-form";

// Icon
import { IoFilterSharp } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";

// Utility
import { translate } from "../../../../../utils/Common";

// Component
import TypeIncidentList from "../../TypeIncidentList";
import PriorityIncidentList from "../../PriorityIncidentList";
import StatusIncidentList from "../../StatusIncidentList";
import DateIncident from "../../DateIncident";

// CSS
import "./index.css";

const FilterIncident = (props) => {
  const { filterOptions, setFilterOptions } = props;

  const { register, errors, handleSubmit } = useForm();
  const [viewFilter, setViewFilter] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCloseView = () => {
    setViewFilter(!viewFilter);
    setSelectedOption(null);
  };

  const submitFilter = (data) => {
    if (Object.keys(data).length !== 0) {
      handleToggleOption(data);
    }
  };

  const optionsSelect = [
    {
      id: 1,
      name: translate("global.type"),
      component: (
        <TypeIncidentList
          errors={errors}
          register={register}
          nameShow={translate("global.select")}
          readOnly={false}
          sizeInput={"form-control-md"}
        />
      ),
    },
    {
      id: 2,
      name: translate("global.priority"),
      component: (
        <PriorityIncidentList
          errors={errors}
          register={register}
          nameShow={translate("global.select")}
          readOnly={false}
          sizeInput={"form-control-md"}
        />
      ),
    },
    {
      id: 3,
      name: translate("global.state"),
      component: (
        <StatusIncidentList
          errors={errors}
          register={register}
          nameShow={translate("global.select")}
          readOnly={false}
          sizeInput={"form-control-md"}
        />
      ),
    },
    {
      id: 4,
      name: translate("global.date"),
      component: (
        <DateIncident
          errors={errors}
          register={register}
          nameShow={translate("global.select")}
          readOnly={false}
          sizeInput={"form-control-md"}
        />
      ),
    },
  ];

  const renderComponent = () => {
    const selected = optionsSelect.find(
      (option) => option.id === selectedOption
    );
    return selected ? selected.component : null;
  };

  const handleToggleOption = (option) => {
    const filterKey = Object.keys(option)[0];
    const newOption = option[filterKey];

    let newFilterOptions = { ...filterOptions };

    if (!filterOptions[filterKey].includes(Number(newOption))) {
      if (filterKey === "startDate") {
        newFilterOptions[filterKey] = [newOption];
        newFilterOptions["endDate"] = [option.endDate];
      } else {
        newFilterOptions[filterKey].push(Number(newOption));
      }
    }

    new setFilterOptions(newFilterOptions);
    handleCloseView();
  };

  const renderBtnFilter = (
    <form>
      <div className="d-flex align-items-center">
        <div
          className={`btn-filter-date mt-2 ${viewFilter && "isActive"}`}
          onClick={handleCloseView}
        >
          {translate("global.filterBy")} <IoFilterSharp className="ml-2" />
        </div>
      </div>

      {viewFilter && (
        <div
          className="btn-filter-date-admin"
          style={{
            position: "absolute",
            marginTop: "10px",
            right: "25px",
            zIndex: "35",
          }}
        >
          <div className="d-flex justify-content-between">
            <select
              className="form-select form-control"
              style={{ fontSize: "12px", marginRight: "5px" }}
              onChange={(e) => setSelectedOption(parseInt(e.target.value))}
            >
              <option value="">-- {translate("global.select")}</option>
              {optionsSelect.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-2">{selectedOption && renderComponent()}</div>

          {selectedOption && (
            <button
              type="button"
              className="btn btn-blue mt-2 w-100"
              onClick={handleSubmit(submitFilter)}
            >
              <IoIosSearch className="mr-2" /> {translate("global.search")}
            </button>
          )}
        </div>
      )}
    </form>
  );

  return <div>{renderBtnFilter}</div>;
};

export default FilterIncident;
