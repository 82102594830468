import React, { useState, useEffect, useContext } from 'react';
import { getClientDomain, getToken, translate } from '../../../../utils/Common';
import AuthContext from '../../../../contexts/auth';
import ModalContext from '../../../../contexts/modal';
// style
import './index.css';
// components
import PackageOptionPanel from './PackageOptionPanel';
import PackageInfo from './PackageInfo';
import TableEstatusBoxit from './TableEstatusBoxit';
import TableEstatusProveedor from './TableEstatusProveedor';
import TableFacturacionProveedor from './TableFacturacionProveedor';
import { verifyAccessViewPermissions } from '../../../../config/permissions';

const PackageDetails = (props) => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const [openModalChangeClient, setOpenModalChangeClient] = useState(false);

    const [idPaquete,] = useState(props.selectedPackage.IdPaquete);

    // panel de options
    const [optionSelected, setOptionSelected] = useState("");
    const onClickPanelOption = (value) => {
        setOptionSelected(value);
    }

    const [updateComponent, setUpdateComponent] = useState(false);
    const getInfoPaquete = async (data) => {
        try {
            setUpdateComponent(!updateComponent);
            modalContext.setIsOpenModal(false) 
            let dataToSend = {};
            dataToSend.IdUsuario = data.IdUsuario;
            dataToSend.numeroPaquete = data.Nro_Id;

            const response = await fetch(`${getClientDomain(2)}/dashboard/packages/find/tracking` , {
                method: 'POST',
                connection: 'keep-alive',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend)
            });

            const result = await response.json();
            if (result.code === 200) {
                if (result.payload?.length > 0)
                    if (result.payload[0]) {
                        props.handleSelectedPackage(result.payload[0]);
                        const index = props.packagesFound.findIndex(obj => obj.IdPaquete === props.selectedPackage.IdPaquete);
                        props.packagesFound[index] = result.payload[0];
                    }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => window.innerWidth < 1040 ? setIsMobile(true) : setIsMobile(false);

        window.addEventListener('resize', handleResize);

        if (window.innerWidth < 1040) setIsMobile(true);
        
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const handleOpenModalChangeClient = () => {
        setOpenModalChangeClient(!openModalChangeClient);
    }

    return (
        <>
            <div className="dashboardCard tracking-detalle">
                <div className="row mx-0 mb-3">
                    {
                        ( (props.selectedPackage?.IdEstatus !== 4 && props.selectedPackage?.IdEstatus !== 6 && props.selectedPackage?.IdEstatus !== 3) ||
                        ( props.selectedPackage?.IdEstatus !== 4 && props.selectedPackage?.IdEstatus !== 6)) &&
                        <div className={ "col-12 p-0 package-options-panel " + (isMobile ? "mb-3 col-md-12 col-lg-12" : "col-md-3 col-lg-3") }>
                            <PackageOptionPanel 
                                selectedPackage={ props.selectedPackage }
                                optionSelected={ optionSelected }
                                onClickPanelOption={ onClickPanelOption }
                                handleCancelBusqueda={ props.handleCancelBusqueda }
                                getInfoPaquete={ getInfoPaquete }
                                isMobile={ isMobile } 
                                handleOpenModalChangeClient={ handleOpenModalChangeClient } />
                        </div>
                    }

                    <div className={ "col-12 p-0" + (
                        ( (props.selectedPackage?.IdEstatus !== 4 && props.selectedPackage?.IdEstatus !== 6 && props.selectedPackage?.IdEstatus !== 3) ||
                        ( props.selectedPackage?.IdEstatus !== 4 && props.selectedPackage?.IdEstatus !== 6))
                        ?
                        (isMobile ? " col-md-12 col-lg-12" : " col-md-9 col-lg-9") : " col-md-12 col-lg-12") } >
                        <PackageInfo    
                            selectedPackage={ props.selectedPackage }
                            handleSelectedPackage={ props.handleSelectedPackage }
                            optionSelected={ optionSelected }
                            onClickPanelOption={ onClickPanelOption }
                            getInfoPaquete={ getInfoPaquete }
                            isOpenModalChangeClient = {openModalChangeClient}
                            handleOpenModalChangeClient={ handleOpenModalChangeClient }
                             />
                    </div>
                </div>

                <TableEstatusBoxit 
                    idPaquete={ idPaquete }
                    updateComponent={updateComponent} />

                { (userContext.user?.IdUsuario === 8 && userContext.user?.IsAdmin) && 
                <>
                    <TableEstatusProveedor
                        idPaquete={ idPaquete }
                        updateComponent={updateComponent} />
                    <TableFacturacionProveedor 
                        idPaquete={ idPaquete }
                        updateComponent={updateComponent} />
                </>
                }

                <div className="row justify-content-center m-0">
                    <button className="btn btn-sm btn-outline-fucsia m-2" type="button" onClick={ () => props.handleCancelBusqueda() }>{translate("global.cancel")}</button>
                    <button className="btn btn-sm btn-fucsia m-2" type="button" onClick={ () => props.handleSelectedPackage(null) }>{translate("global.return")}</button>
                </div>
            </div>
        </>);
}

export default PackageDetails;