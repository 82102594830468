import { useContext, useState } from "react";
import AuthContext from '../../../../contexts/auth';
import { translate } from "../../../../utils/Common"
// components
import TipsForDropOff from "./TipsForDropOff";
import FormSearchPackage from "./FormSearchPackage";
import DropOffPackageFound from "./DropOffPackageFound";
import "./index.css";

const DropOff = ({ handleModal }) => {
    const [dropOffPackage, setDropOffPackage] = useState(null);
    const userContext = useContext(AuthContext);

    return (
        <div className="dropoff-content text-left">
            <div className="dashboardCard">
                <p className="mb-0"><span className="font-bold">{translate("global.ubication")}: </span>{ userContext.user?.Direccion ? (userContext.user?.Direccion) : translate("global.toBeAssigned") }</p>
                <p className="mb-0"><span className="font-bold">ID {translate("global.ubication")}: </span>{ userContext.user?.IdPlataforma ? (userContext.user?.IdPlataforma) : translate("global.toBeAssigned")}</p>
            </div>

            <div className="dashboardCard text-center py-2 px-0">
                <ul id="progressbar">
                    <li className="li-dropoff activeDrop" id="search">{translate("global.search")}</li>
                    <li className={ "li-dropoff " + (dropOffPackage ? "activeDrop" : "") } id="receive">{translate("dashboard.home.counter_home.receive")}</li>
                </ul>
            </div>

            { !dropOffPackage ? 
                <FormSearchPackage 
                    searchType={ 1 }
                    setPackage={ setDropOffPackage } />
                :
                <DropOffPackageFound
                    dropOffPackage={ dropOffPackage }
                    setDropOffPackage={ setDropOffPackage }
                    handleModal={ handleModal } />
            }

            <TipsForDropOff />
        </div>
    );
}

export default DropOff;