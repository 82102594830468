import { useState, useEffect, useContext } from "react";
// icons
import * as BsIcons from "react-icons/bs";
import ImagePreview from "../../../../../components/ImagePreview";
//others
import {
  translate,
  getFormatDate,
  getStatusText,
  formatter,
  getNameImage,
  viewImageTypeBuffer,
} from "../../../../../utils/Common";
import "./index.css";
import { useBilling } from "../../../../../hooks/useBilling";
import ApprovalsButtons from "../ApprovalsButtons";
import Spinner from "../../../../../components/Spinner";
import ModalContext from "../../../../../contexts/modal";

const DetailsInvoicing = ({ item, setItemSelected, reloadData, selectDate }) => {
  const { getViewImage, updateStatus } = useBilling();
  const modalContext = useContext(ModalContext);

  const [idStatus, setIdStatus] = useState(item.IdEstatus);
  const statusInfo = getStatusText(idStatus);
  const [imageUrl, setImageUrl] = useState(null);
  const [activateText, setActivateText] = useState(false);
  const [additionalText, setAdditionalText] = useState(item.Descripcion);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [errorText, setErrorText] = useState("");

  const renderTitleHeader = (
    <div className="d-flex align-items-center">
      <div
        className="header-adminBilling m-0"
        onClick={() => {setItemSelected(null); reloadData(selectDate)}}
      >
        <BsIcons.BsCreditCard
          size={35}
          style={{ paddingRight: "15px", fontWeight: "bold" }}
        />{" "}
        {translate("sidebar.options.accounting.billing")}
      </div>
      <div className="mr-2 ml-2"> / </div>
      <div style={{ color: "#424996", fontSize: "18px", fontWeight: "500" }}>
        {translate("global.invoice")} #{item.IdBilling}
      </div>
    </div>
  );

  const renderAdditionalInfo = (
    <div className="mt-4">
      <div className="d-flex justify-content-between mb-3">
        <div className="font-weight-bold mb-3" style={{ fontSize: "16px" }}>
          {translate("global.additionalInfo")}
        </div>
        <button
          className="btn btn-primary"
          style={{ fontSize: "12px" }}
          onClick={() => handleEditDescriptions()}
          disabled={isLoading}
        >
          {!isLoading ? (
            <>
              {activateText
                ? translate("global.save")
                : translate("global.edit")}{" "}
            </>
          ) : (
            <Spinner type={1} size={18} />
          )}
        </button>
      </div>

      <textarea
        onChange={(e) => setAdditionalText(e.target.value)}
        disabled={!activateText}
        className="form-control"
        rows="4"
        style={{
          boxShadow: "0px 1px 11.2px 4px rgba(203, 203, 203, 0.25)",
          border: "0px solid",
          fontSize: "14px",
        }}
      >
        {additionalText}
      </textarea>
      {errorText && <div className="text-danger mt-4">{errorText}</div>}
    </div>
  );

  const renderLoading = (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <Spinner type={1} size={"25"} />
    </div>
  );

  const handleEditDescriptions = () => {
    const form = {
      idBilling: item.IdBilling,
      status: item.IdEstatus,
      description: additionalText,
    };

    const controller = new AbortController();
    const updateBilling = async () => {
      setIsLoading(true);
      const response = await updateStatus(controller.signal, form);

      setActivateText(!activateText);
      setIsLoading(false);
      response.code === 200
        ? modalContext.confirm(
            translate("dashboard.userprofile.personal.successful_update"),
            <p>
              {translate("global.updateInvoice")}:
              <strong> #{item.IdBilling}</strong>
            </p>,
            <button
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
            true
          )
        : modalContext.confirm(
            translate("global.alerts.ops"),
            <span>{translate("login.resetpwd.validate.catchError")}.</span>,
            <button
              type="button"
              className="btn btn-sm btn-fucsia"
              onClick={() => {
                modalContext.setIsOpenModal(false);
              }}
            >
              {translate("global.OK")}
            </button>,
            true
          );
    };

    if (!activateText) {
      setActivateText(!activateText);
    } else {
      if (!additionalText) {
        setErrorText("El campo de texto no puede estar vacío");
      } else {
        updateBilling();
        setErrorText("");
      }
    }

    return () => controller.abort();
  };

  const renderContent = (
    <div className="text-left" style={{ padding: "2rem 3rem" }}>
      {renderTitleHeader}
      <div className="row mt-3">
        <div className="col-lg-6 col-sm-12">
          <div className="card-billing-admin">
            <div className="card-billing-section">
              <div className="d-flex justify-content-between">
                <div className="card-billing-title">
                  {translate("global.invoice")} #{item.IdBilling}
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="card-billing-status"
                    style={{
                      color: statusInfo.color,
                      background: statusInfo.background,
                      padding: "8px",
                      borderRadius: "25px",
                      marginRight: "10px",
                    }}
                  >
                    {statusInfo.icon} {statusInfo.text}
                  </div>
                  <div>
                    {statusInfo.actionAdmin && (
                      <>
                        <ApprovalsButtons item={item} setStatus={setIdStatus} additionalText={additionalText} setAdditionalText={setAdditionalText} reloadData={reloadData}/>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="card-billing-subtitle font-weight-bold">
                {item.NombreCliente}
              </div>
              <div className="card-billing-subtitle">
                {getFormatDate(item.FechaBillingInicio)} -{" "}
                {getFormatDate(item.FechaBillingFin)}
              </div>
            </div>

            <div className="card-billing-table">
    
              <div className="card-billing-table-item" style={{"color":"red"}}>
                <div className="font-weight-bold">
                  {translate("global.returned")}
                </div>
                {formatter.format(item.MontoDevuelto)}
              </div>
              <div
                className="card-billing-table-item"
                style={{ color: "#36B516" }}
              >
                <div className="font-weight-bold">
                  {translate("global.cashback")}
                </div>
                {formatter.format(item.MontoCashback)}
              </div>
              
              <div className="card-billing-table-item">
                <div className="font-weight-bold">
                  {translate("global.invoiced")}
                </div>
                {formatter.format(item.MontoFacturado)}
              </div>

              <div className="card-billing-table-item">
                <div className="font-weight-bold">
                  ITBMS (7%)
                </div>
                {formatter.format(item.ITBMS)}
              </div>
              
              <div className="card-billing-table-item">
                <div className="font-weight-bold">
                  {translate("global.total")}
                </div>
                {formatter.format(item.MontoFacturado+item.ITBMS)}
              </div>

            </div>

            <div className="card-billing-section">
              <div className="d-flex justify-content-between">
                <div className="w-100">
                  <div className="mb-3">
                    <div className="font-weight-bold">
                      {translate("global.transactions")}
                    </div>
                    <div>
                      {item.CantUsoPuerta} {translate("global.deliveries")}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-weight-bold">
                      {translate("global.packages_expired.title")}
                    </div>
                    <div className="text-lowercase">
                      {item.CantPaquetesVencidos} {translate("global.packages")}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-weight-bold">
                      {translate("global.pickup24h")}
                    </div>
                    <div className="text-lowercase">
                      {item.CantidadPaquetes24hr} {translate("global.of")}{" "}
                      {item.CantUsoPuerta} {translate("global.packages")}
                    </div>
                  </div>
                </div>

                <div className="w-100">
                  <div className="mb-3">
                    <div className="font-weight-bold">
                      {translate("global.paymentsReceived")}
                    </div>
                    <div>{formatter.format(item.PagosRecibidos)}</div>
                  </div>
                  <div className="mb-3">
                    <div className="font-weight-bold">
                      {translate("global.mountExpiredPackages")}
                    </div>
                    <div>{formatter.format(item.MontoPaquetesVencidos)}</div>
                  </div>
                  <div className="mb-3">
                    <div className="font-weight-bold">
                      {translate("global.taxPaymentMethods")}
                    </div>
                    <div>{formatter.format(item.ComisionMetodosPago)}</div>
                  </div>
                </div>
              </div>
              <hr />

              {statusInfo.infoText && renderAdditionalInfo}
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="card-billing-admin">
            <div
              className="p-4"
              style={{
                textAlign: "center",
                background: "#F6F8FF",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {translate("global.detailsPayments")}
            </div>

            <div className="card-billing-table">
              <div className="card-billing-table-item">
                <div className="font-weight-bold">
                  {translate("global.amountPayment")}
                </div>
                {item.MontoPagado ? formatter.format(item.MontoPagado) : "-"}
              </div>
              <div className="card-billing-table-item">
                <div className="font-weight-bold">
                  {translate("global.datePayment")}
                </div>
                {item.FechaPago ? getFormatDate(item.FechaPago) : "-"}
              </div>
              <div className="card-billing-table-item">
                <div className="font-weight-bold">
                  {translate("global.methodPayment")}
                </div>
                {item.Banco || "-"}
              </div>
            </div>

            <div>
              {isLoadingImg ? (
                <div className="d-flex justify-content-center align-items-center p-5">
                  <Spinner type={1} size={25} />
                </div>
              ) : (
                <>
                  {imageUrl ? (
                    <ImagePreview url={imageUrl} />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center p-5">
                      {translate("global.imageNotAvailable")}
                    </div>
                  )}
                </>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const reloadDetailsUsage = async () => {
      const controller = new AbortController();
      try { 
        setIsLoadingImg(true);
        if (item.ComprobantePago) {
          const file = getNameImage(item.ComprobantePago);
        const response = await getViewImage(controller.signal, {
          image: file.name,
        });
        

        const convertImg = viewImageTypeBuffer(response, file.type);
        setImageUrl(convertImg);
        }else{
        setImageUrl(null);
        }
        setIsLoadingImg(false);
      } catch (err) {
        console.log(err);
        setImageUrl(null);
        setIsLoadingImg(false);
        // setDetailsUsage({});
      }
    };
    reloadDetailsUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return renderContent;
};

export default DetailsInvoicing;
