import { useEffect, useState } from "react";
import { getClientDomain, getToken, translate,  } from "../../../../utils/Common";

const InfoClient = ({ idCliente, handleMethodsChosen, methodsChosen }) => {
    const [infoCliente, setInfoCliente] = useState(null);

    const getFullName = () => {
        return `${ infoCliente.Nombre ? infoCliente.Nombre?.toString() : '' } ${ infoCliente.Apellidos ? infoCliente.Apellidos?.toString() : '' }`
    }

    useEffect(() => {
        const controller = new AbortController();
        try {
            const getInfo = async () => {
                const response = await fetch(`${getClientDomain()}/clients/searchSpecific/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    body: JSON.stringify({ buscarCliente: idCliente })
                });

                const result = await response.json();
                if (result.code === 200) {
                    if (result.payload) {
                        setInfoCliente(result.payload[0]);
                        let validate = methodsChosen?.map(m => m.id === 5 && true).shift()
                        if (!validate) Number(result.payload[0].SaldoCliente) > 0 && handleMethodsChosen([...methodsChosen, { id: 5, name: 'ABONO CLIENTE', amount: Number(result.payload[0].SaldoCliente) }])
                    }
                    else setInfoCliente({ NombreCliente: '', Saldo: 0 });
                }
                else {
                    setInfoCliente({ NombreCliente: '', Saldo: 0 });
                }
            }

            getInfo();
        } catch (err) {
            console.log(err);
            setInfoCliente([ { NombreCliente: '', Saldo: 0 }]);
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-2 mb-2 mx-3">
            <div className="form-row mx-0 mb-1 border-bottom">
                <h6 className="ml-2 my-1">{translate("global.customerInformation")}</h6> 
            </div>
            
            <div className="form-row mx-0">
                <div className="col-7 pb-0 mb-0" >
                    <label className="mb-0" htmlFor="inputNombreCliente">{translate("global.name")}:</label>
                    <input type="text" value={ infoCliente ? getFullName() : '' } id="inputNombreCliente" readOnly className="form-control form-control-sm font-small font-bold"/>
                </div>

                <div className="col-5 pb-0 mb-0" >
                    <label className="mb-0" htmlFor="inputSaldo">{translate("dashboard.platforms.details.cashBalance")}:</label>
                    <input type="text" value={ infoCliente ? `$ ${infoCliente.SaldoCliente}` : '$ 0.00' } id="inputSaldo" readOnly className="form-control form-control-sm font-small font-bold"/>
                </div>
            </div>
        </div>
    );
}

export default InfoClient;