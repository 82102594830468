import "./index.css";

const markerStyle = {
  cursor: "pointer",
  width: "auto",
  color: "gray",
};

const LocationPinMap = ({ text, onClick }) => (
  <div className="pin" style={markerStyle} draggable={false} onClick={onClick}>
    <img
      src={"../img/marker_boxit.png"}
      alt="circle_logo_boxit"
      width={35}
      className="img-pin-map"
    />
  </div>
);

export default LocationPinMap;
