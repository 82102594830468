import AuthContext from "../../contexts/auth/index";
import { useContext } from "react";
import { sqlDateFormat, statusOffline, statusOnline } from "../../utils/Common";


const ItemStatusPlatforms = ({ item, index}) => {
    const UserContext = useContext(AuthContext);
    
    return (
        <tr key={'itemStatus-'+index}>
        <td className="p-1 font-xsmall text-left">
          <div className="p-0 m-0 ml-2">
            <span>{item.Plataforma}</span>
          </div>
        </td>
        <td className="p-1 text-center d-flex align-items-center justify-content-center">
          {
            item.Estatus && (
              <>
              {item.Estatus === "ONLINE" ? statusOnline : statusOffline}
              </>
            )
          }
          
        </td>
        <td className="p-1 font-xsmall text-left">
          <span>
            {item.Estatus === "OFFLINE" &&
              UserContext.user?.IsAdmin &&
              item.LastConnection && sqlDateFormat(item.LastConnection, 1)}
          </span>
        </td>
      </tr>
    );
}

export default ItemStatusPlatforms;
