import React, { useState } from "react";
import {  BiArrowBack } from "react-icons/bi";
import { FaCube, FaMoneyBill, FaBalanceScale } from "react-icons/fa";
import { getFormatDateHour, translate } from "../../utils/Common";
import "./index.css";
import ShowTable from "../../components/ShowTable"
import ItemPickupOnSite from "../ShowTable/ItemPickupOnSite";

const ItemsAccounting = ({ transaction }) => {
  const [trSelected, setTrSelected] = useState(null);
  const [headerRow] = useState(
    <tr>
      <th>ID {translate("global.transaction")}</th>
      <th>{translate("global.date")}</th>
      <th>(ID) - {translate("global.client")}</th>
      <th>{translate("global.address")}</th>
    </tr>
    
  );

  const handleRowClick = (selectedItem) => {
    setTrSelected(selectedItem);
  };

  if (trSelected){
    return (
      <div className="pl-4 pr-4">
        <div className="header-tr">
          <span className="header-tr-items">
            ID {translate("global.transaction")}: &nbsp;
            <strong>#{trSelected?.IdTransaccion ?? "-"}</strong>
          </span>
          <button
            className="btn header-tr-btn"
            onClick={() => setTrSelected(null)}
          >
            <BiArrowBack /> {translate("global.goBack")}
          </button>
        </div>

        <div className="card-columns">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <FaCube /> {translate("global.transaction")}
              </h5>
              <p className="card-text">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.service")}</span>
                    <span class="text-secondary">
                      {trSelected?.Servicio ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item ">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">{translate("global.provider")}</span>
                      <span class="text-secondary">
                        {trSelected?.Proveedor ?? "-"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bold">{translate("global.client")}</span>
                      <span class="text-secondary">
                        ({trSelected?.IdCliente ?? "-"}) -{" "}
                        {trSelected?.NombreCliente ?? "-"}
                      </span>
                    </div>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">Id {translate("global.transaction")}</span>
                    <span class="text-secondary">
                      {trSelected?.IdTransaccion ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.date")}</span>
                    <span class="text-secondary">
                      {getFormatDateHour(trSelected?.FechaTransaccion) ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.numTracking")}</span>
                    <span class="text-secondary">
                      {trSelected?.NumeroTracking ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">
                      {translate("global.numPurchasePackage")}
                    </span>
                    <span class="text-secondary">
                      {trSelected?.CompraPaquete ?? "-"}
                    </span>
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <FaBalanceScale /> {translate("global.details")}
              </h5>
              <p className="card-text">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">
                      {translate("global.identification")}
                    </span>
                    <span class="text-secondary">
                      {trSelected?.NumeroIdentificacion ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.address")}</span>
                    <span class="text-secondary">
                      {trSelected?.Direccion ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.weightLbs")}</span>
                    <span class="text-secondary">
                      {trSelected?.PesoLibras ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.formPayment")}</span>
                    <span class="text-secondary">
                      {trSelected?.FormaPago ?? "-"}
                    </span>
                  </li>

                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">
                      {translate("global.BalanceBeforeTransaction")}
                    </span>
                    <span class="text-secondary">
                      {trSelected?.SaldoAntesTransac ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">
                      {translate("global.BalanceAtTheEndOfTransaction")}
                    </span>
                    <span class="text-secondary">
                      {trSelected?.SaldoFinalizarTransac ?? "-"}
                    </span>
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <FaMoneyBill /> {translate("global.MonetaryDetails")}
              </h5>
              <p className="card-text">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">
                      {translate("global.usedFinalTransaction")}
                    </span>
                    <span class="text-secondary">
                      {trSelected?.AbonoFinalTransac ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.usedPayment")}</span>
                    <span class="text-secondary">
                      {trSelected?.AbonoUsado ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.Change")}</span>
                    <span class="text-secondary">
                      {trSelected?.Cambio ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.PackageCost")}</span>
                    <span class="text-secondary">
                      {trSelected?.CostoPaquete ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.AmountItbms")}</span>
                    <span class="text-secondary">
                      {trSelected?.MontoItbms ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.OtherCharges")}</span>
                    <span class="text-secondary">
                      {trSelected?.OtrosCargos ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.Pay")}</span>
                    <span class="text-secondary">
                      {trSelected?.Pago ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.price")}</span>
                    <span class="text-secondary">
                      {trSelected?.Precio ?? "-"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    <span className="font-weight-bold">{translate("global.BaseAmountItbms")}</span>
                    <span class="text-secondary">
                      {trSelected?.montobaseitbms ?? "-"}
                    </span>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div />
      </div>
    );
  } else {
    return (
      <ShowTable
        itemArray={transaction}
        headerRow={headerRow}
        Item={(props) => (
          <ItemPickupOnSite {...props} selectItem={handleRowClick} />
        )}
        noItemsFound={translate("dashboard.delivery.table.noItemsFound")}
        actualPage={1}
        itemsShowing={transaction?.length}
      />
    );
  }
 
};

export default ItemsAccounting;
