import { useState, useRef, useContext, useEffect } from "react";
import AuthContext from "../../../contexts/auth";
import Spinner from "../../../components/Spinner";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiCheckCircle, BiCircle, BiUser, BiXCircle } from "react-icons/bi";
import { BsFillBriefcaseFill, BsBuilding } from "react-icons/bs";
import { getClientDomain } from "../../../utils/Common";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// icons
import * as MdIcons from "react-icons/md";
import { translate } from "../../../utils/Common";

const FormSignUpSteps = ({ onSignupSuccess }) => {
  const userContext = useContext(AuthContext);
  const { register, handleSubmit, setError, errors, reset, watch } = useForm();
  const [message, setMessage] = useState();
  const [isServerRun, setIsServerRun] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const history = useHistory();
  const pwd = useRef({});
  pwd.current = watch("pwd", "");
  // para validar números, letras y caracteres especiales
  const regexNum = new RegExp("(?=.*[0-9])");
  const regexChar = new RegExp(
    /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿¢£€¥©®™µ°ªº±÷¬§¶¼½¾°F°C≠≤≥≈∑∫∆√πΩ≡≈≠†‡↔↑↓←→∞∩∂∇]+/
  );
  const regexLetras = new RegExp("(?=.{6,})");

  const onSubmit = async (data) => {
    try {
      setMessage("");
      setIsServerRun(false);
      setIsLoading(true);
      data.membershipId = plan;
      const result = await userContext.signUp(data);
      setIsLoading(false);

      switch (result) {
        case "SIGNUP_SUCCESSFULLY":
          reset();
          onSignupSuccess(true);
          setIsServerRun(true);
          break;
        case "USERNAME_ALREADY_EXIST":
          setError("user", {
            type: "",
            message: translate("login.signup.validate.userExist"),
          });
          setIsServerRun(true);
          break;
        case "EMAIL_ALREADY_EXIST":
          setError("email", {
            type: "",
            message: translate("login.signup.validate.emailExist"),
          });
          setIsServerRun(true);
          // regresar a la pantalla anterior
          handlePreviousStep();
          break;
        case "SERVER_ERROR":
          setMessage(translate("login.signup.validate.serverError"));
          setIsServerRun(false);
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setIsServerRun(false);
    }
  };

  const backLogin = () => history.push("/login");

  // Constants
  const INITIAL_STEP = 1;
  const TOTAL_STEPS = 3;
  const [step, setStep] = useState(INITIAL_STEP);

  // Funciones para avanzar y retroceder entre pasos
  const handleNextStep = () => {
    const nextStep = Math.min(step + 1, TOTAL_STEPS);
    setStep(nextStep);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, INITIAL_STEP));
  };

  const [plan, setPlan] = useState(3);
  const options = [
    { id: 3, label: "Basic", icon: <BiUser className="btn-plan-icon" /> },
    {
      id: 2,
      label: "Business",
      icon: <BsFillBriefcaseFill className="btn-plan-icon" />,
    },
    {
      id: 1,
      label: "Enterprise",
      icon: <BsBuilding className="btn-plan-icon" />,
    },
  ];
  const handlePlanSelect = (selectedPlan) => {
    selectedPlan === 3 && setPlan(selectedPlan); // CAMBIO TEMPORAL, solo permite el plan basic
  };

  const getTerminosCondiciones = async () => {
    try {
      const response = await fetch(`${getClientDomain()}/files/pdf/terminos`, {
        method: "GET",
        accept: "application/json",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      const blob = await response.blob();
      if (blob) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Términos_y_Condiciones_Boxit_Logística_Inteligente.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log("error 1:", error);
    }
  };

  const checkFormCompletion = () => {
    if (plan === 3) {
      const requiredFields = ["name", "lastname", "email"];
      const isComplete = requiredFields.every((field) => !!watch(field));
      setIsFormComplete(isComplete);
    } else {
      const requiredFields = [
        "companyName",
        "comercialActivity",
        "email",
        "phoneNumber",
      ];
      const isComplete = requiredFields.every((field) => !!watch(field));
      setIsFormComplete(isComplete);
    }
  };

  useEffect(() => {
    checkFormCompletion();
  }, [watch]);

  const [charactersValidated, setCharactersValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);

  const handlePasswordChange = (e) => {
    regexLetras.test(e)
      ? setCharactersValidated(true)
      : setCharactersValidated(false);
    regexNum.test(e) ? setNumberValidated(true) : setNumberValidated(false);
    regexChar.test(e) ? setSpecialValidated(true) : setSpecialValidated(false);
  };

  const [isFocused, setIsFocused] = useState(false);
  const [showValidatePwd, setshowValidatePwd] = useState(false);
  const renderValidatePwd = (
    <Tooltip id={`tooltip-${"bottom"}`}>
      <div className="mb-2 text-left">
        <p className="font-small m-0 p-0 font-bold">
          {translate("login.signup.validate1")}
        </p>
        <p
          className="font-small m-0 p-0"
          style={{ color: charactersValidated ? "green" : "gray" }}
        >
          <span className="mr-1">
            {charactersValidated ? <BiCheckCircle /> : <BiCircle />}{" "}
          </span>
          {translate("login.signup.validate2")}
        </p>
        <p
          className="font-small m-0 p-0"
          style={{ color: numberValidated ? "green" : "gray" }}
        >
          <span className="mr-1">
            {numberValidated ? <BiCheckCircle /> : <BiCircle />}{" "}
          </span>
          {translate("login.signup.validate3")}
        </p>
        <p
          className="font-small m-0 p-0"
          style={{ color: specialValidated ? "green" : "gray" }}
        >
          <span className="mr-1">
            {specialValidated ? <BiCheckCircle /> : <BiCircle />}{" "}
          </span>
          {translate("login.signup.validate4")}
        </p>
      </div>
    </Tooltip>
  );

  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    const originalPassword = watch("pwd");
    setPasswordsMatch(confirmPassword === originalPassword);
  };

  const handleConfirmPasswordFocus = () => {
    setIsConfirmPasswordFocused(true);
  };

  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const renderConfirmPwd = (
    <Tooltip id="tooltip-confirm-password">
     <p
        className="font-small m-0 p-0"
        style={{ color: passwordsMatch ? "green" : "red" }}>
        <span className="mr-1">
          {passwordsMatch ? <BiCheckCircle /> : <BiXCircle />}{" "}
        </span>
        {passwordsMatch ? translate("login.signup.validate.passMatch") : translate("login.signup.validate.passNotMatch")}
      </p>
  </Tooltip>
  );

  return (
    <>
      <div className="m-0 p-0">
        {/* <h5 className="mb-2">{translate("login.signup.title")}</h5> */}
        <p className="mb-1 text-justify">
          {step === 1 && translate("login.signup.instructions.greeting")}
          {translate("login.signup.instructions.form")}
          <span className="mb-2 text-left text-danger">
            * {translate("login.signup.required")}
          </span>
        </p>
      </div>

      {/* STEPPER */}
      {/* Steps - Head */}
      <div className="step-head">
        <div className="stepper-container">
          {[1, 2, 3].map((stepNumber) => (
            <div
              key={stepNumber}
              className={`step-circle ${step === stepNumber ? "active" : ""}`}
            >
              <p
                className={`step-title ${step === stepNumber ? "active" : ""}`}
              >
                {stepNumber === 1
                  ? translate("login.signup.steps.plans")
                  : stepNumber === 2
                  ? translate("login.signup.steps.data")
                  : translate("login.signup.steps.access")}
              </p>
            </div>
          ))}
        </div>

        <div className="progress-container">
          <div className="progress" style={{ height: "2px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(step - 1) * 50}%` }}
              aria-valuenow={(step - 1) * 50}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      </div>
      {/* Steps - Head */}

      <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="step-plan"
          style={{ display: step === 1 ? "block" : "none" }}
        >
          {/* PLANS STEP */}
          <div className="cards-plans">
            {options.map((option) => (
              <div
                key={option.id}
                className={`btn-plan ${plan === option.id ? "active" : ""}`}
                onClick={() => handlePlanSelect(option.id)}
              >
                {option.icon}
                <label
                  className="form-check-label"
                  htmlFor={`option-${option.label}`}
                >
                  {option.label}
                </label>
                <div
                  className={`option-circle ${
                    plan === option.id ? "active" : ""
                  }`}
                ></div>
              </div>
            ))}
          </div>
        </div>

        {/* DATA STEP */}
        <div
          className="step-data"
          style={{ display: step === 2 ? "block" : "none" }}
        >
          {plan !== 3 ? (
            <>
              <div className="form-row">
                <div className="form-group text-left col-md-12">
                  <label htmlFor="inputCompanyName" className="mb-1">
                    <span className="text-danger">*</span>{" "}
                    {translate("login.signup.company")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.companyName && " is-invalid")
                    }
                    id="inputCompanyName"
                    name="companyName"
                    ref={register({
                      required: true,
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.companyName && (
                    <div className="invalid-feedback">
                      {errors.companyName.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group text-left col-md-12">
                  <label htmlFor="inputComercialActivity" className="mb-1">
                    <span className="text-danger">*</span>{" "}
                    {translate("login.signup.description")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.comercialActivity && " is-invalid")
                    }
                    id="inputComercialActivity"
                    name="comercialActivity"
                    ref={register({
                      required: true,
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.comercialActivity && (
                    <div className="invalid-feedback">
                      {errors.comercialActivity.message}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-row">
                <div className="form-group text-left col-md-6">
                  <label htmlFor="inputName" className="mb-1">
                    <span className="text-danger">*</span>{" "}
                    {translate("login.signup.contact")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.name && " is-invalid")
                    }
                    id="inputName"
                    name="name"
                    ref={register({
                      required: true,
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">
                      {errors.name.message}
                    </div>
                  )}
                </div>

                <div className="form-group text-left col-md-6">
                  <label htmlFor="inputLastname" className="mb-1">
                    <span className="text-danger">*</span>{" "}
                    {translate("login.signup.lastname")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.lastname && "is-invalid")
                    }
                    id="inputLastname"
                    name="lastname"
                    ref={register({
                      required: true,
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.lastname && (
                    <div className="invalid-feedback">
                      {errors.lastname.message}
                    </div>
                  )}
                </div>

                <div className="form-group text-left col-md-12">
                  <label htmlFor="inputCompanyName" className="mb-1">
                    {translate("login.signup.company")}
                  </label>
                  <input
                    disabled={!isServerRun}
                    type="text"
                    className={
                      "form-control form-control-md " +
                      (errors.companyName && " is-invalid")
                    }
                    id="inputCompanyName"
                    name="companyName"
                    ref={register({
                      maxLength: {
                        value: 32,
                        message: translate("login.signup.validate.maxLength32"),
                      },
                    })}
                  />
                  {errors.companyName && (
                    <div className="invalid-feedback">
                      {errors.companyName.message}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="form-group text-left">
            <label htmlFor="inputEmail" className="mb-1">
              <span className="text-danger">*</span>{" "}
              {translate("login.signup.email")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputEmail">
                  <MdIcons.MdEmail size={18} className="text-dark" />
                </span>
              </div>
              <input
                disabled={!isServerRun}
                type="email"
                className={
                  "form-control form-control-md " +
                  (errors.email && "is-invalid")
                }
                id="inputEmail"
                name="email"
                ref={register({ required: true })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group text-left col-md-12">
              <label htmlFor="inputPhone" className="mb-1">
                {plan !== 3 && <span className="text-danger mr-1">*</span>}
                {translate("global.phone")}
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MdIcons.MdPhone size={18} className="text-dark" />
                  </span>
                </div>
                <input
                  disabled={!isServerRun}
                  type="text"
                  placeholder="(Ej: 63494559)"
                  className={
                    "form-control form-control-md " +
                    (errors.phoneNumber && "is-invalid")
                  }
                  id="inputPhone"
                  name="phoneNumber"
                  ref={register({
                    required: plan !== 3 ? true : false,
                    maxLength: {
                      value: 32,
                      message: translate("login.signup.validate.maxLength32"),
                    },
                  })}
                />
                {errors.phoneNumber && (
                  <div className="invalid-feedback">
                    {errors.phoneNumber.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* ACCESS STEP */}
        <div
          className="step-access"
          style={{ display: step === 3 ? "block" : "none" }}
        >
          <div className="form-row">
            <div className="form-group text-left col-md-12">
              <label htmlFor="inputUsername" className="mb-1">
                <span className="text-danger">*</span>{" "}
                {translate("login.signup.user")}:{" "}
                <span className="font-xsmall">
                  ({translate("login.signup.sixcharacters")})
                </span>
              </label>
              <input
                disabled={!isServerRun}
                maxLength={20}
                type="text"
                className={
                  "form-control form-control-md text-uppercase " +
                  (errors.username && " is-invalid")
                }
                id="inputUsername"
                name="username"
                ref={register({
                  required: true,
                  minLength: {
                    value: 6,
                    message: translate("login.signup.validate.minLength6"),
                  },
                  maxLength: {
                    value: 20,
                    message: translate("login.signup.validate.maxLength20"),
                  },
                })}
              />
              {errors.username && (
                <div className="invalid-feedback">
                  {errors.username.message}
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group text-left col-12 col-md-6 mb-1">
              <label htmlFor="inputPwd" className="mb-1">
                <span className="text-danger">*</span>{" "}
                {translate("login.signup.password")}:
              </label>
              <OverlayTrigger
                show={showValidatePwd && isFocused}
                placement={"bottom"}
                overlay={renderValidatePwd}
              >
                <input
                  disabled={!isServerRun}
                  maxLength={15}
                  type="password"
                  className={
                    "form-control form-control-md " +
                    (errors.pwd && " is-invalid")
                  }
                  id="inputPwd"
                  name="pwd"
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  onFocus={() => {
                    setIsFocused(true);
                    setshowValidatePwd(true);
                  }}
                  onBlur={() => {
                    setIsFocused(false);
                    setshowValidatePwd(false);
                  }}
                  ref={register({
                    required: true,
                    minLength: {
                      value: 6,
                      message: translate("login.signup.validate.minLength6"),
                    },
                    maxLength: {
                      value: 15,
                      message: translate("login.signup.validate.maxLength15"),
                    },
                    validate: (value) =>
                      (regexLetras.test(value) &&
                        regexNum.test(value) &&
                        regexChar.test(value)) ||
                      translate("login.signup.validate.pwd"),
                  })}
                />
              </OverlayTrigger>

              {errors.pwd && (
                <div className="invalid-feedback">{errors.pwd.message}</div>
              )}
            </div>

            <div className="form-group text-left col-12 col-md-6 mb-1">
              <label htmlFor="inputConfirmPwd" className="mb-1">
                <span className="text-danger">*</span>{" "}
                {translate("login.signup.confirm")}:
              </label>
              <OverlayTrigger
                show={isConfirmPasswordFocused}
                placement="bottom"
                overlay={renderConfirmPwd}
              >
                <input
                  disabled={!isServerRun}
                  maxLength={15}
                  type="password"
                  className={
                    "form-control form-control-md " +
                    (errors.confirmPwd && " is-invalid")
                  }
                  id="inputConfirmPwd"
                  name="confirmPwd"
                  ref={register({
                    required: true,
                    minLength: {
                      value: 6,
                      message: translate("login.signup.validate.minLength6"),
                    },
                    maxLength: {
                      value: 15,
                      message: translate("login.signup.validate.maxLength15"),
                    },
                    validate: (value) =>
                      value === pwd.current ||
                      translate("login.signup.validate.passNotMatch"),
                  })}
                  onChange={(e) => handleConfirmPasswordChange(e)}
                  onFocus={handleConfirmPasswordFocus}
                  onBlur={handleConfirmPasswordBlur}
                />
              </OverlayTrigger>

              {errors.confirmPwd && (
                <div className="invalid-feedback">
                  {errors.confirmPwd.message}
                </div>
              )}
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="form-group text-left col-md-12 mb-1">
              <div>
                <div className="text-bold mb-1">
                  <span className="text-danger">*</span>{" "}
                  {translate("global.termsConditions")}
                </div>
                <p className="m-0 p-0 text-justify">
                  {translate("login.signup.descriptionTerms")}
                  <span
                    className="text-bold link-span"
                    style={{color:"#424996"}}
                    onClick={() => getTerminosCondiciones()}
                  >
                    {" "}
                    {translate("global.termsConditions")}
                  </span>
                  .
                </p>
              </div>
              <div className="form-check">
                <input
                  disabled={!isServerRun}
                  type="checkbox"
                  className={
                    "form-check-input " + (errors.conditions && "is-invalid")
                  }
                  id="checkConditions"
                  name="conditions"
                  required
                  ref={register}
                />
                <label htmlFor="checkConditions" className="m-0">
                  {translate("login.signup.accept")}
                </label>
                {errors.conditions && (
                  <div className="invalid-feedback">
                    {errors.conditions.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="text-center my-2 text-danger">
            {message && (
              <div className="text-center mt-3">
                <span className="text-danger font-xsmall">{message}</span>
              </div>
            )}
          </div>
        </div>

        {/* ACTIONS BUTTONS */}
        <div className="form-group mt-1 mb-0">
          {/* Buttons */}
          {step !== INITIAL_STEP ? (
            <button
              type="button"
              onClick={handlePreviousStep}
              disabled={step === INITIAL_STEP}
              className="btn btn-outline-blue btn-signup rounded-pill text-uppercase m-2"
            >
              {translate("login.signup.btn.previous")}
            </button>
          ) : (
            <button
              type="reset"
              onClick={() => backLogin()}
              className="btn btn-outline-blue btn-signup rounded-pill text-uppercase m-2"
            >
              {translate("login.signup.buttonReturn")}
            </button>
          )}

          {step !== TOTAL_STEPS ? (
            <button
              type="button"
              onClick={handleNextStep}
              disabled={step === 2 && !isFormComplete}
              className="btn btn-blue btn-signup rounded-pill text-uppercase m-2"
            >
              {translate("login.signup.btn.next")}
            </button>
          ) : (
            <button
              disabled={!isServerRun}
              className="btn btn-blue btn-signup rounded-pill text-uppercase m-2"
              type="submit"
            >
              {!isLoading ? (
                translate("login.signup.buttonAccept")
              ) : (
                <Spinner type={1} size={18} />
              )}
            </button>
          )}
          {/* Buttons */}
        </div>
      </form>
    </>
  );
};

export default FormSignUpSteps;
