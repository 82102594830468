import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../../contexts/auth';
import { getClientDomain, translate } from '../../../../utils/Common';

// components
import StatusPlatforms from './StatusPlatforms';
import MostUsedPlatform from './MostUsedPlatform';
import PackagesPickUpLastYear from './PackagesPickUpLastYear';
import PackagesInPlatforms from '../UserStats/PackagesInPlatforms';
import GraphPackagesPickUpByPlatform from '../UserStats/GraphPackagesPickUpByPlatform';
import GraphOrders from './GraphOrders';
// import CashInLockers from './CashInLockers';
import ActiveClients from './ActiveClients';
import ActiveClientsLastMonth from './ActiveClientsLastMonth';

const AdminHome = () => {
    const UserContext = useContext(AuthContext);
    
    const [statusPlatforms, setStatusPlatforms] = useState(null);
    const [cantPackagesInLocker, setCantPackagesInLocker] = useState(null);
    // const [cashInLockers, setCashInLockers] = useState(null);
    const [clientesActivos, setClientesActivos] = useState(null);
    const [clientesLastMonth, setClientesLastMonth] = useState(null);
    const [cantPackagesPickUp, setCantPackagesPickUp] = useState(null);
    const [cantPackagesPickUpLastYear, setCantPackagesPickUpLastYear] = useState(null);
    const [cantMonthlyOrders, setCantMonthlyOrders] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        const getDashboardInfo = async () => {
            // try {
            //     const response = await fetch(`${getClientDomain()}/orders/stats/cantMonthlyOrders`, {
            //         method: 'GET',
            //         headers: {
            //             'authorization': `Bearer ${sessionStorage.getItem('token')}`
            //         },
            //         signal: controller.signal
            //     });

            //     const result = await response.json();

            //     if (result) {
            //         if (result.payload) {
            //             if (result.payload.length > 0) {
            //                 setCantMonthlyOrders(result.payload);
            //             }
            //             else {
            //                 setCantMonthlyOrders(0);
            //             }
            //         }
            //         else {
            //             setCantMonthlyOrders(0);
            //         }
            //     }
            // } catch (error) {
            //     console.log(error);
            // }
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/clients/count/total`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload.length > 0) {
                            setClientesActivos(result.payload[0].ClientesActivos);
                            setClientesLastMonth(result.payload[0].ClientesLast30Days);
                        }
                        else {
                            setClientesActivos(0);
                            setClientesLastMonth(0);
                        }
                    }
                    else {
                        setClientesActivos(0);
                        setClientesLastMonth(0);
                    }
                }
            } catch (error) {
                console.log(error);
            }
            try {
                let dataToSend = {};
                dataToSend.mes = new Date().getMonth() + 1;
                dataToSend.ano = new Date().getFullYear();

                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/pickUpXMonth/user/${UserContext.user?.Ruc}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    body: JSON.stringify(dataToSend)
                });

                const result = await response.json();
                if (result) {
                    if (result.code === 200) {
                        if (result.payload) {
                            if (result.payload.length > 0) {
                                setCantPackagesPickUp(result.payload);
                            }
                            else {
                                setCantPackagesPickUp([]);
                            }
                        }
                    }
                    else {
                        setCantPackagesPickUp([]);
                    }
                }
            } catch (error) {
               // console.log(error);
            }
            try {
                let dataToSend = {};
                dataToSend.ano = new Date().getFullYear();
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/pickUpLastYear/user/${UserContext.user?.Ruc}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    body: JSON.stringify(dataToSend)
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        let cantPaquetes = 0;

                        if (result.payload?.length > 0) {
                            result.payload?.map(plataforma => cantPaquetes += plataforma.value);
                            setCantPackagesPickUpLastYear(cantPaquetes)
                        }
                        else setCantPackagesPickUpLastYear(0);
                    }
                    else setCantPackagesPickUpLastYear(0);
                }
            } catch (error) {
                // console.log(error);
            }
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/stats/packagesInLockers/user/${UserContext.user?.Ruc}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload?.length > 0) {
                            setCantPackagesInLocker(result.payload);
                        }
                        else {
                            setCantPackagesInLocker([]);
                        }
                    }
                }
            } catch (error) {
                // console.log(error);
            }
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/status/client/${UserContext.user?.IdCliente}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        if (result.payload.length > 0) {
                            setStatusPlatforms(result.payload);
                        }
                        else {
                            setStatusPlatforms([]);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        getDashboardInfo();
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return  <>
                <div className="row mx-2 d-flex justify-content-between text-center">
                    {/* <div className="col-12 col-md-6 p-2">
                        <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <GraphOrders cantMonthlyOrders={ cantMonthlyOrders } cardTitle={ translate("dashboard.home.purchase_orders") } />
                        </div>
                    </div> */}

                    <div className="col-12 col-md-6 p-2">
                        <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <GraphPackagesPickUpByPlatform cantPackagesPickUp={ cantPackagesPickUp } />
                        </div>
                    </div>

                    <div className="col-12 col-md-6 p-2">
                        <div className="row p-0 pb-2 m-0">
                            <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <MostUsedPlatform cantPackagesPickUp={ cantPackagesPickUp }/> 
                            </div>
                        </div>

                        <div className="row p-0 pt-2 m-0">
                            <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                <PackagesPickUpLastYear cantPackagesPickUpLastYear={ cantPackagesPickUpLastYear } />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mx-2 d-flex justify-content-between text-center">
                
                    {/* <div className="col-12 col-md-3 p-2">
                        <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <ActiveClients clientesActivos={ clientesActivos } />
                        </div>
                    </div> */}

                    {/* <div className="col-12 col-md-3 p-2">
                        <div className="dashboardCard m-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                            <ActiveClientsLastMonth clientesLastMonth={ clientesLastMonth } />
                        </div>
                    </div> */}
                </div>
                
                <div className="row mx-2 mb-2 justify-content-between text-center">
                    <div className="col-12 col-md-7 p-2">
                        <div className="dashboardCard m-0 px-0 w-100 h-100">
                            <StatusPlatforms statusPlatforms={ statusPlatforms } />
                        </div>
                    </div>

                    <div className="col-12 col-md-5 p-2">
                        <div className="dashboardCard m-0 px-0 w-100 h-100">
                            <PackagesInPlatforms cantPackagesInLocker={ cantPackagesInLocker } />
                        </div>
                    </div>
                </div>
            </>
}

export default AdminHome;