import "./index.css";

const TabsCustome = ({ items, activeTab, setActiveTab }) => {

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const visibleTabs = items.filter((tab) => tab.show === true);

  return (
    <div className="custom-tabs">
      <div className="row">
        {visibleTabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => handleTabClick(index)}
            className={`col-12 col-md-12 col-lg-2 tab-item mt-2 ${index === activeTab ? 'active' : ''} `}
          >
           <span style={{paddingRight:"5px"}}>{tab.icon}</span> {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {items[activeTab].component}
      </div>
    </div>
  );
};

export default TabsCustome;
