import { useState, useEffect } from 'react';
import { useIncidents } from '../../../hooks/useIncidents';
import { getNameIncidentsPriority, translate } from '../../../utils/Common';

const PriorityIncidentList = ({
    register, 
    errors,
    nameShow,
    idShow,
    readOnly,
    sizeInput
}) => {
    const { priorityIncidents , getPriorities } = useIncidents();
    const [prioritiesIncidents,sePrioritiesIncidents] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        const handlePriority = async () => {
            const response = await getPriorities(abortController.signal);
            sePrioritiesIncidents(response);
        }

        if (!priorityIncidents) handlePriority()
        else sePrioritiesIncidents(priorityIncidents);
        
        return () => abortController.abort();
    }, [getPriorities, priorityIncidents]);
    
    return  (
        <>
        <select className={`${sizeInput ?? "form-control-sm" } form-control font-small`} 
            readOnly={ readOnly } 
            name="priorityIncident" 
            ref={ register({
              validate: value => value !== (translate("global.select") || translate("global.noResults")) || translate("global.chooseValidPriority")
          }) }>
            <option defaultValue key={0} className="font-small">{nameShow ? nameShow : translate("global.noResults")}</option>
            { prioritiesIncidents ?
                prioritiesIncidents.map(item => 
                    (idShow !== item.id) && 
                    <option key={item.id} className="font-small" value={item.id}>
                    {getNameIncidentsPriority(item.id)}
                </option>)
                :
                <option defaultValue key={1} className="font-small">{translate("global.noResults")}</option>
            }
        </select>
        {errors.priorityIncident && <span className="text-error">{errors.priorityIncident.message}</span> }
    </>
    )
}

export default PriorityIncidentList;