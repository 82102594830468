import { getFormatDateHour } from '../../utils/Common';
import './index.css';
const ItemPickupOnSite = ({ item, selectItem }) => {
    return (
        <tr className='orderRow' onClick={() => selectItem(item)} key={item.ID}>
            <th>{item.IdTransaccion}</th>
            <td>{getFormatDateHour(item.FechaTransaccion)}</td>
            <td>{"("+item.IdCliente + ") - " +item.NombreCliente}</td>
            <td>{item?.Direccion ?? "-"}</td>
        </tr>
    );
}

export default ItemPickupOnSite;