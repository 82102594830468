import { useContext, useState } from "react";
import { translate } from "../../../../utils/Common";
import AuthContext from "../../../../contexts/auth/index";
import "./index.css";
import TablePaginationPlatforms from "../../../../components/TablePaginationPlatforms";

// estatus de las plataformas
const StatusPlatforms = ({ statusPlatforms }) => {
  const UserContext = useContext(AuthContext);
  const [headerRow] = useState(
    <tr>
            <th className="p-1 font-xsmall border-bottom border-top text-left">
              <div className="p-0 m-0 ml-2">
                {translate("dashboard.home.status_platforms.platform")}
              </div>
            </th>
            <th className="p-1 font-xsmall border-bottom border-top text-center">
              {translate("dashboard.home.status_platforms.status")}
            </th>
            {UserContext.user?.IsAdmin && (
              <th className="p-1 font-xsmall border-bottom border-top text-left">
                {translate("dashboard.home.status_platforms.last_connection")}
              </th>
            )}
     </tr>
);

  return ( <TablePaginationPlatforms data={statusPlatforms} headerRow={headerRow} tittle={translate("dashboard.home.status_platforms.title")} filterStatus notFound={translate("dashboard.home.status_platforms.asign")}/> );
};

export default StatusPlatforms;
