import React from 'react';
import './index.css'; 

const NumberWithPercentage = ({ number }) => {
  return (
    <div className="number-with-percentage">
      <div className="plat-circle">
        <svg viewBox="0 0 36 36" className="circular-chart">
          <path className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path className="circle-fill"
            strokeDasharray={`${number} 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div className="plat-perc-number">{number}%</div>
      </div>
    </div>
  );
};

export default NumberWithPercentage;
