import { useState, useEffect } from "react";
import Spinner from "../Spinner";
import { statusOffline, statusOnline, translate } from "../../utils/Common";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import "./index.css";
import ItemStatusPlatforms from "./ItemStatusPlatforms";
import ItemPackageLockers from "./ItemPackageLockers";

const TablePaginationPlatforms = ({
  data,
  headerRow,
  tittle,
  filterStatus,
  isActivePackage,
  notFound= null
}) => {
  const [currentChunk, setCurrentChunk] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    console.log("data", data);
    filterPlatforms();
    setCurrentChunk(0);
  }, [searchValue, data, filterType]);

  const normalizeString = (str) =>
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

  const filterPlatforms = () => {
    const normalizedSearch = normalizeString(searchValue);
    let platforms = data
      ? data.filter((platform) =>
        normalizeString(platform.Plataforma).includes(normalizedSearch)
      )
      : [];

    if (filterType) {
      platforms = platforms.filter(
        (p) => p.Estatus === (filterType === "active" ? "ONLINE" : "OFFLINE")
      );
    }
    setFilteredPlatforms(platforms);
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    setSearchValue("");
  };

  const renderStatusButton = (status, count, onClick) => (
    <div className="d-flex pr-2 btnStatusPlatform" onClick={onClick}>
      <div>{status}</div>
      <strong className="pr-1">{count}</strong>
      {status === statusOnline ? "ONLINE" : "OFFLINE"}
    </div>
  );

  const renderFilterButtons = () => (
    <>
      {filterType === "active"
        ? renderStatusButton(statusOnline, getStatusCount("ONLINE"), () =>
          handleFilterChange("active")
        )
        : renderStatusButton(statusOffline, getStatusCount("OFFLINE"), () =>
          handleFilterChange("inactive")
        )}
      {filterType && (
        <div
          className="d-flex btnStatusPlatform"
          onClick={() => handleFilterChange("")}
        >
          <FaTimes style={{ cursor: "pointer" }} />
          <strong className="pr-1">{translate("global.Remove")}</strong>
        </div>
      )}
    </>
  );

  const getStatusCount = (status) =>
    data ? data.filter((p) => p.Estatus === status).length : 0;

  const renderPaginationButtons = () => (
    <>
      <button
        className="btn btn-outline-light btn-link"
        onClick={() => setCurrentChunk((prev) => Math.max(prev - 1, 0))}
        disabled={currentChunk === 0}
      >
        <FaAngleLeft />
      </button>
      <button
        className="btn btn-outline-light btn-link"
        onClick={() =>
          setCurrentChunk((prev) => Math.min(prev + 1, totalChunks - 1))
        }
        disabled={currentChunk === totalChunks - 1}
      >
        <FaAngleRight />
      </button>
    </>
  );

  const renderSearchInput = () => (
    <input
      type="text"
      className="form-control"
      placeholder={`${translate("global.search")}...`}
      style={{ fontSize: "12px" }}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );

  const renderHeader = () => (
    <div className="d-flex justify-content-between pt-2">
      <div>
        <strong className="pr-1">{data?.length || 0}</strong>
        {translate("global.platforms")}
      </div>
      {filterStatus && !isActivePackage && renderFilterButtons()}
    </div>
  );

  const renderPageInfo = () => (
    <div className="d-flex justify-content-center pt-2">
      <span>
        {translate("global.page")} {currentChunk + 1} {translate("global.of")}{" "}
        {totalChunks}
      </span>
    </div>
  );

  const totalChunks = Math.ceil(filteredPlatforms.length / 10);
  const displayedPlatforms = filteredPlatforms.slice(
    currentChunk * 10,
    (currentChunk + 1) * 10
  );

  return (
    <>
      <div className="d-flex justify-content-left pl-3">
        <h6>{tittle}</h6>
      </div>

      <div className="pl-3 pr-3 pb-3 pt-2">
        <div className="row">
          <div className="col-8">{renderSearchInput()}</div>
          <div className="col-4">{renderPaginationButtons()}</div>
        </div>
        {renderHeader()}
      </div>

      <table className="table table-borderless">
        <thead>{headerRow}</thead>
        <tbody>
          {data ? (
            displayedPlatforms.length > 0 ? (
              displayedPlatforms.map((item, index) =>
                !isActivePackage ? (
                  <ItemStatusPlatforms
                    key={`platform-${index}`}
                    item={item}
                    index={index}
                  />
                ) : (
                  <ItemPackageLockers
                    key={`package-${index}`}
                    item={item}
                    index={index}
                  />
                )
              )
            ) : (
              <tr>
                <td colSpan="3" className="p-1 font-small">
                  <span>{ notFound ?? translate("dashboard.home.status_platforms.asign")}</span>
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td className="p-1 font-xsmall td-spinner">
                <Spinner type={1} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data && renderPageInfo()}
    </>
  );
};

export default TablePaginationPlatforms;
