import GoogleMap from 'google-map-react';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ModalContext from '../../../contexts/modal';
import { getClientDomain, getToken, translate } from '../../../utils/Common';
import DestinationMarker from '../../../components/DestinationMarker';
import * as BsIcons from 'react-icons/bs';
const MyMarker = ({ text }) => <div className='customMarkerFrom'><img src="/img/logo-b.png" alt="SmartLocker" width="30px" /><p className='bg-white px-1 py-2 mt-1 border shadow rounded'>{text}</p></div>
const GoogleMapLocation = () => {
    const { idPackage, from, locName, redirect } = useParams();
    const history = useHistory();
    const modalContext = useContext(ModalContext);
    const [currentLocation,] = useState({ lat: Number(from?.split(',')[0]), lng: Number(from?.split(',')[1]), name: locName?.replace(/-/g, ' ') });
    const [center, setCenter] = useState({ lat: Number(from?.split(',')[0]), lng: Number(from?.split(',')[1]) });
    const [address, setAddress] = useState('');
    const [showAddress, setShowAddress] = useState(locName);
    const [error, setError] = useState(null);
    const [destination, setDestination] = useState(null);
    const onMapClick = async ({ lat, lng }) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBPB23k206u6hMehMV1fqCUqrh0UopfB_I`);
        const result = await response.json();
        if (result?.status === "OK") {
            setShowAddress(result.results[0].formatted_address);
            setDestination({ lat, lng });
            setCenter({ lat, lng });
        }
        else if (result?.status === "ZERO_RESULTS") setError(translate("components.showItems.results.NOT_RESULTS"));
        else setError(translate("global.error.NOT_RESULT_DIRECTION"));
    }
    const handleSearch = async () => {
        setError(null);
        if (address && address !== '') {
            let addressDest = address?.toString().trim().replace(/ /g, '+');
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${addressDest}&key=AIzaSyBPB23k206u6hMehMV1fqCUqrh0UopfB_I`);
            const result = await response.json();
            if (result?.status === "OK") {
                setCenter(result.results[0].geometry.location);
                setDestination(result.results[0].geometry.location);
                setShowAddress(result.results[0].formatted_address);
            }
            else if (result?.status === "ZERO_RESULTS") setError(translate("components.showItems.results.NOT_RESULTS"));
            else setError(translate("global.error.NOT_RESULT_DIRECTION"));
        }
        else setError(translate("global.error.NOT_VALID_DIRECTION"))
    }
    const resetInput = () => {
        setAddress('');
        setShowAddress('');
        setError(null);
        setDestination(false);
        setCenter(currentLocation);
    }
    const handleSendAddress = async () => {
        try {
            let dataToSend = {};

            dataToSend.idPaquete = idPackage;
            dataToSend.lat = destination.lat;
            dataToSend.lng = destination.lng;
            dataToSend.direccion = showAddress;

            modalContext.setIsOpenModal(false);

            const response = await fetch(`${getClientDomain(2)}/dashboard/packages/delivery/addAddress` , {
                method: 'POST',
                connection: 'keep-alive',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend)
            });

            const result = await response.json();
            if (result) {
                if (result.code === 200) {
                    if (result.payload) {
                        if (result.payload[0].Procesado === 1) {
                            modalContext.confirm(translate("global.alert.ready"), 
                                <span>{translate("dashboard.pickLocation.message.ORDER_SUCCESS")}</span>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                            history.push(`/dashboard/lockers/${redirect}`);
                        }
                        if (result.payload[0].Procesado === 0) {
                            if (result.payload[0].Mensaje === "PACKAGE_NOT_FOUND") {
                                modalContext.confirm(translate("global.alert.attention"), 
                                    <span>{translate("dashboard.pickLocation.message.PACKAGE_NOT_FOUND")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);
                                history.push(`/dashboard/lockers/${redirect}`);
                            }
                            else if (result.payload[0].Mensaje === "PACKAGE_HAS_ALREADY_DELIVERY") {
                                modalContext.confirm(translate("global.alert.attention"), 
                                    <span>{translate("dashboard.pickLocation.message.PACKAGE_HAS_ALREADY_DELIVERY")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);
                                history.push(`/dashboard/lockers/${redirect}`);
                            }
                            else if (result.payload[0].Mensaje === "ORDER_NOT_REGISTERED") {
                                modalContext.confirm(translate("global.alert.attention"), 
                                    <span>{translate("dashboard.pickLocation.message.ORDER_NOT_REGISTERED")}</span>,
                                    <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                    true);
                                history.push(`/dashboard/lockers/${redirect}`);
                            }
                        }
                    }
                }
                else if (result.error || result.code === 404 || result.code === 500) {
                    modalContext.confirm(translate("global.alerts.ops"), 
                        <span>{translate("dashboard.pickLocation.message.NOT_FOUND_ERROR")}</span>,
                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                }
            }
        } catch (error) {
            console.log(error);
            modalContext.confirm('¡Ops!', 
                <span>{translate("dashboard.pickLocation.message.REQUEST_ERROR")}</span>,
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }
    return (
        <>
            <p className='mx-0 mt-0 mb-1'>{translate("dashboard.pickLocation.description")}:</p>
            <div className={`input-group mb-${error ? '0' : '3'}`}>
                <input 
                    type="text" 
                    className='form-control form-control-sm' 
                    placeholder={translate("dashboard.pickLocation.writeAddress")}
                    onChange={(e) => setAddress(e.target.value)}
                    value={address} />
                <div className="input-group-append">
                    <button className="btn btn-sm btn-fucsia" type='button' onClick={handleSearch}>{translate("global.search")}</button>
                    <button className="btn btn-sm btn-outline-fucsia" type='button' onClick={resetInput}>{translate("global.clean")}</button>
                </div>
            </div>
            { error && <p className="mb-3 mt-1 text-danger">{error}</p> }
            <div style={{ height: '20rem', width: '100%' }}>
                <GoogleMap 
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                    center={center}
                    zoom={18}
                    defaultCenter={currentLocation}
                    defaultZoom={17}
                    onClick={onMapClick}
                >
                    <MyMarker 
                        lat={currentLocation.lat}
                        lng={currentLocation.lng}
                        text={currentLocation.name}
                    />
                    { destination &&
                        <DestinationMarker 
                            lat={destination.lat }
                            lng={destination.lng }
                            text={showAddress ? showAddress : translate("global.destination")}
                        />
                    }
                </GoogleMap>
            </div>
            { destination && 
            <div>
                <p className='mt-3 mb-1 text-success'><span className="mt-1">{translate("dashboard.pickLocation.addressSelect")}</span> <BsIcons.BsCheckCircleFill size={15}/></p>
                <p className='mt-2 mb-1'>{translate("dashboard.pickLocation.question")}</p>
                <button className="btn btn-sm btn-outline-fucsia" type='buttton' onClick={handleSendAddress}>{translate("global.accept")}</button>
            </div>
            }
        </>
    );
}

export default GoogleMapLocation;