import React, { useState, useContext } from "react";
import {
  getToken,
  setUserSession,
  getFormatDate,
  translate,
} from "../../../utils/Common";
import AuthContext from "../../../contexts/auth";
import ModalContext from "../../../contexts/modal";
import { useForm } from "react-hook-form";

// icons
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";


const FormPersonalInformation = ({
  updateSidebarInfo,
  userInformation,
  validateUpdatePwd,
  setValidateUpdatePwd,
  validateUpdatePwdLocker,
  setValidateUpdatePwdLocker
}) => {
  
  const { handleSubmit, register, errors, clearErrors } = useForm();
  const UserContext = useContext(AuthContext);
  
  const modalContext = useContext(ModalContext);
  // para validar si de verdad se quiere actualizar los datos
  const [validate, setValidate] = useState(false);
  

  // send data to server for update
  const sendUpdate = async (data) => {
    try {
      data.idCliente = userInformation.IdCliente;
      const result = await UserContext.updateInfo(data);

      if (result) {
        setValidate(false);

        switch (result) {
          case "INFO_UPDATED_SUCCESS":
            UserContext.user.Nombre = data.name.toString().toUpperCase();
            UserContext.user.NombreSimple =
              data.name.toString().toUpperCase().substring(0, 1) +
              data.name.toString().toLowerCase().substring(1, data.name.length);
            UserContext.user.Apellidos = data.lastname.toString().toUpperCase();
            UserContext.user.ApellidoSimple =
              data.lastname.toString().toUpperCase().substring(0, 1) +
              data.lastname
                .toString()
                .toLowerCase()
                .substring(1, data.lastname.length);
            setUserSession(getToken(), UserContext.user);
            updateSidebarInfo();

            // handle response information in modal
            modalContext.confirm(
              translate("dashboard.userprofile.personal.successful_update"),
              translate("dashboard.userprofile.personal.success_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-fucsia mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("dashboard.userprofile.personal.ok_button")}
              </button>,
              true
            );
            break;

          case "INVALID_PARAMS":
            modalContext.confirm(
              translate("dashboard.userprofile.personal.error_header"),
              translate("dashboard.userprofile.personal.error_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-fucsia mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("dashboard.userprofile.personal.ok_button")}
              </button>,
              true
            );
            break;

          case "USER_NOT_AUTHORIZED":
            modalContext.confirm(
              translate("dashboard.userprofile.personal.error_header"),
              translate("dashboard.userprofile.personal.error_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-fucsia mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("dashboard.userprofile.personal.ok_button")}
              </button>,
              true
            );
            break;

          default:
            // show error in modal
            modalContext.confirm(
              translate("dashboard.userprofile.personal.error_header"),
              translate("dashboard.userprofile.personal.error_message"),
              <button
                type="button"
                key="1"
                className="btn btn-sm btn-fucsia mr-2"
                onClick={() => modalContext.setIsOpenModal(false)}
              >
                {translate("dashboard.userprofile.personal.ok_button")}
              </button>,
              true
            );
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelUpdate = () => {
    clearErrors();
    setValidate(false);
  };

  return (
    <>
      <form
        className="personalInfo-form text-left"
        onSubmit={handleSubmit(sendUpdate)}
      >
        <div className="row">
          <div className="col-12 mb-0 text-left">
            <span className="business-signup-date boxit-blue">
              {userInformation
                ? `${translate(
                    "dashboard.userprofile.personal.registered_since"
                  )} ${userInformation.FechaCreacion ? getFormatDate(userInformation.FechaCreacion.substring(10, 0) ): ""  }`
                : ""}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-6 my-1">
            <label htmlFor="inputName" className="profile-subtitle">
              {translate("dashboard.userprofile.personal.name_label")}
            </label>
            <input
              defaultValue={
                UserContext.user?.NombreSimple
                  ? UserContext.user?.NombreSimple
                  : ""
              }
              disabled={!validate}
              name="name"
              type="text"
              className={
                "form-control form-control-md " +
                (errors.name ? " is-invalid" : "")
              }
              id="inputName"
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.userprofile.personal.invalid_name"
                  ),
                },
                maxLength: {
                  value: 32,
                  message: translate(
                    "dashboard.userprofile.personal.name_max_length"
                  ),
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: translate(
                    "dashboard.userprofile.personal.no_numbers_allowed"
                  ),
                },
              })}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )}
          </div>

          <div className="col-6 my-1">
            <label htmlFor="inputLastname" className="profile-subtitle">
              {translate("dashboard.userprofile.personal.lastname_label")}
            </label>
            <input
              defaultValue={
                UserContext.user?.ApellidoSimple
                  ? UserContext.user?.ApellidoSimple
                  : ""
              }
              disabled={!validate}
              name="lastname"
              type="text"
              className={
                "form-control form-control-md " +
                (errors.lastname ? " is-invalid" : "")
              }
              id="inputLastname"
              ref={register({
                required: {
                  value: true,
                  message: translate(
                    "dashboard.userprofile.personal.invalid_name"
                  ),
                },
                maxLength: {
                  value: 32,
                  message: translate(
                    "dashboard.userprofile.personal.name_max_length"
                  ),
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: translate(
                    "dashboard.userprofile.personal.no_numbers_allowed"
                  ),
                },
              })}
            />
            {errors.lastname && (
              <div className="invalid-feedback">{errors.lastname.message}</div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12 my-1">
            <label htmlFor="inputBornDate" className="profile-subtitle">
              {translate("dashboard.userprofile.personal.birthdate_label")}
            </label>
            <input
              value={
                userInformation
                  ? userInformation.FechaNacimiento
                    ? getFormatDate( userInformation?.FechaNacimiento.substring(10, 0)
                      )
                    : ""
                  : ""
              }
              disabled
              type="text"
              className="form-control"
              id="inputBornDate"
            />
          </div>

          <div className="col-md-6 col-sm-12 my-1">
            <label htmlFor="inputPhoneNumber" className="profile-subtitle">
              {translate("dashboard.userprofile.personal.phone_label")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FaIcons.FaPhoneAlt size={16} />
                </span>
              </div>
              <input
                defaultValue={userInformation ? userInformation?.Telefono : ""}
                disabled={!validate}
                name="phoneNumber"
                type="text"
                placeholder=""
                className={
                  "form-control form-control-md " +
                  (errors.phoneNumber ? " is-invalid" : "")
                }
                id="inputPhoneNumber"
                ref={register({
                  required: {
                    value: true,
                    message: translate(
                      "dashboard.userprofile.personal.invalid_phone"
                    ),
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: translate(
                      "dashboard.userprofile.personal.invalid_phone"
                    ),
                  },
                  maxLength: {
                    value: 32,
                    message: translate(
                      "dashboard.userprofile.personal.phone_max_length"
                    ),
                  },
                })}
              />
              {errors.phoneNumber && (
                <div className="invalid-feedback">
                  {errors.phoneNumber.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 my-1">
            <label htmlFor="inputEmail" className="profile-subtitle">
              {translate("dashboard.userprofile.personal.email_label")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputEmail">
                  <MdIcons.MdEmail size={18} />
                </span>
              </div>
              <input
                defaultValue={
                  UserContext.user?.Email
                    ? UserContext.user?.Email
                    : ""
                }
                disabled={!validate}
                name="email"
                type="email"
                className={
                  "form-control form-control-md " +
                  (errors.email ? " is-invalid" : "")
                }
                id="inputEmail"
                ref={register({
                  required: {
                    value: true,
                    message: translate(
                      "dashboard.userprofile.personal.invalid_email"
                    ),
                  },
                  maxLength: {
                    value: 60,
                    message: translate(
                      "dashboard.userprofile.personal.email_max_length"
                    ),
                  },
                })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>
          </div>
        </div>

        {!validateUpdatePwd && !validateUpdatePwdLocker && (
          <>
            <div className="row mb-0">
              <div className="col-12 mb-0 text-left">
                <p className="mb-0">
                  {translate(
                    "dashboard.userprofile.personal.change_password_info"
                  )}
                </p>

                <button
                  className="btn btn-sm btn-hide ml-1"
                  type="button"
                  onClick={() =>
                    modalContext.confirm(
                      translate("dashboard.userprofile.personal.attention"),
                      translate(
                        "dashboard.userprofile.personal.confirm_password_change"
                      ),
                      [
                        <button
                          type="button"
                          key="1"
                          className="btn btn-sm btn-update btn-outline-fucsia mr-2"
                          onClick={() => {
                            modalContext.confirm("", "", "", false);
                            setValidateUpdatePwd(true);
                          }}
                        >
                          {translate("dashboard.userprofile.personal.accept")}
                        </button>,
                        <button
                          type="button"
                          key="2"
                          className="btn btn-sm btn-update btn-fucsia"
                          onClick={() => {
                            modalContext.confirm("", "", "", false);
                            setValidateUpdatePwd(false);
                          }}
                        >
                          {translate("dashboard.userprofile.personal.cancel")}
                        </button>,
                      ],
                      true
                    )
                  }
                >
                  <span className="font-bold">
                    {translate("dashboard.userprofile.personal.update_password")}
                  </span>
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                {validate && (
                  <button
                    type="button"
                    className="btn btn-outline-fucsia m-2"
                    onClick={() => handleCancelUpdate()}
                  >
                    {translate("dashboard.userprofile.updatepsw.cancel")}
                  </button>
                )}
                {userInformation &&
                  (validate ? (
                    <button className="btn btn-fucsia m-2" type="submit">
                      {translate("dashboard.userprofile.personal.update")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-fucsia m-2"
                      type="button"
                      onClick={() =>
                        modalContext.confirm(
                          translate("dashboard.userprofile.personal.attention"),
                          translate(
                            "dashboard.userprofile.personal.confirmation_message"
                          ),
                          [
                            <button
                              type="button"
                              key="1"
                              className="btn btn-sm btn-update btn-outline-fucsia mr-2"
                              onClick={() => {
                                modalContext.setIsOpenModal(false);
                                setValidate(true);
                              }}
                            >
                              {translate(
                                "dashboard.userprofile.personal.accept"
                              )}
                            </button>,
                            <button
                              type="button"
                              key="2"
                              className="btn btn-sm btn-update btn-fucsia"
                              onClick={() => {
                                modalContext.setIsOpenModal(false);
                                setValidate(false);
                              }}
                            >
                              {translate(
                                "dashboard.userprofile.personal.cancel"
                              )}
                            </button>,
                          ],
                          true
                        )
                      }
                    >
                      {translate("dashboard.userprofile.personal.update")}
                    </button>
                  ))}
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default FormPersonalInformation;
