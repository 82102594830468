import React, { useState } from 'react';
import {getClientDomain, translate} from '../../../utils/Common';
import Collapse from 'react-bootstrap/Collapse';
import ReactPlayer from "react-player"
import './index.css';

const Question = ({ question, answers, youtubeId }) => {
    // para manejar los collapse
    const [openQuestion1, setOpenQuestion1] = useState(false);

    return <div className="col-12 col-md-4 p-2">
                <div className="p-2 question-container">
                    <div className="text-left ml-1 questions" onClick={ () => setOpenQuestion1(!openQuestion1) } >
                        <span  
                            className="mb-1 font-bold"
                            aria-expanded={openQuestion1} 
                            aria-controls="collapseQuestion1" >
                            { question }
                        </span>
                    </div>
                    <Collapse in={openQuestion1}>
                        <div id="collapseQuestion1" className="text-left my-1 ml-1">
                            { answers?.map((answer, index) => <p className="m-0 p-0 mb-2" key={ index }>{ answer }</p>) }
                            { youtubeId && 
                                <div className="mb-2 video-responsive">
                                    <ReactPlayer 
                                        url={`https://www.youtube.com/watch?v=${ youtubeId }`}
                                        width="100%"
                                        height="100%" />
                                </div> 
                            }
                        </div>
                    </Collapse>
                </div>
            </div>
}

const TerminosYCondiciones = () => {
    const [openTerminos, setOpenTerminos] = useState(false);
    const getTerminosCondiciones = async () => {
        try {
            const response = await fetch(`${getClientDomain()}/files/pdf/terminos`, {
                method: 'GET',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            });

            const blob = await response.blob();
            if (blob) {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',`${translate("dashboard.faqs.termsCondition.pdf.name")}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.log('error 1:',error);
        }
    }

    return (
        /* Terminos y condiciones */
        <div className="col-12 col-md-4 p-2">
        <div className="p-2 question-container">
            <div className="text-left ml-1">
                <span onClick={ () => setOpenTerminos(!openTerminos) } 
                    className="questions mb-1 font-bold"
                    aria-expanded={openTerminos} 
                    aria-controls="collapseTerminos" >
                    {translate("dashboard.faqs.termsCondition.pdf.description")}
                </span>
            </div>
            <Collapse in={openTerminos}>
                <div id="collapseTerminos" className="text-left my-1 ml-1">
                    <p className="m-0 p-0 mb-2" >{translate("dashboard.faqs.termsCondition.pdf.access")}:</p>
                    <p className="m-0 p-0 mb-2 questions" onClick={ () => getTerminosCondiciones() } style={{color:"#424996"}}>{translate("global.termsConditions")}</p>
                </div>
            </Collapse>
        </div>
    </div>
    );
}

const Faqs = () => {
    const serverQuestions = [
        {
            question: translate("dashboard.faqs.que_es_bli"),
            answers: [translate("dashboard.faqs.que_es_bli_answer")]
        },
        {
            question: translate("dashboard.faqs.como_funciona_bli"),
            answers: [translate("dashboard.faqs.como_funciona_bli_answer")]
        },
        {
            question: translate("dashboard.faqs.como_retira_paquete_cliente"),
            answers: [
                translate("dashboard.faqs.como_retira_paquete_cliente_answer_1"),
                translate("dashboard.faqs.como_retira_paquete_cliente_answer_2"),
                translate("dashboard.faqs.como_retira_paquete_cliente_answer_3"),
                translate("dashboard.faqs.como_retira_paquete_cliente_answer_4"),
                translate("dashboard.faqs.como_retira_paquete_cliente_answer_5")
            ]
        },
        {
            question: translate("dashboard.faqs.tiempo_entregar_paquete_reserva_puerta"),
            answers: [translate("dashboard.faqs.tiempo_entregar_paquete_reserva_puerta_answer")]
        },
        {
            question: translate("dashboard.faqs.tiempo_retirar_paquete_locker"),
            answers: [
                translate("dashboard.faqs.tiempo_retirar_paquete_locker_answer_1"),
                translate("dashboard.faqs.tiempo_retirar_paquete_locker_answer_2")
            ]
        },
        {
            question: translate("dashboard.faqs.problema_retirar_paquete_cliente"),
            answers: [
                translate("dashboard.faqs.problema_retirar_paquete_cliente_answer_1"),
                translate("dashboard.faqs.problema_retirar_paquete_cliente_answer_2"),
                translate("dashboard.faqs.problema_retirar_paquete_cliente_answer_3"),
                translate("dashboard.faqs.problema_retirar_paquete_cliente_answer_4")
            ]
        },
        {
            question: translate("dashboard.faqs.carga_masiva"),
            answers: [translate("dashboard.faqs.carga_masiva_answer")]
        },
        {
            question: translate("dashboard.faqs.como_hacer_carga_masiva_paquetes"),
            answers: [
                translate("dashboard.faqs.como_hacer_carga_masiva_paquetes_answer_1"),
                translate("dashboard.faqs.como_hacer_carga_masiva_paquetes_answer_2"),
                translate("dashboard.faqs.como_hacer_carga_masiva_paquetes_answer_3"),
                translate("dashboard.faqs.como_hacer_carga_masiva_paquetes_answer_4")
            ]
        },
        {
            question: translate("dashboard.faqs.dimensiones_casilleros_boxits"),
            answers: [
                translate("dashboard.faqs.dimensiones_casilleros_boxits_answer_1"),
                translate("dashboard.faqs.dimensiones_casilleros_boxits_answer_2"),
                translate("dashboard.faqs.dimensiones_casilleros_boxits_answer_3"),
                translate("dashboard.faqs.dimensiones_casilleros_boxits_answer_4")
            ]
        },
        {
            question: translate("dashboard.faqs.ubicaciones_boxits"),
            answers: [
                translate("dashboard.faqs.ubicaciones_boxits_answer_1"),
                translate("dashboard.faqs.ubicaciones_boxits_answer_2"),
                translate("dashboard.faqs.ubicaciones_boxits_answer_3"),
                translate("dashboard.faqs.ubicaciones_boxits_answer_4"),
                translate("dashboard.faqs.ubicaciones_boxits_answer_5"),
                translate("dashboard.faqs.ubicaciones_boxits_answer_6"),
                translate("dashboard.faqs.ubicaciones_boxits_answer_8")
            ],
            youtubeId: "laj7DRPWrtk"
        },
        {
            question: translate("dashboard.faqs.como_retirar_paquete_boxit"),
            answers: [translate("dashboard.faqs.como_retirar_paquete_boxit_answer")],
            youtubeId: "A2YsJ5tPhXc"
        },
        {
            question: translate("dashboard.faqs.paquete_entregado"),
            answers: [translate("dashboard.faqs.paquete_entregado_answer")],
            youtubeId: "t1nJEb9V-c8"
        },
        {
            question: translate("dashboard.faqs.metodos_pago_disponibles"),
            answers: [translate("dashboard.faqs.metodos_pago_disponibles_answer")],
            youtubeId: "-YwQDhRfvdk"
        }
    ];

    return  <div className="faqs-container">
                <div
                    id="cover-faqs"
                    className="text-center d-flex mx-auto flex-column py-4"
                >
                    <h1 className="faqs-title">{translate("dashboard.faqs.title")}</h1>
                    <p className="lead font-small">
                        {translate("dashboard.faqs.question")}
                    </p>
                </div>

                <div className="faqs-content">
                    <div className="row m-2">
                        { serverQuestions?.map((faq, index) => <Question key={ index } question={ faq.question } answers={ faq.answers } youtubeId={ faq?.youtubeId } />) }

                        <TerminosYCondiciones />
                    </div>
                </div>
            </div>
}

export default Faqs;