import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import "./index.css";
import { translate } from "../../../../utils/Common";

const PopUp = () => {
  const [show, setShow] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    const modalClosed = localStorage.getItem("popUpClosedLlego");
    if (!modalClosed) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("popUpClosedLlego", "true");
    }
    setShow(false);
  };

  const handleCheckboxChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        <h6 className="text-left" style={{color:"black"}}>{translate("global.popUps.llegoAlert.title")} </h6>
        </Modal.Header>
      <Modal.Body >
        <div >
          <p className="text-left">
            {translate("global.popUps.llegoAlert.description1")}  {" "} <a href="https://www.loquepedi.com/"  onClick={handleClose}><u>www.loquepedi.com</u></a>   {translate("global.popUps.llegoAlert.description2")}
          </p>

          <div className="d-flex align-items-center" style={{border:"1px solid #dadada", borderRadius:"16px", padding:"5px"}}>
            <img src="./img/logo_llego.png" alt="logo_llego" width={"150px"} />
               
          <p className="text-left">
            <strong style={{color:"black"}}>Llegó Lo que pedí</strong> 
            {translate("global.popUps.llegoAlert.description3")}
          </p>
          </div>

      
        <Form.Group controlId="formBasicCheckbox" className="mt-3 text-left">
            <Form.Check 
              type="checkbox" 
              label={translate("global.dontShowAgain")}
              onChange={handleCheckboxChange} 
            />
          </Form.Group>

          <div style={{display:"flex", justifyContent:"center",gap:"6px"}}>
          <a
            href="https://www.loquepedi.com/"
          className="btn btn-outline-blue"
          style={{ borderRadius: "50px" }}
          onClick={handleClose}
        >
          {translate("global.enterHere")}
        </a>
        <button
          className="btn btn-blue"
          style={{ borderRadius: "50px" }}
          onClick={handleClose}
        >
          {translate("global.close")}
        </button>
          </div>
          
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUp;
