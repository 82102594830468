import React from 'react';
import {translate} from "../../utils/Common"

const NotFound = (props) => {
    return (
        <div className="login signup-container d-flex align-items-center justify-content-center"   style={{
            backgroundImage: "url('/img/pattern-boxit2.png')",
            backgroundSize: "cover",
          }}>
            <div className="container text-center text-light">
                <h1 style={{color:"rgb(147, 92, 162)"}}>:( Error 404</h1>
                <p className="font-small" style={{color:"rgb(147, 92, 162)"}}>{translate("global.error404")}</p>
            </div>
        </div>
    );
}

export default NotFound;