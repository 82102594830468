import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {BsCheckLg} from "react-icons/bs"
import "./index.css";

// icons

// components
import Header from "../../../components/Landing/Header";
import Footer from "../../../components/Landing/Footer/Footer";

export default function Plans() {


  return (
    <>
      <Header />
      <div
        style={{
          backgroundImage: "url('/img/pattern-boxit2.png')",
          backgroundSize: "cover",
          borderTop: "1px solid rgba(233, 233, 233, 0.4)",
          paddingBottom: "25px",
        }}
      >
        <div className="section-pricing">
          <div className="d-flex justify-content-center flex-column">
            <h1
              style={{ textAlign: "center", marginBottom: "25px" }}
              className="tittle-pricing"
            >
              ¡Únete a la comunidad BLI!
            </h1>

            <div className="row card-pricing">
              <div
                className="img-pricing col-lg-6 col-md-6"
                style={{
                  backgroundImage: "url('img/package_delivery_frame.png')",
                  backgroundSize: "cover",
                  borderTop: "1px solid rgba(233, 233, 233, 0.4)",
                  padding: "25px",
                }}
              ></div>

              <div className="col-lg-6 col-md-6 sect-details">
                <h4 className="tittle-pricing">Plan Basic</h4>
                <div className="d-flex align-items-center cost-pricing">
                  <div
                    className="d-flex align-items-center"
                    style={{ color: "#935CA2" }}
                  >
                    <span>$</span>
                    <h3>1.00</h3>
                  </div>{" "}
                  <span style={{ marginLeft: "5px", color: "#39A98A3" }}>
                    {" "}
                    /por uso de puerta
                  </span>
                </div>

                <hr style={{ background: "#E7E7E7", marginBottom: "25px" }} />

                <div className="description-pricing">
                  <div className="d-flex">
                    <div className="mr-3"><BsCheckLg className="icon-check-1"/></div>
                    <div className="">
                      Crear tu cuenta de afiliado es totalmente{" "}
                      <strong>GRATIS</strong>{" "}
                    </div>
                  </div>
                </div>

                <div className="description-pricing">
                  <div className="d-flex">
                    <div className="mr-3"><BsCheckLg className="icon-check-2"/></div>
                    <div>
                       Acceso inmediato a toda nuestra red y sistema de gestión BLI
                    </div>
                  </div>
                </div>

                <div className="description-pricing">
                  <div className="d-flex">
                    <div className="mr-3"><BsCheckLg className="icon-check-1"/></div>
                    <div>
                      Cada entrega realizada a cualquier puerta Boxit tiene
                      un costo de <strong>$1.00</strong>
                    </div>
                  </div>
                </div>

                <div className="description-pricing">
                  <div className="d-flex">
                    <div className="mr-3"><BsCheckLg className="icon-check-2"/></div>
                    <div>
                       Retiros disponibles hasta 48 horas
                    </div>
                  </div>
                </div>

                <Link to={"/registrar"}>
                  <button className="btn-pricing">Empezar ahora</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
