import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthContext from "../../../../contexts/auth";
import ModalContext from "../../../../contexts/modal";
import {getToken, getClientDomain, translate} from "../../../../utils/Common";
// icons
import * as BsIcons from "react-icons/bs";

const DropOffPackageFound = ({ dropOffPackage, setDropOffPackage }) => {
    const { register, handleSubmit } = useForm();
    const [messageResult, setMessageResult] = useState('');
    const [receiveValidate, setReceiveValidate] = useState(false);
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext)

    const receivePackage = async (data) => {
        try {
            setMessageResult('');
            if (userContext.user?.IdCliente) {
                data.idCliente = Number(userContext.user?.IdCliente);
                data.idPaquete = Number(data.idPaquete);

                if (((dropOffPackage?.IdPlataforma === userContext.user?.IdPlataforma) && !userContext.user?.IsAdmin) || userContext.user?.IsAdmin) {
                    const response = await fetch(`${getClientDomain(2)}/dashboard/packages/receive` , {
                        method: 'POST',
                        connection: 'keep-alive',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${getToken()}`
                        },
                        body: JSON.stringify(data)
                    });
    
                    const result = await response.json();
                    if (result.code === 200) {
                        if (result.payload?.length > 0) {
                            switch (result.payload[0].Mensaje) {
                                case 'PACKAGE_NOT_REGISTERED':
                                    setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.PACKAGE_NOT_REGISTERED"));
                                    break;
                                case 'PLATFORM_INCORRECT':
                                    setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.PLATFORM_INCORRECT"));
                                    break;
                                case 'PACKAGE_HAS_ALREADY_RECEIVE':
                                    setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.PACKAGE_HAS_ALREADY_RECEIVE"));
                                    break;
                                case 'PACKAGE_RECEIVED_SUCCESSFULLY':
                                    setDropOffPackage(null);
                                    setDropOffPackage(null);
                                    completeReceivePackage();
                                    break;
                                default:
                                    break;
                            }
                        }
                        else {
                            setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.SEARCH_ERROR"));
                        }
                    }
                    else {
                        setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.SEARCH_ERROR"));
                    }
                }
                else {
                    setReceiveValidate(false);
                    setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.SUCURSAL_ERROR"));
                }
            }
            else {
                setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.SESSION_ERROR"));
            }
        } catch (error) {
            console.log(error);
            setMessageResult(translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"));
        }
    }

    const confirmReceive = (data) => {
        modalContext.confirm(
            translate("global.alert.attention"),
            translate("dashboard.packages.details.dropOff.confirmReceive.questions"),
            [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => { modalContext.setIsOpenModal(false); receivePackage(data); } }>{translate("global.yes")}</button>,
            <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ], true )
    }

    const completeReceivePackage  = () => {
        modalContext.confirm(
            translate("login.resetpwd.success.title"),
            translate("dashboard.packages.details.dropOff.receivePackage.PACKAGE_RECEIVED_SUCCESSFULLY"),
            [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => { modalContext.setIsOpenModal(false) } }>{translate("global.OK")}</button> ], true )
        };

    const getStatusPackage = (value) => {
        switch (value) {
            case 1:
                return <span className="text-warning font-bold">{translate("dashboard.packages.details.dropOff.status.toBeAssigned")}</span>
            case 2:
                return <span className="text-info font-bold">{translate("dashboard.packages.details.dropOff.status.assigned")}</span>
            case 3:
                return <span className="text-warning font-bold">{translate("dashboard.packages.details.dropOff.status.boxit")}</span>
            case 4:
                return <span className="text-success font-bold">{translate("dashboard.packages.details.dropOff.status.delivered")}</span>
            case 5:
                return <span className="text-warning font-bold">{translate("global.status.returned")}</span>
            case 6:
                return <span className="text-danger font-bold">{translate("dashboard.packages.details.dropOff.status.annulled")}</span>
        
            default:
                break;
        }
    }

    useEffect(() => {
        if (((dropOffPackage?.IdEstatus !== 1 && dropOffPackage?.IdEstatus !== 2) && !userContext.user?.IsAdmin) || ((dropOffPackage?.IdEstatus === 4 && dropOffPackage?.IdEstatus === 6) && userContext.user?.IsAdmin)) {
            setMessageResult(translate("dashboard.packages.details.dropOff.statusIncorrect"));
            setReceiveValidate(false);
        }
        else {
            if ((dropOffPackage?.IdPlataforma !== userContext.user?.IdPlataforma) && !userContext.user?.IsAdmin) {
                setMessageResult(translate("dashboard.packages.details.dropOff.changeSucursal"));
                setReceiveValidate(false);
            }
            else {
                setReceiveValidate(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <form className="dashboardCard p-0" onSubmit={ handleSubmit(confirmReceive) }>
            <div className="form-row mx-0 mb-1 titleDropOffPackage">
                <h6 className="my-1"><BsIcons.BsBoxSeam size={ 18 } className="mx-2" />{translate("dashboard.packages.details.infoPackage")}</h6> 
            </div>
            
            <div className="form-row mx-3">
                <div className="form-group col-4 col-md-2 mb-2">
                    <label className="mb-0" htmlFor="inputIdPaquete">ID Boxit:</label>
                    <input type="text" value={ dropOffPackage?.IdPaquete ? dropOffPackage?.IdPaquete : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputIdPaquete" name="idPaquete" ref={ register }/>
                </div>

                <div className="form-group col-8 col-md-5 mb-2">
                    <label className="mb-0" htmlFor="inputNumeroPaquete">{ translate("global.numPackages")}:</label>
                    <input type="text" value={ dropOffPackage?.Nro_Id ? dropOffPackage?.Nro_Id : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputNumeroPaquete"/>
                </div>

                <div className="form-group col-12 col-md-5 mb-2">
                    <label className="mb-0" htmlFor="inputNumeroTracking">{ translate("global.numTracking")}:</label>
                    <input type="text" value={ dropOffPackage?.NumeroTracking ? dropOffPackage?.NumeroTracking : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputNumeroTracking"/>
                </div>
            </div>

            <div className="form-row mx-3">
                <div className="form-group col-4 col-md-3 mb-2">
                    <label className="mb-0" htmlFor="inputCliente">{ translate("global.client")}</label>
                    <input type="text" value={ dropOffPackage?.Cliente ? dropOffPackage?.Cliente : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputCliente"/>
                </div>

                <div className="form-group col-8 col-md-5 mb-2">
                    <label className="mb-0" htmlFor="inputEmail">{translate("global.email")}:</label>
                    <input type="text" value={ dropOffPackage?.Email ? dropOffPackage?.Email : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputEmail"/>
                </div>

                <div className="form-group col-12 col-md-4 mb-2">
                    <label className="mb-0" htmlFor="inputPlataforma">{translate("global.ubication")}:</label>
                    <input type="text" value={ dropOffPackage?.Plataforma ? dropOffPackage?.Plataforma : '' } readOnly className="form-control-sm font-small form-control font-bold" id="inputPlataforma"/>
                </div>
            </div>

            <div className="form-row mx-3">
                <div className="form-group col-4 col-md-3 mb-2">
                    <label className="mb-0" htmlFor="inputPrecio">{translate("global.price")}</label>
                    <input type="text" value={ dropOffPackage?.Precio !== null ? `$ ${dropOffPackage?.Precio}` : '' } readOnly className="form-control-sm font-small form-control-plaintext font-bold" id="inputPrecio"/>
                </div>

                <div className="form-group col-8 col-md-9 mb-2">
                    <p className="mb-1" >{translate("global.status")}: </p>
                    { dropOffPackage?.IdEstatus && getStatusPackage(dropOffPackage?.IdEstatus) }
                </div>
            </div>

            <div className="form-row mx-3">
                <div className="form-group text-center col-12 mb-2">
                    <p className="text-danger mb-1">{ messageResult }</p>
                    { receiveValidate && <button className="btn btn-sm btn-blue m-2" type="submit" >{translate( "sidebar.options.counter.dropoff")}</button> }
                    <button className="btn btn-sm btn-outline-blue m-2" type="button" onClick={ () => setDropOffPackage(null) }>{translate( "global.cancel")}</button>
                </div>
            </div>
        </form>
    );
}

export default DropOffPackageFound;