import { FaBox, FaUndo } from "react-icons/fa";
import {translate, setStatusName, setStatusTypePackageName} from "../../utils/Common"
const ItemPackage = ({ index, showBox, item, setSelectedItem }) => { 
    const handleEstatus = (item) => {
        let estatus = '';
        if (item.IdEstatus === 1) estatus = "text-warning";
        else if(item.IdEstatus === 2) estatus = "text-info";
        else if(item.IdEstatus === 3) estatus = "text-warning";
        else if(item.IdEstatus === 4) estatus = "text-success";
        else if(item.IdEstatus === 5) estatus = "text-warning";
        else if(item.IdEstatus === 6) estatus = "text-danger";

        return <span className={ estatus + " font-bold" }>{ setStatusName(item.IdEstatus) }</span>
    }

    return (
    <div className="col-6 col-lg-3 p-2 item-found">
        <div className="border py-2 px-2 item-inside item-search text-left font-xsmall" onClick={ () => setSelectedItem(item) }>
            <p className="mb-0 font-bold text-left">Nro. { index + 1 }</p>
            { showBox &&
                <div id="box">
                    <img src="/img/box.png" alt="boxit-box" width="80px" />
                </div>
            }
            <div className="m-1 text-break">
                <p className="mb-0 font-bold text-center">{translate("global.numPackage")}: </p>
                <p className="m-1 mb-0 font-bold p-1 text-center nroPackageFound-blue">{ item.Nro_Id ? item.Nro_Id : '' }</p>
              
                <p className="mb-0"><span className="font-bold">{translate("global.numTracking")}:</span> { item.NumeroTracking ? item.NumeroTracking : '' }</p>
                <p className="mb-0 font-bold">{translate("global.client")}: 
                    <span className="ml-1 boxit-fucsia">
                        { item.IdTipoUsuario !== 1 ?
                            (item.Email)
                            :
                            (`${item.Cliente} (B${item.IdCliente})`)
                        }
                    </span>
                </p>
                <p className="mb-0 font-bold">{translate("global.status")}: { item.IdEstatus ? handleEstatus(item) : '' }</p>
                <p className="mb-2 mt-2"><span className="font-bold">{translate("global.typeOfPackage")}:</span> <span className="ml-1" style={{background: item?.IdTipoPaquete!== 3 ? "#f26c3f" : "#4abfee", padding:"4px 6px", color:"white", borderRadius:"15px"}}> {item?.IdTipoPaquete!== 3 ? <><FaBox className="mr-1"/>{translate("global.status.typePackage.regular")}</> : <><FaUndo className="mr-1"/>{translate("global.status.typePackage.returned")}</>}</span></p>
                <p className="mb-0 font-bold text-center border-bottom">{translate("global.ubication")}:</p>
                <p className="mb-0 font-small font-bold text-center">{ item.Plataforma ? item.Plataforma : "" }</p>
            </div>
        </div>
    </div>
    );
}

export default ItemPackage;