import { useState } from "react";
// import { BsTranslate } from "react-icons/bs";
import {MdGTranslate} from "react-icons/md"
import { FaAngleDown } from "react-icons/fa";
import "./index.css";
import { translate } from "../../utils/Common";

const TraductorList = () => {
  const langStorage = localStorage.getItem("language");
  const [language, setLanguage] = useState(langStorage);
  const [modalLanguages, setModalLanguages] = useState(false);

  const handleModalLanguage = () => {
    setModalLanguages((prevState) => !prevState);
  };

  const handleSelectLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", (lang));
    setModalLanguages((prevState) => !prevState);
    window.location.reload();
  };

  return (
    <>
      {/* Traductor List */}
      <div className={`d-flex align-items-center btn-traductor visible`} onClick={handleModalLanguage}>
        <MdGTranslate color="#424996" className="mr-2"/>
        <span className="mr-1 name-translate">{language.toUpperCase()}</span>
        <FaAngleDown color="#424996"/>
      </div>

    {/* Modal Traductor */}
    <div className={`modal-custome ${modalLanguages ? "visible" : "invisible"}`}>
        <h3>{translate("translate.title")}</h3>
        <div className="modal-custome-content">
            <div className="modal-custome-card" onClick={()=>{handleSelectLanguage("en")}}> 
              <div style={{backgroundImage:"url(/img/usa_flag.png)", backgroundSize: 'cover', width: 70, height: 70, marginBottom:"15px"}}/>
              <span>{translate("translate.en")}</span>
            </div>
            <div className="modal-custome-card" onClick={()=>{handleSelectLanguage("es")}}>
            <div style={{backgroundImage:"url(/img/es_flag.png)", backgroundSize: 'cover', width: 70, height: 70, marginBottom:"15px"}}/>
              <span>{translate("translate.es")}</span>
            </div>
            <div className="modal-custome-card" onClick={()=>{handleSelectLanguage("pt")}}> 
            <div style={{backgroundImage:"url(/img/br_flag.png)", backgroundSize: 'cover', width: 70, height: 70, marginBottom:"15px"}}/>
              <span>{translate("translate.pt")}</span>
            </div>
        </div>
      </div>

    </>
  );
};

export default TraductorList;
