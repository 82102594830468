import React, { useContext, useState } from "react";
import { TbFlag3 } from "react-icons/tb";
import "./index.css";
import {
  getFlagPriorityIncident,
  getFormatDate,
  getIconStatusIncident,
  getNameIncidentsPriority,
  getNameIncidentsStatus,
  getNameIncidentsTypes,
  translate,
} from "../../utils/Common";
import AuthContext from "../../contexts/auth";
import { Link } from 'react-router-dom';

const ItemIncident = ({ item }) => {
  
  const userContext = useContext(AuthContext);
  const isAdmin = userContext.user?.IsAdmin;
  
  const [showName, setShowName] = useState(false);

  return (
    <tr
      className="orderRow"
      key={item.IdIncidents}
      onMouseEnter={() => setShowName(true)}
      onMouseLeave={() => setShowName(false)}
      style={{ position: "relative" }}
    >
      <td># {item?.IdIncidents}</td>
      {isAdmin && <td>{item?.NameClient}</td>} 
      <td>{getNameIncidentsTypes(item.IdType)}</td>
      <td>
        {getFlagPriorityIncident(item.IdPriority)}
        <span className={`flag-name ${showName ? "show-name" : ""}  ml-2`}>
          {getNameIncidentsPriority(item.IdPriority)}
        </span>
      </td>
      <td><span className="pr-1">{getIconStatusIncident(item.IdStatus)}</span><span>{getNameIncidentsStatus(item.IdStatus)}</span></td>
      <td>{getFormatDate(item.DateIncident || "")}</td>
      <td><Link to={`/dashboard/incidents/${item.IdIncidents}`}>
      <button className="btn btn-fucsia">{translate("global.seeMore")}</button>
    </Link></td>
    </tr>
  );
};

export default ItemIncident;
