import Spinner from '../../../../components/Spinner';
import Tooltip from '../../../../components/Tooltip'
import { translate } from '../../../../utils/Common';

// paquetes en boxits vencidos por retirar
const MoneyStatCard = ({ value, cardTitle, tooltipMessage }) => {

    return (
        (value || value === 0) ?
            <>
                <h4 className="p-0 m-0 text-bold">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) }</h4>
                <div className="d-flex flex-inline">
                    <p className="p-0 m-0">{ cardTitle ? cardTitle : translate("global.packages_expired.title") }</p>
                    <Tooltip  className="mx-1"
                        info={ 
                            tooltipMessage ? tooltipMessage : translate("dashboard.insights.moneyStat.message")
                        } />
                </div>
            </>
            :
            <Spinner type={ 1 }/>
    );    
}

export default MoneyStatCard;